import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { None } from "../../hat/None";
import { Hoodie } from "../../hat/woman/Hoodie";
import { Woman } from "../../type/Woman";
import { ZombieWoman } from "../../type/ZombieWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Middle Hair";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const MiddleHair: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(MiddleHair, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 9H7V11H6V12H5V17H6V18H7V15H6V13H8V12H9V11H16V12H17V13V18H18V17H19V12H18V11H17V9Z"
        fill={hairColor}
      />
    </svg>
  );
};

MiddleHair.layerType = LAYER_TYPE;
MiddleHair.gender = GENDER;
MiddleHair.allowSemiTransparentColors = false;
MiddleHair.title = TITLE;
MiddleHair.key = KEY;
MiddleHair.isExtension = true;
MiddleHair.paletteNames = PALETTE_NAMES;
MiddleHair.whitelist = [Woman, ZombieWoman];
MiddleHair.blacklist = [None, Hoodie]
