import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Meadow";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Meadow: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="18" width="24" height="6" fill="#58DD85" />
      <rect y="17" width="24" height="1" fill="black" />
      <rect width="24" height="17" fill="#6AC2FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 5H21V6H19V8H20V9H23V8H24V6V5ZM0 9H2V10H3V12H0V10V9Z"
        fill="white"
      />
    </svg>
  );
};

Meadow.layerType = LAYER_TYPE;
Meadow.gender = GENDER;
Meadow.title = TITLE;
Meadow.key = KEY;
Meadow.paletteNames = PALETTE_NAMES;
