import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";

import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "Plain";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Glass";
const PALETTE_NAME_2: PaletteName = "Frame";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const Plain1px: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Plain1px, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Plain1px, PALETTE_NAME_2).colors;
  }, [palettes]);
  const glassesColor = useMemo(() => palette1[0], [palette1]);
  const frameColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11H9V14H12V11ZM16 11H13V14H16V11Z"
        fill={glassesColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 11H6V12H9V11ZM13 11H12V12H13V11Z"
        fill={frameColor}
      />
    </svg>
  );
};

Plain1px.layerType = LAYER_TYPE;
Plain1px.gender = GENDER;
Plain1px.title = TITLE;
Plain1px.key = KEY;
Plain1px.paletteNames = PALETTE_NAMES;
Plain1px.whitelist = [AlienMan, ApeMan];
