import { LayerFC } from "../../types/LayerFC";
import { Bob } from "./Bob";
import { Braid } from "./Braid";
import { Bun } from "./Bun";
import { Dreads } from "./Dreads";
import { Long1 } from "./Long1";
import { Long10 } from "./Long10";
import { Long11 } from "./Long11";
import { Long12 } from "./Long12";
import { Long13 } from "./Long13";
import { Long2 } from "./Long2";
import { Long3 } from "./Long3";
import { Long4 } from "./Long4";
import { Long5 } from "./Long5";
import { Long6 } from "./Long6";
import { Long7 } from "./Long7";
import { Long8 } from "./Long8";
import { Long9 } from "./Long9";
import { LongBraid1 } from "./LongBraid1";
import { LongBraid2 } from "./LongBraid2";
import { LongCurl } from "./LongCurl";
import { Middle } from "./Middle";
import { Mohawk } from "./Mohawk";
import { Normal } from "./Normal";
import { Pigtails } from "./Pigtails";
import { PigtailsS } from "./PigtailsS";
import { Rasta } from "./Rasta";
import { Short } from "./Short";
import { ShortCurl } from "./ShortCurl";
import { Side1 } from "./Side1";
import { Side2 } from "./Side2";
import { SideWild } from "./SideWild";
import { SpaceBuns } from "./SpaceBuns";
import { SuperShort } from "./SuperShort";
import { Untreated } from "./Untreated";
import { Upturned } from "./Upturned";
import { Wild } from "./Wild";
import { WildBun } from "./WildBun";
import { Wreath } from "./Wreath";

export const hairWomanLayers: LayerFC[] = [
    Mohawk,
    SuperShort,
    ShortCurl,
    Short,
    Upturned,
    Long1,
    Long2,
    Long3,
    Long4,
    Long5,
    Long9,
    Long6,
    Long7,
    Long8,
    Long11,
    Long12,
    Side1,
    Side2,
    SideWild,
    Long10,
    Middle,
    Long13,
    Wild,
    Normal,
    LongCurl,
    Bob,
    Untreated,
    LongBraid1,
    LongBraid2,
    Braid,
    Bun,
    SpaceBuns,
    Pigtails,
    PigtailsS,
    WildBun,
    Dreads,
    Wreath,
    Rasta,
];
