import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Beenie M";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hat";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const BeenieM: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(BeenieM, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hatColor = useMemo(() => palette1[0], [palette1]);
  const secondaryhatColor = useMemo(() => palette1[1], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6H15V7H9V6ZM8 8V7H9V8H8ZM7 9V8H8V9H7ZM7 9L7 11H6V9H7ZM16 8H15V7H16V8ZM17 9V8H16V9H17ZM17 9V11H18V9H17Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7H15V8H16V9H17V11H7V9H8V8H9V7Z"
        fill={hatColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 9H7V10V11H8V10H9V11H10V10H11V11H12V10H13V11H14V10H15V11H16V10H17V9Z"
        fill={secondaryhatColor}
      />
    </svg>
  );
};

BeenieM.layerType = LAYER_TYPE;
BeenieM.gender = GENDER;
BeenieM.title = TITLE;
BeenieM.key = KEY;
BeenieM.paletteNames = PALETTE_NAMES;
