import { Man } from "../../type/Man";
import { ZombieMan } from "../../type/ZombieMan";
import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "Shades L";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}-2px`;
const PALETTE_NAMES: PaletteName[] = [];

export const ShadesL2px: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 11H6V12H8V13V14H9V15H11V14H12V13H14V14H15V15H17V14H18V13V12V11Z"
        fill="black"
      />
    </svg>
  );
};

ShadesL2px.layerType = LAYER_TYPE;
ShadesL2px.gender = GENDER;
ShadesL2px.title = TITLE;
ShadesL2px.key = KEY;
ShadesL2px.paletteNames = PALETTE_NAMES;
ShadesL2px.whitelist = [Man, ZombieMan];
