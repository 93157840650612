import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Type";
const GENDER: Gender = "Man";
const TITLE = "Alien";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Alien Skin";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const AlienMan: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(AlienMan, PALETTE_NAME_1).colors;
  }, [palettes]);
  const skinColor = useMemo(() => palette1[0], [palette1]);
  const secondarySkinColor = useMemo(() => palette1[1], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6H8V7H7V12H6H5V13H6V14H7V20V24H10V21H15V20H16V7H15V6Z"
        fill={skinColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 11H11V12H10V11ZM10 12V13H9V12H10ZM15 12H14V13H15V12ZM15 12V11H16V12H15ZM6 12H7V13H6V12ZM13 14H12V17H13V14Z"
        fill={secondarySkinColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5H15V6H8V5ZM7 7V6H8V7H7ZM5 12V11H6V7H7V12H6H5ZM5 13H4V12H5V13ZM6 14H5V13H6V14ZM6 14H7V24H6V14ZM16 7H15V6H16V7ZM16 20H17V7H16V12H15V11H14V12H15V13H16V20ZM15 21V20H16V21H15ZM15 21V22H11V24H10V21H11H15ZM9 11H10V12H9V11ZM10 12H11V13H10V12Z"
        fill="black"
      />
    </svg>
  );
};

AlienMan.layerType = LAYER_TYPE;
AlienMan.gender = GENDER;
AlienMan.title = TITLE;
AlienMan.key = KEY;
AlienMan.paletteNames = PALETTE_NAMES;
