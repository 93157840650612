import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Middle";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Middle: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Middle, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 4H7V5H6V6H5V8H4V11H3V14H2V15H3H4V16H3V18H2V19H3H4V20H5V22H6V14H5V12H6V11H7V10H8V8H9V7H15V8H16V10H17V18H18V16H19V15H18V7H17V5H16V4Z"
        fill={hairColor}
      />
    </svg>
  );
};

Middle.allowSemiTransparentColors = false;
Middle.layerType = LAYER_TYPE;
Middle.gender = GENDER;
Middle.title = TITLE;
Middle.key = KEY;
Middle.paletteNames = PALETTE_NAMES;
Middle.blacklist = [ApeMan, AlienMan]
