import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Man";
const TITLE = "Big Chain";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Jewelry";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const BigChainMan: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(BigChainMan, PALETTE_NAME_1).colors;
  }, [palettes]);

  const jewelryColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 20H8V21H7V20Z" fill={jewelryColor} />
      <path d="M8 21H9V22H8V21Z" fill={jewelryColor} />
      <path d="M9 22H10V23H9V22Z" fill={jewelryColor} />
    </svg>
  );
};

BigChainMan.layerType = LAYER_TYPE;
BigChainMan.gender = GENDER;
BigChainMan.title = TITLE;
BigChainMan.key = KEY;
BigChainMan.paletteNames = PALETTE_NAMES;
BigChainMan.zIndex = 35;
