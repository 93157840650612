import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Generic";
const TITLE = "Pipe";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Pipe: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="14" y="19" width="1" height="1" fill="#7E5222" />
      <rect x="14" y="20" width="1" height="1" fill="black" />
      <rect x="15" y="20" width="1" height="1" fill="#7E5222" />
      <rect x="15" y="19" width="1" height="1" fill="black" />
      <rect x="16" y="21" width="1" height="1" fill="#7E5222" />
      <rect x="16" y="22" width="1" height="1" fill="black" />
      <rect x="19" y="20" width="3" height="2" fill="#7E5222" />
      <rect x="19" y="21" width="1" height="1" fill="#623D16" />
      <rect x="20" y="22" width="1" height="1" fill="#623D16" />
      <rect x="21" y="21" width="1" height="1" fill="#623D16" />
      <rect x="21" y="22" width="1" height="1" fill="black" />
      <rect x="17" y="22" width="3" height="1" fill="#7E5222" />
      <rect x="17" y="23" width="4" height="1" fill="black" />
      <rect x="19" y="19" width="4" height="1" fill="black" />
      <rect x="22" y="20" width="1" height="2" fill="black" />
      <rect x="20" y="17" width="1" height="1" fill="white" fillOpacity="0.5" />
      <rect x="20" y="15" width="1" height="1" fill="white" fillOpacity="0.5" />
      <rect x="19" y="12" width="3" height="2" fill="white" fillOpacity="0.5" />
      <rect x="20" y="11" width="1" height="1" fill="white" fillOpacity="0.5" />
      <rect x="15" y="21" width="1" height="1" fill="black" />
      <rect x="16" y="20" width="1" height="1" fill="black" />
      <rect x="17" y="21" width="1" height="1" fill="black" />
      <rect x="18" y="19" width="1" height="3" fill="black" />
      <rect x="14" y="18" width="1" height="1" fill="black" />
      <rect x="13" y="19" width="1" height="1" fill="black" />
    </svg>
  );
};

Pipe.layerType = LAYER_TYPE;
Pipe.gender = GENDER;
Pipe.title = TITLE;
Pipe.key = KEY;
Pipe.paletteNames = PALETTE_NAMES;
