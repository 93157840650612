import { LayerFC } from "../../types/LayerFC";
import { Wide } from "./Wide";
import { Default } from "./Default";
import { Lipstick } from "./Lipstick";
import { Mask } from "./Mask";
import { Oh } from "./Oh";
import { Smile } from "./Smile";
import { Zombie } from "./Zombie";

export const mouthWomanLayers: LayerFC[] = [
  Default,
  Lipstick,
  Smile,
  Oh,
  Wide,
  Mask,
  Zombie,
];
