import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Mohawk";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Mohawk: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Mohawk, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2H13V8H12V7H11V6H10H9V5H10V4H11V3H12V2Z"
        fill={hairColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 1H13H12V2H11V3H10V4H9V5H8V6H9V5H10V4H11V3H12V2H13V6H14V1Z"
        fill="black"
      />
    </svg>
  );
};

Mohawk.allowSemiTransparentColors = false;
Mohawk.layerType = LAYER_TYPE;
Mohawk.gender = GENDER;
Mohawk.title = TITLE;
Mohawk.key = KEY;
Mohawk.paletteNames = PALETTE_NAMES;
Mohawk.blacklist = [ApeMan, AlienMan]
