import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Woman";
const TITLE = "Cheeks";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CheeksWoman: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 15H16V17H15V15Z" fill="#F24E1E" fillOpacity="0.25" />
      <path d="M8 15H10V17H8V15Z" fill="#F24E1E" fillOpacity="0.25" />
    </svg>
  );
};

CheeksWoman.layerType = LAYER_TYPE;
CheeksWoman.gender = GENDER;
CheeksWoman.title = TITLE;
CheeksWoman.key = KEY;
CheeksWoman.paletteNames = PALETTE_NAMES;
CheeksWoman.zIndex = 45;
