import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Generic";
const TITLE = "Bubble Gum";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Bubble";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const BubbleGum: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(BubbleGum, PALETTE_NAME_1).colors;
  }, [palettes]);

  const bubbleColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 17H19V20H14V17Z" fill={bubbleColor} />
      <path d="M13 18H14V19H13V18Z" fill={bubbleColor} />
      <path d="M15 16H18V17H15V16Z" fill={bubbleColor} />
      <path d="M15 20H18V21H15V20Z" fill={bubbleColor} />
      <path d="M17 18H18V19H17V18Z" fill="white" fillOpacity="0.3" />
      <path d="M16 17H17V18H16V17Z" fill="white" fillOpacity="0.3" />
    </svg>
  );
};

BubbleGum.layerType = LAYER_TYPE;
BubbleGum.gender = GENDER;
BubbleGum.title = TITLE;
BubbleGum.key = KEY;
BubbleGum.paletteNames = PALETTE_NAMES;
