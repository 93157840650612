import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Hoodie";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Clothes";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Hoodie: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Hoodie, PALETTE_NAME_1).colors;
  }, [palettes]);

  const clothesColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 5H15V6H10V5ZM9 7V6H10V7H9ZM8 8V7H9V8H8ZM7 9V8H8V9H7ZM7 12V9H6V12H5V15H4V20H5V22H6V24H7V22H8V20V15V12H9V11H10V10H15V11H16V15V20H15V21H14V22H13H12V24H13V23H16V24H17V23H18V22H19V21H20V20H21V15H20V12H19V9H18V8H17V7H16V6H15V7H16V8H17V9H10V10H9V11H8V12H7ZM6 15H7V12H6V15ZM6 20H5V15H6V20ZM17 10H16V11H17V13H18V15H19V20H18V21H17V22H16V23H17V22H18V21H19V20H20V15H19V12H18V11H17V10ZM17 10H18V9H17V10ZM6 20H7V22H6V20Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6H15V7H16V8H17V9H18V12H19V15H18V13H17V11H16V10H15V9H10V10H9V11H8V12H7V9H8V8H9V7H10V6ZM6 15V12H7V15H6ZM6 20H5V15H6V20ZM7 22H6V20H7V22ZM7 22H8V24H7V22ZM19 20H20V15H19V20ZM18 21H19V20H18V21ZM17 22H18V21H17V22ZM16 23H17V22H16V23ZM16 23H13V24H16V23Z"
        fill={clothesColor}
      />
    </svg>
  );
};

Hoodie.layerType = LAYER_TYPE;
Hoodie.gender = GENDER;
Hoodie.title = TITLE;
Hoodie.key = KEY;
Hoodie.paletteNames = PALETTE_NAMES;
Hoodie.zIndex = 45;
