export const LAYER_TYPES = [
  "Type",
  "Eyes",
  "Glasses",
  "Ear",
  "Mouth",
  "Beard",
  "Clothes",
  "Hair",
  "Hat",
  "Special",
  "Background",
] as const;

export type LayerType = typeof LAYER_TYPES[number];
