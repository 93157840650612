import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Pixelenca 4";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Pixelenca4: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="#352F44" />
      <rect x="1" width="3" height="6" fill="#27252D" />
      <rect x="1" y="8" width="3" height="5" fill="#756875" />
      <rect x="15" y="12" width="3" height="10" fill="#27252D" />
      <rect x="20" y="16" width="3" height="8" fill="#756875" />
      <rect x="6" width="2" height="2" fill="#27252D" />
      <rect x="6" y="5" width="2" height="3" fill="#756875" />
      <rect x="10" width="3" height="3" fill="#756875" />
      <rect
        width="3"
        height="2"
        transform="matrix(1 0 0 -1 1 18)"
        fill="#E2867F"
      />
      <rect
        width="3"
        height="10"
        transform="matrix(1 0 0 -1 15 10)"
        fill="#E2867F"
      />
      <rect x="10" y="3" width="3" height="1" fill="#27252D" />
      <rect x="10" y="15" width="3" height="9" fill="#756875" />
      <rect x="20" y="12" width="3" height="1" fill="#756875" />
      <rect x="20" y="13" width="3" height="1" fill="#E2867F" />
      <rect x="6" y="20" width="2" height="4" fill="#27252D" />
      <rect x="10" y="6" width="3" height="9" fill="#27252D" />
      <rect x="20" y="1" width="3" height="9" fill="#27252D" />
      <rect x="1" y="18" width="3" height="6" fill="#27252D" />
      <rect x="6" y="10" width="2" height="8" fill="#756875" />
      <rect x="1" y="13" width="3" height="1" fill="#84AA8A" />
      <rect x="6" y="2" width="2" height="1" fill="#84AA8A" />
      <rect x="20" width="3" height="1" fill="#84AA8A" />
      <rect x="15" y="22" width="3" height="2" fill="#84AA8A" />
    </svg>
  );
};

Pixelenca4.layerType = LAYER_TYPE;
Pixelenca4.gender = GENDER;
Pixelenca4.title = TITLE;
Pixelenca4.key = KEY;
Pixelenca4.paletteNames = PALETTE_NAMES;
Pixelenca4.price = 0.001;
