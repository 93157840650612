import { FC, useMemo } from "react";
import styled from "styled-components";
import { COLOR_MAP } from "../constants/Color";
import { ColorPicker } from "./ColorPicker";

interface SwatchProps {
  paletteKey?: string;
  className?: string;
  size?: number;
  isActive?: boolean;
  opensToTop?: boolean;
  colors: readonly string[];
  onClick: () => void;
}

const SwatchWrapper = styled.div`
  position: relative;
`;

const SwatchSVG = styled.svg`
  cursor: pointer;
`;

export const Swatch: FC<SwatchProps> = (props) => {
  const isTransparent = useMemo(
    () => props.colors.some((color) => color.length > 7),
    [props.colors]
  );
  const size = useMemo(() => props.size ?? 18, [props.size]);
  const background = useMemo(
    () =>
      isTransparent && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 0H12V2H10V0ZM14 2H12V4H10V6H12V8H10V10H12V12H10V14H12V16H10V18H12V16H14V18H16V16H18V14H16V12H18V10H16V8H18V6H16V4H18V2H16V0H14V2ZM14 4H12V6H14V8H12V10H14V12H12V14H14V16H16V14H14V12H16V10H14V8H16V6H14V4ZM14 4H16V2H14V4Z"
          fill="white"
          fillOpacity="0.4"
        />
      ),
    [isTransparent]
  );
  const color1 = useMemo(() => props.colors[0], [props.colors]);
  const color2 = useMemo(
    () => props.colors[1] || color1,
    [props.colors, color1]
  );
  const color3 = useMemo(
    () => props.colors[2] || "transparent",
    [props.colors]
  );
  const color4 = useMemo(
    () => props.colors[3] || "transparent",
    [props.colors]
  );
  const color5 = useMemo(
    () => props.colors[4] || "transparent",
    [props.colors]
  );
  const color6 = useMemo(
    () => props.colors[5] || "transparent",
    [props.colors]
  );
  const borderColor = useMemo(
    () => (props.isActive ? COLOR_MAP.primary : "transparent"),
    [props.isActive]
  );

  return (
    <SwatchWrapper>
      <SwatchSVG
        className={props.className}
        width={size}
        height={size}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={props.onClick}
      >
        <rect x="0" y="0" width="18" height="18" fill="black" />
        {background}
        <rect x="0" y="0" width="9" height="18" fill={color1} />
        <rect x="9" y="0" width="9" height="18" fill={color2} />
        <path d="M3 12H6V18H3V12Z" fill={color3} />
        <path d="M6 15H9V21H6V15Z" fill={color4} />
        <rect x="6" y="6" width="3" height="3" fill={color5} />
        <rect x="9" y="9" width="3" height="3" fill={color6} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0H0V5H2V2H5V0H2ZM2 13H0V18H2H5V16H2V13ZM16 13H18V18H16H13V16H16V13ZM18 0H16H13V2H16V5H18V0Z"
          fill={borderColor}
        />
      </SwatchSVG>
      {props.paletteKey && (
        <ColorPicker
          opensToTop={props.opensToTop}
          paletteKey={props.paletteKey}
        />
      )}
    </SwatchWrapper>
  );
};
