import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Cap Diamond";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Jewelry";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const CapDiamond: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(CapDiamond, PALETTE_NAME_1).colors;
  }, [palettes]);

  const diamondColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 5H9V6H8V7H7V11H17H20V10H17V7H16V6H15V5Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 6H11V7H10V8H11V9H12V10H13V9H14V8H15V7H14V6Z"
        fill={diamondColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 6H12V7H11V8H12V7H13V6Z"
        fill="white"
        fillOpacity="0.4"
      />
    </svg>
  );
};

CapDiamond.layerType = LAYER_TYPE;
CapDiamond.gender = GENDER;
CapDiamond.title = TITLE;
CapDiamond.key = KEY;
CapDiamond.paletteNames = PALETTE_NAMES;
