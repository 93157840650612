import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { ApeMan } from "../../type/ApeMan";
import { Man } from "../../type/Man";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Eyes";
const GENDER: Gender = "Man";
const TITLE = "Wink";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Eyes";
const PALETTE_NAME_2: PaletteName = "Sclera";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const Wink: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Wink, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Wink, PALETTE_NAME_2).colors;
  }, [palettes]);

  const eyesColor = useMemo(() => palette1[0], [palette1]);
  const scleraColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="14" y="12" width="1" height="1" fill={scleraColor} />
      <path d="M15 12H16V13H15V12Z" fill={eyesColor} />
      <path d="M9 13H11V12H9V13Z" fill="black" />
      <path d="M9 11H11V12H9V11Z" fill="black" fillOpacity="0.15" />
      <path d="M14 11H16V12H14V11Z" fill="black" fillOpacity="0.15" />
    </svg>
  );
};

Wink.layerType = LAYER_TYPE;
Wink.gender = GENDER;
Wink.title = TITLE;
Wink.key = KEY;
Wink.paletteNames = PALETTE_NAMES;
Wink.whitelist = [Man, ApeMan]
