import { Man } from "../../type/Man";
import { ZombieMan } from "../../type/ZombieMan";
import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "Diamonds";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Diamonds0px: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 11H12V12H14V11H17V12H18V13H17V14H16V15H15V14H14V13H12V14H11V15H10V14H9V13H8V12H9V11Z"
        fill="#37CAEA"
      />
      <path d="M9 12H10V13H9V12Z" fill="white" fillOpacity="0.4" />
      <path d="M10 11H11V12H10V11Z" fill="white" fillOpacity="0.4" />
      <path d="M14 12H15V13H14V12Z" fill="white" fillOpacity="0.4" />
      <path d="M15 11H16V12H15V11Z" fill="white" fillOpacity="0.4" />
    </svg>
  );
};

Diamonds0px.layerType = LAYER_TYPE;
Diamonds0px.gender = GENDER;
Diamonds0px.title = TITLE;
Diamonds0px.key = KEY;
Diamonds0px.paletteNames = PALETTE_NAMES;
Diamonds0px.whitelist = [Man, ZombieMan];
Diamonds0px.zIndex = 100;
