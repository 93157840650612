import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";

import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "Diamonds";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}-1px`;
const PALETTE_NAMES: PaletteName[] = [];

export const Diamonds1px: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 11H11V12H12V13H11V14H10V15H9V14H8V13H7V12H8V11ZM14 11H17V12H18V13H17V14H16V15H15V14H14V13H13V12H14V11Z"
        fill="#37CAEA"
      />
      <path d="M8 12H9V13H8V12Z" fill="white" fillOpacity="0.4" />
      <path d="M9 11H10V12H9V11Z" fill="white" fillOpacity="0.4" />
      <path d="M14 12H15V13H14V12Z" fill="white" fillOpacity="0.4" />
      <path d="M15 11H16V12H15V11Z" fill="white" fillOpacity="0.4" />
    </svg>
  );
};

Diamonds1px.layerType = LAYER_TYPE;
Diamonds1px.gender = GENDER;
Diamonds1px.title = TITLE;
Diamonds1px.key = KEY;
Diamonds1px.paletteNames = PALETTE_NAMES;
Diamonds1px.whitelist = [AlienMan, ApeMan];
Diamonds1px.zIndex = 100;
