import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Cap Nouns";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapNouns: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 10H20V11H7V8H8V7H9V6H15V7H16V8H17V10Z" fill="black" />
      <path d="M11.25 8H12.9167V8.41669H13.3333V8.83335H12.9167V9.67H11.25V8Z" fill="#FF0000" />
      <path d="M13.3333 8H15V9.67H13.3333V8Z" fill="#FF0000" />
      <path d="M10 8.41669H11.25V8.83335H10.4167V9.25002H10V8.41669Z" fill="#FF0000" />
      <rect x="14.1667" y="8.41669" width="0.416667" height="0.833333" fill="black" />
      <rect x="13.75" y="8.41669" width="0.416667" height="0.833333" fill="white" />
      <rect x="11.6667" y="8.41669" width="0.416667" height="0.833333" fill="white" />
      <rect x="12.0833" y="8.41669" width="0.416667" height="0.833333" fill="black" />
    </svg>
  );
};

CapNouns.layerType = LAYER_TYPE;
CapNouns.gender = GENDER;
CapNouns.title = TITLE;
CapNouns.key = KEY;
CapNouns.paletteNames = PALETTE_NAMES;
CapNouns.isBranded = true;
CapNouns.price = 0.001;
