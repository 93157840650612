import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { glassesWomanLayers } from "../glasses/woman";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Woman";
const TITLE = "Laser Top";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Laser";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const LaserTopWoman: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(LaserTopWoman, PALETTE_NAME_1).colors;
  }, [palettes]);

  const laserColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 0H22V1H21V2H20V3H19V4H18V5H17V6H16V7H15V8H14V9H13V10H12V11H11V12H10V13H9V14H11V13H12V12H13V11H14V10H15V9H16V8H17V7H18V6H19V5H20V4H21V3H22V2H23V1H24V0ZM16 14V13H17V12H18V11H19V10H20V9H21V8H22V7H23V6H24V5V4H23V5H22V6H21V7H20V8H19V9H18V10H17V11H16V12H15V13H14V14H16Z"
        fill={laserColor}
      />
    </svg>
  );
};

LaserTopWoman.layerType = LAYER_TYPE;
LaserTopWoman.gender = GENDER;
LaserTopWoman.title = TITLE;
LaserTopWoman.key = KEY;
LaserTopWoman.paletteNames = PALETTE_NAMES;
LaserTopWoman.blacklist = [...glassesWomanLayers];
