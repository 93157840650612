import { LayerFC } from "../types/LayerFC";
import { Solid } from "./Solid";
import { Check } from "./Check";
import { Checks } from "./Checks";
import { Beach } from "./Beach";
import { Sky } from "./Sky";
import { Creature } from "./Creature";
import { Pixelenca1 } from "./Pixelenca1";
import { Pixelenca2 } from "./Pixelenca2";
import { Pixelenca3 } from "./Pixelenca3";
import { Pixelenca4 } from "./Pixelenca4";
import { Pixelenca5 } from "./Pixelenca5";
import { Pixelenca6 } from "./Pixelenca6";
import { Pixelenca7 } from "./Pixelenca7";
import { Pixelenca8 } from "./Pixelenca8";
import { Pixelenca9 } from "./Pixelenca9";
import { Pixelenca10 } from "./Pixelenca10";
import { Pixelenca11 } from "./Pixelenca11";
import { Pixelenca12 } from "./Pixelenca12";
import { Pixelenca13 } from "./Pixelenca13";
import { Rainbow } from "./Rainbow";
import { Matrix } from "./Matrix";
import { Meadow } from "./Meadow";
import { Grid } from "./Grid";
import { Venice } from "./Venice";
import { Space } from "./Space";
import { Ocean } from "./Ocean";
import { Base } from "./Base";
import { Opepen } from "./Opepen";

export const backgroundLayers: LayerFC[] = [
  Solid,
  Sky,
  Creature,
  Space,
  Beach,
  Ocean,
  Venice,
  Meadow,
  Pixelenca1,
  Pixelenca2,
  Pixelenca3,
  Pixelenca4,
  Pixelenca5,
  Pixelenca6,
  Pixelenca7,
  Pixelenca8,
  Pixelenca9,
  Pixelenca10,
  Pixelenca11,
  Pixelenca12,
  Pixelenca13,
  Matrix,
  Rainbow,
  Check,
  Checks,
  Opepen,
  Base,
  Grid,
];
