import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Woman";
const TITLE = "Diamonds";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Diamonds: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 12H17V13H14V12Z" fill="#37CAEA" />
      <path d="M8 12H11V13H8V12Z" fill="#37CAEA" />
      <path d="M14 14H17V15H14V14Z" fill="#37CAEA" />
      <path d="M8 14H11V15H8V14Z" fill="#37CAEA" />
      <path d="M13 13H18V14H13V13Z" fill="#37CAEA" />
      <path d="M7 13H12V14H7V13Z" fill="#37CAEA" />
      <path d="M15 15H16V16H15V15Z" fill="#37CAEA" />
      <path d="M9 15H10V16H9V15Z" fill="#37CAEA" />
      <path d="M8 13H9V14H8V13Z" fill="white" fillOpacity="0.4" />
      <path d="M9 12H10V13H9V12Z" fill="white" fillOpacity="0.4" />
      <path d="M14 13H15V14H14V13Z" fill="white" fillOpacity="0.4" />
      <path d="M15 12H16V13H15V12Z" fill="white" fillOpacity="0.4" />
    </svg>
  );
};

Diamonds.layerType = LAYER_TYPE;
Diamonds.gender = GENDER;
Diamonds.title = TITLE;
Diamonds.key = KEY;
Diamonds.paletteNames = PALETTE_NAMES;
