import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Cap Backward Open";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Cap";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const CapBackwardOpen: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(CapBackwardOpen, PALETTE_NAME_1).colors;
  }, [palettes]);

  const capColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6H9V7H8V8H7V9V10H4V11H7H11H14H16H17V8H16V7H15V6ZM14 10H11V9H14V10Z"
        fill={capColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 7H10V8H9V9H10V8H11V7Z"
        fill="white"
        fillOpacity="0.2"
      />
    </svg>
  );
};

CapBackwardOpen.layerType = LAYER_TYPE;
CapBackwardOpen.gender = GENDER;
CapBackwardOpen.title = TITLE;
CapBackwardOpen.key = KEY;
CapBackwardOpen.paletteNames = PALETTE_NAMES;
