import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Cap Backward Color";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapBackwardColor: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="11" y="10" width="3" height="1" fill="#5E95EF" />
      <path d="M10 6H9V7H8V8H7V10H4V11H8V8H9V7H10V6Z" fill="#EA3937" />
      <path d="M15 6H10V7H9V8H8V11H11V9H14V11H17V8H16V7H15V6Z" fill="white" />
    </svg>
  );
};

CapBackwardColor.layerType = LAYER_TYPE;
CapBackwardColor.gender = GENDER;
CapBackwardColor.title = TITLE;
CapBackwardColor.key = KEY;
CapBackwardColor.paletteNames = PALETTE_NAMES;