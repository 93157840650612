import { PaletteName } from "../../../constants/Palettes";
import { AlienWoman } from "../type/AlienWoman";
import { ApeWoman } from "../type/ApeWoman";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Woman";
const TITLE = "Tattoo Neck";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const TattooNeckWoman: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 22H9V24H10V23H11V22H10Z"
        fill="#434343"
      />
    </svg>
  );
};

TattooNeckWoman.layerType = LAYER_TYPE;
TattooNeckWoman.gender = GENDER;
TattooNeckWoman.title = TITLE;
TattooNeckWoman.key = KEY;
TattooNeckWoman.paletteNames = PALETTE_NAMES;
TattooNeckWoman.blacklist = [ApeWoman, AlienWoman];
TattooNeckWoman.zIndex = 15;
