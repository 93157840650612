import { FC, useMemo } from "react";
import styled from "styled-components";
import { usePalettes } from "../hooks/usePalletes";
import { LayerFC } from "./layerFCs/types/LayerFC";
import { LayerType } from "./layerFCs/types/LayerType";
import { Swatch } from "./Swatch";

interface SwatchesProps {
  layerFCs: LayerFC[];
  layerType: LayerType;
  isExtension?: boolean;
  opensToTop?: boolean;
  className?: string;
}

const SwatchesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  > div {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
  }
`;

export const Swatches: FC<SwatchesProps> = (props) => {
  const palettes = usePalettes();
  const selectedKey = useMemo(
    () => (!palettes || palettes.isLoading ? undefined : palettes.selectedKey),
    [palettes]
  );
  const swatches = useMemo(
    () =>
      !palettes || palettes.isLoading
        ? []
        : props.layerFCs.reduce((acc, layerFC) => {
            if (
              layerFC.layerType !== props.layerType ||
              !layerFC.isExtension !== !props.isExtension
            ) {
              return acc;
            }
            layerFC.paletteNames.forEach((paletteName) => {
              const { key, colors } = palettes.getColorForLayerFC(
                layerFC,
                paletteName
              );
              acc.push(
                <Swatch
                  key={key}
                  paletteKey={key}
                  isActive={selectedKey === key}
                  colors={colors}
                  opensToTop={props.opensToTop}
                  onClick={() => palettes.selectPalette(layerFC, paletteName)}
                />
              );
            });
            return acc;
          }, [] as JSX.Element[]),
    [
      props.layerFCs,
      props.layerType,
      props.isExtension,
      props.opensToTop,
      palettes,
      selectedKey,
    ]
  );

  if (!swatches.length) {
    return null;
  }

  return (
    <SwatchesWrapper className={props.className}>{swatches}</SwatchesWrapper>
  );
};
