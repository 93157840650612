import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Clothes";
const GENDER: Gender = "Man";
const TITLE = "Hoodie";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Clothes";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Hoodie: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Hoodie, PALETTE_NAME_1).colors;
  }, [palettes]);

  const clothesColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 18H6V19H5V18ZM5 20V19H4V20H3V23H4V24H5V23H6V20H5ZM5 20V23H4V20H5ZM14 22H13V23H12V22H11V23H12V24H13V23H14V22Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 19H5V20H4V23H5V24H6V23H5V20H6V19ZM11 23H12V24H11V23ZM12 23V22H13V23H12Z"
        fill={clothesColor}
      />
    </svg>
  );
};

Hoodie.layerType = LAYER_TYPE;
Hoodie.gender = GENDER;
Hoodie.title = TITLE;
Hoodie.key = KEY;
Hoodie.paletteNames = PALETTE_NAMES;
