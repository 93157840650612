import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Generic";
const TITLE = "Surfboard";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Surfboard: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="17" y="10" width="2" height="1" fill="#D352F3"/>
      <rect x="16" y="11" width="1" height="1" fill="#D352F3"/>
      <rect x="15" y="12" width="1" height="2" fill="#D352F3"/>
      <rect x="14" y="14" width="1" height="3" fill="#D352F3"/>
      <path d="M13 17H14V24H13V17Z" fill="#D352F3"/>
      <rect x="21" y="14" width="1" height="3" fill="#D352F3"/>
      <rect x="22" y="17" width="1" height="7" fill="#D352F3"/>
      <rect x="19" y="11" width="1" height="1" fill="#D352F3"/>
      <rect x="20" y="12" width="1" height="2" fill="#D352F3"/>
      <rect x="18" y="11" width="1" height="13" fill="white"/>
      <rect x="19" y="12" width="1" height="12" fill="white"/>
      <rect x="20" y="14" width="1" height="10" fill="white"/>
      <rect x="21" y="17" width="1" height="7" fill="white"/>
      <rect x="17" y="11" width="1" height="13" fill="#EFEFEF"/>
      <rect x="16" y="12" width="1" height="12" fill="#EFEFEF"/>
      <rect x="15" y="14" width="1" height="10" fill="#EFEFEF"/>
      <rect x="14" y="17" width="1" height="7" fill="#EFEFEF"/>
    </svg>
  );
};

Surfboard.layerType = LAYER_TYPE;
Surfboard.gender = GENDER;
Surfboard.title = TITLE;
Surfboard.key = KEY;
Surfboard.paletteNames = PALETTE_NAMES;
Surfboard.zIndex = 5;
