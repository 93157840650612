import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Woman";
const TITLE = "3D";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const ThreeD: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 11V13H8V15H17V11H7Z" fill="white" />
      <rect x="9" y="12" width="3" height="2" fill="#328DFD" />
      <rect x="13" y="12" width="3" height="2" fill="#FD3232" />
    </svg>
  );
};

ThreeD.layerType = LAYER_TYPE;
ThreeD.gender = GENDER;
ThreeD.title = TITLE;
ThreeD.key = KEY;
ThreeD.paletteNames = PALETTE_NAMES;
