import { FC } from "react";
import { Gender } from "./layerFCs/types/Gender";
import { LayerFC } from "./layerFCs/types/LayerFC";
import { LayerTitle } from "./LayerTitle";
import { TextToggle } from "./TextToggle";

interface GenderSelectProps {
  layerFCs: LayerFC[];
  onChange: (gender: Gender) => void;
  gender: Gender;
  className?: string;
}

export const GenderSelect: FC<GenderSelectProps> = (props) => {
  return (
    <LayerTitle
      layerFCs={props.layerFCs}
      layerType="Type"
      className={props.className}
    >
      <TextToggle
        isActive={props.gender === "Man"}
        onClick={() => props.onChange("Man")}
      >
        Male
      </TextToggle>
      {" or "}
      <TextToggle
        isActive={props.gender === "Woman"}
        onClick={() => props.onChange("Woman")}
      >
        Female
      </TextToggle>
    </LayerTitle>
  );
};
