import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Fresh Wild";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const FreshWild: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(FreshWild, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3H17V4H16H15V5H14V3H12V4H11V5H10V3H9V4H8V5H7V4H6V6H5V5H4V8H5V9H6V11H7H8V10H9V9H10V10H12V9H13V10H14V9H15V10H16V11H17V9H18V8H19V7H20V5H19H18V3ZM18 5V6H17V5H18ZM12 9H11V8H12V9Z"
        fill={hairColor}
      />
    </svg>
  );
};

FreshWild.allowSemiTransparentColors = false;
FreshWild.layerType = LAYER_TYPE;
FreshWild.gender = GENDER;
FreshWild.title = TITLE;
FreshWild.key = KEY;
FreshWild.paletteNames = PALETTE_NAMES;
FreshWild.blacklist = [ApeMan, AlienMan]