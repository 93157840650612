import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Space";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Space";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Space: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Space, PALETTE_NAME_1).colors;
  }, [palettes]);

  const spaceColor = useMemo(() => palette1[0], [palette1]);
  const milkywayColor = useMemo(() => palette1[1], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="24"
        height="24"
        fill={spaceColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1H7V2H8V1ZM3 4H2V5H3V7H4V5H5V4H4V2H3V4ZM3 9H4V10H3V9ZM18 3H17V4H18V3ZM22 18H23V19H22V18ZM20 21H19V22H20V21ZM22 8H24V13H21V12H19V13H18V14H17V15H16V16H15V17H13V18H12V19H11V20H10V21H7V22H4V23H1V22H0V15H1V16H4V15H7V14H10V13H11V12H12V11H14V10H16V9H17V8H19V7H22V8Z"
        fill={milkywayColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2H13V3H12V2ZM4 4H3V5H4V4ZM2 10H1V11H2V10ZM9 6H8V7H9V6ZM20 2H21V3H20V2ZM20 16H19V17H20V16Z"
        fill="white"
      />
    </svg>
  );
};

Space.allowSemiTransparentColors = false;
Space.layerType = LAYER_TYPE;
Space.gender = GENDER;
Space.title = TITLE;
Space.key = KEY;
Space.paletteNames = PALETTE_NAMES;
Space.price = 0.002;
