import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Wild Bun";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAME_2: PaletteName = "Holder";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const WildBun: LayerFC = (props) => {
  const palettes = usePalettes();

  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(WildBun, PALETTE_NAME_1).colors;
  }, [palettes]);

  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(WildBun, PALETTE_NAME_2).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  const holderColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5V4H7V2H5V1H6V2V3H3V4H2V6H3V8H2V9H3V8H4V10H5V8H6V12H7V11H8V10H9V9H13V10H12V11H13V10H14V9H15V10H16V11H17V7H18V8H19V7H18V6H16V5H17V4H16V5H8ZM7 6V5H8V6H7ZM7 6V7H6V6H7ZM5 8V7H4V8H5Z"
        fill={hairColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5H7V6H6V7H7V6H8V5Z"
        fill={holderColor}
      />
    </svg>
  );
};

WildBun.allowSemiTransparentColors = false;
WildBun.layerType = LAYER_TYPE;
WildBun.gender = GENDER;
WildBun.title = TITLE;
WildBun.key = KEY;
WildBun.paletteNames = PALETTE_NAMES;
WildBun.blacklist = [ApeMan, AlienMan];
