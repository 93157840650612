import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Pixelenca 3";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Pixelenca3: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_156_9201)">
        <rect width="24" height="24" fill="#141B31" />
        <rect x="1" y="-1" width="2" height="4" fill="#3B5F88" />
        <rect x="4" width="2" height="1" fill="#F3BD46" />
        <rect x="4" y="3" width="2" height="4" fill="#F3BD46" />
        <rect x="4" y="11" width="2" height="7" fill="#F3BD46" />
        <rect x="1" y="4" width="2" height="1" fill="#BAD4CD" />
        <rect x="4" y="7" width="2" height="1" fill="#BAD4CD" />
        <rect x="1" y="8" width="2" height="4" fill="#52A492" />
        <rect x="21" y="16" width="2" height="1" fill="#52A492" />
        <rect x="15" width="5" height="1" fill="#52A492" />
        <rect x="21" y="19" width="2" height="5" fill="#52A492" />
        <rect x="1" y="12" width="2" height="1" fill="#BAD4CD" />
        <rect x="1" y="21" width="2" height="1" fill="#DED5C7" />
        <rect x="4" y="2" width="2" height="1" fill="#DED5C7" />
        <rect x="4" y="10" width="2" height="1" fill="#DED5C7" />
        <rect x="21" y="15" width="2" height="1" fill="#DED5C7" />
        <rect x="1" y="22" width="2" height="1" fill="#F3BD46" />
        <rect x="1" y="15" width="2" height="6" fill="#ECB4A4" />
        <rect x="1" y="5" width="2" height="1" fill="#F7D273" />
        <rect x="21" y="14" width="2" height="1" fill="#F7D273" />
        <rect x="4" y="21" width="2" height="3" fill="#BED8CE" />
        <rect x="7" width="4" height="24" fill="#DED5C7" />
        <rect x="15" y="3" width="5" height="13" fill="#BED8CE" />
        <rect x="15" y="18" width="5" height="5" fill="#F3BD46" />
        <rect x="12" y="7" width="2" height="16" fill="#DED5C7" />
        <rect x="12" width="2" height="4" fill="#BED8CE" />
        <rect x="12" y="4" width="2" height="1" fill="#ECB4A4" />
        <rect x="12" y="23" width="2" height="1" fill="#BED8CE" />
        <rect x="21" width="2" height="2" fill="#3B5F88" />
        <rect x="21" y="2" width="2" height="1" fill="#BED8CE" />
        <rect x="21" y="3" width="2" height="2" fill="#DED5C7" />
        <rect x="21" y="7" width="2" height="7" fill="#3B5F88" />
      </g>
      <defs>
        <clipPath id="clip0_156_9201">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

Pixelenca3.layerType = LAYER_TYPE;
Pixelenca3.gender = GENDER;
Pixelenca3.title = TITLE;
Pixelenca3.key = KEY;
Pixelenca3.paletteNames = PALETTE_NAMES;
Pixelenca3.price = 0.001;
