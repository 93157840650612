import { FC, useCallback, useRef, useState, useEffect } from "react";
import { RemoveScroll } from "react-remove-scroll";
import styled from "styled-components";
import { COLOR_MAP } from "../constants/Color";
import { useBanner } from "../hooks/useBanner";
import { useExport } from "../hooks/useExport";
import { Text } from "./Text";

interface ShareProps {
  className?: string;
  onClose: () => void;
}

const SharePopoverWrapper = styled(Text)`
  position: absolute;
  background-color: ${COLOR_MAP.gray700};
  border: 1px solid ${COLOR_MAP.gray500};
  display: flex;
  flex-direction: column;
  padding: 4px;
  right: 0;
  input {
    border: 0;
    width: 0;
    height: 0;
    font-size: 0;
    padding: 0;
    margin: 0;
  }
`;

const SharePopoverOption = styled(Text)`
  color: ${COLOR_MAP.gray300};
  display: flex;
  height: 32px;
  padding: 0 12px;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${COLOR_MAP.white};
    background-color: ${COLOR_MAP.dark};
  }
`;

export const SharePopover: FC<ShareProps> = (props) => {
  const [timeoutId, setTimeoutId] = useState<number | undefined>();
  const { generatePNGImageUrl } = useExport();
  const inputRef = useRef<HTMLInputElement>(null);
  const bannerHandler = useBanner();

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const handeDownloadClick = useCallback(() => {
    generatePNGImageUrl().then((imageUrl) => {
      const aElement = document.createElement("a");
      aElement.href = imageUrl;
      aElement.download = "SelfMadePunk.png";
      aElement.click();
    });
  }, [generatePNGImageUrl]);

  const handeCopyUrlClick = useCallback(() => {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    if (!bannerHandler.isLoading) {
      bannerHandler.setBanner({ content: "Copied to clipboard" });
    }
  }, [bannerHandler]);

  const handeShareOnTwitterClick = useCallback(() => {
    const url = window.location.href;
    const tweetUrl = `https://twitter.com/intent/tweet?original_referer=${encodeURIComponent(
      url
    )}&text=${encodeURIComponent(
      "My SelfMadePunk\n\nCreate your own on selfmadepunks.com"
    )}&url=${encodeURIComponent(url)}`;
    window.open(tweetUrl, "_blank");
  }, []);

  const handleBlur = useCallback(() => {
    setTimeoutId(
      window.setTimeout(() => {
        props.onClose();
      }, 200)
    );
  }, [props]);

  const handleFocus = useCallback(() => {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }
  }, [timeoutId]);

  return (
    <RemoveScroll>
      <SharePopoverWrapper $fontSize="normal">
        <input ref={inputRef} onBlur={handleBlur} onFocus={handleFocus} />
        <SharePopoverOption onClick={handeDownloadClick}>
          Download
        </SharePopoverOption>
        <SharePopoverOption onClick={handeCopyUrlClick}>
          Copy Punk URL
        </SharePopoverOption>
        <SharePopoverOption onClick={handeShareOnTwitterClick}>
          Share On Twitter
        </SharePopoverOption>
      </SharePopoverWrapper>
    </RemoveScroll>
  );
};
