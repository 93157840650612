import { LayerFC } from "../../types/LayerFC";
import { CollarL } from "./CollarL";
import { CollarM } from "./CollarM";
import { Fur } from "./Fur";
import { FurXL } from "./FurXL";
import { Hoodie } from "./Hoodie";
import { HoodieL } from "./HoodieL";
import { JoggingSuit } from "./JoggingSuit";
import { Polo } from "./Polo";
import { Shirt } from "./Shirt";
import { Suit } from "./Suit";
import { Sweater } from "./Sweater";


export const clothesManLayers: LayerFC[] = [
  Shirt,
  Polo,
  Sweater,
  Suit,
  CollarM,
  CollarL,
  JoggingSuit,
  Fur,
  FurXL,
  Hoodie,
  HoodieL,
];
