import { LayerFC } from "../../types/LayerFC";
import { BigShades } from "./BigShades";
import { ColoredGlasses } from "./ColoredGlasses";
import { Diamonds } from "./Diamonds";
import { Hearts } from "./Hearts";
import { Mask } from "./Mask";
import { NerdGlasses } from "./NerdGlasses";
import { NoFrame } from "./NoFrame";
import { Plain } from "./Plain";
import { Shades } from "./Shades";
import { Ski } from "./Ski";
import { SportGlasses } from "./SportGlasses";
import { Sunglasses } from "./Sunglasses";
import { ThreeD } from "./Three3D";
import { TopFrame } from "./TopFrame";
import { VR } from "./VR";

export const glassesWomanLayers: LayerFC[] = [
  Shades,
  Sunglasses,
  BigShades,
  SportGlasses,
  NoFrame,
  TopFrame,
  NerdGlasses,
  ColoredGlasses,
  Plain,
  Ski,
  VR,
  ThreeD,
  Mask,
  Hearts,
  Diamonds,
];
