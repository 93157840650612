import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Long 12";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Long12: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Long12, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 7H15V8H16V9H17V11H18V24H13V22H14V21H15V20H16V19H17V14H16V13H11V12H10V13H8V14H7V19H8V24H5V13H6V10H7V9H8V8H9V7Z" fill={hairColor} />
    </svg>
  );
};

Long12.allowSemiTransparentColors = false;
Long12.layerType = LAYER_TYPE;
Long12.gender = GENDER;
Long12.title = TITLE;
Long12.key = KEY;
Long12.paletteNames = PALETTE_NAMES;
Long12.blacklist = [ApeWoman, AlienWoman]
