import Navigation from "./components/Navigation";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { FC } from "react";
import styled from "styled-components";
import About from "./pages/About";
import Minted from "./pages/Minted";
import Claimed from "./pages/Claimed";
import Footer from "./components/Footer";
import { Banner } from "./components/Banner";

const AppWrapper = styled.div`
  max-width: 1520px;
  min-height: 100%;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: 520px) {
    padding: 20px 16px 32px 16px;
    overflow-x: hidden;
  }
`;

const App: FC = () => {
  return (
    <Routes>
      <Route path="/admin/minted" element={<Minted />} />
      <Route path="/admin/claimed" element={<Claimed />} />
      <Route
        path="*"
        element={
          <AppWrapper>
            <Banner />
            <Navigation />
            <Routes>
              <Route path="/about" element={<About />} />
              <Route path="*" element={<Home />} />
            </Routes>
            <Footer />
          </AppWrapper>
        }
      />
    </Routes>
  );
};

export default App;
