import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "Sunglasses Shine";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Sunglasses: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 11V10H12V11H13V10H18V13H17V11H14V13H13V12H12V13H11V11H8V13H7V12H6V11H7Z" fill="black" />
      <path d="M8 13H11V14H8V13Z" fill="black" />
      <path d="M14 13H17V14H14V13Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11 11H8V13H11V11ZM17 11H14V13H17V11Z" fill="#4D5B49" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10 11H11V12H10V11ZM10 12V13H9V12H10ZM16 11H17V12H16V11ZM16 12V13H15V12H16Z" fill="white" fill-opacity="0.3" />
    </svg>
  );
};

Sunglasses.layerType = LAYER_TYPE;
Sunglasses.gender = GENDER;
Sunglasses.title = TITLE;
Sunglasses.key = KEY;
Sunglasses.paletteNames = PALETTE_NAMES;
