import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Cap ES";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapES: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 6H15V7H16V8H17V10H20V11H7V8H8V7H9V6Z" fill="#043361"/>
      <path d="M11.5 7H12.625V7.375H11.875V7.75H12.25V8.125H11.875V8.5H12.625V8.125H13.75V8.5H13V8.875H13.75V10H12.625V9.625H13.375V9.25H12.625V8.875H11.5V7Z" fill="white"/>
    </svg>
  );
};

CapES.layerType = LAYER_TYPE;
CapES.gender = GENDER;
CapES.title = TITLE;
CapES.key = KEY;
CapES.paletteNames = PALETTE_NAMES;
CapES.isBranded = true;
CapES.price = 0.01;
