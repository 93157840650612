import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";
import { capWhitelist } from "./whitelists";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Middle Hair";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}-Cap`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const MiddleHairCap: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(MiddleHairCap, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 9H8H9V10H8V11H7V12H6V17H5V15H4V10H5V9ZM18 9H15V10H16V11H17V16H18V15H19V10H18V9Z"
        fill={hairColor}
      />
      <rect x="11" y="7" width="3" height="1" fill={hairColor} />
    </svg>
  );
};

MiddleHairCap.layerType = LAYER_TYPE;
MiddleHairCap.gender = GENDER;
MiddleHairCap.allowSemiTransparentColors = false;
MiddleHairCap.title = TITLE;
MiddleHairCap.key = KEY;
MiddleHairCap.isExtension = true;
MiddleHairCap.paletteNames = PALETTE_NAMES;
MiddleHairCap.whitelist = capWhitelist;
