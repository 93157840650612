import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Rasta";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Rasta: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Rasta, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 5H16V6H17H18V7H19V9H20V13H21V17H20V15H19V17H20V18H19V19H18V21H17V12H16V9H15V8H12V9H11V8H10V11H9V9H8V15H7V17H6V20H7V21H6V22H5V20H4V21H3V20H2V19H3V17H4V16H3V17H2V18H1V16H2V14H3V12H4V11H3V12H2V10H3V9H4V8H5V7H6V6H7H8V5H15ZM2 12V13H1V12H2ZM17 21V22H16V21H17ZM19 19H20V21H19V19ZM6 14H7V12H6V14Z"
        fill={hairColor}
      />
    </svg>
  );
};

Rasta.allowSemiTransparentColors = false;
Rasta.layerType = LAYER_TYPE;
Rasta.gender = GENDER;
Rasta.title = TITLE;
Rasta.key = KEY;
Rasta.paletteNames = PALETTE_NAMES;
Rasta.blacklist = [ApeMan, AlienMan]
