import { PaletteName } from "../../../constants/Palettes";
import { ZombieMan } from "../type/ZombieMan";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Man";
const TITLE = "Zombie Brain";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];
export const ZombieBrainMan: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6H13V7H12V6ZM11 8V7H12V8H11ZM10 9H11V8H10V9ZM8 10V9H10V10H8ZM8 10V11H7V10H8Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6H8V7H7V10H8V9H10V8H11V7H12V6H11H10Z"
        fill="#E57C7D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6H11V7H10V6ZM7 7H9V8H10V9H8V8H7V7Z"
        fill="#A95786"
      />
    </svg>
  );
};

ZombieBrainMan.layerType = LAYER_TYPE;
ZombieBrainMan.gender = GENDER;
ZombieBrainMan.title = TITLE;
ZombieBrainMan.key = KEY;
ZombieBrainMan.paletteNames = PALETTE_NAMES;
ZombieBrainMan.whitelist = [ZombieMan];
ZombieBrainMan.zIndex = 25;
