import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { None } from "../../hat/None";
import { Woman } from "../../type/Woman";
import { ZombieWoman } from "../../type/ZombieWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Short Hair";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const ShortHair: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(ShortHair, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 9H8V11V12H9V11H15V12H16V11V9Z"
        fill={hairColor}
      />
    </svg>
  );
};

ShortHair.layerType = LAYER_TYPE;
ShortHair.gender = GENDER;
ShortHair.title = TITLE;
ShortHair.key = KEY;
ShortHair.isExtension = true;
ShortHair.paletteNames = PALETTE_NAMES;
ShortHair.whitelist = [Woman, ZombieWoman];
ShortHair.blacklist = [None]
