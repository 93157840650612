import { FC, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { LayerTypeToggle } from "./LayerTypeToggle";
import { LayerType, LAYER_TYPES } from "./layerFCs/types/LayerType";
import { LayerFC } from "./layerFCs/types/LayerFC";
import { Gender } from "./layerFCs/types/Gender";

interface LayerTypesProps {
  onLayerTypeChange: (layerType: LayerType) => void;
  selectedLayerFCs: LayerFC[];
  gender: Gender;
  className?: string;
}

const LayerTypesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  @media screen and (max-width: 480px) {
    justify-content: flex-start;
    overflow-y: hidden;
    overflow-x: scroll;
    margin-right: -16px;
  }
  div {
    padding: 8px;
  }
  ::-webkit-scrollbar {
    height: 4ppx;
  }
  ::-webkit-scrollbar-track {
    background: #1e2024;
  }
`;

export const LayerTypes: FC<LayerTypesProps> = (props) => {
  const [selectedLayerType, setSelectedLayerType] = useState<LayerType>(
    LAYER_TYPES[0]
  );

  const toggleClickHandler = useCallback(
    (layerType: LayerType) => {
      setSelectedLayerType(layerType);
      props.onLayerTypeChange(layerType);
    },
    [props]
  );
  useEffect(() => {
    props.onLayerTypeChange(selectedLayerType);
  });

  const children = useMemo(
    () =>
      LAYER_TYPES.map((layerType) =>
        layerType === "Hat" ? null : (
          <LayerTypeToggle
            key={layerType}
            layerType={layerType}
            isActive={layerType === selectedLayerType}
            selectedLayerFCs={props.selectedLayerFCs}
            gender={props.gender}
            onClick={() => toggleClickHandler(layerType)}
          />
        )
      ),
    [toggleClickHandler, selectedLayerType, props]
  );

  return (
    <LayerTypesWrapper className={props.className}>
      {children}
    </LayerTypesWrapper>
  );
};
