import { CSSProperties, FC } from "react";
import styled from "styled-components";
// Layers
import { ReactComponent as ActiveItemSvg } from "../assets/icons/layers/ActiveItem.svg";
import { ReactComponent as BackgroundSvg } from "../assets/icons/layers/Background.svg";
import { ReactComponent as BeardSvg } from "../assets/icons/layers/Beard.svg";
import { ReactComponent as ClothesSvg } from "../assets/icons/layers/Clothes.svg";
import { ReactComponent as EarSvg } from "../assets/icons/layers/Ear.svg";
import { ReactComponent as EarExtensionSvg } from "../assets/icons/layers/EarExtension.svg";
import { ReactComponent as EyesSvg } from "../assets/icons/layers/Eyes.svg";
import { ReactComponent as GlassesSvg } from "../assets/icons/layers/Glasses.svg";
import { ReactComponent as HairSvg } from "../assets/icons/layers/Hair.svg";
import { ReactComponent as HatSvg } from "../assets/icons/layers/Hat.svg";
import { ReactComponent as HatExtensionSvg } from "../assets/icons/layers/HatExtension.svg";
import { ReactComponent as MouthSvg } from "../assets/icons/layers/Mouth.svg";
import { ReactComponent as SpecialSvg } from "../assets/icons/layers/Special.svg";
import { ReactComponent as TypeSvg } from "../assets/icons/layers/Type.svg";
// Misc
import { ReactComponent as GMSvg } from "../assets/icons/misc/GM.svg";
import { ReactComponent as InfoSvg } from "../assets/icons/misc/Info.svg";
import { ReactComponent as PinataSvg } from "../assets/icons/misc/Pinata.svg";
import { ReactComponent as ShareSvg } from "../assets/icons/misc/Share.svg";
import { ReactComponent as TrashSvg } from "../assets/icons/misc/Trash.svg";
import { ReactComponent as WAGMISvg } from "../assets/icons/misc/WAGMI.svg";
import { ReactComponent as YourBrandSvg } from "../assets/icons/misc/YourBrand.svg";
// Navigation
import { ReactComponent as AboutSvg } from "../assets/icons/navigation/About.svg";
import { ReactComponent as BackSvg } from "../assets/icons/navigation/Back.svg";
import { ReactComponent as ChevronDownSvg } from "../assets/icons/navigation/ChevronDown.svg";
import { ReactComponent as ChevronUpSvg } from "../assets/icons/navigation/ChevronUp.svg";
import { ReactComponent as CloseSvg } from "../assets/icons/navigation/Close.svg";
import { ReactComponent as CurrentColorSvg } from "../assets/icons/navigation/CurrentColor.svg";
import { ReactComponent as MetaMaskSvg } from "../assets/icons/navigation/MetaMask.svg";
import { ReactComponent as RedoSvg } from "../assets/icons/navigation/Redo.svg";
import { ReactComponent as TwitterSvg } from "../assets/icons/navigation/Twitter.svg";
import { ReactComponent as UndoSvg } from "../assets/icons/navigation/Undo.svg";
import { ReactComponent as OpenSeaSvg } from "../assets/icons/navigation/OpenSea.svg";
import { ReactComponent as LooksRareSvg } from "../assets/icons/navigation/LooksRare.svg";

interface IconProps {
  type: IconType;
  onClick?: () => void;
  style?: CSSProperties;
  className?: string;
}

const ICON_MAP = {
  // Layers
  ActiveItem: ActiveItemSvg,
  Background: BackgroundSvg,
  Beard: BeardSvg,
  Clothes: ClothesSvg,
  Ear: EarSvg,
  EarExtension: EarExtensionSvg,
  Eyes: EyesSvg,
  Glasses: GlassesSvg,
  Hair: HairSvg,
  Hat: HatSvg,
  HatExtension: HatExtensionSvg,
  Mouth: MouthSvg,
  Special: SpecialSvg,
  Type: TypeSvg,
  // Misc
  GM: GMSvg,
  Info: InfoSvg,
  Pinata: PinataSvg,
  Share: ShareSvg,
  Trash: TrashSvg,
  WAGMI: WAGMISvg,
  YourBrand: YourBrandSvg,
  // Navigation
  About: AboutSvg,
  Back: BackSvg,
  ChevronDown: ChevronDownSvg,
  ChevronUp: ChevronUpSvg,
  Close: CloseSvg,
  CurrentColor: CurrentColorSvg,
  MetaMask: MetaMaskSvg,
  LooksRare: LooksRareSvg,
  OpenSea: OpenSeaSvg,
  Redo: RedoSvg,
  Twitter: TwitterSvg,
  Undo: UndoSvg,
} as const;

export type IconType = keyof typeof ICON_MAP;

const IconWrapper = styled.svg``;

export const Icon: FC<IconProps> = (props) => {
  return (
    <IconWrapper
      className={props.className}
      style={props.style}
      as={ICON_MAP[props.type]}
      onClick={props.onClick}
    />
  );
};
