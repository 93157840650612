import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Long 10";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Long10: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Long10, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 5H15V6H16V7H17V9H18V14H19V19H18V22H19V24H13V22H14V21H15V20H16V19H17V11H16V10H15V9H14V8H11V9H9V10H8V11H7V12H6V15H7V19H8V24H4V23H5V21H4V17H3V13H4V10H5V8H6V7H7V6H9V5Z" fill={hairColor} />
    </svg>
  );
};

Long10.allowSemiTransparentColors = false;
Long10.layerType = LAYER_TYPE;
Long10.gender = GENDER;
Long10.title = TITLE;
Long10.key = KEY;
Long10.paletteNames = PALETTE_NAMES;
Long10.blacklist = [ApeWoman, AlienWoman]

