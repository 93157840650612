import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Cap TCG";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapTCG: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 8H20V9H6V6H7V5H8V4H15V5H16V6H17V8Z" fill="#192445" />
      <path d="M11.5 5.75H11.75V6H12V6.25H11.75V7H12V7.25H11.5V6.25H11.25V6H11.5V5.75Z" fill="white" />
      <path d="M12.25 6.25H12.5V7H12.25V6.25Z" fill="white" />
      <path d="M12.5 7H13V7.25H12.5V7Z" fill="white" />
      <path d="M12.5 6.25H13V6H12.5V6.25Z" fill="white" />
      <path d="M13.25 6.25H13.5V7H13.25V6.25Z" fill="white" />
      <path d="M14 7.5V7.25H13.5V7H14V6.25H13.5V6H14.25V7.5H14Z" fill="white" />
      <path d="M14 7.5V7.75H13.5V7.5H14Z" fill="white" />
    </svg>
  );
};

CapTCG.layerType = LAYER_TYPE;
CapTCG.gender = GENDER;
CapTCG.title = TITLE;
CapTCG.key = KEY;
CapTCG.paletteNames = PALETTE_NAMES;
CapTCG.isBranded = true
CapTCG.price = 0.01;
