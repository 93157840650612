import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Woman";
const TITLE = "Sweat";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const SweatWoman: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="9" y="9" width="1" height="1" fill="white" fillOpacity="0.5" />
    </svg>
  );
};

SweatWoman.layerType = LAYER_TYPE;
SweatWoman.gender = GENDER;
SweatWoman.title = TITLE;
SweatWoman.key = KEY;
SweatWoman.paletteNames = PALETTE_NAMES;
SweatWoman.zIndex = 15;
