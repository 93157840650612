import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Cap Chapter One";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapC1: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 10H20V11H7V8H8V7H9V6H15V7H16V8H17V10Z" fill="#031A28" />
      <path
        d="M12 7H14V7.5H13.5V9.5H14V10H12V9.5H13V8.5H12.5V8H13V7.5H12V7Z"
        fill="#B7FF9B"
      />
      <path d="M12 7.5V9.5H11.5V7.5H12Z" fill="#B7FF9B" />
      <path d="M14 9.5V9H14.5V9.5H14Z" fill="#B7FF9B" />
      <path d="M14 7.5H14.5V8H14V7.5Z" fill="#B7FF9B" />
    </svg>
  );
};

CapC1.layerType = LAYER_TYPE;
CapC1.gender = GENDER;
CapC1.title = TITLE;
CapC1.key = KEY;
CapC1.paletteNames = PALETTE_NAMES;
CapC1.isBranded = true;
CapC1.price = 0.01;
