import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Cap Diamond";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Jewelry";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const CapDiamond: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(CapDiamond, PALETTE_NAME_1).colors;
  }, [palettes]);

  const diamondColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 4H15V5H16V6H17V9H20V10H6V6H7V5H8V4Z" fill="black" />
      <path d="M11 5H14V6H15V7H14V8H13V9H12V8H11V7H10V6H11V5Z" fill={diamondColor} />
      <path d="M11 6H12V7H11V6Z" fill="white" fillOpacity="0.4" />
      <path d="M12 5H13V6H12V5Z" fill="white" fillOpacity="0.4" />
    </svg>
  );
};

CapDiamond.layerType = LAYER_TYPE;
CapDiamond.gender = GENDER;
CapDiamond.title = TITLE;
CapDiamond.key = KEY;
CapDiamond.paletteNames = PALETTE_NAMES;
