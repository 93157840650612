import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Rasta";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Rasta: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Rasta, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6H16V7H17V8H18V9H19V11H20V12H21V13H20V14H21V18H20V16H19V18H20V19H19V20H18V22H17V13H16V10H15V9H13V10H12V9H11V12H10V10H9V16H8V19H7V21H8V22H7V23H6V21H5V22H4V21H3V20H4V18H5V17H4V18H3V19H2V17H3V15H4V13H5V12H4V13H3V11H4V10H5V9H6V8H7V7H8H9V6H15ZM5 9H4V8H5V9ZM3 13V14H2V13H3ZM17 22V23H16V22H17ZM19 20H20V22H19V20ZM7 14H8V13H7V14Z"
        fill={hairColor}
      />
    </svg>
  );
};

Rasta.allowSemiTransparentColors = false;
Rasta.layerType = LAYER_TYPE;
Rasta.gender = GENDER;
Rasta.title = TITLE;
Rasta.key = KEY;
Rasta.paletteNames = PALETTE_NAMES;
Rasta.blacklist = [ApeWoman, AlienWoman]

