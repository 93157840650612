import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Grid";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Grid: LayerFC = (props) => {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1 0H0V1H1V0ZM3 0H2V1H3V0ZM4 0H5V1H4V0ZM7 0H6V1H7V0ZM8 0H9V1H8V0ZM11 0H10V1H11V0ZM12 0H13V1H12V0ZM15 0H14V1H15V0ZM16 0H17V1H16V0ZM19 0H18V1H19V0ZM20 0H21V1H20V0ZM23 0H22V1H23V0Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2 1H1V2H2V1ZM4 1H3V2H4V1ZM5 1H6V2H5V1ZM8 1H7V2H8V1ZM9 1H10V2H9V1ZM12 1H11V2H12V1ZM13 1H14V2H13V1ZM16 1H15V2H16V1ZM17 1H18V2H17V1ZM20 1H19V2H20V1ZM21 1H22V2H21V1ZM24 1H23V2H24V1Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1 2H0V3H1V2ZM3 2H2V3H3V2ZM4 2H5V3H4V2ZM7 2H6V3H7V2ZM8 2H9V3H8V2ZM11 2H10V3H11V2ZM12 2H13V3H12V2ZM15 2H14V3H15V2ZM16 2H17V3H16V2ZM19 2H18V3H19V2ZM20 2H21V3H20V2ZM23 2H22V3H23V2Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2 3H1V4H2V3ZM4 3H3V4H4V3ZM5 3H6V4H5V3ZM8 3H7V4H8V3ZM9 3H10V4H9V3ZM12 3H11V4H12V3ZM13 3H14V4H13V3ZM16 3H15V4H16V3ZM17 3H18V4H17V3ZM20 3H19V4H20V3ZM21 3H22V4H21V3ZM24 3H23V4H24V3Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1 4H0V5H1V4ZM3 4H2V5H3V4ZM4 4H5V5H4V4ZM7 4H6V5H7V4ZM8 4H9V5H8V4ZM11 4H10V5H11V4ZM12 4H13V5H12V4ZM15 4H14V5H15V4ZM16 4H17V5H16V4ZM19 4H18V5H19V4ZM20 4H21V5H20V4ZM23 4H22V5H23V4Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2 5H1V6H2V5ZM4 5H3V6H4V5ZM5 5H6V6H5V5ZM8 5H7V6H8V5ZM9 5H10V6H9V5ZM12 5H11V6H12V5ZM13 5H14V6H13V5ZM16 5H15V6H16V5ZM17 5H18V6H17V5ZM20 5H19V6H20V5ZM21 5H22V6H21V5ZM24 5H23V6H24V5Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1 6H0V7H1V6ZM3 6H2V7H3V6ZM4 6H5V7H4V6ZM7 6H6V7H7V6ZM8 6H9V7H8V6ZM11 6H10V7H11V6ZM12 6H13V7H12V6ZM15 6H14V7H15V6ZM16 6H17V7H16V6ZM19 6H18V7H19V6ZM20 6H21V7H20V6ZM23 6H22V7H23V6Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2 7H1V8H2V7ZM4 7H3V8H4V7ZM5 7H6V8H5V7ZM8 7H7V8H8V7ZM9 7H10V8H9V7ZM12 7H11V8H12V7ZM13 7H14V8H13V7ZM16 7H15V8H16V7ZM17 7H18V8H17V7ZM20 7H19V8H20V7ZM21 7H22V8H21V7ZM24 7H23V8H24V7Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1 8H0V9H1V8ZM3 8H2V9H3V8ZM4 8H5V9H4V8ZM7 8H6V9H7V8ZM8 8H9V9H8V8ZM11 8H10V9H11V8ZM12 8H13V9H12V8ZM15 8H14V9H15V8ZM16 8H17V9H16V8ZM19 8H18V9H19V8ZM20 8H21V9H20V8ZM23 8H22V9H23V8Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2 9H1V10H2V9ZM4 9H3V10H4V9ZM5 9H6V10H5V9ZM8 9H7V10H8V9ZM9 9H10V10H9V9ZM12 9H11V10H12V9ZM13 9H14V10H13V9ZM16 9H15V10H16V9ZM17 9H18V10H17V9ZM20 9H19V10H20V9ZM21 9H22V10H21V9ZM24 9H23V10H24V9Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1 10H0V11H1V10ZM3 10H2V11H3V10ZM4 10H5V11H4V10ZM7 10H6V11H7V10ZM8 10H9V11H8V10ZM11 10H10V11H11V10ZM12 10H13V11H12V10ZM15 10H14V11H15V10ZM16 10H17V11H16V10ZM19 10H18V11H19V10ZM20 10H21V11H20V10ZM23 10H22V11H23V10Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2 11H1V12H2V11ZM4 11H3V12H4V11ZM5 11H6V12H5V11ZM8 11H7V12H8V11ZM9 11H10V12H9V11ZM12 11H11V12H12V11ZM13 11H14V12H13V11ZM16 11H15V12H16V11ZM17 11H18V12H17V11ZM20 11H19V12H20V11ZM21 11H22V12H21V11ZM24 11H23V12H24V11Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1 12H0V13H1V12ZM3 12H2V13H3V12ZM4 12H5V13H4V12ZM7 12H6V13H7V12ZM8 12H9V13H8V12ZM11 12H10V13H11V12ZM12 12H13V13H12V12ZM15 12H14V13H15V12ZM16 12H17V13H16V12ZM19 12H18V13H19V12ZM20 12H21V13H20V12ZM23 12H22V13H23V12Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2 13H1V14H2V13ZM4 13H3V14H4V13ZM5 13H6V14H5V13ZM8 13H7V14H8V13ZM9 13H10V14H9V13ZM12 13H11V14H12V13ZM13 13H14V14H13V13ZM16 13H15V14H16V13ZM17 13H18V14H17V13ZM20 13H19V14H20V13ZM21 13H22V14H21V13ZM24 13H23V14H24V13Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1 14H0V15H1V14ZM3 14H2V15H3V14ZM4 14H5V15H4V14ZM7 14H6V15H7V14ZM8 14H9V15H8V14ZM11 14H10V15H11V14ZM12 14H13V15H12V14ZM15 14H14V15H15V14ZM16 14H17V15H16V14ZM19 14H18V15H19V14ZM20 14H21V15H20V14ZM23 14H22V15H23V14Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2 15H1V16H2V15ZM4 15H3V16H4V15ZM5 15H6V16H5V15ZM8 15H7V16H8V15ZM9 15H10V16H9V15ZM12 15H11V16H12V15ZM13 15H14V16H13V15ZM16 15H15V16H16V15ZM17 15H18V16H17V15ZM20 15H19V16H20V15ZM21 15H22V16H21V15ZM24 15H23V16H24V15Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1 16H0V17H1V16ZM3 16H2V17H3V16ZM4 16H5V17H4V16ZM7 16H6V17H7V16ZM8 16H9V17H8V16ZM11 16H10V17H11V16ZM12 16H13V17H12V16ZM15 16H14V17H15V16ZM16 16H17V17H16V16ZM19 16H18V17H19V16ZM20 16H21V17H20V16ZM23 16H22V17H23V16Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2 17H1V18H2V17ZM4 17H3V18H4V17ZM5 17H6V18H5V17ZM8 17H7V18H8V17ZM9 17H10V18H9V17ZM12 17H11V18H12V17ZM13 17H14V18H13V17ZM16 17H15V18H16V17ZM17 17H18V18H17V17ZM20 17H19V18H20V17ZM21 17H22V18H21V17ZM24 17H23V18H24V17Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1 18H0V19H1V18ZM3 18H2V19H3V18ZM4 18H5V19H4V18ZM7 18H6V19H7V18ZM8 18H9V19H8V18ZM11 18H10V19H11V18ZM12 18H13V19H12V18ZM15 18H14V19H15V18ZM16 18H17V19H16V18ZM19 18H18V19H19V18ZM20 18H21V19H20V18ZM23 18H22V19H23V18Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2 19H1V20H2V19ZM4 19H3V20H4V19ZM5 19H6V20H5V19ZM8 19H7V20H8V19ZM9 19H10V20H9V19ZM12 19H11V20H12V19ZM13 19H14V20H13V19ZM16 19H15V20H16V19ZM17 19H18V20H17V19ZM20 19H19V20H20V19ZM21 19H22V20H21V19ZM24 19H23V20H24V19Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1 20H0V21H1V20ZM3 20H2V21H3V20ZM4 20H5V21H4V20ZM7 20H6V21H7V20ZM8 20H9V21H8V20ZM11 20H10V21H11V20ZM12 20H13V21H12V20ZM15 20H14V21H15V20ZM16 20H17V21H16V20ZM19 20H18V21H19V20ZM20 20H21V21H20V20ZM23 20H22V21H23V20Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2 21H1V22H2V21ZM4 21H3V22H4V21ZM5 21H6V22H5V21ZM8 21H7V22H8V21ZM9 21H10V22H9V21ZM12 21H11V22H12V21ZM13 21H14V22H13V21ZM16 21H15V22H16V21ZM17 21H18V22H17V21ZM20 21H19V22H20V21ZM21 21H22V22H21V21ZM24 21H23V22H24V21Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1 22H0V23H1V22ZM3 22H2V23H3V22ZM4 22H5V23H4V22ZM7 22H6V23H7V22ZM8 22H9V23H8V22ZM11 22H10V23H11V22ZM12 22H13V23H12V22ZM15 22H14V23H15V22ZM16 22H17V23H16V22ZM19 22H18V23H19V22ZM20 22H21V23H20V22ZM23 22H22V23H23V22Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2 23H1V24H2V23ZM4 23H3V24H4V23ZM5 23H6V24H5V23ZM8 23H7V24H8V23ZM9 23H10V24H9V23ZM12 23H11V24H12V23ZM13 23H14V24H13V23ZM16 23H15V24H16V23ZM17 23H18V24H17V23ZM20 23H19V24H20V23ZM21 23H22V24H21V23ZM24 23H23V24H24V23Z" fill="#CCCCCC"/>
</svg>
  );
};

Grid.layerType = LAYER_TYPE;
Grid.gender = GENDER;
Grid.title = TITLE;
Grid.key = KEY;
Grid.paletteNames = PALETTE_NAMES;
