import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Clothes";
const GENDER: Gender = "Man";
const TITLE = "Hoodie L";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Clothes";
const PALETTE_NAME_2: PaletteName = "Shirt";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const HoodieL: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(HoodieL, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(HoodieL, PALETTE_NAME_2).colors;
  }, [palettes]);

  const collarColor = useMemo(() => palette1[0], [palette1]);
  const shirtColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 18H6V19H5V18Z" fill="black" />
      <path d="M4 20V19H5V20H4Z" fill="black" />
      <path d="M4 23H3V20H4V23Z" fill="black" />
      <path d="M4 23H5V24H4V23Z" fill="black" />
      <path d="M12 22H13V23H12V22Z" fill="black" />
      <path d="M13 23H14V24H13V23Z" fill="black" />
      <path d="M8 21H7V22H8V21Z" fill="black" />
      <path d="M6 21H7V22H12V23H13V24H5V23H4V20H5V19H6V21Z" fill={collarColor} />
      <path d="M9 23H8V24H9V23Z" fill={shirtColor} />
      <path d="M12 23H11V24H12V23Z" fill={shirtColor} />
    </svg>
  );
};

HoodieL.layerType = LAYER_TYPE;
HoodieL.gender = GENDER;
HoodieL.title = TITLE;
HoodieL.key = KEY;
HoodieL.paletteNames = PALETTE_NAMES;
