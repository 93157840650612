import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { AlienMan } from "../type/AlienMan";
import { ApeMan } from "../type/ApeMan";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Beard";
const GENDER: Gender = "Man";
const TITLE = "Full XL";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Beard";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const BigXL: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(BigXL, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 17H17V22H16V23H9V22H7V21H6V18H5V21H6V22H7V23H9V24H16V23H17V22H18V17Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14H7V15V16V17V18H6V21H7V22H9V23H16V22H17V21V18V17H16V16V15H15V16H9V15H8V14ZM11 18H14V19H11V18Z"
        fill={hairColor}
      />
    </svg>
  );
};

BigXL.allowSemiTransparentColors = false;
BigXL.layerType = LAYER_TYPE;
BigXL.gender = GENDER;
BigXL.title = TITLE;
BigXL.key = KEY;
BigXL.paletteNames = PALETTE_NAMES;
BigXL.blacklist = [ApeMan, AlienMan]
