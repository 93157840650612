import { PaletteName } from "../../../constants/Palettes";
import { ApeMan } from "../type/ApeMan";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Man";
const TITLE = "Devil";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const DevilMan: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7 3H6V4H5V7H6V4H7V5H8V4H7V3ZM15 4H16V5H15V4ZM17 4H16V3H17V4ZM17 4V7H18V4H17Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6 4H7V5H8V6H10V7H9V8H8H7V7H6V4ZM15 6H14V7H15V8H16V7H17V4H16V5H15V6Z" fill="#FF5858" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10 6H9V7H7V8H9V7H10V6ZM15 6H14V7H15V8H16V7H15V6Z" fill="#BF4242" />
    </svg>
  );
};

DevilMan.layerType = LAYER_TYPE;
DevilMan.gender = GENDER;
DevilMan.title = TITLE;
DevilMan.key = KEY;
DevilMan.paletteNames = PALETTE_NAMES;
DevilMan.whitelist = [ApeMan];
