import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { None as HatNone } from "../hat/None";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";
import { AlienMan } from "../type/AlienMan";
import { AlienWoman } from "../type/AlienWoman";
import { ApeMan } from "../type/ApeMan";
import { ApeWoman } from "../type/ApeWoman";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Generic";
const TITLE = "None";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const None: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    ></svg>
  );
};

None.layerType = LAYER_TYPE;
None.gender = GENDER;
None.title = TITLE;
None.key = KEY;
None.isExtension = true;
None.paletteNames = PALETTE_NAMES;
None.blacklist = [HatNone, AlienMan, AlienWoman, ApeMan, ApeWoman];
None.price = 0;
None.zIndex = 0;
