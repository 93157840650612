import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Ear";
const GENDER: Gender = "Man";
const TITLE = "Pencil";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Pencil: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="10" width="4" height="1" fill="#FFE979" />
      <rect x="3" y="10" width="1" height="1" fill="#DC8FBA" />
      <rect x="8" y="10" width="1" height="1" fill="#555555" />
      <rect x="5" y="11" width="1" height="4" fill="black" />
    </svg>
  );
};

Pencil.isExtension = true;
Pencil.layerType = LAYER_TYPE;
Pencil.gender = GENDER;
Pencil.title = TITLE;
Pencil.key = KEY;
Pencil.paletteNames = PALETTE_NAMES;
Pencil.zIndex = 90;
