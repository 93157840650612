import { FC, useEffect, useMemo } from "react";
import styled from "styled-components";
import { COLOR_MAP } from "../constants/Color";
import { availableLayerFCs } from "./layerFCs";
import { LayerFC } from "./layerFCs/types/LayerFC";
import { LayerTitle } from "./LayerTitle";
import { TextToggle } from "./TextToggle";

export type Headgear = "Hair" | "Hat" | "Branded Hat";

interface HeadgearSelectProps {
  layerFCs: LayerFC[];
  onChange: (headgear: Headgear) => void;
  headgear: Headgear;
  className?: string;
  isBranded: boolean;
}

const TitleWrapper = styled(LayerTitle)`
  display: flex;
  gap: 12px;
`;

const TitleSpacer = styled.div`
  width: 2px;
  height: 13px;
  margin-top: -1px;
  background-color: ${COLOR_MAP.gray500};
`;

export const HeadgearSelect: FC<HeadgearSelectProps> = (props) => {
  const typeLayerFC = useMemo(
    () => props.layerFCs.find((layerFC) => layerFC.layerType === "Type"),
    [props.layerFCs]
  );

  const headgear: Headgear = useMemo(
    () =>
      props.headgear === "Hat" && props.isBranded
        ? "Branded Hat"
        : props.headgear,
    [props.headgear, props.isBranded]
  );

  const gender = useMemo(() => typeLayerFC?.gender || "Generic", [typeLayerFC]);

  const hairLayerFCs = useMemo(
    () =>
      availableLayerFCs({
        layerType: "Hair",
        gender,
        selectedLayerFCs: props.layerFCs,
      }),
    [props.layerFCs, gender]
  );

  const hatLayerFCs = useMemo(
    () =>
      availableLayerFCs({
        layerType: "Hat",
        gender,
        selectedLayerFCs: props.layerFCs,
        isBranded: false,
      }),
    [props.layerFCs, gender]
  );

  const brandedHatLayerFCs = useMemo(
    () =>
      availableLayerFCs({
        layerType: "Hat",
        gender,
        selectedLayerFCs: props.layerFCs,
        isBranded: true,
      }),
    [props.layerFCs, gender]
  );

  useEffect(() => {
    if (
      headgear === "Hair" &&
      hairLayerFCs.length === 0 &&
      hatLayerFCs.length > 0
    ) {
      props.onChange("Hat");
    }
    if (
      headgear === "Hat" &&
      hatLayerFCs.length === 0 &&
      hairLayerFCs.length > 0
    ) {
      props.onChange("Hair");
    }
  }, [props, headgear, hairLayerFCs, hatLayerFCs]);

  return (
    <TitleWrapper
      layerFCs={props.layerFCs}
      layerType={headgear === "Branded Hat" ? "Hat" : headgear}
      className={props.className}
    >
      <TextToggle
        isActive={headgear === "Hair"}
        isDisabled={!hairLayerFCs.length}
        onClick={() => props.onChange("Hair")}
      >
        Hair
      </TextToggle>
      <TitleSpacer />
      <TextToggle
        isActive={headgear === "Hat"}
        isDisabled={!hatLayerFCs.length}
        onClick={() => props.onChange("Hat")}
      >
        Hat
      </TextToggle>
      <TitleSpacer />
      <TextToggle
        isActive={headgear === "Branded Hat"}
        isDisabled={!brandedHatLayerFCs.length}
        onClick={() => props.onChange("Branded Hat")}
      >
        Branded Hat
      </TextToggle>
    </TitleWrapper>
  );
};
