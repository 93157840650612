import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { LayerTypes } from "../components/LayerTypes";
import { Text } from "../components/Text";
import { LayerType } from "../components/layerFCs/types/LayerType";
import { Layers } from "../components/Layers";
import { Preview } from "../components/Preview";
import { LayerFC } from "../components/layerFCs/types/LayerFC";
import { Gender } from "../components/layerFCs/types/Gender";
import { useNavigate } from "react-router";
import { HistoryButton } from "../components/HistoryButton";
import { typeLayers } from "../components/layerFCs/type";
import {
  layerFCsCleanup,
  MAX_SPECIAL_LAYERS,
} from "../helpers/layerFCsCleanup";
import { layerFCsSort } from "../helpers/layerFCsSort";
import { layerFCsFill } from "../helpers/layerFCsFill";
import { layerFCs } from "../components/layerFCs";
import { useLayerFCsParser } from "../hooks/useLayerFCsParser";
import { GenderSelect } from "../components/GenderSelect";
import { Headgear, HeadgearSelect } from "../components/HeadGearSelect";
import { LayerTitle } from "../components/LayerTitle";
import { Swatches } from "../components/Swatches";
import { PurchaseSection } from "../components/PurchaseSection";
import { keyForLayerFCPaletteName, usePalettes } from "../hooks/usePalletes";
import { PALETTES } from "../constants/Palettes";

const HomeWrapper = styled.div`
  position: relative;
`;

const HomePreviewWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 40px;
  @media screen and (max-width: 520px) {
    gap: 24px;
  }
`;

const HomeLayerType = styled(Text)`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 32px;
  height: 20px;
  align-items: center;
  font-size: 12px;
`;

const Home: FC = () => {
  const location = useLocation();
  const { stringify, parse } = useLayerFCsParser();
  const [selectedGender, setSelectedGender] = useState<Gender>(
    Math.random() < 0.5 ? "Man" : "Woman"
  );
  const palettes = usePalettes();
  const [selectedHeadGear, setSelectedHeadGear] = useState<"Hair" | "Hat">(
    "Hair"
  );
  const [selectedLayerType, setSelectedLayerType] = useState<LayerType>("Type");
  const [selectedLayerFCs, setSelectedLayerFCs] = useState<LayerFC[]>(
    layerFCsSort(
      layerFCsFill(
        layerFCsCleanup([
          typeLayers.find((layerFC) => layerFC.gender === selectedGender)!,
        ])
      )
    )
  );
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isBranded, setIsBranded] = useState(false);
  const [undoStack, setUndoStack] = useState<LayerFC[][]>([]);
  const [redoStack, setRedoStack] = useState<LayerFC[][]>([]);
  const changeUrl = useCallback(
    (layerFCs: LayerFC[]) => {
      stringify(layerFCs).then((path) => {
        const targetUrl = `/${path}`;
        if (targetUrl !== location.pathname) {
          navigate(targetUrl);
        }
      });
    },
    [navigate, location, stringify]
  );
  useEffect(() => {
    if (isLoading && !palettes.isLoading) {
      parse(location.pathname.slice(1)).then((layerFCs) => {
        const gender = layerFCs.find(
          (layerFC) => layerFC.layerType === "Type"
        )?.gender;
        if (
          layerFCs.length > 0 &&
          gender &&
          selectedLayerFCs.map((layerFC) => layerFC.key).join() !==
            layerFCs.map((layerFC) => layerFC.key).join()
        ) {
          setSelectedGender(gender);
          setSelectedLayerFCs(
            layerFCsSort(layerFCsFill(layerFCsCleanup(layerFCs)))
          );
        }
      });
      setIsLoading(false);
    }
  }, [isLoading, palettes]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!isLoading) {
      changeUrl(selectedLayerFCs);
    }
  }, [changeUrl, isLoading, selectedLayerFCs, stringify]);
  const changeSelectedLayerFCs = useCallback(
    (layerFCs: LayerFC[]) => {
      setSelectedLayerFCs(layerFCs);
      setUndoStack([...undoStack, layerFCs]);
      setRedoStack([]);
      changeUrl(layerFCs);
    },
    [undoStack, changeUrl]
  );
  const undoClickHandler = useCallback(() => {
    const layerFCs = undoStack.at(-1);
    if (!layerFCs) return;
    setSelectedLayerFCs(layerFCs);
    setRedoStack([...redoStack, layerFCs]);
    setUndoStack(undoStack.slice(0, -1));
    changeUrl(layerFCs);
  }, [changeUrl, undoStack, redoStack]);
  const redoClickHandler = useCallback(() => {
    const layerFCs = redoStack.at(-1);
    if (!layerFCs) return;
    setSelectedLayerFCs(layerFCs);
    setUndoStack([...undoStack, layerFCs]);
    setRedoStack(redoStack.slice(0, -1));
    changeUrl(layerFCs);
  }, [changeUrl, undoStack, redoStack]);
  const handleSelectedLayerFCChange = useCallback(
    (layerFC: LayerFC) => {
      changeSelectedLayerFCs(
        layerFCsSort(
          layerFCsFill(layerFCsCleanup([...selectedLayerFCs, layerFC]))
        )
      );
      if (layerFC.allowSemiTransparentColors === false && !palettes.isLoading) {
        for (const paletteName of layerFC.paletteNames) {
          if (
            palettes
              .getColorForLayerFC(layerFC, paletteName)
              .colors.some((color) => color.length > 7)
          ) {
            // Has unallowed color
            const key = keyForLayerFCPaletteName(layerFC.key, paletteName);
            palettes.changeSelectedColors(
              key,
              PALETTES[paletteName].colors[
                PALETTES[paletteName].defaultIndex ?? 0
              ]!
            );
          }
        }
      }
    },
    [palettes, selectedLayerFCs, changeSelectedLayerFCs]
  );
  const currentTypeLayerFC = useMemo(
    () => selectedLayerFCs.find((layerFC) => layerFC.layerType === "Type"),
    [selectedLayerFCs]
  );
  const handleSelectedGenderChange = useCallback(
    (gender: Gender) => {
      setSelectedGender(gender);
      if (currentTypeLayerFC) {
        const newTypeLayerFC = layerFCs.find(
          (layerFC) =>
            layerFC.layerType === "Type" &&
            layerFC.title === currentTypeLayerFC.title &&
            layerFC.gender === gender &&
            layerFC.title
        );
        if (newTypeLayerFC) {
          handleSelectedLayerFCChange(newTypeLayerFC);
        }
      }
    },
    [currentTypeLayerFC, handleSelectedLayerFCChange]
  );
  const currentLayerType = useMemo(
    () => (selectedLayerType === "Hair" ? selectedHeadGear : selectedLayerType),
    [selectedHeadGear, selectedLayerType]
  );
  const handleSelectedHeadGearChange = useCallback((headGear: Headgear) => {
    setIsBranded(headGear === "Branded Hat");
    setSelectedHeadGear(headGear === "Branded Hat" ? "Hat" : headGear);
  }, []);
  const titleElement = useMemo(() => {
    if (selectedLayerType === "Type") {
      return (
        <GenderSelect
          layerFCs={selectedLayerFCs}
          onChange={handleSelectedGenderChange}
          gender={selectedGender}
        />
      );
    }
    if (selectedLayerType === "Hair" || selectedLayerType === "Hat") {
      return (
        <HeadgearSelect
          isBranded={isBranded}
          layerFCs={selectedLayerFCs}
          onChange={handleSelectedHeadGearChange}
          headgear={selectedHeadGear}
        />
      );
    }
    if (selectedLayerType === "Special") {
      const specialLayerFCCount = selectedLayerFCs.filter(
        (layerFC) => layerFC.layerType === "Special" && layerFC.title !== "None"
      ).length;
      return (
        <LayerTitle layerFCs={selectedLayerFCs} layerType={selectedLayerType}>
          {selectedLayerType} {specialLayerFCCount}/{MAX_SPECIAL_LAYERS}
        </LayerTitle>
      );
    }
    return (
      <LayerTitle layerFCs={selectedLayerFCs} layerType={selectedLayerType}>
        {selectedLayerType}
      </LayerTitle>
    );
  }, [
    isBranded,
    selectedLayerFCs,
    selectedLayerType,
    handleSelectedGenderChange,
    handleSelectedHeadGearChange,
    selectedGender,
    selectedHeadGear,
  ]);

  return (
    <HomeWrapper>
      <HomePreviewWrapper>
        <HistoryButton
          style={{ justifyContent: "flex-end" }}
          iconType="Undo"
          isDisabled={undoStack.length === 0}
          onClick={undoClickHandler}
        />
        <Preview layerFCs={selectedLayerFCs} />
        <HistoryButton
          iconType="Redo"
          isDisabled={redoStack.length === 0}
          onClick={redoClickHandler}
        />
      </HomePreviewWrapper>
      <LayerTypes
        selectedLayerFCs={selectedLayerFCs}
        onLayerTypeChange={setSelectedLayerType}
        gender={selectedGender}
      />
      <Layers
        selectedLayerFCs={selectedLayerFCs}
        onSelectedLayerFCChange={handleSelectedLayerFCChange}
        gender={selectedGender}
        layerType={currentLayerType}
        isBranded={currentLayerType === "Hat" && isBranded}
        children={titleElement}
      />
      <Layers
        isExtension={true}
        selectedLayerFCs={selectedLayerFCs}
        onSelectedLayerFCChange={handleSelectedLayerFCChange}
        gender={selectedGender}
        layerType={currentLayerType}
      >
        <HomeLayerType $fontColor="gray300" $fontSize="small">
          {currentLayerType} Extensions{" "}
          <Swatches
            layerFCs={selectedLayerFCs}
            layerType={currentLayerType}
            opensToTop={true}
            isExtension={true}
          />
        </HomeLayerType>
      </Layers>
      <PurchaseSection
        layerFCs={selectedLayerFCs}
        onSelectedLayerFCChange={handleSelectedLayerFCChange}
      />
    </HomeWrapper>
  );
};

export default Home;
