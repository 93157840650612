import { LayerFC } from "../components/layerFCs/types/LayerFC";
import { LayerType } from "../components/layerFCs/types/LayerType";

const defaultLayerPrice: Record<LayerType, number> = {
  Background: 0,
  Type: 0.019,
  Ear: 0,
  Hair: 0,
  Hat: 0.001,
  Clothes: 0.001,
  Beard: 0.001,
  Mouth: 0,
  Eyes: 0,
  Glasses: 0.001,
  Special: 0.002,
};

const defaultExtensionLayerPrice: Partial<Record<LayerType, number>> = {
  Ear: 0.001,
  Hat: 0,
};

export const layerFCPrice = (layerFC: LayerFC): number => {
  if (layerFC.price !== undefined) {
    return layerFC.price;
  }
  const extensionPrice = defaultExtensionLayerPrice[layerFC.layerType];
  if (layerFC.isExtension && extensionPrice !== undefined) {
    return extensionPrice;
  }
  return defaultLayerPrice[layerFC.layerType];
};

export const layerFCsPrice = (layerFCs: LayerFC[]): number =>
  layerFCs.reduce((acc, layerFC) => acc + layerFCPrice(layerFC), 0);
