import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Mouth";
const GENDER: Gender = "Man";
const TITLE = "Mask";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Mask";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Mask: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Mask, PALETTE_NAME_1).colors;
  }, [palettes]);
  const maskColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 12H6V13H7V14H8V15H9V18H7V19H9V20H10V21H15V20H16V15H9V14H8V13H7V12Z"
        fill={maskColor}
      />
    </svg>
  );
};

Mask.layerType = LAYER_TYPE;
Mask.gender = GENDER;
Mask.title = TITLE;
Mask.key = KEY;
Mask.paletteNames = PALETTE_NAMES;
