import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Long 3";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Long3: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Long3, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6H9V7H8H7V8H6V9H5V12H4V16H3V21H2V24H3H4H5H6H7H8V19H7V13H8V11H9V10H10V9H15V10H16V12H17V19H16V20H15V21H14V22H13V24H14H15H16H17H18H19H20V23H19V11H18V9H17V7H16H15V6H10Z"
        fill={hairColor}
      />
    </svg>
  );
};

Long3.allowSemiTransparentColors = false;
Long3.layerType = LAYER_TYPE;
Long3.gender = GENDER;
Long3.title = TITLE;
Long3.key = KEY;
Long3.paletteNames = PALETTE_NAMES;
Long3.blacklist = [ApeWoman, AlienWoman]
