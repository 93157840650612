import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Cap Forward";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hat";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const CapForward: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(CapForward, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hatColor = useMemo(() => palette1[0], [palette1]);
  const secondaryhatColor = useMemo(() => palette1[1], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 4H8V5H7V6H6V10H19V8H18V7H17V6H16V5H15V4ZM10 8H9V9H7V6H8V5H15V6H16V7H10V8ZM10 8H18V9H10V8Z"
        fill="black"
      />
      <path d="M8 5H15V6H16V7H10V8H9V9H7V6H8V5Z" fill={hatColor} />
      <path d="M8 5H9V6H8V5Z" fill={secondaryhatColor} />
      <path d="M8 6V7H7V6H8Z" fill={secondaryhatColor} />
      <path d="M18 8H10V9H18V8Z" fill={secondaryhatColor} />
    </svg>
  );
};

CapForward.allowSemiTransparentColors = false;
CapForward.layerType = LAYER_TYPE;
CapForward.gender = GENDER;
CapForward.title = TITLE;
CapForward.key = KEY;
CapForward.paletteNames = PALETTE_NAMES;
