import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Receding Hairline";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const RecedingHairline: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(RecedingHairline, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6H8V7H7V9H8V8H9V7H10V6H9ZM14 6H15V7H14V6ZM15 7V8H16V7H15Z"
        fill={hairColor}
      />
    </svg>
  );
};

RecedingHairline.layerType = LAYER_TYPE;
RecedingHairline.gender = GENDER;
RecedingHairline.title = TITLE;
RecedingHairline.key = KEY;
RecedingHairline.paletteNames = PALETTE_NAMES;
RecedingHairline.blacklist = [ApeMan, AlienMan]
