import { PaletteName } from "../../../constants/Palettes";
import { hatManLayers } from "../hat/man";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Man";
const TITLE = "Beats";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const BeatsMan: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6" y="15" width="1" height="1" fill="#E7E9E5" />
      <rect x="7" y="6" width="1" height="1" fill="#E7E9E5" />
      <rect x="7" y="11" width="1" height="4" fill="#E7E9E5" />
      <rect x="6" y="7" width="1" height="4" fill="#E7E9E5" />
      <rect x="4" y="11" width="3" height="4" fill="#CECFCB" />
      <rect x="6" y="6" width="1" height="1" fill="#CECFCB" />
      <rect x="5" y="7" width="1" height="9" fill="#CECFCB" />
      <rect x="17" y="11" width="1" height="4" fill="#E7E9E5" />
    </svg>
  );
};

BeatsMan.layerType = LAYER_TYPE;
BeatsMan.gender = GENDER;
BeatsMan.title = TITLE;
BeatsMan.key = KEY;
BeatsMan.paletteNames = PALETTE_NAMES;
BeatsMan.blacklist = [...hatManLayers]