import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Generic";
const TITLE = "Banana";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Banana: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="11" y="18" width="3" height="1" fill="#EFF2B2" />
      <rect x="11" y="19" width="4" height="1" fill="#EFF2B2" />
      <rect x="13" y="20" width="1" height="1" fill="#EFF2B2" />
      <rect x="14" y="20" width="3" height="1" fill="#EAD775" />
      <rect x="16" y="21" width="2" height="1" fill="#EAD775" />
      <rect x="9" y="21" width="2" height="1" fill="#EAD775" />
      <rect x="12" y="21" width="3" height="3" fill="#EAD775" />
      <rect x="10" y="20" width="3" height="1" fill="#EAD775" />
    </svg>
  );
};

Banana.layerType = LAYER_TYPE;
Banana.gender = GENDER;
Banana.title = TITLE;
Banana.key = KEY;
Banana.paletteNames = PALETTE_NAMES;
