import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Generic";
const TITLE = "Display";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Display: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="19" y="12" width="5" height="12" fill="#C4C4C4" />
      <rect
        x="19"
        y="12"
        width="1"
        height="12"
        fill="black"
        fillOpacity="0.1"
      />
    </svg>
  );
};

Display.layerType = LAYER_TYPE;
Display.gender = GENDER;
Display.title = TITLE;
Display.key = KEY;
Display.paletteNames = PALETTE_NAMES;
Display.zIndex = 100;
