import { PaletteName } from "../../../constants/Palettes";
import { AlienMan } from "../type/AlienMan";
import { ApeMan } from "../type/ApeMan";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Man";
const TITLE = "Tattoo Neck";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const TattooNeckMan: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 21H8V22H9V23H8V24H7V21ZM9 22V21H10V22H9Z"
        fill="#434343"
      />
    </svg>
  );
};

TattooNeckMan.layerType = LAYER_TYPE;
TattooNeckMan.gender = GENDER;
TattooNeckMan.title = TITLE;
TattooNeckMan.key = KEY;
TattooNeckMan.paletteNames = PALETTE_NAMES;
TattooNeckMan.blacklist = [ApeMan, AlienMan];
TattooNeckMan.zIndex = 15;
