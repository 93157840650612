import { availableLayerFCs } from "../components/layerFCs";
import { LayerFC } from "../components/layerFCs/types/LayerFC";
import { LAYER_TYPES } from "../components/layerFCs/types/LayerType";
import { layerFCsGender } from "./layerFCsGender";

export const layerFCsFill = (layerFCs: LayerFC[]): LayerFC[] => {
  const gender = layerFCsGender(layerFCs);

  for (const layerType of LAYER_TYPES) {
    if (!layerFCs.some((layerFC) => layerFC.layerType === layerType)) {
      const availableLayerFCsForLayer = availableLayerFCs({
        gender,
        layerType,
        selectedLayerFCs: layerFCs,
      });
      if (availableLayerFCsForLayer.length > 0) {
        return layerFCsFill([...layerFCs, availableLayerFCsForLayer[0]]);
      }
    }
  }

  return layerFCs;
};
