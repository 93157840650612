import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { None } from "../../hat/None";
import { Bandana } from "../../hat/woman/Bandana";
import { BeenieBubble } from "../../hat/woman/BeenieBubble";
import { BeenieL } from "../../hat/woman/BeenieL";
import { BeenieM } from "../../hat/woman/BeenieM";
import { CapDiamond } from "../../hat/woman/CapDiamond";
import { CapForward } from "../../hat/woman/CapForward";
import { Crown } from "../../hat/woman/Crown";
import { Hoodie } from "../../hat/woman/Hoodie";
import { Police } from "../../hat/woman/Police";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Band";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Clothes";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Band: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Band, PALETTE_NAME_1).colors;
  }, [palettes]);

  const bandColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="8" y="11" width="8" height="1" fill={bandColor} />
    </svg>
  );
};

Band.layerType = LAYER_TYPE;
Band.gender = GENDER;
Band.title = TITLE;
Band.key = KEY;
Band.isExtension = true;
Band.paletteNames = PALETTE_NAMES;
Band.blacklist = [
  None,
  AlienWoman,
  ApeWoman,
  BeenieM,
  BeenieL,
  BeenieBubble,
  CapDiamond,
  CapForward,
  Crown,
  Hoodie,
  Police,
  Bandana,
];
