import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Bandana";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hat";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Bandana: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Bandana, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hatColor = useMemo(() => palette1[0], [palette1]);
  const secondaryhatColor = useMemo(() => palette1[1], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5H16V6H17V8H16V9H15V10H12V9H8H7H6V10H5V11H4V12H3V9H2V8H3H4H5H6V7H7V6H8V5Z"
        fill={hatColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 5H8V6H7V7H6V8H5V9H4V8H3V9H4V10H5V9H6V8H7V7H8V6H16V8H15V9H12V8H8V9H12V10H15V9H16V8H17V6H16V5Z"
        fill={secondaryhatColor}
      />
    </svg>
  );
};

Bandana.allowSemiTransparentColors = false;
Bandana.layerType = LAYER_TYPE;
Bandana.gender = GENDER;
Bandana.title = TITLE;
Bandana.key = KEY;
Bandana.paletteNames = PALETTE_NAMES;
