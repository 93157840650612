import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Generic";
const TITLE = "Mic";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Mic: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 16H17V17H18V22H17V23H14V22H13V17H14V16Z"
        fill="#929292"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 16H15V17H16V16ZM14 18H13V19H14V18ZM17 18H18V19H17V18ZM17 20H18V21H17V20ZM13 20H14V21H13V20Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 18H15V21H16V22H17V23H18V24H19H20H21V23H20V22H19V21H18V20H17V19H16V18Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 18H15V19H16V20H17V21H18V22H19V23H20V24H21V23H20V22H19V21H18V20H17V19H16V18Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 20H15V21H16V22H17V23H18V24H19V23H18V22H17V21H16V20Z"
        fill="white"
        fillOpacity="0.2"
      />
    </svg>
  );
};

Mic.layerType = LAYER_TYPE;
Mic.gender = GENDER;
Mic.title = TITLE;
Mic.key = KEY;
Mic.paletteNames = PALETTE_NAMES;
Mic.zIndex = 100;
