import { LayerFC } from "../types/LayerFC";
import { hatExtensionsManLayers } from "./man";
import { None } from "./None";
import { hatExtensionsWomanLayers } from "./woman";

export const hatextensionLayers: LayerFC[] = [
  None,
  ...hatExtensionsManLayers,
  ...hatExtensionsWomanLayers,
];
