import { PaletteName } from "../../../constants/Palettes";
import { AlienMan } from "../type/AlienMan";
import { ApeMan } from "../type/ApeMan";
import { Man } from "../type/Man";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Man";
const TITLE = "Blood Neck";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const BloodNeckMan: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="7" y="17" width="1" height="6" fill="#C54424"/>
      <rect x="8" y="19" width="1" height="3" fill="#C54424"/>
    </svg>
  );
};

BloodNeckMan.layerType = LAYER_TYPE;
BloodNeckMan.gender = GENDER;
BloodNeckMan.title = TITLE;
BloodNeckMan.key = KEY;
BloodNeckMan.paletteNames = PALETTE_NAMES;
BloodNeckMan.blacklist = [Man, ApeMan, AlienMan];
BloodNeckMan.zIndex = 65;
