import { FC, useMemo } from "react";
import styled from "styled-components";
import { Icon, IconType } from "./Icon";
import { COLOR_MAP } from "../constants/Color";
import { LayerFC } from "./layerFCs/types/LayerFC";
import { Gender } from "./layerFCs/types/Gender";
import { availableLayerFCs, getLayerTypesForLayerType } from "./layerFCs";
import { LayerType } from "./layerFCs/types/LayerType";

interface LayerTypeToggleProps {
  layerType: LayerType & IconType;
  isActive: boolean;
  onClick: () => void;
  selectedLayerFCs: LayerFC[];
  gender: Gender;
  className?: string;
}

interface LayerTypeToggleWrapperProps {
  $isActive: boolean;
  $isDisabled: boolean;
}

const LayerTypeToggleWrapper = styled.div<LayerTypeToggleWrapperProps>`
  color: ${({ $isActive }) => COLOR_MAP[$isActive ? "primary" : "gray300"]};
  color: ${({ $isDisabled }) => $isDisabled && COLOR_MAP.gray700};
  pointer-events: ${({ $isActive }) => $isActive && "none"};
  pointer-events: ${({ $isDisabled }) => $isDisabled && "none"};
  cursor: pointer;
  &:hover {
    color: ${COLOR_MAP.white};
  }
`;

const LayerTypeToggleIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  @media screen and (max-width: 400px) {
    width: 18px;
    height: 18px;
  }
`;

export const LayerTypeToggle: FC<LayerTypeToggleProps> = (props) => {
  const layerFCs = useMemo(
    () =>
      availableLayerFCs({
        layerType: getLayerTypesForLayerType(props.layerType),
        gender: props.gender,
        selectedLayerFCs: props.selectedLayerFCs,
      }),
    [props]
  );
  const isDisabled = useMemo(() => layerFCs.length === 0, [layerFCs]);

  return (
    <LayerTypeToggleWrapper
      $isActive={props.isActive}
      $isDisabled={isDisabled}
      className={props.className}
      onClick={props.onClick}
    >
      <LayerTypeToggleIcon type={props.layerType} />
    </LayerTypeToggleWrapper>
  );
};
