import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Opepen";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Opepen: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="black"/>
      <path d="M6 3H9V4H11V6H12V9H11V11H9V12H6V11H4V9H3V6H4V4H6V3Z" fill="lime"/>
      <path d="M15 3H18V4H20V6H21V9H20V11H18V12H15V11H13V9H12V6H13V4H15V3Z" fill="blue"/>
      <path d="M15 12H18V13H20V15H21V18H20V20H18V21H15V20H13V18H12V15H13V13H15V12Z" fill="white"/>
      <path d="M6 12H9V13H11V15H12V18H11V20H9V21H6V20H4V18H3V15H4V13H6V12Z" fill="red"/>
      <rect x="21" y="2" width="1" height="1" fill="white"/>
    </svg>
  );
};

Opepen.layerType = LAYER_TYPE;
Opepen.gender = GENDER;
Opepen.title = TITLE;
Opepen.key = KEY;
Opepen.paletteNames = PALETTE_NAMES;
Opepen.price = 0.001;
