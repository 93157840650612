import { LayerFC } from "../types/LayerFC";
import { Man } from "./Man";
import { Woman } from "./Woman";
import { ApeMan } from "./ApeMan";
import { ApeWoman } from "./ApeWoman";
import { AlienMan } from "./AlienMan";
import { AlienWoman } from "./AlienWoman";
import { ZombieMan } from "./ZombieMan";
import { ZombieWoman } from "./ZombieWoman";

export const typeLayers: LayerFC[] = [
  Man,
  Woman,
  ApeMan,
  ApeWoman,
  ZombieMan,
  ZombieWoman,
  AlienMan,
  AlienWoman,
];
