import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Short 7";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Short7: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Short7, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 3H11V4H12V5H13V4H15V5H17V6H18V7H17V8H18V11H17V12H16V10H15V9H14V10H13V9H10V10H8V11H7V12H5V14H4V12H3V11H4V10H5V9H4V8H5V6H6V5H7V6H8V4H9V5H10V3Z" fill={hairColor} />
    </svg>
  );
};

Short7.allowSemiTransparentColors = false;
Short7.layerType = LAYER_TYPE;
Short7.gender = GENDER;
Short7.title = TITLE;
Short7.key = KEY;
Short7.paletteNames = PALETTE_NAMES;
Short7.blacklist = [ApeMan, AlienMan];
