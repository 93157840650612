import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Type";
const GENDER: Gender = "Man";
const TITLE = "Ape";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Ape Skin";
const PALETTE_NAME_2: PaletteName = "Nose";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const ApeMan: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(ApeMan, PALETTE_NAME_1).colors;
  }, [palettes]);

  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(ApeMan, PALETTE_NAME_2).colors;
  }, [palettes]);

  const skinColor = useMemo(() => palette1[0], [palette1]);
  const secondarySkinColor = useMemo(() => palette1[1], [palette1]);
  const noseColor = useMemo(() => palette2[0], [palette2]);

  if (skinColor === "#EB8DEC") {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 6H8V7H7V9V12H6V14H7V24H8H9H10V21H9V20H8V18H9V17H10V16H9V15H8V9H9V8H15V9H16V7H15V6ZM16 16H15V17H16V16Z"
          fill="#EB8DEC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 8H9V9H8V15H9V16H10V17H9V20H10V21H15V20H16V17H15V16H16V9H15V8Z"
          fill="#F0D6AE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 5H8V6H7V7H6V12H5V14H6V24H7V14H6V12H7V7H8V6H15V7H16V20H15V21H11H10V20H9V18H8V20H9V21H10V24H11V22H15V21H16V20H17V7H16V6H15V5Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 15H11V16H12V15ZM14 15H13V16H14V15Z"
          fill={noseColor}
        />
        <rect x="7" y="16" width="1" height="3" fill="#3B43ED" />
        <rect x="7" y="15" width="1" height="1" fill="#99F7A2" />
        <rect x="7" y="19" width="1" height="2" fill="#99F7A2" />
        <rect x="7" y="21" width="1" height="3" fill="#F7ED8D" />
        <rect x="10" y="7" width="4" height="1" fill="#F7ED8D" />
        <rect x="15" y="7" width="1" height="1" fill="#3B43ED" />
        <rect x="8" y="6" width="1" height="1" fill="#99F7A2" />
        <rect x="7" y="7" width="1" height="1" fill="#99F7A2" />
        <rect x="6" y="12" width="2" height="1" fill="#F7ED8D" />
        <rect x="7" y="9" width="1" height="1" fill="#3B43ED" />
        <rect x="7" y="8" width="2" height="1" fill="#F7ED8D" />
        <rect x="11" y="6" width="4" height="1" fill="#3B43ED" />
        <rect x="8" y="23" width="1" height="1" fill="#99F7A2" />
        <rect x="8" y="18" width="1" height="2" fill="#3B43ED" />
      </svg>
    );
  }

  if (skinColor === "#637C4D") {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 6H8V7H7V9V12H6V14H7V24H8H9H10V21H9V20H8V18H9V17H10V16H9V15H8V9H9V8H15V9H16V7H15V6ZM16 16H15V17H16V16Z"
          fill="#637C4D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 8H9V9H8V15H9V16H10V17H9V20H10V21H15V20H16V17H15V16H16V9H15V8Z"
          fill="#E1C8A4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 5H8V6H7V7H6V12H5V14H6V24H7V14H6V12H7V7H8V6H15V7H16V20H15V21H11H10V20H9V18H8V20H9V21H10V24H11V22H15V21H16V20H17V7H16V6H15V5Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 15H11V16H12V15ZM14 15H13V16H14V15Z"
          fill={noseColor}
        />
        <rect x="7" y="22" width="1" height="2" fill="#AD6661" />
        <rect x="8" y="22" width="1" height="2" fill="#6C130A" />
        <rect x="7" y="21" width="1" height="1" fill="#6C130A" />
        <rect x="6" y="12" width="1" height="1" fill="#6C130A" />
        <rect x="9" y="7" width="1" height="1" fill="#6C130A" />
        <rect x="10" y="6" width="1" height="1" fill="#6C130A" />
        <rect x="14" y="6" width="1" height="1" fill="#6C130A" />
        <rect x="15" y="7" width="1" height="1" fill="#6C130A" />
        <rect x="7" y="8" width="2" height="1" fill="#6C130A" />
        <rect x="7" y="7" width="2" height="1" fill="#AD6661" />
        <rect x="8" y="6" width="2" height="1" fill="#AD6661" />
      </svg>
    );
  }

  if (skinColor === "#3C444D") {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15 6H8V7H7V9V12H6V14H7V24H8H9H10V21H9V20H8V18H9V17H10V16H9V15H8V9H9V8H15V9H16V7H15V6ZM16 16H15V17H16V16Z" fill="#3C444D" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15 8H9V9H8V15H9V16H10V17H9V20H10V21H15V20H16V17H15V16H16V9H15V8Z" fill="#F7D9B0" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15 5H8V6H7V7H6V12H5V14H6V24H7V14H6V12H7V7H8V6H15V7H16V20H15V21H11H10V20H9V18H8V20H9V21H10V24H11V22H15V21H16V20H17V7H16V6H15V5Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12 15H11V16H12V15ZM14 15H13V16H14V15Z" fill={noseColor} />
        <path d="M10 6H11V8H10V6Z" fill="#7F8FA4" />
        <path d="M8 20H9V21H8V20Z" fill="#7F8FA4" />
        <path d="M9 21H10V23H9V21Z" fill="#7F8FA4" />
        <path d="M7 12H8V15H7V12Z" fill="#7F8FA4" />
        <path d="M9 15H8V16H9V15Z" fill="#7F8FA4" />
      </svg>
    );
  }

  if (skinColor === "#FE015C") {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15 6H8V7H7V9V12H6V14H7V24H8H9H10V21H9V20H8V18H9V17H10V16H9V15H8V9H9V8H15V9H16V7H15V6ZM16 16H15V17H16V16Z" fill="#FE015C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15 8H9V9H8V15H9V16H10V17H9V20H10V21H15V20H16V17H15V16H16V9H15V8Z" fill="#E4C8A1" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15 5H8V6H7V7H6V12H5V14H6V24H7V14H6V12H7V7H8V6H15V7H16V20H15V21H11H10V20H9V18H8V20H9V21H10V24H11V22H15V21H16V20H17V7H16V6H15V5Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12 15H11V16H12V15ZM14 15H13V16H14V15Z" fill="black" />
        <path d="M11 6H14V7H11V6Z" fill="white" />
        <path d="M8 21H9V22H8V21Z" fill="white" />
        <path d="M9 7H8V6H9V7Z" fill="white" />
        <path d="M7 19H8V20H7V19Z" fill="white" />
        <path d="M9 23H8V24H9V23Z" fill="white" />
        <path d="M12 6H13V7H12V6Z" fill="#15CB6B" />
        <path d="M9 21H10V22H9V21Z" fill="#15CB6B" />
        <path d="M10 23H9V24H10V23Z" fill="#15CB6B" />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6H8V7H7V9V12H6V14H7V24H8H9H10V21H9V20H8V18H9V17H10V16H9V15H8V9H9V8H15V9H16V7H15V6ZM16 16H15V17H16V16Z"
        fill={skinColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8H9V9H8V15H9V16H10V17H9V20H10V21H15V20H16V17H15V16H16V9H15V8Z"
        fill={secondarySkinColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 5H8V6H7V7H6V12H5V14H6V24H7V14H6V12H7V7H8V6H15V7H16V20H15V21H11H10V20H9V18H8V20H9V21H10V24H11V22H15V21H16V20H17V7H16V6H15V5Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 15H11V16H12V15ZM14 15H13V16H14V15Z"
        fill={noseColor}
      />
    </svg>
  );
};

ApeMan.layerType = LAYER_TYPE;
ApeMan.gender = GENDER;
ApeMan.title = TITLE;
ApeMan.key = KEY;
ApeMan.paletteNames = PALETTE_NAMES;
