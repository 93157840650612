import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";

import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "Shades L";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const ShadesL1px: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 11H6V12H8V13V14H9V15H11V14H12V13H13V14H14V15H16V14H17V13V12V11Z"
        fill="black"
      />
    </svg>
  );
};

ShadesL1px.layerType = LAYER_TYPE;
ShadesL1px.gender = GENDER;
ShadesL1px.title = TITLE;
ShadesL1px.key = KEY;
ShadesL1px.paletteNames = PALETTE_NAMES;
ShadesL1px.whitelist = [AlienMan, ApeMan];
