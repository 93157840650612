import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { AlienMan } from "../type/AlienMan";
import { ApeMan } from "../type/ApeMan";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Beard";
const GENDER: Gender = "Man";
const TITLE = "Chin";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Beard";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Chin: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Chin, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="12" y="20" width="1" height="1" fill={hairColor} />
    </svg>
  );
};

Chin.layerType = LAYER_TYPE;
Chin.gender = GENDER;
Chin.title = TITLE;
Chin.key = KEY;
Chin.paletteNames = PALETTE_NAMES;
Chin.blacklist = [ApeMan, AlienMan]
