import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Cap Nouns";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapNouns: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 8H20V9H6V6H7V5H8V4H15V5H16V6H17V8Z" fill="black" />
      <path d="M11.25 6H12.9167V6.41669H13.3333V6.83335H12.9167V7.67H11.25V6Z" fill="#FF0000" />
      <path d="M13.3333 6H15V7.67H13.3333V6Z" fill="#FF0000" />
      <path d="M10 6.41669H11.25V6.83335H10.4167V7.25002H10V6.41669Z" fill="#FF0000" />
      <rect x="14.1667" y="6.41669" width="0.416667" height="0.833333" fill="black" />
      <rect x="13.75" y="6.41669" width="0.416667" height="0.833333" fill="white" />
      <rect x="11.6667" y="6.41669" width="0.416667" height="0.833333" fill="white" />
      <rect x="12.0833" y="6.41669" width="0.416667" height="0.833333" fill="black" />
    </svg>
  );
};

CapNouns.layerType = LAYER_TYPE;
CapNouns.gender = GENDER;
CapNouns.title = TITLE;
CapNouns.key = KEY;
CapNouns.paletteNames = PALETTE_NAMES;
CapNouns.isBranded = true
CapNouns.price = 0.001;
