import { LayerFC } from "../../types/LayerFC";
import { Angry } from "./Angry";
import { Grim } from "./Grim";
import { Left } from "./Left";
import { OneRobotEye } from "./OneRoboEye";
import { Right } from "./Right";
import { Scan } from "./Scan";
import { Sceptic } from "./Sceptic";
import { Sleep } from "./Sleep";
import { Wink } from "./Wink";
import { ZombieDefault } from "./ZombieDefault";
import { ZombieFull } from "./ZombieFull";

export const eyesManLayers: LayerFC[] = [
  Right,
  Left,
  Grim,
  Wink,
  Sleep,
  Angry,
  Sceptic,
  OneRobotEye,
  Scan,
  ZombieDefault,
  ZombieFull,
];
