import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Cap Layer3";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapLayer3: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 6H9V7H8V8H7V11H20V10H17V8H16V7H15V6Z" fill="black" />
      <path
        d="M14 7.5H13V8H12.5V8.5H13V8H14V8.5H13.5V9H14V9.5H13V9H12.5V9.5H13V10H14V9.5H14.5V8H14V7.5Z"
        fill="#FFFC55"
      />
      <path d="M11 7.5H10.5V10H12V9.5H11V7.5Z" fill="#FFFC55" />
    </svg>
  );
};

CapLayer3.layerType = LAYER_TYPE;
CapLayer3.gender = GENDER;
CapLayer3.title = TITLE;
CapLayer3.key = KEY;
CapLayer3.paletteNames = PALETTE_NAMES;
CapLayer3.isBranded = true;
CapLayer3.price = 0.01;
