import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "Huge";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Glass";
const PALETTE_NAME_2: PaletteName = "Frame";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const Transparent: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Transparent, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Transparent, PALETTE_NAME_2).colors;
  }, [palettes]);

  const glassesColor = useMemo(() => palette1[0], [palette1]);
  const frameColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 10H7V11H5V12H7V14H8V15H11V14H12V13H13V14H14V15H17V14H18V10H17H13V11H12V10H8ZM13 11H17V14H14V13H13V11ZM12 11V13H11V14H8V11H12Z"
        fill={frameColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 11H8V14H11V13H12V11H11ZM13 11H14H17V14H14V13H13V11Z"
        fill={glassesColor}
      />
    </svg>
  );
};

Transparent.layerType = LAYER_TYPE;
Transparent.gender = GENDER;
Transparent.title = TITLE;
Transparent.key = KEY;
Transparent.paletteNames = PALETTE_NAMES;
