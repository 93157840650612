import { PaletteName } from "../../../../constants/Palettes";
import { ZombieMan } from "../../type/ZombieMan";
import { Man } from "../../type/Man";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Mouth";
const GENDER: Gender = "Man";
const TITLE = "Tongue Outside";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const TongueOutside: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="10" y="18" width="5" height="2" fill="black" />
      <path d="M15 20V22H13V21H12V20H11V19H14V20H15Z" fill="#FC7171" />
      <rect x="10" y="17" width="5" height="1" fill="white" />
    </svg>
  );
};

TongueOutside.layerType = LAYER_TYPE;
TongueOutside.gender = GENDER;
TongueOutside.title = TITLE;
TongueOutside.key = KEY;
TongueOutside.paletteNames = PALETTE_NAMES;
TongueOutside.blacklist = [Man, ZombieMan]
