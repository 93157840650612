import { PaletteName } from "../../../../constants/Palettes";
import { ZombieMan } from "../../type/ZombieMan";
import { Man } from "../../type/Man";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Mouth";
const GENDER: Gender = "Man";
const TITLE = "Laugh Tongue";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const LaughTongueWide: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="10" y="17" width="5" height="1" fill="white"/>
    <rect x="10" y="18" width="5" height="1" fill="black"/>
    <rect x="11" y="18" width="3" height="1" fill="#E76161"/>
    </svg>
  );
};

LaughTongueWide.layerType = LAYER_TYPE;
LaughTongueWide.gender = GENDER;
LaughTongueWide.title = TITLE;
LaughTongueWide.key = KEY;
LaughTongueWide.paletteNames = PALETTE_NAMES;
LaughTongueWide.blacklist = [Man, ZombieMan]
