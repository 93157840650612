import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { ApeWoman } from "../../type/ApeWoman";
import { Woman } from "../../type/Woman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Eyes";
const GENDER: Gender = "Woman";
const TITLE = "Angry";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Eyes";
const PALETTE_NAME_2: PaletteName = "Sclera";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const Angry: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Angry, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Angry, PALETTE_NAME_2).colors;
  }, [palettes]);

  const eyesColor = useMemo(() => palette1[0], [palette1]);
  const scleraColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 11H9V12H11V13H12V12H11V11ZM16 11H14V12H13V13H14V12H16V11Z"
        fill="black"
      />
      <path d="M9 13H10V14H9V13Z" fill={scleraColor} />
      <path d="M14 13H15V14H14V13Z" fill={scleraColor} />
      <path d="M9 12H11V13H9V12Z" fill="black" fillOpacity="0.15" />
      <path d="M14 12H16V13H14V12Z" fill="black" fillOpacity="0.15" />
      <path d="M10 13H11V14H10V13Z" fill={eyesColor} />
      <path d="M15 13H16V14H15V13Z" fill={eyesColor} />
    </svg>
  );
};

Angry.layerType = LAYER_TYPE;
Angry.gender = GENDER;
Angry.title = TITLE;
Angry.key = KEY;
Angry.paletteNames = PALETTE_NAMES;
Angry.whitelist = [Woman, ApeWoman];