import { LayerFC } from "../../types/LayerFC";
import { Earphones } from "./Earphones";
import { EarringL } from "./EarringL";
import { EarringM } from "./EarringM";
import { EarringS } from "./EarringS";
import { Piercing } from "./Piercing";

export const earWomanLayers: LayerFC[] = [
  Piercing,
  EarringS,
  EarringM,
  EarringL,
  Earphones,
];
