import { LayerFC } from "../../types/LayerFC";
import { Bun } from "./Bun";
import { Crazy } from "./Crazy";
import { Curls } from "./Curls";
import { Dreads } from "./Dreads";
import { FreshWild } from "./FreshWild";
import { Iroquois } from "./Iroquois";
import { Long } from "./Long";
import { Messy } from "./Messy";
import { Middle } from "./Middle";
import { Mohawk } from "./Mohawk";
import { Rasta } from "./Rasta";
import { RecedingHairline } from "./RecedingHairline";
import { Shaved } from "./Shaved";
import { Short1 } from "./Short1";
import { Short2 } from "./Short2";
import { Short3 } from "./Short3";
import { Short4 } from "./Short4";
import { Short5 } from "./Short5";
import { Short6 } from "./Short6";
import { Short7 } from "./Short7";
import { Short8 } from "./Short8";
import { Short9 } from "./Short9";
import { ShortCurl } from "./ShortCurl";
import { ShortCut } from "./ShortCut";
import { ShortWild } from "./ShortWild";
import { Upturned } from "./Upturned";
import { Wild } from "./Wild";
import { WildBun } from "./WildBun";


export const hairManLayers: LayerFC[] = [
    Mohawk,
    Iroquois,
    Upturned,
    Short6,
    Short1,
    Shaved,
    ShortCut,
    Short5,
    Short4,
    RecedingHairline,
    Short2,
    Short3,
    ShortCurl,
    ShortWild,
    Curls,
    Short8,
    Short9,
    Messy,
    Short7,
    Wild,
    Crazy,
    FreshWild,
    Middle,
    Long,
    Dreads,
    Bun,
    WildBun,
    Rasta,
];