import { FC, useMemo, useState } from "react";
import styled from "styled-components";
import { COLOR_MAP } from "../constants/Color";
import { layerFCsPrice } from "../helpers/layerFCsPrice";
import { useMintCount } from "../hooks/useMintCount";
import { CartAttribute } from "./CartAttribute";
import { Icon } from "./Icon";
import { LayerFC } from "./layerFCs/types/LayerFC";
import { MintButton } from "./MintButton";
import { SharePopover } from "./SharePopover";
import { Text } from "./Text";

interface PurchaseSectionProps {
  layerFCs: LayerFC[];
  onSelectedLayerFCChange: (layerFC: LayerFC) => void;
  className?: string;
}

const AttributesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const PurchaseSectionWrapper = styled.div`
  width: 280px;
  @media (max-width: 1250px) {
    margin: 0 auto;
    padding-top: 40px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
    max-width: 632px;
  }
  @media (min-width: 1251px) {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const PurchaseSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
`;

const ShareButton = styled(Icon)`
  color: ${COLOR_MAP.gray300};
  cursor: pointer;
  &:hover {
    color: ${COLOR_MAP.white};
  }
`;

const TotalPrice = styled(Text)`
  margin-top: 32px;
  margin-bottom: 20px;
  margin-left: auto;
  text-align: center;
  font-size: 14px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  &:before {
    content: "";
    display: block;
    width: 20px;
  }
`;

const PriceInfoPopover = styled.div`
  position: absolute;
  background-color: ${COLOR_MAP.gray700};
  border: 1px solid ${COLOR_MAP.gray500};
  display: flex;
  flex-direction: column;
  padding: 12px;
  right: 0;
  bottom: 30px;
  gap: 8px;
  align-items: flex-start;
`;

const PriceInfoIcon = styled(Icon)`
  color: ${COLOR_MAP.warining};
  cursor: help;
  + ${PriceInfoPopover} {
    display: none;
  }
  &:hover + ${PriceInfoPopover} {
    display: inherit;
  }
`;

export const PurchaseSection: FC<PurchaseSectionProps> = (props) => {
  const [isSharePopoverOpen, setIsSharePopoverOpen] = useState(false);
  const { mintCount } = useMintCount();

  const attributes = useMemo(
    () =>
      props.layerFCs.map((layerFC) => (
        <CartAttribute
          key={layerFC.key}
          onSelectedLayerFCChange={props.onSelectedLayerFCChange}
          layerFC={layerFC}
          layerFCs={props.layerFCs}
        />
      )),
    [props.layerFCs, props.onSelectedLayerFCChange]
  );

  const totalPrice = useMemo(
    () => layerFCsPrice(props.layerFCs),
    [props.layerFCs]
  );

  return (
    <PurchaseSectionWrapper className={props.className}>
      {mintCount !== undefined && (
        <>
          <PurchaseSectionHeader>
            <Text $fontColor="gray300" $fontSize="normal">
              {mintCount < 10_000 ? "Traits" : "Share"}
            </Text>
            <ShareButton
              type="Share"
              onClick={() => setIsSharePopoverOpen(true)}
            />
          </PurchaseSectionHeader>
          {mintCount < 10_000 && (
            <>
              {isSharePopoverOpen && (
                <SharePopover onClose={() => setIsSharePopoverOpen(false)} />
              )}
              <AttributesWrapper>{attributes}</AttributesWrapper>
              <TotalPrice $fontColor="primary" className="btn-mint">
                <Text>{totalPrice.toFixed(3)} ETH</Text>
                <PriceInfoIcon type="Info" />
                <PriceInfoPopover>
                  <Text $fontSize="normal" $fontColor="gray300">
                    Why so high?
                  </Text>
                  <Text $fontSize="normal" $fontColor="gray100">
                    We pay the mint costs
                  </Text>
                </PriceInfoPopover>
              </TotalPrice>
              <MintButton price={totalPrice} layerFCs={props.layerFCs} />
            </>
          )}
        </>
      )}
    </PurchaseSectionWrapper>
  );
};
