import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Pixelenca 10";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Pixelenca10: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="#1A1A1A" />
      <rect x="1" y="1" width="5" height="1" fill="#B8D9CE" />
      <rect x="7" y="1" width="5" height="1" fill="#B8D9CE" />
      <rect x="15" y="1" width="2" height="1" fill="#1F3359" />
      <rect x="18" y="1" width="5" height="1" fill="#FCBC19" />
      <rect x="12" y="1" width="1" height="1" fill="#FCBC19" />
      <rect x="2" y="9" width="10" height="2" fill="#FCBC19" />
      <rect x="1" y="14" width="8" height="2" fill="#B8D9CE" />
      <rect x="10" y="14" width="13" height="2" fill="#305D8A" />
      <rect x="8" y="17" width="15" height="4" fill="#B8D9CE" />
      <rect x="14" y="1" width="1" height="1" fill="#E0D7C5" />
      <rect x="1" y="3" width="1" height="5" fill="#305D8A" />
      <rect x="2" y="3" width="21" height="5" fill="#1F3359" />
      <rect x="14" y="9" width="8" height="2" fill="#1F3359" />
      <rect x="22" y="9" width="1" height="2" fill="#B8D9CE" />
      <rect x="13" y="9" width="1" height="2" fill="#FCBC19" />
      <rect x="1" y="9" width="1" height="2" fill="#E0D7C5" />
      <rect x="14" y="12" width="9" height="1" fill="#E0D7C5" />
      <rect x="1" y="12" width="10" height="1" fill="#DB4F54" />
      <rect x="21" y="22" width="2" height="1" fill="#DB4F54" />
      <rect x="1" y="22" width="9" height="1" fill="#7CA9BF" />
      <rect x="11" y="22" width="9" height="1" fill="#FCBC19" />
      <rect x="1" y="17" width="6" height="4" fill="#305D8A" />
    </svg>
  );
};

Pixelenca10.layerType = LAYER_TYPE;
Pixelenca10.gender = GENDER;
Pixelenca10.title = TITLE;
Pixelenca10.key = KEY;
Pixelenca10.paletteNames = PALETTE_NAMES;
Pixelenca10.price = 0.001;
