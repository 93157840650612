import { LayerFC } from "../types/LayerFC";
import { None } from "./None";
import { BubbleGum } from "./BubbleGum";
import { HeartKiss } from "./HeartKiss";
import { Mic } from "./Mic";
import { Pencil } from "./Pencil";
import { Display } from "./Display";
import { Pipe } from "./Pipe";
import { Cigarette } from "./Cigarette";
import { Cigar } from "./Cigar";
import { Pizza } from "./Pizza";
import { Banana } from "./Banana";
import { Confetti } from "./Confetti";
import { Slobber } from "./Slobber";
import { BigChainMan } from "./BigChainMan";
import { SmallChainMan } from "./SmallChainMan";
import { BeatsMan } from "./BeatsMan";
import { LaserMan } from "./LaserMan";
import { SweatMan } from "./SweatMan";
import { CheeksMan } from "./CheeksMan";
import { HeadbandMan } from "./HeadbandMan";
import { EyePiercingMan } from "./EyePiercingMan";
import { ChinMan } from "./ChinMan";
import { BigChainWoman } from "./BigChainWoman";
import { SmallChainWoman } from "./SmallChainWoman";
import { LaserWoman } from "./LaserWoman";
import { BindlWoman } from "./BindlWoman";
import { ChokerWoman } from "./ChokerWoman";
import { SweatWoman } from "./SweatWoman";
import { CheeksWoman } from "./CheeksWoman";
import { HeadbandWoman } from "./HeadbandWoman";
import { EyePiercingWoman } from "./EyePiercingWoman";
import { BloodS } from "./BloodS";
import { BloodM } from "./BloodM";
import { BloodL } from "./BloodL";
import { BloodXL } from "./BloodXL";
import { TattooFaceMan } from "./TattooFaceMan";
import { TattooNeckMan } from "./TattooNeckMan";
import { TattooNeckWoman } from "./TattooNeckWoman";
import { BeatsManCap } from "./BeatsManCap";
import { Surfboard } from "./Surfboard";
import { DiademWoman } from "./DiademWoman";
import { BloodNeckMan } from "./BloodNeckMan";
import { ZombieBrainMan } from "./ZombieBrainMan";
import { BloodNose } from "./BloodNose";
import { LaserTopMan } from "./LaserTopMan";
import { LaserTopWoman } from "./LaserTopWoman";
import { Skateboard } from "./Skateboard";
import { DevilMan } from "./DevilMan";
import { DevilWoman } from "./DevilWoman";
import { Drink } from "./Drink";
import { Dog } from "./Dog";

export const specialLayers: LayerFC[] = [
    None,
    BubbleGum,
    HeartKiss,
    Mic,
    Pencil,
    Pizza,
    Banana,
    Drink,
    Confetti,
    Slobber,
    Pipe,
    Cigarette,
    Cigar,
    Surfboard,
    Skateboard,
    Display,
    Dog,

    //Only Man
    BeatsMan,
    BeatsManCap,
    HeadbandMan,
    BigChainMan,
    SmallChainMan,
    LaserMan,
    LaserTopMan,
    SweatMan,
    CheeksMan,
    EyePiercingMan,
    ChinMan,
    TattooFaceMan,
    TattooNeckMan,
    BloodNeckMan,
    ZombieBrainMan,
    BloodNose,

    //Only Woman
    BigChainWoman,
    SmallChainWoman,
    LaserWoman,
    LaserTopWoman,
    BindlWoman,
    ChokerWoman,
    SweatWoman,
    CheeksWoman,
    EyePiercingWoman,
    TattooNeckWoman,
    DiademWoman,
    HeadbandWoman,

    //Only Zombie
    BloodS,
    BloodM,
    BloodL,
    BloodXL,

    //Only Ape
    DevilMan,
    DevilWoman,
];
