import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Crown";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Crown: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 2H13V3H12V2ZM12 4H11V3H12V4ZM13 4H12V5H8V4H9V3H8V4H7V5H8V6H7V7H6V9H7V11H17V9H18V7H17V6H16V5H17V4H16V3H15V4H16V5H13V4ZM13 4H14V3H13V4Z" fill="#F1D743"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10 6H9V7H8V9H9V7H10V6ZM13 6H12V7H11V9H14V7H13V6ZM17 7H16V6H15V7H16V9H17V7Z" fill="#D8BD28"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13 3H12V4H13V3ZM9 9H8V10H9V9ZM13 9H12V10H13V9ZM16 9H17V10H16V9Z" fill="#E53D65"/>
    </svg>
  );
};

Crown.layerType = LAYER_TYPE;
Crown.gender = GENDER;
Crown.title = TITLE;
Crown.key = KEY;
Crown.paletteNames = PALETTE_NAMES;
