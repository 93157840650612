import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Pixelenca 1";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Pixelenca1: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="#EAE4D9" />
      <rect x="1" y="1" width="2" height="10" fill="#DED7C7" />
      <rect x="1" y="12" width="2" height="1" fill="#84A8BD" />
      <rect x="21" y="14" width="2" height="2" fill="#84A8BD" />
      <rect x="21" y="17" width="2" height="2" fill="#F3BD46" />
      <rect x="21" y="20" width="2" height="3" fill="#DED7C7" />
      <rect x="1" y="14" width="2" height="6" fill="#F3BD46" />
      <rect x="1" y="21" width="2" height="2" fill="#BED8CE" />
      <rect x="4" y="6" width="1" height="2" fill="#BED8CE" />
      <rect x="4" y="9" width="1" height="8" fill="#ECB4A4" />
      <rect x="4" y="18" width="1" height="6" fill="#DED7C7" />
      <rect x="4" width="1" height="5" fill="#F6D276" />
      <rect x="6" y="1" width="1" height="3" fill="#84A8BD" />
      <rect x="6" y="5" width="1" height="8" fill="#DED7C7" />
      <rect x="6" y="14" width="1" height="9" fill="#52A492" />
      <rect x="11" y="7" width="1" height="7" fill="#52A492" />
      <rect x="11" y="15" width="1" height="5" fill="#DED7C7" />
      <rect x="8" width="2" height="4" fill="#DED7C7" />
      <rect x="8" y="5" width="2" height="11" fill="#BED8CE" />
      <rect x="13" y="4" width="2" height="3" fill="#ECB4A4" />
      <rect x="13" y="8" width="2" height="12" fill="#F6D276" />
      <rect x="8" y="17" width="2" height="7" fill="#84A8BD" />
      <rect x="16" y="1" width="2" height="3" fill="#F3BD46" />
      <rect x="13" width="2" height="3" fill="#BED8CE" />
      <rect x="11" y="1" width="1" height="5" fill="#ECB4A4" />
      <rect x="16" y="5" width="2" height="8" fill="#DED7C7" />
      <rect x="19" y="3" width="1" height="4" fill="#84A8BD" />
      <rect x="19" y="8" width="1" height="9" fill="#BED8CE" />
      <rect x="21" y="1" width="2" height="5" fill="#ECB4A4" />
      <rect x="21" y="7" width="2" height="6" fill="#DED7C7" />
      <rect x="19" y="18" width="1" height="6" fill="#F6D276" />
      <rect x="16" y="14" width="2" height="4" fill="#ECB4A4" />
      <rect x="16" y="19" width="2" height="4" fill="#84A8BD" />
      <rect x="13" y="21" width="2" height="3" fill="#DED7C7" />
      <rect x="11" y="21" width="1" height="2" fill="#DED7C7" />
      <rect x="19" width="1" height="2" fill="#52A492" />
    </svg>
  );
};

Pixelenca1.layerType = LAYER_TYPE;
Pixelenca1.gender = GENDER;
Pixelenca1.title = TITLE;
Pixelenca1.key = KEY;
Pixelenca1.paletteNames = PALETTE_NAMES;
Pixelenca1.price = 0.002;
