import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Crazy";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Crazy: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Crazy, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2H8V3H9V4H10V3H12V2H13V3H14V4H13V5H14V4H16V3H17V4V5H18V6H20V7H19V8H17V9H16V8H15V9H14V8H13V9H12V8H10V9H9V10H8V9H6V12H5V14H4V12H3V10H4V8H3H2V6H3V7H5V6H4V5H6V4V3H7V2ZM4 14V15H3V14H4Z"
        fill={hairColor}
      />
    </svg>
  );
};

Crazy.allowSemiTransparentColors = false;
Crazy.layerType = LAYER_TYPE;
Crazy.gender = GENDER;
Crazy.title = TITLE;
Crazy.key = KEY;
Crazy.paletteNames = PALETTE_NAMES;
Crazy.blacklist = [ApeMan, AlienMan]
