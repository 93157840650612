import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Sky";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Sky: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="#4CC1FF"/>
      <path d="M13 0H24V7H23V8H15V7H13V6H11V4H12V2H13V0Z" fill="white"/>
      <path d="M4 8H8V10H9V12H8V13H3V14H0V6H2V7H4V8Z" fill="white"/>
    </svg>
  );
};

Sky.layerType = LAYER_TYPE;
Sky.gender = GENDER;
Sky.title = TITLE;
Sky.key = KEY;
Sky.paletteNames = PALETTE_NAMES;
