import { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Text } from "./Text";

interface LogoProps {
  className?: string;
}

const LogoWrapper = styled(Text)`
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
    color: white;
  }
  &:after {
    content: "SMP";
    font-size: 12px;
    @media screen and (min-width: 541px) {
      content: "SelfMadePunks";
    }
  }
`;

const LogoImg = styled.img`
  width: 21px;
  height: 18px;
  padding-right: 8px;
`;

export const Logo: FC<LogoProps> = (props) => {
  return (
    <LogoWrapper
      className={props.className}
      $fontColor="white"
      as={Link}
      to="/"
    >
      <LogoImg src="/logo/smp.svg" alt="Logo" />
    </LogoWrapper>
  );
};
