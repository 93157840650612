import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Base";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Base: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="#2753F5"/>
      <rect width="1" height="1" fill="#C2CFFC"/>
      <rect x="1" width="1" height="1" fill="#C0CDFC"/>
      <rect x="2" width="1" height="1" fill="#BECBFC"/>
      <rect x="3" width="1" height="1" fill="#BBC9FC"/>
      <rect x="4" width="1" height="1" fill="#B9C7FB"/>
      <rect x="5" width="1" height="1" fill="#B5C4FB"/>
      <rect x="6" width="1" height="1" fill="#B2C1FB"/>
      <rect x="7" width="1" height="1" fill="#AEBFFB"/>
      <rect x="8" width="1" height="1" fill="#AABCFB"/>
      <rect x="9" width="1" height="1" fill="#A6B8FB"/>
      <rect x="10" width="1" height="1" fill="#A2B5FA"/>
      <rect x="11" width="1" height="1" fill="#9EB1FA"/>
      <rect x="12" width="1" height="1" fill="#9AAEFA"/>
      <rect x="13" width="1" height="1" fill="#95ABFA"/>
      <rect x="14" width="1" height="1" fill="#91A7FA"/>
      <rect x="15" width="1" height="1" fill="#8DA4FA"/>
      <rect x="16" width="1" height="1" fill="#89A1F9"/>
      <rect x="17" width="1" height="1" fill="#869EF9"/>
      <rect x="18" width="1" height="1" fill="#829CF9"/>
      <rect x="19" width="1" height="1" fill="#8099F9"/>
      <rect x="20" width="1" height="1" fill="#7C97F8"/>
      <rect x="21" width="1" height="1" fill="#7A95F9"/>
      <rect x="22" width="1" height="1" fill="#7893F8"/>
      <rect x="23" width="1" height="1" fill="#7591F9"/>
      <rect y="1" width="1" height="1" fill="#C5D1FC"/>
      <rect y="11" width="1" height="1" fill="#E7ECFE"/>
      <rect y="9" width="1" height="1" fill="#DFE5FD"/>
      <rect y="10" width="1" height="1" fill="#E3E9FE"/>
      <rect y="8" width="1" height="1" fill="#DBE2FD"/>
      <rect y="7" width="1" height="1" fill="#D7DFFD"/>
      <rect y="6" width="1" height="1" fill="#D3DCFD"/>
      <rect y="5" width="1" height="1" fill="#D0DAFD"/>
      <rect y="4" width="1" height="1" fill="#CDD7FD"/>
      <rect y="3" width="1" height="1" fill="#CAD5FD"/>
      <rect y="2" width="1" height="1" fill="#C7D3FC"/>
      <rect x="1" y="1" width="1" height="1" fill="#C2CFFC"/>
      <rect x="1" y="11" width="1" height="1" fill="#E7ECFE"/>
      <rect x="1" y="9" width="1" height="1" fill="#DEE5FD"/>
      <rect x="1" y="10" width="1" height="1" fill="#E3E9FD"/>
      <rect x="1" y="8" width="1" height="1" fill="#D9E1FD"/>
      <rect x="1" y="7" width="1" height="1" fill="#D5DEFD"/>
      <rect x="1" y="6" width="1" height="1" fill="#D2DBFC"/>
      <rect x="1" y="5" width="1" height="1" fill="#CED8FC"/>
      <rect x="1" y="4" width="1" height="1" fill="#CAD5FC"/>
      <rect x="1" y="3" width="1" height="1" fill="#C8D3FC"/>
      <rect x="1" y="2" width="1" height="1" fill="#C5D1FC"/>
      <rect x="2" y="1" width="1" height="1" fill="#C0CDFC"/>
      <rect x="2" y="11" width="1" height="1" fill="#E7ECFE"/>
      <rect x="2" y="9" width="1" height="1" fill="#DDE4FD"/>
      <rect x="2" y="10" width="1" height="1" fill="#E2E8FD"/>
      <rect x="2" y="8" width="1" height="1" fill="#D8E0FD"/>
      <rect x="2" y="7" width="1" height="1" fill="#D4DDFD"/>
      <rect x="2" y="6" width="1" height="1" fill="#CFD9FD"/>
      <rect x="2" y="5" width="1" height="1" fill="#CCD6FC"/>
      <rect x="2" y="4" width="1" height="1" fill="#C8D3FC"/>
      <rect x="2" y="3" width="1" height="1" fill="#C5D1FC"/>
      <rect x="2" y="2" width="1" height="1" fill="#C5D1FC"/>
      <rect x="3" y="1" width="1" height="1" fill="#BDCAFC"/>
      <rect x="3" y="11" width="1" height="1" fill="#E7ECFE"/>
      <rect x="3" y="9" width="1" height="1" fill="#DBE3FD"/>
      <rect x="3" y="10" width="1" height="1" fill="#E1E7FD"/>
      <rect x="3" y="8" width="1" height="1" fill="#D6DEFD"/>
      <rect x="3" y="7" width="1" height="1" fill="#D1DAFC"/>
      <rect x="3" y="6" width="1" height="1" fill="#CDD7FC"/>
      <rect x="3" y="5" width="1" height="1" fill="#C9D4FC"/>
      <rect x="3" y="4" width="1" height="1" fill="#C5D1FC"/>
      <rect x="3" y="3" width="1" height="1" fill="#C2CFFC"/>
      <rect x="3" y="2" width="1" height="1" fill="#C0CDFC"/>
      <rect x="4" y="1" width="1" height="1" fill="#BBC9FC"/>
      <rect x="4" y="11" width="1" height="1" fill="#E6EBFE"/>
      <rect x="4" y="9" width="1" height="1" fill="#DAE1FD"/>
      <rect x="4" y="10" width="1" height="1" fill="#E0E6FD"/>
      <rect x="4" y="8" width="1" height="1" fill="#D4DDFD"/>
      <rect x="4" y="7" width="1" height="1" fill="#CFD9FD"/>
      <rect x="4" y="6" width="1" height="1" fill="#CAD5FC"/>
      <rect x="4" y="5" width="1" height="1" fill="#C6D1FC"/>
      <rect x="4" y="4" width="1" height="1" fill="#C2CFFC"/>
      <rect x="4" y="3" width="1" height="1" fill="#BFCCFC"/>
      <rect x="4" y="2" width="1" height="1" fill="#BDCAFC"/>
      <rect x="5" y="1" width="1" height="1" fill="#B7C6FB"/>
      <rect x="5" y="11" width="1" height="1" fill="#E6EBFE"/>
      <rect x="5" y="9" width="1" height="1" fill="#D8DFFD"/>
      <rect x="5" y="10" width="1" height="1" fill="#DFE5FD"/>
      <rect x="5" y="8" width="1" height="1" fill="#D1DAFC"/>
      <rect x="5" y="7" width="1" height="1" fill="#CBD6FC"/>
      <rect x="5" y="6" width="1" height="1" fill="#C6D2FC"/>
      <rect x="5" y="5" width="1" height="1" fill="#C2CFFC"/>
      <rect x="5" y="4" width="1" height="1" fill="#BFCCFC"/>
      <rect x="5" y="3" width="1" height="1" fill="#BCCAFB"/>
      <rect x="5" y="2" width="1" height="1" fill="#B9C7FB"/>
      <rect x="6" y="1" width="1" height="1" fill="#B3C3FC"/>
      <rect x="6" y="8" width="1" height="1" fill="#CED7FD"/>
      <rect x="6" y="7" width="1" height="1" fill="#C7D3FC"/>
      <rect x="6" y="6" width="1" height="1" fill="#C2CFFC"/>
      <rect x="6" y="5" width="1" height="1" fill="#BECBFC"/>
      <rect x="6" y="4" width="1" height="1" fill="#BBC9FB"/>
      <rect x="6" y="3" width="1" height="1" fill="#B8C6FB"/>
      <rect x="6" y="2" width="1" height="1" fill="#B5C4FB"/>
      <rect x="7" y="1" width="1" height="1" fill="#B0C0FB"/>
      <rect x="6" y="11" width="1" height="1" fill="#E4EAFE"/>
      <rect x="6" y="9" width="1" height="1" fill="#D1DAFD"/>
      <rect x="6" y="10" width="1" height="1" fill="#DAE1FD"/>
      <rect x="7" y="11" width="1" height="1" fill="#E4EAFE"/>
      <rect x="7" y="9" width="1" height="1" fill="#D1DAFD"/>
      <rect x="7" y="10" width="1" height="1" fill="#DAE1FD"/>
      <rect x="7" y="8" width="1" height="1" fill="#C9D4FC"/>
      <rect x="7" y="7" width="1" height="1" fill="#C3CFFC"/>
      <rect x="7" y="6" width="1" height="1" fill="#BECBFC"/>
      <rect x="7" y="5" width="1" height="1" fill="#BAC8FC"/>
      <rect x="7" y="4" width="1" height="1" fill="#B6C5FB"/>
      <rect x="7" y="3" width="1" height="1" fill="#B4C3FB"/>
      <rect x="7" y="2" width="1" height="1" fill="#B2C1FB"/>
      <rect x="8" y="1" width="1" height="1" fill="#ABBCFB"/>
      <rect x="8" y="11" width="1" height="1" fill="#E2E8FD"/>
      <rect x="8" y="9" width="1" height="1" fill="#CBD6FC"/>
      <rect x="8" y="10" width="1" height="1" fill="#D6DEFD"/>
      <rect x="8" y="8" width="1" height="1" fill="#C3CFFC"/>
      <rect x="8" y="7" width="1" height="1" fill="#BCCAFB"/>
      <rect x="8" y="6" width="1" height="1" fill="#B8C6FB"/>
      <rect x="8" y="5" width="1" height="1" fill="#B4C3FB"/>
      <rect x="8" y="4" width="1" height="1" fill="#B2C1FB"/>
      <rect x="8" y="3" width="1" height="1" fill="#AFBFFB"/>
      <rect x="8" y="2" width="1" height="1" fill="#ADBEFB"/>
      <rect x="9" y="1" width="1" height="1" fill="#A7B9FB"/>
      <rect x="9" y="11" width="1" height="1" fill="#E0E6FD"/>
      <rect x="9" y="9" width="1" height="1" fill="#C2CFFC"/>
      <rect x="9" y="10" width="1" height="1" fill="#CFD9FD"/>
      <rect x="9" y="8" width="1" height="1" fill="#BBC8FC"/>
      <rect x="9" y="7" width="1" height="1" fill="#B5C4FB"/>
      <rect x="9" y="6" width="1" height="1" fill="#B1C1FB"/>
      <rect x="9" y="5" width="1" height="1" fill="#AEBEFB"/>
      <rect x="9" y="4" width="1" height="1" fill="#ABBCFB"/>
      <rect x="9" y="3" width="1" height="1" fill="#AABBFB"/>
      <rect x="9" y="2" width="1" height="1" fill="#A8BAFA"/>
      <rect x="10" y="1" width="1" height="1" fill="#D9D9D9"/>
      <rect x="10" y="11" width="1" height="1" fill="black"/>
      <rect x="10" y="9" width="1" height="1" fill="black"/>
      <rect x="10" y="10" width="1" height="1" fill="black"/>
      <rect x="10" y="8" width="1" height="1" fill="black"/>
      <rect x="10" y="7" width="1" height="1" fill="black"/>
      <rect x="10" y="6" width="1" height="1" fill="#D9D9D9"/>
      <rect x="10" y="5" width="1" height="1" fill="#D9D9D9"/>
      <rect x="10" y="4" width="1" height="1" fill="#D9D9D9"/>
      <rect x="10" y="3" width="1" height="1" fill="#D9D9D9"/>
      <rect x="10" y="2" width="1" height="1" fill="#D9D9D9"/>
      <rect x="10" y="1" width="1" height="1" fill="#A3B5FA"/>
      <rect x="10" y="11" width="1" height="1" fill="#DAE1FD"/>
      <rect x="10" y="9" width="1" height="1" fill="#B7C5FC"/>
      <rect x="10" y="10" width="1" height="1" fill="#C3CFFC"/>
      <rect x="10" y="8" width="1" height="1" fill="#B0C0FB"/>
      <rect x="10" y="7" width="1" height="1" fill="#ACBDFB"/>
      <rect x="10" y="6" width="1" height="1" fill="#A8BAFB"/>
      <rect x="10" y="5" width="1" height="1" fill="#A7B9FB"/>
      <rect x="10" y="4" width="1" height="1" fill="#A5B8FB"/>
      <rect x="10" y="3" width="1" height="1" fill="#A4B6FB"/>
      <rect x="10" y="2" width="1" height="1" fill="#A3B6FB"/>
      <rect x="11" y="1" width="1" height="1" fill="#9EB2FA"/>
      <rect x="11" y="8" width="1" height="1" fill="#A3B6FA"/>
      <rect x="11" y="7" width="1" height="1" fill="#A1B4FB"/>
      <rect x="11" y="6" width="1" height="1" fill="#A0B3FA"/>
      <rect x="11" y="5" width="1" height="1" fill="#9FB3FA"/>
      <rect x="11" y="4" width="1" height="1" fill="#9FB2FA"/>
      <rect x="11" y="3" width="1" height="1" fill="#9EB2FA"/>
      <rect x="11" y="2" width="1" height="1" fill="#9EB2FA"/>
      <rect x="12" y="1" width="1" height="1" fill="#99AEFA"/>
      <rect x="12" y="11" width="1" height="1" fill="#7591F9"/>
      <rect x="11" y="11" width="1" height="1" fill="#C3CFFC"/>
      <rect x="12" y="9" width="1" height="1" fill="#92A8FA"/>
      <rect x="11" y="9" width="1" height="1" fill="#A6B8FB"/>
      <rect x="12" y="10" width="1" height="1" fill="#8CA3F9"/>
      <rect x="11" y="10" width="1" height="1" fill="#ACBDFB"/>
      <rect x="12" y="8" width="1" height="1" fill="#94AAF9"/>
      <rect x="12" y="7" width="1" height="1" fill="#96ABFA"/>
      <rect x="12" y="6" width="1" height="1" fill="#97ACFA"/>
      <rect x="12" y="5" width="1" height="1" fill="#98ADFA"/>
      <rect x="12" y="4" width="1" height="1" fill="#98ADFA"/>
      <rect x="12" y="3" width="1" height="1" fill="#99ADFA"/>
      <rect x="12" y="2" width="1" height="1" fill="#99AEFA"/>
      <rect x="13" y="1" width="1" height="1" fill="#94AAFA"/>
      <rect x="13" y="11" width="1" height="1" fill="#5E7FF7"/>
      <rect x="13" y="9" width="1" height="1" fill="#819BF9"/>
      <rect x="13" y="10" width="1" height="1" fill="#7591F9"/>
      <rect x="13" y="8" width="1" height="1" fill="#88A0F9"/>
      <rect x="13" y="7" width="1" height="1" fill="#8CA3FA"/>
      <rect x="13" y="6" width="1" height="1" fill="#8EA5F9"/>
      <rect x="13" y="5" width="1" height="1" fill="#90A7FA"/>
      <rect x="13" y="4" width="1" height="1" fill="#92A8FA"/>
      <rect x="13" y="3" width="1" height="1" fill="#93A9FA"/>
      <rect x="13" y="2" width="1" height="1" fill="#94AAFA"/>
      <rect x="14" y="1" width="1" height="1" fill="#90A7FA"/>
      <rect x="14" y="11" width="1" height="1" fill="#587AF6"/>
      <rect x="14" y="9" width="1" height="1" fill="#7591F8"/>
      <rect x="14" y="10" width="1" height="1" fill="#6987F8"/>
      <rect x="14" y="8" width="1" height="1" fill="#7D97F9"/>
      <rect x="14" y="7" width="1" height="1" fill="#839CF9"/>
      <rect x="14" y="6" width="1" height="1" fill="#879FF9"/>
      <rect x="14" y="5" width="1" height="1" fill="#89A1F9"/>
      <rect x="14" y="4" width="1" height="1" fill="#8CA3FA"/>
      <rect x="14" y="3" width="1" height="1" fill="#8DA4FA"/>
      <rect x="14" y="2" width="1" height="1" fill="#8FA6F9"/>
      <rect x="15" y="1" width="1" height="1" fill="#8CA3F9"/>
      <rect x="15" y="11" width="1" height="1" fill="#5578F7"/>
      <rect x="15" y="9" width="1" height="1" fill="#6D8AF8"/>
      <rect x="15" y="10" width="1" height="1" fill="#6382F8"/>
      <rect x="15" y="8" width="1" height="1" fill="#7591F8"/>
      <rect x="15" y="7" width="1" height="1" fill="#7B96F9"/>
      <rect x="15" y="6" width="1" height="1" fill="#8099F9"/>
      <rect x="15" y="5" width="1" height="1" fill="#839CF9"/>
      <rect x="15" y="4" width="1" height="1" fill="#869FF9"/>
      <rect x="15" y="3" width="1" height="1" fill="#88A0F9"/>
      <rect x="15" y="2" width="1" height="1" fill="#8AA2F9"/>
      <rect x="16" y="1" width="1" height="1" fill="#88A0F9"/>
      <rect x="16" y="11" width="1" height="1" fill="#5476F7"/>
      <rect x="16" y="9" width="1" height="1" fill="#6886F8"/>
      <rect x="16" y="10" width="1" height="1" fill="#5E7FF7"/>
      <rect x="16" y="8" width="1" height="1" fill="#6F8CF8"/>
      <rect x="16" y="7" width="1" height="1" fill="#7591F8"/>
      <rect x="16" y="6" width="1" height="1" fill="#7A94F8"/>
      <rect x="16" y="5" width="1" height="1" fill="#7E98F9"/>
      <rect x="16" y="4" width="1" height="1" fill="#819AF9"/>
      <rect x="16" y="3" width="1" height="1" fill="#849DF9"/>
      <rect x="16" y="2" width="1" height="1" fill="#869FF9"/>
      <rect x="17" y="1" width="1" height="1" fill="#849DF9"/>
      <rect x="17" y="11" width="1" height="1" fill="#5376F7"/>
      <rect x="17" y="9" width="1" height="1" fill="#6383F7"/>
      <rect x="17" y="10" width="1" height="1" fill="#5C7DF7"/>
      <rect x="17" y="8" width="1" height="1" fill="#6B88F8"/>
      <rect x="17" y="7" width="1" height="1" fill="#708DF8"/>
      <rect x="17" y="6" width="1" height="1" fill="#7591F8"/>
      <rect x="17" y="5" width="1" height="1" fill="#7994F8"/>
      <rect x="17" y="4" width="1" height="1" fill="#7D97F9"/>
      <rect x="17" y="3" width="1" height="1" fill="#8099F9"/>
      <rect x="17" y="2" width="1" height="1" fill="#829BF9"/>
      <rect x="18" y="1" width="1" height="1" fill="#819AF9"/>
      <rect x="18" y="11" width="1" height="1" fill="#5276F7"/>
      <rect x="18" y="9" width="1" height="1" fill="#6181F8"/>
      <rect x="18" y="10" width="1" height="1" fill="#597BF7"/>
      <rect x="18" y="8" width="1" height="1" fill="#6785F7"/>
      <rect x="18" y="7" width="1" height="1" fill="#6C89F7"/>
      <rect x="18" y="6" width="1" height="1" fill="#718EF8"/>
      <rect x="18" y="5" width="1" height="1" fill="#7591F8"/>
      <rect x="18" y="4" width="1" height="1" fill="#7994F8"/>
      <rect x="18" y="3" width="1" height="1" fill="#7C96F9"/>
      <rect x="18" y="2" width="1" height="1" fill="#7E98F9"/>
      <rect x="19" y="1" width="1" height="1" fill="#7D97F9"/>
      <rect x="19" y="11" width="1" height="1" fill="#5275F7"/>
      <rect x="19" y="9" width="1" height="1" fill="#5E7FF7"/>
      <rect x="19" y="10" width="1" height="1" fill="#587AF7"/>
      <rect x="19" y="8" width="1" height="1" fill="#6483F8"/>
      <rect x="19" y="7" width="1" height="1" fill="#6987F8"/>
      <rect x="19" y="6" width="1" height="1" fill="#6E8BF8"/>
      <rect x="19" y="5" width="1" height="1" fill="#728EF8"/>
      <rect x="19" y="4" width="1" height="1" fill="#7591F9"/>
      <rect x="19" y="3" width="1" height="1" fill="#7893F9"/>
      <rect x="19" y="2" width="1" height="1" fill="#7B95F9"/>
      <rect x="20" y="1" width="1" height="1" fill="#7A95F9"/>
      <rect x="20" y="11" width="1" height="1" fill="#5275F7"/>
      <rect x="20" y="9" width="1" height="1" fill="#5D7EF7"/>
      <rect x="20" y="10" width="1" height="1" fill="#5779F7"/>
      <rect x="20" y="8" width="1" height="1" fill="#6282F8"/>
      <rect x="20" y="7" width="1" height="1" fill="#6785F8"/>
      <rect x="20" y="6" width="1" height="1" fill="#6B89F8"/>
      <rect x="20" y="5" width="1" height="1" fill="#6F8CF8"/>
      <rect x="20" y="4" width="1" height="1" fill="#728EF8"/>
      <rect x="20" y="3" width="1" height="1" fill="#7591F8"/>
      <rect x="20" y="2" width="1" height="1" fill="#7893F8"/>
      <rect x="21" y="1" width="1" height="1" fill="#7893F8"/>
      <rect x="21" y="11" width="1" height="1" fill="#5174F7"/>
      <rect x="21" y="9" width="1" height="1" fill="#5B7DF7"/>
      <rect x="21" y="10" width="1" height="1" fill="#5779F7"/>
      <rect x="21" y="8" width="1" height="1" fill="#6080F7"/>
      <rect x="21" y="7" width="1" height="1" fill="#6483F7"/>
      <rect x="21" y="6" width="1" height="1" fill="#6887F8"/>
      <rect x="21" y="5" width="1" height="1" fill="#6C89F8"/>
      <rect x="21" y="4" width="1" height="1" fill="#6F8CF8"/>
      <rect x="21" y="3" width="1" height="1" fill="#738FF8"/>
      <rect x="21" y="2" width="1" height="1" fill="#7591F9"/>
      <rect x="22" y="1" width="1" height="1" fill="#7591F8"/>
      <rect x="22" y="11" width="1" height="1" fill="#5174F7"/>
      <rect x="22" y="9" width="1" height="1" fill="#5A7CF7"/>
      <rect x="22" y="10" width="1" height="1" fill="#5578F7"/>
      <rect x="22" y="8" width="1" height="1" fill="#5E7FF8"/>
      <rect x="22" y="7" width="1" height="1" fill="#6282F7"/>
      <rect x="22" y="6" width="1" height="1" fill="#6685F8"/>
      <rect x="22" y="5" width="1" height="1" fill="#6A88F8"/>
      <rect x="22" y="4" width="1" height="1" fill="#6D8AF8"/>
      <rect x="22" y="3" width="1" height="1" fill="#708DF8"/>
      <rect x="22" y="2" width="1" height="1" fill="#738FF8"/>
      <rect x="23" y="1" width="1" height="1" fill="#738FF8"/>
      <rect x="23" y="11" width="1" height="1" fill="#5074F7"/>
      <rect x="13" y="12" width="1" height="1" fill="#3E65F6"/>
      <rect x="14" y="12" width="1" height="1" fill="#456BF6"/>
      <rect x="15" y="12" width="1" height="1" fill="#476DF6"/>
      <rect x="16" y="12" width="1" height="1" fill="#496EF7"/>
      <rect x="17" y="12" width="1" height="1" fill="#496EF6"/>
      <rect x="18" y="12" width="1" height="1" fill="#4B6FF6"/>
      <rect x="19" y="12" width="1" height="1" fill="#4B70F6"/>
      <rect x="20" y="12" width="1" height="1" fill="#4B70F6"/>
      <rect x="21" y="12" width="1" height="1" fill="#4C70F6"/>
      <rect x="22" y="12" width="1" height="1" fill="#4C71F7"/>
      <rect x="23" y="12" width="1" height="1" fill="#4C71F7"/>
      <rect x="14" y="13" width="1" height="1" fill="#345DF6"/>
      <rect x="15" y="13" width="1" height="1" fill="#3A62F5"/>
      <rect x="16" y="13" width="1" height="1" fill="#3F66F6"/>
      <rect x="17" y="13" width="1" height="1" fill="#4168F6"/>
      <rect x="18" y="13" width="1" height="1" fill="#446AF6"/>
      <rect x="19" y="13" width="1" height="1" fill="#446AF6"/>
      <rect x="20" y="13" width="1" height="1" fill="#466CF6"/>
      <rect x="21" y="13" width="1" height="1" fill="#476CF6"/>
      <rect x="22" y="13" width="1" height="1" fill="#476DF6"/>
      <rect x="23" y="13" width="1" height="1" fill="#486DF6"/>
      <rect x="15" y="14" width="1" height="1" fill="#305AF5"/>
      <rect x="16" y="14" width="1" height="1" fill="#355FF6"/>
      <rect x="17" y="14" width="1" height="1" fill="#3962F6"/>
      <rect x="18" y="14" width="1" height="1" fill="#3C64F6"/>
      <rect x="19" y="14" width="1" height="1" fill="#3E66F6"/>
      <rect x="20" y="14" width="1" height="1" fill="#4067F6"/>
      <rect x="21" y="14" width="1" height="1" fill="#4268F6"/>
      <rect x="22" y="14" width="1" height="1" fill="#4369F6"/>
      <rect x="23" y="14" width="1" height="1" fill="#436AF6"/>
      <rect x="16" y="15" width="1" height="1" fill="#2E58F5"/>
      <rect x="17" y="15" width="1" height="1" fill="#325CF5"/>
      <rect x="18" y="15" width="1" height="1" fill="#365FF6"/>
      <rect x="19" y="15" width="1" height="1" fill="#3961F6"/>
      <rect x="20" y="15" width="1" height="1" fill="#3B63F6"/>
      <rect x="21" y="15" width="1" height="1" fill="#3D64F6"/>
      <rect x="22" y="15" width="1" height="1" fill="#3E66F6"/>
      <rect x="23" y="15" width="1" height="1" fill="#4067F6"/>
      <rect x="17" y="16" width="1" height="1" fill="#2C57F5"/>
      <rect x="18" y="16" width="1" height="1" fill="#315BF5"/>
      <rect x="19" y="16" width="1" height="1" fill="#345DF6"/>
      <rect x="20" y="16" width="1" height="1" fill="#365FF5"/>
      <rect x="21" y="16" width="1" height="1" fill="#3861F5"/>
      <rect x="22" y="16" width="1" height="1" fill="#3B63F6"/>
      <rect x="23" y="16" width="1" height="1" fill="#3C64F6"/>
      <rect x="18" y="17" width="1" height="1" fill="#2B57F5"/>
      <rect x="19" y="17" width="1" height="1" fill="#2F5AF5"/>
      <rect x="20" y="17" width="1" height="1" fill="#325BF5"/>
      <rect x="21" y="17" width="1" height="1" fill="#345EF6"/>
      <rect x="22" y="17" width="1" height="1" fill="#365FF5"/>
      <rect x="23" y="17" width="1" height="1" fill="#3961F6"/>
      <rect x="19" y="18" width="1" height="1" fill="#2B56F5"/>
      <rect x="20" y="18" width="1" height="1" fill="#2E59F5"/>
      <rect x="21" y="18" width="1" height="1" fill="#315BF5"/>
      <rect x="22" y="18" width="1" height="1" fill="#335DF5"/>
      <rect x="23" y="18" width="1" height="1" fill="#355EF5"/>
      <rect x="20" y="19" width="1" height="1" fill="#2B56F5"/>
      <rect x="21" y="19" width="1" height="1" fill="#2D58F5"/>
      <rect x="22" y="19" width="1" height="1" fill="#305AF5"/>
      <rect x="23" y="19" width="1" height="1" fill="#325CF6"/>
      <rect x="21" y="20" width="1" height="1" fill="#2A56F5"/>
      <rect x="22" y="20" width="1" height="1" fill="#2D58F5"/>
      <rect x="23" y="20" width="1" height="1" fill="#2F5AF5"/>
      <rect x="22" y="21" width="1" height="1" fill="#2A55F5"/>
      <rect x="23" y="21" width="1" height="1" fill="#2C57F5"/>
      <rect x="23" y="22" width="1" height="1" fill="#2A56F5"/>
      <rect x="23" y="9" width="1" height="1" fill="#597BF7"/>
      <rect x="23" y="10" width="1" height="1" fill="#5578F7"/>
      <rect x="23" y="8" width="1" height="1" fill="#5D7EF7"/>
      <rect x="23" y="7" width="1" height="1" fill="#6181F7"/>
      <rect x="23" y="6" width="1" height="1" fill="#6584F8"/>
      <rect x="23" y="5" width="1" height="1" fill="#6886F8"/>
      <rect x="23" y="4" width="1" height="1" fill="#6B89F8"/>
      <rect x="23" y="3" width="1" height="1" fill="#6E8BF8"/>
      <rect x="23" y="2" width="1" height="1" fill="#708DF8"/>
    </svg>
  );
};

Base.layerType = LAYER_TYPE;
Base.gender = GENDER;
Base.title = TITLE;
Base.key = KEY;
Base.paletteNames = PALETTE_NAMES;
Base.price = 0.001;
