import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Short 3";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Short3: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Short3, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4H9H11H15V5H16H17V6H18V11H17V10H16V8H15V7H11V8H9V9H8V13H7V12H6H5V14H4V9H3V8H4V7H5V6H6V5H7H8V4ZM5 14H6V17H5V14ZM17 5V4H18V5H17Z"
        fill={hairColor}
      />
    </svg>
  );
};

Short3.allowSemiTransparentColors = false;
Short3.layerType = LAYER_TYPE;
Short3.gender = GENDER;
Short3.title = TITLE;
Short3.key = KEY;
Short3.paletteNames = PALETTE_NAMES;
Short3.blacklist = [ApeMan, AlienMan]
