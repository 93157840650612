import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Cap Backward Color";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapBackwardColor: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="11" y="8" width="3" height="1" fill="#5E95EF" />
      <path d="M9 4H8V5H7V6H6V8H3V9H7V6H8V5H9V4Z" fill="#EA3937" />
      <path d="M15 4H9V5H8V6H7V9H11V7H14V9H17V6H16V5H15V4Z" fill="white" />
    </svg>
  );
};

CapBackwardColor.layerType = LAYER_TYPE;
CapBackwardColor.gender = GENDER;
CapBackwardColor.title = TITLE;
CapBackwardColor.key = KEY;
CapBackwardColor.paletteNames = PALETTE_NAMES;
