import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";
import {  hairWhitelist } from "./whitelists";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Curl";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Curl: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Curl, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 10H14V11H13V10ZM13 11V12H12V11H13ZM15 10H16V11H15V10ZM7 12V11V10H9V11H8V12H7Z"
        fill={hairColor}
      />
    </svg>
  );
};

Curl.layerType = LAYER_TYPE;
Curl.gender = GENDER;
Curl.title = TITLE;
Curl.key = KEY;
Curl.isExtension = true;
Curl.paletteNames = PALETTE_NAMES;
Curl.whitelist = hairWhitelist;
