import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Wreath";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Wreath: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Wreath, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4H8V5H7V6H6V8H5V10H4V19H5V21H6V24H7H8V11H9V10H15V11H16V12H17V7H16V5H15H14V4Z"
        fill={hairColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5H13V6H14V7H13H12H11V6H12V5ZM9 8V6H10V7H11V8H10H9ZM7 9V7H8V8H9V9H8H7ZM6 11V9H7V10H8V11H7H6ZM5 13V11H6V12H7V13H6H5ZM5 14V13H4V15H5H6V14H5ZM16 8H15H14V7H15V6H16V8ZM17 9H16V8H17V9ZM17 9H18V11H17V9Z"
        fill="#9AAC84"
      />
    </svg>
  );
};

Wreath.allowSemiTransparentColors = false;
Wreath.layerType = LAYER_TYPE;
Wreath.gender = GENDER;
Wreath.title = TITLE;
Wreath.key = KEY;
Wreath.paletteNames = PALETTE_NAMES;
Wreath.blacklist = [ApeWoman, AlienWoman]
