import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Side Wild";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const SideWild: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(SideWild, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6H15V7H16V8H17V9H18V11H19V12H20V13H19V15H18V16H19V17H17V16H16V11H15V13H14V14H13V12H14V10H13V9H11V10H12V11H10V10H8V12H7V10V8H8V7H9V6H10Z"
        fill={hairColor}
      />
    </svg>
  );
};

SideWild.allowSemiTransparentColors = false;
SideWild.layerType = LAYER_TYPE;
SideWild.gender = GENDER;
SideWild.title = TITLE;
SideWild.key = KEY;
SideWild.paletteNames = PALETTE_NAMES;
SideWild.blacklist = [ApeWoman, AlienWoman];
