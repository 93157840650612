import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { ApeMan } from "../../type/ApeMan";
import { Man } from "../../type/Man";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Eyes";
const GENDER: Gender = "Man";
const TITLE = "Scan";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Laser Eyes";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Scan: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Scan, PALETTE_NAME_1).colors;
  }, [palettes]);

  const laserColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 11H9V12H11V11ZM16 11H14V12H16V11Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 12H9V13H11V12ZM16 12H14V13H16V12Z"
        fill={laserColor}
      />
    </svg>
  );
};


Scan.layerType = LAYER_TYPE;
Scan.gender = GENDER;
Scan.title = TITLE;
Scan.key = KEY;
Scan.paletteNames = PALETTE_NAMES;
Scan.whitelist = [Man, ApeMan];
