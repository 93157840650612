import { useCallback } from "react";

const CANVAS_SIZE = 1200;

export const useExport = () => {
  const generateSVGImageUrl = useCallback(() => {
    const svgElement: SVGSVGElement | null = document.querySelector(".preview");
    if (!svgElement) return "";
    const svgString = btoa(new XMLSerializer().serializeToString(svgElement));
    return `data:image/svg+xml;base64,${svgString}`;
  }, []);

  const generatePNGImageUrl = useCallback(async () => {
    return new Promise<string>((resolve, reject) => {
      const canvasElement = document.createElement("canvas");
      canvasElement.width = CANVAS_SIZE;
      canvasElement.height = CANVAS_SIZE;
      const context = canvasElement.getContext("2d");
      if (!context) return;
      context.imageSmoothingEnabled = false;
      const imgElement = document.createElement("img");
      imgElement.onload = () => {
        context.drawImage(imgElement, 0, 0, CANVAS_SIZE, CANVAS_SIZE);
        resolve(canvasElement.toDataURL("image/png"));
      };
      imgElement.src = generateSVGImageUrl();
    });
  }, [generateSVGImageUrl]);

  return { generateSVGImageUrl, generatePNGImageUrl };
};
