import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Pixelenca 11";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Pixelenca11: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="#B8D9CE" />
      <rect width="4" height="1" fill="#33607A" />
      <rect y="1" width="4" height="1" fill="#E3D6C5" />
      <rect x="19" y="1" width="5" height="1" fill="#E3D6C5" />
      <rect y="2" width="4" height="1" fill="#DB4E54" />
      <rect y="14" width="4" height="1" fill="#DB4E54" />
      <rect y="3" width="4" height="1" fill="#33607A" />
      <rect y="4" width="4" height="10" fill="#573D2E" />
      <rect x="19" y="2" width="5" height="1" fill="#573D2E" />
      <rect y="15" width="4" height="9" fill="#FCBD1A" />
      <rect x="6" y="2" width="5" height="2" fill="#FCBD1A" />
      <rect x="19" y="3" width="5" height="2" fill="#FCBD1A" />
      <rect x="13" y="1" width="4" height="1" fill="#FCBD1A" />
      <rect x="6" y="1" width="5" height="1" fill="#DB4E54" />
      <rect x="19" y="6" width="5" height="18" fill="#DB4E54" />
      <rect x="6" width="5" height="1" fill="#F6B1A1" />
      <rect x="6" y="4" width="5" height="20" fill="#28A691" />
      <rect x="13" y="2" width="4" height="18" fill="#F6B1A1" />
      <rect x="13" width="4" height="1" fill="#28A691" />
      <rect x="13" y="20" width="4" height="4" fill="#DB4E54" />
      <rect x="19" width="5" height="1" fill="#33607A" />
      <rect x="19" y="5" width="5" height="1" fill="#33607A" />
    </svg>
  );
};

Pixelenca11.layerType = LAYER_TYPE;
Pixelenca11.gender = GENDER;
Pixelenca11.title = TITLE;
Pixelenca11.key = KEY;
Pixelenca11.paletteNames = PALETTE_NAMES;
Pixelenca11.price = 0.001;
