import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Long Curl";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const LongCurl: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(LongCurl, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6H15V7H16V8H17V9V11V12H16V11H15V10H11V11H10V10H9V11H8V12H7H6V10H7V8H8V7H9H10V6ZM9 11H10V12H9V11ZM6 14H7V19H8V23H7V21H6V19H5V13H6V14ZM14 22H13V24H14H15H16V23H15V22H14Z"
        fill={hairColor}
      />
    </svg>
  );
};

LongCurl.allowSemiTransparentColors = false;
LongCurl.layerType = LAYER_TYPE;
LongCurl.gender = GENDER;
LongCurl.title = TITLE;
LongCurl.key = KEY;
LongCurl.paletteNames = PALETTE_NAMES;
LongCurl.blacklist = [ApeWoman, AlienWoman]