import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Long 4";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Long4: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Long4, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 5H15V6H16V7H17V8V10V11H16V12H15V11H14H13V10H12V11H10V12H8V14H7V15V19H8V24H7H6H5H4H3V18H4V14H5V10H6V8H7V7H8V6H9V5Z"
        fill={hairColor}
      />
    </svg>
  );
};

Long4.allowSemiTransparentColors = false;
Long4.layerType = LAYER_TYPE;
Long4.gender = GENDER;
Long4.title = TITLE;
Long4.key = KEY;
Long4.paletteNames = PALETTE_NAMES;
Long4.blacklist = [ApeWoman, AlienWoman]
