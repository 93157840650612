import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { glassesManLayers } from "../glasses/man";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Man";
const TITLE = "Laser";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Laser";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const LaserMan: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(LaserMan, PALETTE_NAME_1).colors;
  }, [palettes]);

  const laserColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="9" y="12" width="15" height="1" fill={laserColor} />
    </svg>
  );
};

LaserMan.layerType = LAYER_TYPE;
LaserMan.gender = GENDER;
LaserMan.title = TITLE;
LaserMan.key = KEY;
LaserMan.paletteNames = PALETTE_NAMES;
LaserMan.blacklist = [...glassesManLayers]
