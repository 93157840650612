import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "VR";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const VR: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="8" y="10" width="9" height="4" fill="#B4B4B4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 10H9V11H8V10ZM8 13H7V11H8V13ZM8 13H9V14H8V13ZM16 13H17V14H16V13ZM17 10H16V11H17V10Z"
        fill="#8D8D8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 9H8V10H7V11H8V10H17V14H8V13H7V14H8V15H17V14H18V10H17V9ZM16 11H9V13H16V11Z"
        fill="black"
      />
    </svg>
  );
};

VR.layerType = LAYER_TYPE;
VR.gender = GENDER;
VR.title = TITLE;
VR.key = KEY;
VR.paletteNames = PALETTE_NAMES;
