import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Generic";
const TITLE = "Confetti";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Confetti: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="13" y="18" width="6" height="1" fill="#3164B7" />
      <rect x="18" y="17" width="2" height="2" fill="#3164B7" />
      <rect x="18" y="17" width="1" height="1" fill="white" fillOpacity="0.3" />
      <rect x="17" y="18" width="1" height="1" fill="white" fillOpacity="0.3" />
      <rect x="19" y="14" width="1" height="1" fill="#FBBC05" />
      <rect x="12" y="2" width="1" height="1" fill="#FBBC05" />
      <rect x="10" y="9" width="1" height="1" fill="#3164B7" />
      <rect x="2" y="9" width="1" height="1" fill="#3164B7" />
      <rect x="17" y="4" width="1" height="1" fill="#3164B7" />
      <rect x="5" y="12" width="1" height="1" fill="#FBBC05" />
      <rect x="4" y="4" width="1" height="1" fill="#E17F62" />
      <rect x="20" y="9" width="1" height="1" fill="#E17F62" />
    </svg>
  );
};

Confetti.layerType = LAYER_TYPE;
Confetti.gender = GENDER;
Confetti.title = TITLE;
Confetti.key = KEY;
Confetti.paletteNames = PALETTE_NAMES;
Confetti.zIndex = 95;
