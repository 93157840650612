import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Pixelenca 5";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Pixelenca5: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="#6B7F6C" />
      <rect x="1" width="1" height="15" fill="#E7E2D7" />
      <rect x="7" width="1" height="3" fill="#E7E2D7" />
      <rect x="3" y="9" width="3" height="15" fill="#E7E2D7" />
      <rect x="9" width="2" height="20" fill="#E7E2D7" />
      <rect x="12" y="4" width="3" height="20" fill="#E7E2D7" />
      <rect x="18" width="3" height="15" fill="#E7E2D7" />
      <rect x="18" y="16" width="3" height="8" fill="#F3D7B5" />
      <rect x="3" y="6" width="3" height="2" fill="#E09491" />
      <rect x="9" y="21" width="2" height="3" fill="#E7E2D7" />
      <rect x="12" width="3" height="3" fill="#F3D7B5" />
      <rect x="3" width="3" height="5" fill="#E7E2D7" />
      <rect x="1" y="16" width="1" height="8" fill="#2B496F" />
      <rect x="7" y="4" width="1" height="8" fill="#F3D7B5" />
      <rect x="16" width="1" height="8" fill="#2B496F" />
      <rect x="22" width="1" height="5" fill="#E7E2D7" />
      <rect x="22" y="6" width="1" height="12" fill="#E09491" />
      <rect x="22" y="19" width="1" height="5" fill="#2B496F" />
      <rect x="7" y="13" width="1" height="11" fill="#E7E2D7" />
      <rect x="16" y="9" width="1" height="9" fill="#E7E2D7" />
      <rect x="16" y="19" width="1" height="5" fill="#E7E2D7" />
    </svg>
  );
};

Pixelenca5.layerType = LAYER_TYPE;
Pixelenca5.gender = GENDER;
Pixelenca5.title = TITLE;
Pixelenca5.key = KEY;
Pixelenca5.paletteNames = PALETTE_NAMES;
Pixelenca5.price = 0.001;
