import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";
import { capWhitelist } from "./whitelists";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Long Wild";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}-Cap`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const LongWildCap: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(LongWildCap, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 7H11V8H14V7Z" fill={hairColor} />
      <path
        d="M4 9H3V10H4V13H3V12H2V13H3V16H2V17H3V19H4V20H3V21H4V20H5V21H6V19H7V17H6V15H7V14H6V12H7V11H8V10H9V9H5V10H4V9Z"
        fill={hairColor}
      />
      <path d="M18 19H19V20H18V19Z" fill={hairColor} />
      <path
        d="M18 17V19H17V11H16V10H15V9H18V10H19V14H20V15H19V17H18Z"
        fill={hairColor}
      />
    </svg>
  );
};

LongWildCap.layerType = LAYER_TYPE;
LongWildCap.allowSemiTransparentColors = false;
LongWildCap.gender = GENDER;
LongWildCap.title = TITLE;
LongWildCap.key = KEY;
LongWildCap.isExtension = true;
LongWildCap.paletteNames = PALETTE_NAMES;
LongWildCap.whitelist = capWhitelist;
