import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Space Buns";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const SpaceBuns: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(SpaceBuns, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 4H6V5H5V8H6V9H7V10V12H8H9V11H10V10H15V11H16V12H17V10V9H18V8H19V5H18V4H15V5H14V6H10V5H9V4Z"
        fill={hairColor}
      />
    </svg>
  );
};

SpaceBuns.allowSemiTransparentColors = false;
SpaceBuns.layerType = LAYER_TYPE;
SpaceBuns.gender = GENDER;
SpaceBuns.title = TITLE;
SpaceBuns.key = KEY;
SpaceBuns.paletteNames = PALETTE_NAMES;
SpaceBuns.blacklist = [ApeWoman, AlienWoman]
