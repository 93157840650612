import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";
import { hairWhitelist } from "./whitelists";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Middle Hair";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const MiddleHair: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(MiddleHair, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 10H8H9V11H8V12H7V13H6V18H5V16H4V11H5V10ZM18 10H15V11H16V12H17V17H18V16H19V11H18V10Z"
        fill={hairColor}
      />
    </svg>
  );
};

MiddleHair.layerType = LAYER_TYPE;
MiddleHair.gender = GENDER;
MiddleHair.allowSemiTransparentColors = false;
MiddleHair.title = TITLE;
MiddleHair.key = KEY;
MiddleHair.isExtension = true;
MiddleHair.paletteNames = PALETTE_NAMES;
MiddleHair.whitelist = hairWhitelist;
