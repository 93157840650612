import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Cap Rainbow";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapRainbow: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 10H20V11H7V8H8V7H9V6H15V7H16V8H17V10Z" fill="#194196" />
      <rect x="12" y="7" width="1" height="0.333333" fill="#7951AB" />
      <rect x="12.6667" y="7" width="0.333333" height="0.333333" fill="#5349A3" />
      <rect x="12" y="8" width="1.33333" height="0.333333" fill="#FBE64D" />
      <rect x="13" y="8" width="0.666667" height="0.333333" fill="#F3B33E" />
      <rect x="13.3333" y="8" width="0.666667" height="0.333333" fill="#EA8B35" />
      <rect x="13.6667" y="8" width="0.666667" height="0.333333" fill="#C4514D" />
      <rect x="14" y="8" width="0.333333" height="0.333333" fill="#7951AB" />
      <rect x="12" y="7.66666" width="1" height="0.333333" fill="#F3B33E" />
      <rect x="12.6667" y="7.66666" width="0.666667" height="0.333333" fill="#EA8B35" />
      <rect x="13" y="7.66666" width="1" height="0.333333" fill="#C4514D" />
      <rect x="13.6667" y="7.66666" width="0.333333" height="0.333333" fill="#7951AB" />
      <rect x="12" y="7.33334" width="1" height="0.333333" fill="#C4514D" />
      <rect x="13" y="7.33334" width="0.666667" height="0.333333" fill="#9E538A" />
      <rect x="13.3333" y="7.33334" width="0.333333" height="0.333333" fill="#5349A3" />
      <rect x="12" y="8.33334" width="1.33333" height="0.333333" fill="#5AC589" />
      <rect x="13" y="8.33334" width="0.666667" height="0.333333" fill="#FBE64D" />
      <rect x="13.3333" y="8.33334" width="0.666667" height="0.333333" fill="#F3B33E" />
      <rect x="13.6667" y="8.33334" width="0.666667" height="0.333333" fill="#EA8B35" />
      <rect x="14" y="8.33334" width="0.666667" height="0.333333" fill="#C4514D" />
      <rect x="14.3333" y="8.33334" width="0.333333" height="0.333333" fill="#5349A3" />
      <rect x="13" y="9" width="0.333333" height="0.666667" fill="#56BCAB" />
      <rect x="13" y="9.33334" width="0.666667" height="0.666667" fill="#4CA7D3" />
      <rect x="13.3333" y="9.33334" width="0.666667" height="0.666667" fill="#5AC589" />
      <rect x="13.6667" y="9.33334" width="0.666667" height="0.666667" fill="#FBE64D" />
      <rect x="14.3333" y="9.33334" width="0.666667" height="0.666667" fill="#C4514D" />
      <rect x="14.6667" y="9" width="0.333333" height="0.333333" fill="#5349A3" />
      <rect x="14.6667" y="9.33334" width="0.333333" height="0.666667" fill="#7951AB" />
      <rect x="13.6667" y="9" width="0.333333" height="1" fill="#FBE64D" />
      <rect x="12.3333" y="9" width="0.333333" height="0.333333" fill="#225393" />
      <rect x="12.6667" y="9.33334" width="0.333333" height="0.333333" fill="#225393" />
      <rect x="13.3333" y="9" width="0.333333" height="1" fill="#5AC589" />
      <rect x="14" y="9" width="0.666667" height="0.333333" fill="#EA8B35" />
      <rect x="14" y="9.33334" width="0.333333" height="0.666667" fill="#F3B33E" />
      <rect x="14.3333" y="9" width="0.333333" height="0.333333" fill="#C4514D" />
      <rect x="12" y="8.66666" width="1" height="0.333333" fill="#4CA7D3" />
      <rect x="12.6667" y="8.66666" width="0.666667" height="0.666667" fill="#56BCAB" />
      <rect x="13" y="8.66666" width="0.666667" height="0.333333" fill="#5AC589" />
      <rect x="13.3333" y="8.66666" width="0.666667" height="0.333333" fill="#FBE64D" />
      <rect x="13.6667" y="8.66666" width="0.666667" height="0.333333" fill="#F3B33E" />
      <rect x="12.6667" y="9" width="0.666667" height="0.333333" fill="#4FADCA" />
      <rect x="14" y="8.66666" width="0.666667" height="0.333333" fill="#C4514D" />
      <rect x="14.3333" y="8.66666" width="0.333333" height="0.333333" fill="#9E538A" />
      <rect x="14" y="8.33334" width="0.333333" height="0.666667" fill="#C4514D" />
    </svg>

  );
};

CapRainbow.layerType = LAYER_TYPE;
CapRainbow.gender = GENDER;
CapRainbow.title = TITLE;
CapRainbow.key = KEY;
CapRainbow.paletteNames = PALETTE_NAMES;
CapRainbow.isBranded = true;
CapRainbow.price = 0.01;
