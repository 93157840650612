import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Beach";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Beach: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="#FF7018" />
      <rect width="24" height="24" fill="#FFCA29" />
      <rect y="18" width="24" height="6" fill="#5EB1EB" />
      <rect y="17" width="24" height="1" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0H10V1H11V2H10V3H6V2H5V1H6V0ZM6 10H8V11H7V12H2V11H1V10H2V9H3V8H5V9H6V10ZM17 8H15V9H16V10H21V9H22V8H21V7H20V6H18V7H17V8Z"
        fill="white"
        fillOpacity="0.6"
      />
    </svg>
  );
};

Beach.layerType = LAYER_TYPE;
Beach.gender = GENDER;
Beach.title = TITLE;
Beach.key = KEY;
Beach.paletteNames = PALETTE_NAMES;
Beach.price = 0.001;
