import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Short 8";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Short8: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Short8, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 4H10V5H11V4H15V5H16V6H17V7H18V8H17V10H16V9H15V10H14V8H13V9H12V8H11V9H10V10H9V9H8V10H7V11H6V9H5V8H6V6H7V5H9V4Z" fill={hairColor} />
    </svg>
  );
};

Short8.allowSemiTransparentColors = false;
Short8.layerType = LAYER_TYPE;
Short8.gender = GENDER;
Short8.title = TITLE;
Short8.key = KEY;
Short8.paletteNames = PALETTE_NAMES;
Short8.blacklist = [ApeMan, AlienMan];
