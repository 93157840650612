import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Man";
const TITLE = "Sweat";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];


export const SweatMan: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 7H10V8H9V7Z" fill="white" fillOpacity="0.5" />
      <path d="M8 8H9V9H8V8Z" fill="white" fillOpacity="0.5" />
    </svg>
  );
};

SweatMan.layerType = LAYER_TYPE;
SweatMan.gender = GENDER;
SweatMan.title = TITLE;
SweatMan.key = KEY;
SweatMan.paletteNames = PALETTE_NAMES;
SweatMan.zIndex = 15;
