import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Woman";
const TITLE = "Mask";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Mask: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 11H17V12V14V15H7V14V12V11ZM14 12H16V14H14V12ZM11 12H9V14H11V12Z"
        fill="black"
      />
    </svg>
  );
};

Mask.layerType = LAYER_TYPE;
Mask.gender = GENDER;
Mask.title = TITLE;
Mask.key = KEY;
Mask.paletteNames = PALETTE_NAMES;
