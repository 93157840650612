import { FC, useMemo } from "react";
import { Text } from "./Text";

interface TextToggleProps {
  isActive: boolean;
  isDisabled?: boolean;
  onClick: () => void;
  className?: string;
}

export const TextToggle: FC<TextToggleProps> = (props) => {
  const fontColor = useMemo(() => {
    if (props.isActive) return "primary";
    if (props.isDisabled) return "gray500";
    return "gray300";
  }, [props]);

  return (
    <Text
      style={{
        cursor: "pointer",
        pointerEvents: props.isActive || props.isDisabled ? "none" : undefined,
      }}
      className={props.className}
      $fontColor={fontColor}
      onClick={props.onClick}
    >
      {props.children}
    </Text>
  );
};
