import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Cap Layer3";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapLayer3: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 8H20V9H6V6H7V5H8V4H15V5H16V6H17V8Z" fill="black" />
      <path
        d="M14 5.5H13V6H12.5V6.5H13V6H14V6.5H13.5V7H14V7.5H13V7H12.5V7.5H13V8H14V7.5H14.5V6H14V5.5Z"
        fill="#FFFC55"
      />
      <path d="M11 5.5H10.5V8H12V7.5H11V5.5Z" fill="#FFFC55" />
    </svg>
  );
};

CapLayer3.layerType = LAYER_TYPE;
CapLayer3.gender = GENDER;
CapLayer3.title = TITLE;
CapLayer3.key = KEY;
CapLayer3.paletteNames = PALETTE_NAMES;
CapLayer3.isBranded = true;
CapLayer3.price = 0.01;
