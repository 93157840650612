import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Clothes";
const GENDER: Gender = "Woman";
const TITLE = "Sweater";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Clothes";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Sweater: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Sweater, PALETTE_NAME_1).colors;
  }, [palettes]);

  const clothesColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="8" y="23" width="5" height="1"
      fill={clothesColor}
    />
    <path
      d="M7 23H8V24H7V23Z"
      fill="black"
    />
    <path
      d="M13 23H14V24H13V23Z"
      fill="black"
    />
    </svg>
    
  );
};

Sweater.layerType = LAYER_TYPE;
Sweater.gender = GENDER;
Sweater.title = TITLE;
Sweater.key = KEY;
Sweater.paletteNames = PALETTE_NAMES;
