import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Solid";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Background";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Solid: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Solid, PALETTE_NAME_1).colors;
  }, [palettes]);
  const backgroundColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill={backgroundColor} />
    </svg>
  );
};

Solid.allowSemiTransparentColors = false;
Solid.layerType = LAYER_TYPE;
Solid.gender = GENDER;
Solid.title = TITLE;
Solid.key = KEY;
Solid.paletteNames = PALETTE_NAMES;
