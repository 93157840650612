import { FC } from "react";
import { RemoveScroll } from "react-remove-scroll";
import styled from "styled-components";
import { COLOR_MAP } from "../constants/Color";
import { Icon } from "./Icon";
import { Text } from "./Text";

interface WagmiModalProps {
  onClose: () => void;
  className?: string;
}

const WagmiModalBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
`;

const WagmiModalWrapper = styled.div`
  width: 100vw;
  max-width: 440px;
  position: relative;
  padding-top: 24px;
  background-color: ${COLOR_MAP.gray700};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  @media screen and (max-width: 440px) {
    position: fixed;
    bottom: 0;
    left: 0;
  }
`;

const WagmiModalDescription = styled.div`
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  padding: 0 32px 16px 32px;
`;

const WagmiModalCloseButton = styled.div`
  position: absolute;
  right: 24px;
  cursor: pointer;
`;

const WagmiModalIcon = styled(Icon)`
  padding: 4px 0 12px;
  width: 72px;
  height: 72px;
`;

export const WagmiModal: FC<WagmiModalProps> = (props) => {
  return (
    <WagmiModalBackdrop className={props.className} onClick={props.onClose}>
      <RemoveScroll>
        <WagmiModalWrapper onClick={(e) => e.stopPropagation()}>
          <WagmiModalCloseButton onClick={props.onClose}>
            <Icon type="Close" />
          </WagmiModalCloseButton>
          <WagmiModalIcon type="Pinata" />
          <WagmiModalDescription>
            <Text $fontSize="normal" $fontColor="white">
              WAGMI, your SelfMadePunk will soon be available on LooksRare. This
              can take a few minutes but also a few hours.
            </Text>
          </WagmiModalDescription>
        </WagmiModalWrapper>
      </RemoveScroll>
    </WagmiModalBackdrop>
  );
};
