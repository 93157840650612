import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { AlienMan } from "../type/AlienMan";
import { ApeMan } from "../type/ApeMan";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Beard";
const GENDER: Gender = "Man";
const TITLE = "Handlebars";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Beard";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Handlebars: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Handlebars, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17H11H14H15V20H14V18H11V20H10V17Z"
        fill={hairColor}
      />
    </svg>
  );
};

Handlebars.layerType = LAYER_TYPE;
Handlebars.gender = GENDER;
Handlebars.title = TITLE;
Handlebars.key = KEY;
Handlebars.paletteNames = PALETTE_NAMES;
Handlebars.blacklist = [ApeMan, AlienMan]
