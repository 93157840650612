import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Beenie Bubble";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hat";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const BeenieBubble: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(BeenieBubble, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hatColor = useMemo(() => palette1[0], [palette1]);
  const secondaryhatColor = useMemo(() => palette1[1], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 10H17V7H16V5H14V4H9V5H7V7H6V10Z" fill={hatColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3H6V5H8V3ZM6 7H17V8V9H16V8H15V9H14V8H13V9H12V8H11V9H10V8H9V9H8V8H7V9H6V8V7ZM7 9H8V10H7V9ZM9 9H10V10H9V9ZM11 9H12V10H11V9ZM13 9H14V10H13V9ZM15 9H16V10H15V9Z"
        fill={secondaryhatColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2H8V3H6V2ZM6 5V3H5V5H6ZM7 5H6V6V7H5V10H6V7H7V6H8V5H9V4H14V5H16V7H17V10H18V7H17V5H16V4H14V3H9H8V4V5H7Z"
        fill="black"
      />
    </svg>
  );
};
BeenieBubble.allowSemiTransparentColors = false;
BeenieBubble.layerType = LAYER_TYPE;
BeenieBubble.gender = GENDER;
BeenieBubble.title = TITLE;
BeenieBubble.key = KEY;
BeenieBubble.paletteNames = PALETTE_NAMES;
