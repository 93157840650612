import { FC } from "react";
import styled, { CSSProperties } from "styled-components";
import { Icon } from "./Icon";
import { COLOR_MAP } from "../constants/Color";

interface HistoryButtonProps {
  iconType: "Undo" | "Redo";
  isDisabled: boolean;
  onClick: () => void;
  style?: CSSProperties;
  className?: string;
}

const HistoryButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface IconWrapperProps {
  $isDisabled: boolean;
}

const IconWrapper = styled.div<IconWrapperProps>`
  padding: 15px;
  cursor: pointer;
  pointer-events: ${({ $isDisabled }) => $isDisabled && "none"};
  color: ${({ $isDisabled }) => COLOR_MAP[$isDisabled ? "gray700" : "gray300"]};
  &:hover {
    color: ${COLOR_MAP.white};
    background: rgba(0, 0, 0, 0.2);
  }
  svg {
    display: block;
  }
`;

export const HistoryButton: FC<HistoryButtonProps> = (props) => {
  return (
    <HistoryButtonWrapper className={props.className} style={props.style}>
      <IconWrapper $isDisabled={props.isDisabled} onClick={props.onClick}>
        <Icon type={props.iconType} />
      </IconWrapper>
      {props.children}
    </HistoryButtonWrapper>
  );
};
