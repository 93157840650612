import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Type";
const GENDER: Gender = "Woman";
const TITLE = "Alien";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Alien Skin";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const AlienWoman: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(AlienWoman, PALETTE_NAME_1).colors;
  }, [palettes]);
  const skinColor = useMemo(() => palette1[0], [palette1]);
  const secondarySkinColor = useMemo(() => palette1[1], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 7H15V8H16V9H17V19H16V20H15V21H14V22H13V24H8V19H7V15H6V14H5V13H6V12H7V9H8V8H9V7Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8H9V9H8V13H6V14H7V15H8V19H9V24H12V22H11V21H14V20H15V19H16V14H15V13H16V9H15V8ZM15 13H14V12H15V13ZM11 21H10V20H11V21ZM9 12H10V13H9V12ZM10 13V14H11V13H10Z"
        fill={skinColor}
      />
      <path d="M10 12H11V13H10V12Z" fill={secondarySkinColor} />
      <path d="M10 13V14H9V13H10Z" fill={secondarySkinColor} />
      <path d="M15 13H14V14H15V13Z" fill={secondarySkinColor} />
      <path d="M15 13V12H16V13H15Z" fill={secondarySkinColor} />
      <path d="M12 15H13V17H12V15Z" fill={secondarySkinColor} />
      <path d="M8 13H7V14H8V13Z" fill={secondarySkinColor} />
    </svg>
  );
};

AlienWoman.layerType = LAYER_TYPE;
AlienWoman.gender = GENDER;
AlienWoman.title = TITLE;
AlienWoman.key = KEY;
AlienWoman.paletteNames = PALETTE_NAMES;
