import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Long 2";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Long2: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Long2, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6H9V7H8H7V8H6V10H5V22H4V24H5H6H7H8V12H9V11H10V10H13V9H15V10H16V11H17V19H16V20H15V21H14V22H13V24H14H15H16H17H18V9H17V8H16V7H15V6Z"
        fill={hairColor}
      />
    </svg>
  );
};

Long2.allowSemiTransparentColors = false;
Long2.layerType = LAYER_TYPE;
Long2.gender = GENDER;
Long2.title = TITLE;
Long2.key = KEY;
Long2.paletteNames = PALETTE_NAMES;
Long2.blacklist = [ApeWoman, AlienWoman]
