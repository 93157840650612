import { FC, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { COLOR_MAP } from "../constants/Color";
import { isStorageError, NFT, useApi } from "../hooks/useApi";
import { useBanner } from "../hooks/useBanner";
import { LayerFC } from "./layerFCs/types/LayerFC";
import { Text } from "./Text";
import { WagmiModal } from "./WagmiModal";

interface MintButtonProps {
  layerFCs: LayerFC[];
  price: number;
  className?: string;
}

interface MintButtonWrapperProps {
  $isEnabled: boolean;
}

const MintButtonWrapper = styled.div<MintButtonWrapperProps>`
  height: 42px;
  width: 100%;
  cursor: pointer;
  background-color: ${COLOR_MAP.gray700};
  background-color: ${({ $isEnabled }) => $isEnabled && COLOR_MAP.primary};
  color: ${({ $isEnabled }) => !$isEnabled && COLOR_MAP.gray500};
  pointer-events: ${({ $isEnabled }) => !$isEnabled && "none"};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-bottom: 12px;
`;

export const MintButton: FC<MintButtonProps> = (props) => {
  const [showWagmi, setShowWagmi] = useState(false);
  const [nft, setNFT] = useState<NFT | undefined>();
  const bannerHandler = useBanner();
  const { prepareNFT, proofPurchase, getPreparedNFT } = useApi();

  const updateNFT = useCallback(() => {
    getPreparedNFT().then((nft) => {
      if (isStorageError(nft)) {
        setNFT(undefined);
      } else if (nft.status !== "prepared") {
        setNFT(nft);
      }
    });
  }, [getPreparedNFT]);

  useEffect(() => {
    const interval = window.setInterval(() => {
      updateNFT();
    }, 60_000);
    updateNFT();
    return () => window.clearInterval(interval);
  }, [updateNFT]);

  return (
    <>
      {nft?.transactionHash && (
        <MintButtonWrapper
          $isEnabled={true}
          as="a"
          href={`https://etherscan.io/tx/${nft.transactionHash}`}
        >
          <Text $fontColor="black" $fontSize="normal">
            View On Etherscan
          </Text>
        </MintButtonWrapper>
      )}
      {nft?.status === "minted" && typeof nft.index === "number" && (
        <MintButtonWrapper
          $isEnabled={true}
          as="a"
          href={`https://looksrare.org/collections/0xE617c86530Bc4a7A4819385cE4CAFD31779b94fe/${
            nft.index + 1
          }`}
        >
          <Text $fontColor="black" $fontSize="normal">
            View On LooksRare
          </Text>
        </MintButtonWrapper>
      )}
      {showWagmi && <WagmiModal onClose={() => setShowWagmi(false)} />}
    </>
  );
};
