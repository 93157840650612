import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Cap Designer";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapDesigner: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6H9V7H8V8H7V9V10V11H20V10H17V9V8H16V7H15V6Z"
        fill="black"
      />
      <rect x="12" y="7" width="1" height="1" fill="#F24E1F" />
      <rect x="13" y="7" width="1" height="1" fill="#FF7262" />
      <rect x="13" y="8" width="1" height="1" fill="#19BCFE" />
      <rect x="12" y="8" width="1" height="1" fill="#A259FF" />
      <rect x="12" y="9" width="1" height="1" fill="#08CF84" />
    </svg>
  );
};

CapDesigner.layerType = LAYER_TYPE;
CapDesigner.gender = GENDER;
CapDesigner.title = TITLE;
CapDesigner.key = KEY;
CapDesigner.paletteNames = PALETTE_NAMES;
