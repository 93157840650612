import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Side 2";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Side2: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Side2, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6H15V7H16V8H17V9H18V11H19V20H20V23H21V24H20H19H18H17H16H15H14H13V22H14V21H15V20H16V19H17V12H16V11H15V10H13V9H9V10H8V12H7V19H6V9H7V7H8H9V6ZM7 19V21H8V19H7Z"
        fill={hairColor}
      />
    </svg>
  );
};

Side2.allowSemiTransparentColors = false;
Side2.layerType = LAYER_TYPE;
Side2.gender = GENDER;
Side2.title = TITLE;
Side2.key = KEY;
Side2.paletteNames = PALETTE_NAMES;
Side2.blacklist = [ApeWoman, AlienWoman]
