import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Cap Chapter One";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapC1: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 8H20V9H6V6H7V5H8V4H15V5H16V6H17V8Z" fill="#031A28" />
      <path d="M12 5H14V5.5H13.5V7.5H14V8H12V7.5H13V6.5H12.5V6H13V5.5H12V5Z" fill="#B7FF9B" />
      <path d="M12 5.5V7.5H11.5V5.5H12Z" fill="#B7FF9B" />
      <path d="M14 7.5V7H14.5V7.5H14Z" fill="#B7FF9B" />
      <path d="M14 5.5H14.5V6H14V5.5Z" fill="#B7FF9B" />
    </svg>
  );
};

CapC1.layerType = LAYER_TYPE;
CapC1.gender = GENDER;
CapC1.title = TITLE;
CapC1.key = KEY;
CapC1.paletteNames = PALETTE_NAMES;
CapC1.isBranded = true
CapC1.price = 0.01;
