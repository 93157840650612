import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Dreads";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAME_2: PaletteName = "Holder";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const Dreads: LayerFC = (props) => {
  const palettes = usePalettes();

  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Dreads, PALETTE_NAME_1).colors;
  }, [palettes]);

  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Dreads, PALETTE_NAME_2).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  const holderColor = useMemo(() => palette2[0], [palette2]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6H15V7H16V8H17V9H18V12H17V15H16V11H15V10H14V11H13V10H10V11H9V15H8V12H7V16H6V14H5V10H6V8H7V7H8H9V6Z"
        fill={hairColor} 
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 11H13V12H14V11ZM17 12H18V13H17V12ZM5 14H6V15H5V14ZM9 15H8V16H9V15ZM17 15H16V16H17V15ZM7 16H6V17H7V16Z"
        fill={holderColor}
      />
    </svg>
  );
};

Dreads.allowSemiTransparentColors = false;
Dreads.layerType = LAYER_TYPE;
Dreads.gender = GENDER;
Dreads.title = TITLE;
Dreads.key = KEY;
Dreads.paletteNames = PALETTE_NAMES;
Dreads.blacklist = [ApeWoman, AlienWoman];
