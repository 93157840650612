import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Short Curl";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const ShortCurl: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(ShortCurl, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5H15V6H16V7H17V8V9V10H16V9H15V8H14V9H13V10H12H11V9H12V8H11V9H9V10H8V11H6V10V9V8V7H7V6H8V5Z"
        fill={hairColor}
      />
    </svg>
  );
};

ShortCurl.allowSemiTransparentColors = false;
ShortCurl.layerType = LAYER_TYPE;
ShortCurl.gender = GENDER;
ShortCurl.title = TITLE;
ShortCurl.key = KEY;
ShortCurl.paletteNames = PALETTE_NAMES;
ShortCurl.blacklist = [ApeMan, AlienMan]