import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Clothes";
const GENDER: Gender = "Man";
const TITLE = "Fur";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Fur: LayerFC = (props) => {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6 20H5V21H6V22H7V24H8V21H7V20H6ZM11 22H10V24H11V22Z" fill="#E6D5C7"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7 22H3V24H7V22ZM13 22H11V24H13V22Z" fill="#A56E3F"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4 21H6V22V23H5V22H4V21ZM4 23V22H3V23H2V24H3V23H4ZM4 23V24H5V23H4ZM6 23H7V24H6V23ZM12 22H13V23H12V22ZM12 23V24H11V23H12ZM13 23V24H14V23H13Z" fill="#5C3E24"/>
    </svg>    
  );
};

Fur.layerType = LAYER_TYPE;
Fur.gender = GENDER;
Fur.title = TITLE;
Fur.key = KEY;
Fur.paletteNames = PALETTE_NAMES;
