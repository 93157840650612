import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Normal";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Normal: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Normal, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6H15V7H17V9H18V11H19V17H20V19H18V20H15V18H16V17V11H15H12V12H11V11H9V12H8V15V18V19V20H6V19H3V18H4V15H5V12H6V9H7V8H8V7H9V6Z"
        fill={hairColor}
      />
    </svg>
  );
};

Normal.allowSemiTransparentColors = false;
Normal.layerType = LAYER_TYPE;
Normal.gender = GENDER;
Normal.title = TITLE;
Normal.key = KEY;
Normal.paletteNames = PALETTE_NAMES;
Normal.blacklist = [ApeWoman, AlienWoman]