import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Clothes";
const GENDER: Gender = "Man";
const TITLE = "Polo";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Clothes";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Polo: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Polo, PALETTE_NAME_1).colors;
  }, [palettes]);

  const clothesColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5" y="23" width="3" height="1" fill={clothesColor} />
      <rect x="9" y="23" width="3" height="1" fill={clothesColor} />
    </svg>
  );
};

Polo.layerType = LAYER_TYPE;
Polo.gender = GENDER;
Polo.title = TITLE;
Polo.key = KEY;
Polo.paletteNames = PALETTE_NAMES;
