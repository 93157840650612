import { useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";

interface GMLoadingState {
  isLoading: true;
}

const DURATION_VISIBLE = 2_000;

export type BannerType = "info" | "error";

interface Banner {
  content: string | JSX.Element;
  type?: BannerType;
}

interface GMLoadedState {
  isLoading: false;
  setBanner: (banner: Banner | undefined) => void;
  banner: Banner | undefined;
}

export const useBanner = singletonHook(
  { isLoading: true },
  (): GMLoadingState | GMLoadedState => {
    const [banner, setBanner] = useState<Banner | undefined>();
    const [timeoutHandler, setTimeoutHandler] = useState<number | undefined>();

    useEffect(() => {
      window.clearTimeout(timeoutHandler);
      setTimeoutHandler(
        window.setTimeout(() => {
          setBanner(undefined);
        }, DURATION_VISIBLE)
      );
    }, [banner]);

    return {
      isLoading: false,
      setBanner,
      banner,
    };
  }
);
