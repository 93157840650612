import { FC, useCallback, useMemo } from "react";
import styled from "styled-components";
import { COLOR_MAP } from "../constants/Color";
import { layerFCPrice } from "../helpers/layerFCsPrice";
import { Icon } from "./Icon";
import { availableLayerFCs } from "./layerFCs";
import { LayerFC } from "./layerFCs/types/LayerFC";
import { Text } from "./Text";

interface CartAttributeProps {
  layerFC: LayerFC;
  layerFCs: LayerFC[];
  onSelectedLayerFCChange: (layerFC: LayerFC) => void;
  className?: string;
}

const CartAttributeWrapper = styled.div`
  background-color: ${COLOR_MAP.gray700};
  color: ${COLOR_MAP.gray100};
  height: 36px;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  gap: 12px;
  align-items: center;

  *:nth-child(2) {
    flex: 1;
  }
`;

const LayerIcon = styled(Icon)`
  color: ${COLOR_MAP.gray300};
`;

const Title = styled(Text)`
  flex: 1;
`;

const Price = styled(Text)``;

const TrashIconWrapper = styled.div<{ $isDefaultLayer: boolean }>`
  cursor: pointer;
  color: ${({ $isDefaultLayer }) =>
    $isDefaultLayer ? COLOR_MAP.gray500 : COLOR_MAP.primary};
  pointer-events: ${({ $isDefaultLayer }) => $isDefaultLayer && "none"};
  display: flex;
  align-items: center;
  &:hover {
    color: ${COLOR_MAP.white};
  }
`;

interface TrashIconProps {
  isDefaultLayer: boolean;
  onClick: () => void;
}

const TrashIcon: FC<TrashIconProps> = (props) => {
  return (
    <TrashIconWrapper
      $isDefaultLayer={props.isDefaultLayer}
      onClick={props.onClick}
    >
      <Icon type="Trash" />
    </TrashIconWrapper>
  );
};

export const CartAttribute: FC<CartAttributeProps> = (props) => {
  const gender = useMemo(
    () =>
      props.layerFCs.find((layerFC) => layerFC.layerType === "Type")?.gender ??
      "Generic",
    [props.layerFCs]
  );

  const layerFCs = useMemo(
    () =>
      availableLayerFCs({
        layerType: props.layerFC.layerType,
        gender,
        selectedLayerFCs: props.layerFCs,
      }),
    [gender, props.layerFC, props.layerFCs]
  );

  const price = useMemo(() => layerFCPrice(props.layerFC), [props.layerFC]);

  const isDefaultLayer = useMemo(
    () =>
      props.layerFC.key === layerFCs[0]?.key ||
      props.layerFC.layerType === "Type",
    [props.layerFC, layerFCs]
  );

  const hideAttribute = useMemo(
    () => props.layerFC.title === "None",
    [props.layerFC]
  );

  const handleDeleteClick = useCallback(() => {
    props.onSelectedLayerFCChange(props.layerFC);
  }, [props]);

  if (hideAttribute) {
    return null;
  }

  return (
    <CartAttributeWrapper className={props.className}>
      <LayerIcon type={props.layerFC.layerType} />
      <Title $fontSize="small">{props.layerFC.title}</Title>
      <Price $fontSize="small">{price.toFixed(3)}</Price>
      <TrashIcon isDefaultLayer={isDefaultLayer} onClick={handleDeleteClick} />
    </CartAttributeWrapper>
  );
};
