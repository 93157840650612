import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Clothes";
const GENDER: Gender = "Man";
const TITLE = "Jogging Suit";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Clothes";
const PALETTE_NAME_2: PaletteName = "Shirt";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const JoggingSuit: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(JoggingSuit, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(JoggingSuit, PALETTE_NAME_2).colors;
  }, [palettes]);

  const stripesColor = useMemo(() => palette1[0], [palette1]);
  const suitColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 22H7V23H8V24H5V23H6V22ZM10 23H9V24H12V23H11V22H10V23Z"
        fill={stripesColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 21H7V22H6V21ZM5 23V22H6V23H5ZM5 23V24H4V23H5ZM12 22H11V23H12V24H13V23H12V22Z"
        fill={suitColor}
      />
    </svg>
  );
};

JoggingSuit.layerType = LAYER_TYPE;
JoggingSuit.gender = GENDER;
JoggingSuit.title = TITLE;
JoggingSuit.key = KEY;
JoggingSuit.paletteNames = PALETTE_NAMES;
