import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Long 6";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Long6: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Long6, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6H15V7H16V8H17V9H18V16H17V12H16V11H14V10H11V9H9V10H8V12H7V18H6V19H5V18H4V17H3V16H4V14H5V12H6V9H7V8H8V7H9V6Z"
        fill={hairColor}
      />
    </svg>
  );
};

Long6.allowSemiTransparentColors = false;
Long6.layerType = LAYER_TYPE;
Long6.gender = GENDER;
Long6.title = TITLE;
Long6.key = KEY;
Long6.paletteNames = PALETTE_NAMES;
Long6.blacklist = [ApeWoman, AlienWoman]
