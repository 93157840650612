import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Blue Cap";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const BlueCap: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 4H15V5H16V6H17V8H20V9H6V6H7V5H8V4Z" fill="#2169B5" />
      <path d="M11 4H15V5H16V6H17V8H9V6H10V5H11V4Z" fill="#2476CD" />
    </svg>
  );
};

BlueCap.layerType = LAYER_TYPE;
BlueCap.gender = GENDER;
BlueCap.title = TITLE;
BlueCap.key = KEY;
BlueCap.paletteNames = PALETTE_NAMES;