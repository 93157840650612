import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";

import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "Shades M";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const ShadesM1px: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 11H6V12H8V13H9V14H11V13H12V12H13V13H14V14H16V13H17V12V11Z"
        fill="black"
      />
    </svg>
  );
};

ShadesM1px.layerType = LAYER_TYPE;
ShadesM1px.gender = GENDER;
ShadesM1px.title = TITLE;
ShadesM1px.key = KEY;
ShadesM1px.paletteNames = PALETTE_NAMES;
ShadesM1px.whitelist = [AlienMan, ApeMan];
