import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Man } from "../../type/Man";
import { ZombieMan } from "../../type/ZombieMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "Trucker";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}-2px`;
const PALETTE_NAME_1: PaletteName = "Glass";
const PALETTE_NAME_2: PaletteName = "Frame";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const Trucker2px: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Trucker2px, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Trucker2px, PALETTE_NAME_2).colors;
  }, [palettes]);

  const glassesColor = useMemo(() => palette1[0], [palette1]);
  const frameColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11H8V13V14H11V13H12V12H14V13H15V14H17V13V11H14H12Z"
        fill={glassesColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 10H17V11H8V10ZM8 14H7V12H6V11H7H8V14ZM11 14V15H8V14H11ZM12 13V14H11V13H12ZM14 13V12H12V13H14ZM15 14H14V13H15V14ZM17 14V15H15V14H17ZM17 14H18V11H17V14Z"
        fill={frameColor}
      />
    </svg>
  );
};

Trucker2px.layerType = LAYER_TYPE;
Trucker2px.gender = GENDER;
Trucker2px.title = TITLE;
Trucker2px.key = KEY;
Trucker2px.paletteNames = PALETTE_NAMES;
Trucker2px.whitelist = [Man, ZombieMan];
