import { useCallback } from "react";
import { layerFCs } from "../components/layerFCs";
import { LayerFC } from "../components/layerFCs/types/LayerFC";
import { PaletteName, PALETTES } from "../constants/Palettes";
import { keyForLayerFCPaletteName, usePalettes } from "./usePalletes";
import LZString from "lz-string";
import { useApi } from "./useApi";
import { layerFCsSort } from "../helpers/layerFCsSort";

type PaletteColors = Partial<Record<PaletteName, number>>;

interface LayerFCsTransportFormat {
  layers: { key: string; paletteColors: PaletteColors }[];
}

const cache = new Map<string, string>();

export const useLayerFCsParser = () => {
  const palettes = usePalettes();
  const { getSlug, setSlug } = useApi();

  const parse = useCallback(
    async (strOrId: string) => {
      try {
        if (palettes.isLoading || strOrId === "") {
          return [];
        }
        let base64JsonString = strOrId;
        if (strOrId.length === 32) {
          const imagePath = await getSlug(strOrId);
          if (imagePath) {
            base64JsonString = imagePath.path;
          }
        }
        const json: LayerFCsTransportFormat = JSON.parse(
          LZString.decompressFromBase64(base64JsonString) || ""
        );
        return json.layers.map((layer) => {
          const layerFC = layerFCs.find((layerFC) => layerFC.key === layer.key);
          if (!layerFC) {
            throw new Error(`LayerFC with key ${layer.key} not found`);
          }
          for (const [paletteName, colorIndex] of Object.entries(
            layer.paletteColors
          )) {
            const paletteKey = keyForLayerFCPaletteName(
              layerFC.key,
              paletteName as PaletteName
            );
            const palette = PALETTES[paletteName as PaletteName];
            const colors = palette.colors[colorIndex];
            if (colors) {
              palettes.changeSelectedColors(paletteKey, colors);
            }
          }
          return layerFC;
        });
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    [palettes.isLoading] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const stringify = useCallback(
    async (layerFCs: LayerFC[]) => {
      if (palettes.isLoading) {
        return "";
      }
      const json: LayerFCsTransportFormat = {
        layers: layerFCsSort(layerFCs).map((layerFC) => ({
          key: layerFC.key,
          paletteColors: layerFC.paletteNames.reduce<PaletteColors>(
            (acc, paletteName) => {
              const palette = PALETTES[paletteName];
              const selectedColors =
                palettes.selectedColors[
                  keyForLayerFCPaletteName(layerFC.key, paletteName)
                ];
              const colorIndex = palette.colors.findIndex(
                (colors) => selectedColors.join(";") === colors.join(";")
              );
              acc[paletteName] = colorIndex;
              return acc;
            },
            {}
          ),
        })),
      };
      const path = LZString.compressToBase64(JSON.stringify(json));
      const cachedId = cache.get(path);
      if (cachedId) {
        return cachedId;
      }
      const id = await setSlug({ path });
      if (id) {
        cache.set(path, id);
      }
      return id || path;
    },
    [palettes] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return { stringify, parse };
};
