import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Pixelenca 8";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Pixelenca8: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="#EAE4D9" />
      <rect x="1" y="1" width="1" height="6" fill="white" />
      <rect x="3" y="1" width="1" height="14" fill="white" />
      <rect x="5" y="1" width="2" height="3" fill="#CC5958" />
      <rect x="5" y="16" width="2" height="7" fill="white" />
      <rect x="8" y="1" width="1" height="17" fill="white" />
      <rect x="8" y="20" width="1" height="3" fill="#CC5958" />
      <rect x="10" y="4" width="2" height="10" fill="white" />
      <rect x="13" y="2" width="2" height="14" fill="white" />
      <rect x="16" y="9" width="3" height="10" fill="white" />
      <rect x="1" y="11" width="1" height="12" fill="white" />
      <rect x="1" y="7" width="1" height="2" fill="#CC5958" />
      <rect x="22" y="14" width="1" height="2" fill="#CC5958" />
      <rect x="3" y="15" width="1" height="2" fill="#513F30" />
      <rect x="3" y="19" width="1" height="4" fill="#84A8BD" />
      <rect x="10" y="19" width="2" height="4" fill="#84A8BD" />
      <rect x="16" y="1" width="3" height="2" fill="#F4C267" />
      <rect x="13" y="22" width="2" height="1" fill="#F4C267" />
      <rect x="16" y="3" width="3" height="4" fill="white" />
      <rect x="13" y="1" width="2" height="1" fill="white" />
      <rect x="10" y="1" width="2" height="1" fill="white" />
      <rect x="10" y="2" width="2" height="2" fill="#84A8BD" />
      <rect x="16" y="21" width="3" height="1" fill="white" />
      <rect x="13" y="19" width="2" height="2" fill="white" />
      <rect x="10" y="17" width="2" height="2" fill="white" />
      <rect x="16" y="22" width="3" height="1" fill="#CC5958" />
      <rect x="13" y="21" width="2" height="1" fill="#CC5958" />
      <rect x="20" y="1" width="1" height="4" fill="white" />
      <rect x="22" y="1" width="1" height="11" fill="white" />
      <rect x="22" y="16" width="1" height="7" fill="white" />
      <rect x="20" y="19" width="1" height="4" fill="white" />
      <rect x="20" y="7" width="1" height="10" fill="#513F30" />
      <rect x="5" y="6" width="2" height="8" fill="white" />
    </svg>
  );
};

Pixelenca8.layerType = LAYER_TYPE;
Pixelenca8.gender = GENDER;
Pixelenca8.title = TITLE;
Pixelenca8.key = KEY;
Pixelenca8.paletteNames = PALETTE_NAMES;
Pixelenca8.price = 0.001;
