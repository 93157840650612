import { ZombieMan } from "../../type/ZombieMan";
import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";
import { usePalettes } from "../../../../hooks/usePalletes";
import { useMemo } from "react";

const LAYER_TYPE: LayerType = "Eyes";
const GENDER: Gender = "Man";
const TITLE = "Zombie Default";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Zombie Eyes";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const ZombieDefault: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(ZombieDefault, PALETTE_NAME_1).colors;
  }, [palettes]);
  const eyeColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 11H11V12H9V11ZM10 13H9V14H10V13ZM15 13H14V14H15V13ZM16 11H14V12H16V11Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 12H9V13H10V12ZM15 12H14V13H15V12Z"
        fill={eyeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 12H10V13H11V12ZM16 12H15V13H16V12Z"
        fill="black"
      />
    </svg>
  );
};

ZombieDefault.layerType = LAYER_TYPE;
ZombieDefault.gender = GENDER;
ZombieDefault.title = TITLE;
ZombieDefault.key = KEY;
ZombieDefault.paletteNames = PALETTE_NAMES;
ZombieDefault.whitelist = [ZombieMan];
