import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Generic";
const TITLE = "Skateboard";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Skateboard: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 10H4V11H3V12H2V24H3V12H4V11H8V12H9V24H10V12H9V11H8V10Z" fill="#AE8B61" />
      <path d="M8 11H4V12H3V24H9V12H8V11Z" fill="#CAAE8D" />
      <path d="M3 14H1V17H3V14Z" fill="#342929" />
      <path d="M11 14H9V17H11V14Z" fill="#342929" />
      <path d="M9 15H3V16H5V17H7V16H9V15Z" fill="#716A6A" />
    </svg>
  );
};

Skateboard.layerType = LAYER_TYPE;
Skateboard.gender = GENDER;
Skateboard.title = TITLE;
Skateboard.key = KEY;
Skateboard.paletteNames = PALETTE_NAMES;
Skateboard.zIndex = 5;
