import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Clothes";
const GENDER: Gender = "Man";
const TITLE = "Collar L";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Clothes";
const PALETTE_NAME_2: PaletteName = "Shirt";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const CollarL: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(CollarL, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(CollarL, PALETTE_NAME_2).colors;
  }, [palettes]);

  const collarColor = useMemo(() => palette1[0], [palette1]);
  const shirtColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 23H5V22H12V23H13V24H4V23Z"
        fill={collarColor}
      />
      <path
        d="M8 22H11V23H10V24H9V23H8V22Z"
        fill={shirtColor}
      />
    </svg>

  );
};

CollarL.layerType = LAYER_TYPE;
CollarL.gender = GENDER;
CollarL.title = TITLE;
CollarL.key = KEY;
CollarL.paletteNames = PALETTE_NAMES;
