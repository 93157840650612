import { ZombieWoman } from "../../type/ZombieWoman";
import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Eyes";
const GENDER: Gender = "Woman";
const TITLE = "Zombie Full";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const ZombieFull: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 13H9V14H11V13Z" fill="#F24E1E" />
      <path d="M16 13H14V14H16V13Z" fill="#F24E1E" />
      <path d="M11 12H9V13H11V12Z" fill="black" fillOpacity="0.2" />
      <path d="M10 14H9V15H10V14Z" fill="black" fillOpacity="0.2" />
      <path d="M14 14H15V15H14V14Z" fill="black" fillOpacity="0.2" />
      <path d="M16 12H14V13H16V12Z" fill="black" fillOpacity="0.2" />
    </svg>
  );
};

ZombieFull.layerType = LAYER_TYPE;
ZombieFull.gender = GENDER;
ZombieFull.title = TITLE;
ZombieFull.key = KEY;
ZombieFull.paletteNames = PALETTE_NAMES;
ZombieFull.whitelist = [ZombieWoman];
