import { LayerFC } from "../../types/LayerFC";
import { Angry } from "./Angry";
import { Grim } from "./Grim";
import { Left } from "./Left";
import { Right } from "./Right";
import { Sleep } from "./Sleep";
import { Wink } from "./Wink";
import { ZombieDefault } from "./ZombieDefault";
import { ZombieFull } from "./ZombieFull";

export const eyesWomanLayers: LayerFC[] = [
  Right,
  Left,
  Wink,
  Sleep,
  Angry,
  Grim,
  ZombieDefault,
  ZombieFull,
];
