import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Hoodie } from "../../hat/man/Hoodie";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";
import { capWhitelist } from "./whitelists";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Wild";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}-Cap`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const WildCap: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(WildCap, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7H11V8H14V7ZM5 9H6H7H8H9H10V10H9V11H8V12H7V11H6V12H5V14H6V19H5V17H4V15H3V13H4V12H3V11H4V10H5V9ZM11 9H12H13V10H12V11H11V9ZM16 9H17V10H18V11H17H16V9ZM15 9H14V10H15V9Z"
        fill={hairColor}
      />
    </svg>
  );
};

WildCap.layerType = LAYER_TYPE;
WildCap.gender = GENDER;
WildCap.title = TITLE;
WildCap.key = KEY;
WildCap.isExtension = true;
WildCap.paletteNames = PALETTE_NAMES;
WildCap.whitelist = [Hoodie, ...capWhitelist];
