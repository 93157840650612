import { FC, useMemo } from "react";
import styled from "styled-components";
import { COLOR_MAP } from "../constants/Color";
import { isZIndexBefore } from "../helpers/layerFCsSort";
import { Icon } from "./Icon";
import { availableLayerFCs } from "./layerFCs";
import { LayerFC } from "./layerFCs/types/LayerFC";

interface LayerProps {
  onClick: () => void;
  selectedLayerFCs: LayerFC[];
  layerFC: LayerFC;
  typeLayerFC: LayerFC;
  className?: string;
}

interface LayerWrapperProps {
  $isActive: boolean;
  $isClickable: boolean;
}

const LayerWrapper = styled.div<LayerWrapperProps>`
  position: relative;
  pointer-events: ${({ $isClickable }) => !$isClickable && "none"};
  cursor: pointer;
  width: 72px;
  height: 72px;
  overflow: hidden;

  > svg:first-of-type {
    background-color: ${`${COLOR_MAP.gray300}33`};
  }
  svg {
    width: 72px;
    height: 72px;
    position: absolute;
    top: 0;
    left: 0;
    &:first-child {
      position: relative;
    }
    &:last-child {
      color: ${({ $isActive }) =>
        $isActive ? COLOR_MAP.primary : "transparent"};
      &:hover {
        color: ${COLOR_MAP.white};
      }
    }
  }
`;

const TypeLayerWrapper = styled.div`
  filter: grayscale(1);
  opacity: 0.2;
`;

const ExtensionBaseWrapper = styled.div`
  position: absolute;
  top: 0;
  filter: grayscale(1) contrast(0.25) brightness(0.5);
`;

export const Layer: FC<LayerProps> = (props) => {
  const availableForGenderLayerFCs = useMemo(
    () =>
      props.layerFC.layerType !== "Type"
        ? []
        : availableLayerFCs({
            gender: props.layerFC.gender,
            selectedLayerFCs: [props.layerFC],
          }),
    [props]
  );
  const Eyes = useMemo(() => {
    const LayerFC = availableForGenderLayerFCs.find(
      (layerFC) => layerFC.layerType === "Eyes"
    );
    return LayerFC ? <LayerFC /> : null;
  }, [availableForGenderLayerFCs]);
  const Mouth = useMemo(() => {
    const LayerFC = availableForGenderLayerFCs.find(
      (layerFC) => layerFC.layerType === "Mouth"
    );
    return LayerFC ? <LayerFC /> : null;
  }, [availableForGenderLayerFCs]);
  const ExtensionBaseLayerFC = useMemo(
    () =>
      props.selectedLayerFCs.find(
        (layerFC) =>
          props.layerFC.isExtension &&
          !layerFC.isExtension &&
          layerFC.layerType === props.layerFC.layerType
      ),
    [props.selectedLayerFCs, props.layerFC]
  );
  const isExtensionInForeground = useMemo(() => {
    if (!ExtensionBaseLayerFC) return false;
    return isZIndexBefore(ExtensionBaseLayerFC, props.layerFC);
  }, [props.layerFC, ExtensionBaseLayerFC]);
  const ExtensionBase = useMemo(() => {
    return ExtensionBaseLayerFC ? (
      <ExtensionBaseWrapper>
        <ExtensionBaseLayerFC />
      </ExtensionBaseWrapper>
    ) : null;
  }, [ExtensionBaseLayerFC]);

  const layerForeground = useMemo(() => {
    return (
      <>
        {Eyes}
        {Mouth}
        {isExtensionInForeground && ExtensionBase}
      </>
    );
  }, [Eyes, Mouth, isExtensionInForeground, ExtensionBase]);
  const layerBackground = useMemo(() => {
    const Background =
      props.layerFC.layerType === "Type" ? null : (
        <TypeLayerWrapper>
          <props.typeLayerFC />
        </TypeLayerWrapper>
      );

    return (
      <>
        {Background}
        {!isExtensionInForeground && ExtensionBase}
      </>
    );
  }, [props, isExtensionInForeground, ExtensionBase]);

  const isActive = useMemo(
    () => props.selectedLayerFCs.includes(props.layerFC),
    [props]
  );
  return (
    <LayerWrapper
      className={props.className}
      $isActive={isActive}
      $isClickable={
        props.layerFC.layerType === "Special" ||
        props.layerFC.isExtension ||
        !isActive
      }
      onClick={props.onClick}
      title={props.layerFC.title}
    >
      {layerBackground}
      <props.layerFC />
      {layerForeground}
      <Icon type="ActiveItem" />
    </LayerWrapper>
  );
};
