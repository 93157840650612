import { PaletteName } from "../../../constants/Palettes";
import { ApeWoman } from "../type/ApeWoman";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Woman";
const TITLE = "Devil";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const DevilWoman: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 5H7V6H6V9H7V6H8V7H9V6H8V5Z" fill="black" />
      <path d="M15 6H16V7H15V6Z" fill="black" />
      <path d="M17 6H16V5H17V6Z" fill="black" />
      <path d="M17 6V9H18V6H17Z" fill="black" />
      <path d="M7 6H8V7H9V8H11V9H10V10H8V9H7V6Z" fill="#FF5858" />
      <path d="M15 8H14V9H15V10H16V9H17V6H16V7H15V8Z" fill="#FF5858" />
      <path d="M11 8H10V9H8V10H10V9H11V8Z" fill="#BF4242" />
      <path d="M15 8H14V9H15V10H16V9H15V8Z" fill="#BF4242" />
    </svg>
  );
};

DevilWoman.layerType = LAYER_TYPE;
DevilWoman.gender = GENDER;
DevilWoman.title = TITLE;
DevilWoman.key = KEY;
DevilWoman.paletteNames = PALETTE_NAMES;
DevilWoman.whitelist = [ApeWoman];
