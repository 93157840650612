import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Pixelenca 9";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Pixelenca9: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="#84A8BD" />
      <rect x="1" width="2" height="5" fill="#DED7C7" />
      <rect x="1" y="7" width="2" height="12" fill="#513F30" />
      <rect x="1" y="20" width="2" height="1" fill="#E0D9C8" />
      <rect x="10" y="10" width="1" height="1" fill="#E0D9C8" />
      <rect x="6" y="21" width="3" height="2" fill="#E0D9C8" />
      <rect x="1" y="21" width="2" height="2" fill="#513F30" />
      <path d="M4 0H5V2H4V0Z" fill="#CF5856" />
      <path d="M20 5H21V7H20V5Z" fill="#CF5856" />
      <path d="M20 11H21V20H20V11Z" fill="#223356" />
      <path d="M22 13H23V23H22V13Z" fill="#CC5958" />
      <path d="M4 4H5V21H4V4Z" fill="#DED7C7" />
      <path d="M10 12H11V23H10V12Z" fill="#BED8CE" />
      <rect x="6" width="3" height="19" fill="#F6D276" />
      <rect x="12" width="2" height="8" fill="#B7D2CD" />
      <rect x="12" y="15" width="2" height="8" fill="#F3BD46" />
      <rect x="15" width="2" height="2" fill="#B7D2CD" />
      <rect x="10" width="1" height="10" fill="#513F30" />
      <rect x="12" y="8" width="2" height="1" fill="#513F30" />
      <rect x="18" y="13" width="1" height="1" fill="#513F30" />
      <rect x="15" y="2" width="2" height="1" fill="#513F30" />
      <rect x="18" width="1" height="10" fill="#ECB4A4" />
      <rect x="20" width="1" height="5" fill="#DED7C7" />
      <rect x="22" width="1" height="4" fill="#F6D276" />
      <rect x="22" y="6" width="1" height="5" fill="#B7D2CD" />
      <rect x="4" y="21" width="1" height="1" fill="#8D3D5A" />
      <rect x="4" y="22" width="1" height="1" fill="#D2BA8C" />
      <rect x="12" y="14" width="2" height="1" fill="#CC5958" />
      <rect x="12" y="13" width="2" height="1" fill="#513F30" />
      <rect x="15" y="5" width="2" height="18" fill="#E0D9C8" />
      <rect x="18" y="14" width="1" height="9" fill="#E0D9C8" />
      <rect x="20" y="20" width="1" height="3" fill="#E0D9C8" />
    </svg>
  );
};

Pixelenca9.layerType = LAYER_TYPE;
Pixelenca9.gender = GENDER;
Pixelenca9.title = TITLE;
Pixelenca9.key = KEY;
Pixelenca9.paletteNames = PALETTE_NAMES;
Pixelenca9.price = 0.001;
