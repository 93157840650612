import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Generic";
const TITLE = "Cigar";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Cigar: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="13" y="18" width="5" height="1" fill="#523422" />
      <rect
        x="14"
        y="18"
        width="1"
        height="1"
        fill="#E7D197"
        fillOpacity="0.2"
      />
      <rect x="18" y="18" width="1" height="1" fill="#DA715A" />
      <rect x="19" y="18" width="1" height="1" fill="white" fillOpacity="0.3" />
      <rect x="20" y="15" width="1" height="3" fill="white" fillOpacity="0.3" />
      <rect x="21" y="13" width="1" height="3" fill="white" fillOpacity="0.3" />
    </svg>
  );
};

Cigar.layerType = LAYER_TYPE;
Cigar.gender = GENDER;
Cigar.title = TITLE;
Cigar.key = KEY;
Cigar.paletteNames = PALETTE_NAMES;
