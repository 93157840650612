import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Long 1";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Long1: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Long1, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6H11H9V7H8V8H7V10H6V11V12H5V15H3V16H4V18H5V19H4V20H3V21H4V23H5V24H6H7H8V19H7V15H6V13H8V12H9V11V10H11V11H13V10H14H15V11H16V12H17V13V19H16V20H15V21H14V22H13V24H14H15V23H16V22H17V21H18V19H19V18H18V17H19V12H18V11V10H17V8H16V7H15V6H14H12ZM12 10H11V9H12V10Z"
        fill={hairColor}
      />
    </svg>
  );
};

Long1.allowSemiTransparentColors = false;
Long1.layerType = LAYER_TYPE;
Long1.gender = GENDER;
Long1.title = TITLE;
Long1.key = KEY;
Long1.paletteNames = PALETTE_NAMES;
Long1.blacklist = [ApeWoman, AlienWoman];
