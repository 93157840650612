import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Clothes";
const GENDER: Gender = "Man";
const TITLE = "Fur XL";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Fur";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const FurXL: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(FurXL, PALETTE_NAME_1).colors;
  }, [palettes]);

  const furColor = useMemo(() => palette1[0], [palette1]);
  const darkerColor = useMemo(() => palette1[1], [palette1]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 19H6V21H7V22H8V23H9V24H2V23H3V22H4V20H5V19Z" fill={furColor} />
      <path d="M11 22H13V23H14V24H11V22Z" fill={furColor} />
      <path d="M5 18H6V19H5V18Z" fill={darkerColor} />
      <path d="M4 20V19H5V20H4Z" fill={darkerColor} />
      <path d="M4 21V20H3V22H2V23H1V24H2V23H3V22H5V23H6V22H5V21H4Z" fill={darkerColor} />
      <path d="M13 22H14V23H13V22Z" fill={darkerColor} />
      <path d="M14 23H15V24H14V23Z" fill={darkerColor} />
    </svg>
  );
};

FurXL.layerType = LAYER_TYPE;
FurXL.gender = GENDER;
FurXL.title = TITLE;
FurXL.key = KEY;
FurXL.paletteNames = PALETTE_NAMES;
FurXL.price = 0.01;
