import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Beenie L";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hat";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const BeenieL: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(BeenieL, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hatColor = useMemo(() => palette1[0], [palette1]);
  const secondaryhatColor = useMemo(() => palette1[1], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 5H15V6H9V5ZM8 7V6H9V7H8ZM7 8V7H8V8H7ZM7 8V11H6V8H7ZM16 7H15V6H16V7ZM17 8V7H16V8H17ZM17 8V11H18V8H17Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6H9V7H8V8H7V11H17V8H16V7H15V6Z"
        fill={hatColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 8H8V9H7V8ZM9 9H8V10H7V11H8V10H9V11H10V10H11V11H12V10H13V11H14V10H15V11H16V10H17V9H16V8H15V9H14V8H13V9H12V8H11V9H10V8H9V9ZM9 9H10V10H9V9ZM11 9H12V10H11V9ZM13 9H14V10H13V9ZM15 9H16V10H15V9Z"
        fill={secondaryhatColor}
      />
    </svg>
  );
};

BeenieL.layerType = LAYER_TYPE;
BeenieL.gender = GENDER;
BeenieL.title = TITLE;
BeenieL.key = KEY;
BeenieL.paletteNames = PALETTE_NAMES;
