import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Venice";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Venice: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="#551B93"/>
      <rect width="24" height="2" fill="#F1EAE9"/>
      <rect y="3" width="24" height="2" fill="#EDD6C7"/>
      <rect y="6" width="24" height="2" fill="#E8BFA9"/>
      <rect y="9" width="24" height="2" fill="#E6A98C"/>
      <rect y="12" width="24" height="2" fill="#E18795"/>
      <rect y="15" width="24" height="2" fill="#DE5DB9"/>
      <rect y="18" width="24" height="6" fill="#7AD4FF"/>
    </svg>
  );
};

Venice.layerType = LAYER_TYPE;
Venice.gender = GENDER;
Venice.title = TITLE;
Venice.key = KEY;
Venice.paletteNames = PALETTE_NAMES;
Venice.price = 0.001;
