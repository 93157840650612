import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { None } from "../../hat/None";
import { Hoodie } from "../../hat/woman/Hoodie";
import { Woman } from "../../type/Woman";
import { ZombieWoman } from "../../type/ZombieWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Long Hair";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const LongHair: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(LongHair, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 9H17V11H18V12H19V17H18V18H19V19H18V21H17V22H16V23H15V24H14H13V22H14V21H15V20H16V19H17V13V12H16V11H9V12H8V13H6V15H7V19H8V24H7H6H5V23H4V21H3V20H4V19H5V18H4V16H3V15H5V12H6V11H7V9Z"
        fill={hairColor}
      />
    </svg>
  );
};

LongHair.layerType = LAYER_TYPE;
LongHair.gender = GENDER;
LongHair.allowSemiTransparentColors = false;
LongHair.title = TITLE;
LongHair.key = KEY;
LongHair.isExtension = true;
LongHair.paletteNames = PALETTE_NAMES;
LongHair.whitelist = [Woman, ZombieWoman];
LongHair.blacklist = [None, Hoodie]
