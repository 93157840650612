import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Cowboy Hat";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CowboyHat: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 3H8V4H7V7H16V4H15V3ZM2 7H3V8H20V7H21V9H20V10H3V9H2V7Z"
        fill="#794B11"
      />
      <rect x="6" y="7" width="11" height="1" fill="#502F05" />
    </svg>
  );
};

CowboyHat.layerType = LAYER_TYPE;
CowboyHat.gender = GENDER;
CowboyHat.title = TITLE;
CowboyHat.key = KEY;
CowboyHat.paletteNames = PALETTE_NAMES;
