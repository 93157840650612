import { LayerFC } from "../components/layerFCs/types/LayerFC";
import { LayerType } from "../components/layerFCs/types/LayerType";

const defaultLayerZIndex: Record<LayerType, number> = {
  Background: 0,
  Type: 10,
  Ear: 20,
  Hair: 28,
  Hat: 30,
  Clothes: 40,
  Beard: 50,
  Mouth: 60,
  Eyes: 70,
  Glasses: 80,
  Special: 90,
};

const defaultExtensionLayerZIndex: Partial<Record<LayerType, number>> = {
  Ear: 35,
  Hat: 25,
};

export const isZIndexBefore = (a: LayerFC, b: LayerFC): boolean => {
  return zIndexOfLayerFC(a) > zIndexOfLayerFC(b);
};

export const zIndexOfLayerFC = (layerFC: LayerFC): number => {
  const extensionLayerZIndex = defaultExtensionLayerZIndex[layerFC.layerType];
  const layerZIndex = defaultLayerZIndex[layerFC.layerType];
  if (typeof layerFC.zIndex === "number") {
    return layerFC.zIndex;
  }
  if (layerFC.isExtension && typeof extensionLayerZIndex === "number") {
    return extensionLayerZIndex;
  }
  if (typeof layerZIndex === "number") {
    return layerZIndex;
  }
  return 0;
};

export const layerFCsSort = (layerFCs: LayerFC[]) => {
  return layerFCs.sort((a, b) => {
    const diff = zIndexOfLayerFC(a) - zIndexOfLayerFC(b);
    if (diff === 0) {
      return a.key.localeCompare(b.key);
    }
    return diff;
  });
};
