import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Cap Ryze";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapRyze: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 10H20V11H7V8H8V7H9V6H15V7H16V8H17V10Z" fill="black" />
      <path d="M13.125 7H11.25V7.375H11.625V7.75H12V8.125H12.375V8.5H12.75V8.875H11.625V9.25H12V9.625H12.375V10H13.5V9.625H13.125V9.25H12.75V8.875H13.125V8.5H13.5V7.375H13.125V7Z" fill="white" />
    </svg>
  );
};

CapRyze.layerType = LAYER_TYPE;
CapRyze.gender = GENDER;
CapRyze.title = TITLE;
CapRyze.key = KEY;
CapRyze.paletteNames = PALETTE_NAMES;
CapRyze.isBranded = true;
CapRyze.price = 0.01;
