import { PaletteName } from "../../../../constants/Palettes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Mouth";
const GENDER: Gender = "Man";
const TITLE = "Tongue";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Tongue: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="10" y="17" width="1" height="1" fill="black" />
      <rect x="11" y="18" width="3" height="1" fill="black" />
      <rect x="11" y="17" width="3" height="1" fill="white" />
      <rect x="13" y="18" width="2" height="1" fill="#EB8585" />
    </svg>
  );
};

Tongue.layerType = LAYER_TYPE;
Tongue.gender = GENDER;
Tongue.title = TITLE;
Tongue.key = KEY;
Tongue.paletteNames = PALETTE_NAMES;
Tongue.blacklist = [ApeMan, AlienMan];
