import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { None } from "../../hat/None";
import { Woman } from "../../type/Woman";
import { ZombieWoman } from "../../type/ZombieWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Long Side";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const LongSide: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(LongSide, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 7H9V8H8V9H7V12H6V14H5V18H6V19H7V21H8V15H7V13H8V12H10V11H15V12H16V13H17V9H16V8H15V7Z" fill={hairColor} />
      <path d="M13 22H14V21H15V23H16V24H13V22Z" fill={hairColor} />
    </svg>

  );
};

LongSide.layerType = LAYER_TYPE;
LongSide.gender = GENDER;
LongSide.allowSemiTransparentColors = false;
LongSide.title = TITLE;
LongSide.key = KEY;
LongSide.isExtension = true;
LongSide.paletteNames = PALETTE_NAMES;
LongSide.whitelist = [Woman, ZombieWoman];
LongSide.blacklist = [None]
