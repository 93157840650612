import { PaletteName } from "../../../../constants/Palettes";
import { Man } from "../../type/Man";
import { ZombieMan } from "../../type/ZombieMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Mouth";
const GENDER: Gender = "Man";
const TITLE = "Cry Wide";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CryWide: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="14" y="19" width="1" height="1" fill="black" />
      <rect x="10" y="19" width="4" height="1" fill="#E76161" />
      <rect x="10" y="18" width="5" height="1" fill="black" />
      <rect x="10" y="17" width="5" height="1" fill="white" />
    </svg>
  );
};

CryWide.layerType = LAYER_TYPE;
CryWide.gender = GENDER;
CryWide.title = TITLE;
CryWide.key = KEY;
CryWide.paletteNames = PALETTE_NAMES;
CryWide.blacklist = [Man, ZombieMan];
