import { FC, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { layerFCsSort } from "../helpers/layerFCsSort";
import { LayerFC } from "./layerFCs/types/LayerFC";
import { SharePopover } from "./SharePopover";

interface PreviewProps {
  layerFCs: LayerFC[];
  className?: string;
}

interface PreviewWrapperProps {
  $showRounded: boolean;
}

const OuterPreviewWrapper = styled.div`
  position: relative;
`;

const InnerPreviewWrapper = styled.svg<PreviewWrapperProps>`
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  width: 240px;
  height: 240px;
  background: #282a30;
  cursor: pointer;
  border-radius: ${({ $showRounded }) => $showRounded && "999px"};
  @media screen and (max-width: 520px) {
    width: 200px;
    height: 200px;
  }
  @media screen and (max-width: 380px) {
    width: 172px;
    height: 172px;
  }
`;

export const Preview: FC<PreviewProps> = (props) => {
  const [showRounded, setShowRounded] = useState(false);
  const [showShare, setShowShare] = useState(false);

  const sortedLayerFCs = useMemo(() => layerFCsSort(props.layerFCs), [props]);

  const layers = useMemo(
    () =>
      sortedLayerFCs.map((LayerFC) => {
        return <LayerFC key={LayerFC.key} />;
      }),
    [sortedLayerFCs]
  );

  const previewClickHandler = useCallback(() => {
    setShowRounded(!showRounded);
  }, [showRounded]);

  return (
    <OuterPreviewWrapper
      onClick={previewClickHandler}
      onContextMenu={(e) => {
        e.preventDefault();
        setShowShare(true);
      }}
      className={props.className}
    >
      <InnerPreviewWrapper
        className="preview"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        $showRounded={showRounded}
      >
        {layers}
      </InnerPreviewWrapper>
      {showShare && <SharePopover onClose={() => setShowShare(false)} />}
    </OuterPreviewWrapper>
  );
};
