import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Woman";
const TITLE = "Shades L";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Sunglasses: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 12H17V13H7V12Z" fill="black" />
      <path d="M8 13H12V15H8V13Z" fill="black" />
      <path d="M13 13H17V15H13V13Z" fill="black" />
      <path d="M9 15H11V16H9V15Z" fill="black" />
      <path d="M12 13H13V14H12V13Z" fill="black" />
      <path d="M14 15H16V16H14V15Z" fill="black" />
    </svg>
  );
};

Sunglasses.layerType = LAYER_TYPE;
Sunglasses.gender = GENDER;
Sunglasses.title = TITLE;
Sunglasses.key = KEY;
Sunglasses.paletteNames = PALETTE_NAMES;
