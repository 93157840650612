import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Man } from "../../type/Man";
import { ZombieMan } from "../../type/ZombieMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "Sport";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}-2px`;
const PALETTE_NAME_1: PaletteName = "Glass";
const PALETTE_NAME_2: PaletteName = "Frame";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const Sport2px: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Sport2px, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Sport2px, PALETTE_NAME_2).colors;
  }, [palettes]);

  const glassesColor = useMemo(() => palette1[0], [palette1]);
  const frameColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6" y="11" width="12" height="1" fill={frameColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 12H12V13V14H9V13H8V12ZM18 12H14V13V14H17V13H18V12Z"
        fill={glassesColor}
      />
    </svg>
  );
};

Sport2px.layerType = LAYER_TYPE;
Sport2px.gender = GENDER;
Sport2px.title = TITLE;
Sport2px.key = KEY;
Sport2px.paletteNames = PALETTE_NAMES;
Sport2px.whitelist = [Man, ZombieMan];
