import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Woman";
const TITLE = "Shades M";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Shades: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 12H17V14H16V15H14V14H13V13H12V14H11V15H9V14H8V13H7V12Z"
        fill="black"
      />
    </svg>
  );
};

Shades.layerType = LAYER_TYPE;
Shades.gender = GENDER;
Shades.title = TITLE;
Shades.key = KEY;
Shades.paletteNames = PALETTE_NAMES;
