import { LayerFC } from "../types/LayerFC";
import { None } from "./None";
import { BigL } from "./BigL";
import { BigXL } from "./BigXL";
import { Goat } from "./Goat";
import { Beard } from "./Beard";
import { LuxuriousBeard } from "./LuxuriousBeard";
import { ShavedMustage } from "./ShavedMustage";
import { Shaved } from "./Shaved";
import { ThreeDays } from "./ThreeDays";
import { ShavedMustageChin } from "./ShavedMustageChin";
import { Chinstrap } from "./Chinstrap";
import { Muttonchops } from "./Muttonchops";
import { Handlebars } from "./Handlebars";
import { HandlebarsChin } from "./HandlebarsChin";
import { SmallBeard } from "./SmallBeard";
import { MustageS } from "./MustageS";
import { Chin } from "./Chin";
import { ShavedChin } from "./ShavedChin";
import { MustageM } from "./MustageM";
import { MustageL } from "./MustageL";
import { BigM } from "./BigM";
import { Middle } from "./Middle";
import { LowerLip } from "./LowerLip";
import { ChinShaved } from "./ChinShaved";
import { Middle2 } from "./Middle2";

export const beardLayers: LayerFC[] = [
    None,
    Chin,
    ChinShaved,
    Goat,
    Shaved,
    ShavedChin,
    ShavedMustage,
    ShavedMustageChin,
    MustageS,
    Handlebars,
    HandlebarsChin,
    LowerLip,
    MustageM,
    MustageL,
    SmallBeard,
    Muttonchops,
    Chinstrap,
    ThreeDays,
    Middle,
    Middle2,
    Beard,
    BigM,
    LuxuriousBeard,
    BigL,
    BigXL,
]