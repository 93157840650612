import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Wild Bun";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAME_2: PaletteName = "Holder";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const WildBun: LayerFC = (props) => {
  const palettes = usePalettes();

  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(WildBun, PALETTE_NAME_1).colors;
  }, [palettes]);

  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(WildBun, PALETTE_NAME_2).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  const holderColor = useMemo(() => palette2[0], [palette2]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6V5H8V3H6V2H7V3V4H4V5H3V7H4V9H3V10H4V9H5V11H6V9H7V13H8V12H9V11H10V10H13V11H12V12H13V11H14V10H15V11H16V12H17V8H18V9H19V8H18V7H16V6H17V5H16V6H9ZM8 7V6H9V7H8ZM8 7V8H7V7H8ZM6 9V8H5V9H6Z"
        fill={hairColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6H8V7H7V8H8V7H9V6Z"
        fill={holderColor}
      />
    </svg>
  );
};

WildBun.allowSemiTransparentColors = false;
WildBun.layerType = LAYER_TYPE;
WildBun.gender = GENDER;
WildBun.title = TITLE;
WildBun.key = KEY;
WildBun.paletteNames = PALETTE_NAMES;
WildBun.blacklist = [ApeWoman, AlienWoman]
