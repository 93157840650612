import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { ApeWoman } from "../../type/ApeWoman";
import { Woman } from "../../type/Woman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Eyes";
const GENDER: Gender = "Woman";
const TITLE = "Wink";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Eyes";
const PALETTE_NAME_2: PaletteName = "Sclera";
const PALETTE_NAME_3: PaletteName = "Brow";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2, PALETTE_NAME_3];

export const Wink: LayerFC = (props) => {
  const palettes = usePalettes();

  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Wink, PALETTE_NAME_1).colors;
  }, [palettes]);

  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Wink, PALETTE_NAME_2).colors;
  }, [palettes]);

  const palette3 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Wink, PALETTE_NAME_3).colors;
  }, [palettes]);

  const eyesColor = useMemo(() => palette1[0], [palette1]);
  const scleraColor = useMemo(() => palette2[0], [palette2]);
  const browColor = useMemo(() => palette3[0], [palette3]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="14" y="13" width="1" height="1" fill={scleraColor} />
      <path d="M9 12H11V13H9V12Z" fill={browColor} />
      <path d="M14 12H16V13H14V12Z" fill={browColor} />
      <path d="M15 13H16V14H15V13Z" fill={eyesColor} />
      <path d="M9 14H11V13H9V14Z" fill="black" />
    </svg>
  );
};

Wink.layerType = LAYER_TYPE;
Wink.gender = GENDER;
Wink.title = TITLE;
Wink.key = KEY;
Wink.paletteNames = PALETTE_NAMES;
Wink.whitelist = [Woman, ApeWoman];
