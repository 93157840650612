import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Checks";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Check";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Checks: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Checks, PALETTE_NAME_1).colors;
  }, [palettes]);

  const bgColor = useMemo(() => palette1[0], [palette1]);
  const tickColor = useMemo(() => palette1[1], [palette1]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill={bgColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.63636 1.72727H4.45455V1.54545H4.27273V1.36364H3.54545V1.18182H3.36364V1H2.63636V1.18182H2.45455V1.36364H1.72727V1.54545H1.54545V1.72727H1.36364V2.45455H1.18182V2.63636H1V3.36364H1.18182V3.54545H1.36364V4.27273H1.54545V4.45455H1.72727V4.63636H2.45455V4.81818H2.63636V5H3.36364V4.81818H3.54545V4.63636H4.27273V4.45455H4.45455V4.27273H4.63636V3.54545H4.81818V3.36364H5V2.63636H4.81818V2.45455H4.63636V1.72727ZM3.54545 2.27273H3.72727V2.45455H3.90909V2.63636H3.72727V2.81818H3.54545V3H3.36364V3.18182H3.18182V3.36364H3V3.54545H2.81818V3.72727H2.63636V3.54545H2.45455V3.36364H2.27273V3.18182H2.09091V3H2.27273V2.81818H2.45455V3H2.63636V3.18182H2.81818V3H3V2.81818H3.18182V2.63636H3.36364V2.45455H3.54545V2.27273Z" fill={tickColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.63636 13.7273H4.45455V13.5455H4.27273V13.3636H3.54545V13.1818H3.36364V13H2.63636V13.1818H2.45455V13.3636H1.72727V13.5455H1.54545V13.7273H1.36364V14.4545H1.18182V14.6364H1V15.3636H1.18182V15.5455H1.36364V16.2727H1.54545V16.4545H1.72727V16.6364H2.45455V16.8182H2.63636V17H3.36364V16.8182H3.54545V16.6364H4.27273V16.4545H4.45455V16.2727H4.63636V15.5455H4.81818V15.3636H5V14.6364H4.81818V14.4545H4.63636V13.7273ZM3.54545 14.2727H3.72727V14.4545H3.90909V14.6364H3.72727V14.8182H3.54545V15H3.36364V15.1818H3.18182V15.3636H3V15.5455H2.81818V15.7273H2.63636V15.5455H2.45455V15.3636H2.27273V15.1818H2.09091V15H2.27273V14.8182H2.45455V15H2.63636V15.1818H2.81818V15H3V14.8182H3.18182V14.6364H3.36364V14.4545H3.54545V14.2727Z" fill={tickColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.63636 7.72727H4.45455V7.54545H4.27273V7.36364H3.54545V7.18182H3.36364V7H2.63636V7.18182H2.45455V7.36364H1.72727V7.54545H1.54545V7.72727H1.36364V8.45455H1.18182V8.63636H1V9.36364H1.18182V9.54545H1.36364V10.2727H1.54545V10.4545H1.72727V10.6364H2.45455V10.8182H2.63636V11H3.36364V10.8182H3.54545V10.6364H4.27273V10.4545H4.45455V10.2727H4.63636V9.54545H4.81818V9.36364H5V8.63636H4.81818V8.45455H4.63636V7.72727ZM3.54545 8.27273H3.72727V8.45455H3.90909V8.63636H3.72727V8.81818H3.54545V9H3.36364V9.18182H3.18182V9.36364H3V9.54545H2.81818V9.72727H2.63636V9.54545H2.45455V9.36364H2.27273V9.18182H2.09091V9H2.27273V8.81818H2.45455V9H2.63636V9.18182H2.81818V9H3V8.81818H3.18182V8.63636H3.36364V8.45455H3.54545V8.27273Z" fill={tickColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.63636 19.7273H4.45455V19.5455H4.27273V19.3636H3.54545V19.1818H3.36364V19H2.63636V19.1818H2.45455V19.3636H1.72727V19.5455H1.54545V19.7273H1.36364V20.4545H1.18182V20.6364H1V21.3636H1.18182V21.5455H1.36364V22.2727H1.54545V22.4545H1.72727V22.6364H2.45455V22.8182H2.63636V23H3.36364V22.8182H3.54545V22.6364H4.27273V22.4545H4.45455V22.2727H4.63636V21.5455H4.81818V21.3636H5V20.6364H4.81818V20.4545H4.63636V19.7273ZM3.54545 20.2727H3.72727V20.4545H3.90909V20.6364H3.72727V20.8182H3.54545V21H3.36364V21.1818H3.18182V21.3636H3V21.5455H2.81818V21.7273H2.63636V21.5455H2.45455V21.3636H2.27273V21.1818H2.09091V21H2.27273V20.8182H2.45455V21H2.63636V21.1818H2.81818V21H3V20.8182H3.18182V20.6364H3.36364V20.4545H3.54545V20.2727Z" fill={tickColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6364 1.72727H16.4545V1.54545H16.2727V1.36364H15.5455V1.18182H15.3636V1H14.6364V1.18182H14.4545V1.36364H13.7273V1.54545H13.5455V1.72727H13.3636V2.45455H13.1818V2.63636H13V3.36364H13.1818V3.54545H13.3636V4.27273H13.5455V4.45455H13.7273V4.63636H14.4545V4.81818H14.6364V5H15.3636V4.81818H15.5455V4.63636H16.2727V4.45455H16.4545V4.27273H16.6364V3.54545H16.8182V3.36364H17V2.63636H16.8182V2.45455H16.6364V1.72727ZM15.5455 2.27273H15.7273V2.45455H15.9091V2.63636H15.7273V2.81818H15.5455V3H15.3636V3.18182H15.1818V3.36364H15V3.54545H14.8182V3.72727H14.6364V3.54545H14.4545V3.36364H14.2727V3.18182H14.0909V3H14.2727V2.81818H14.4545V3H14.6364V3.18182H14.8182V3H15V2.81818H15.1818V2.63636H15.3636V2.45455H15.5455V2.27273Z" fill={tickColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6364 13.7273H16.4545V13.5455H16.2727V13.3636H15.5455V13.1818H15.3636V13H14.6364V13.1818H14.4545V13.3636H13.7273V13.5455H13.5455V13.7273H13.3636V14.4545H13.1818V14.6364H13V15.3636H13.1818V15.5455H13.3636V16.2727H13.5455V16.4545H13.7273V16.6364H14.4545V16.8182H14.6364V17H15.3636V16.8182H15.5455V16.6364H16.2727V16.4545H16.4545V16.2727H16.6364V15.5455H16.8182V15.3636H17V14.6364H16.8182V14.4545H16.6364V13.7273ZM15.5455 14.2727H15.7273V14.4545H15.9091V14.6364H15.7273V14.8182H15.5455V15H15.3636V15.1818H15.1818V15.3636H15V15.5455H14.8182V15.7273H14.6364V15.5455H14.4545V15.3636H14.2727V15.1818H14.0909V15H14.2727V14.8182H14.4545V15H14.6364V15.1818H14.8182V15H15V14.8182H15.1818V14.6364H15.3636V14.4545H15.5455V14.2727Z" fill={tickColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6364 7.72727H16.4545V7.54545H16.2727V7.36364H15.5455V7.18182H15.3636V7H14.6364V7.18182H14.4545V7.36364H13.7273V7.54545H13.5455V7.72727H13.3636V8.45455H13.1818V8.63636H13V9.36364H13.1818V9.54545H13.3636V10.2727H13.5455V10.4545H13.7273V10.6364H14.4545V10.8182H14.6364V11H15.3636V10.8182H15.5455V10.6364H16.2727V10.4545H16.4545V10.2727H16.6364V9.54545H16.8182V9.36364H17V8.63636H16.8182V8.45455H16.6364V7.72727ZM15.5455 8.27273H15.7273V8.45455H15.9091V8.63636H15.7273V8.81818H15.5455V9H15.3636V9.18182H15.1818V9.36364H15V9.54545H14.8182V9.72727H14.6364V9.54545H14.4545V9.36364H14.2727V9.18182H14.0909V9H14.2727V8.81818H14.4545V9H14.6364V9.18182H14.8182V9H15V8.81818H15.1818V8.63636H15.3636V8.45455H15.5455V8.27273Z" fill={tickColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6364 19.7273H16.4545V19.5455H16.2727V19.3636H15.5455V19.1818H15.3636V19H14.6364V19.1818H14.4545V19.3636H13.7273V19.5455H13.5455V19.7273H13.3636V20.4545H13.1818V20.6364H13V21.3636H13.1818V21.5455H13.3636V22.2727H13.5455V22.4545H13.7273V22.6364H14.4545V22.8182H14.6364V23H15.3636V22.8182H15.5455V22.6364H16.2727V22.4545H16.4545V22.2727H16.6364V21.5455H16.8182V21.3636H17V20.6364H16.8182V20.4545H16.6364V19.7273ZM15.5455 20.2727H15.7273V20.4545H15.9091V20.6364H15.7273V20.8182H15.5455V21H15.3636V21.1818H15.1818V21.3636H15V21.5455H14.8182V21.7273H14.6364V21.5455H14.4545V21.3636H14.2727V21.1818H14.0909V21H14.2727V20.8182H14.4545V21H14.6364V21.1818H14.8182V21H15V20.8182H15.1818V20.6364H15.3636V20.4545H15.5455V20.2727Z" fill={tickColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 1.72727H10.4545V1.54545H10.2727V1.36364H9.54545V1.18182H9.36364V1H8.63636V1.18182H8.45455V1.36364H7.72727V1.54545H7.54545V1.72727H7.36364V2.45455H7.18182V2.63636H7V3.36364H7.18182V3.54545H7.36364V4.27273H7.54545V4.45455H7.72727V4.63636H8.45455V4.81818H8.63636V5H9.36364V4.81818H9.54545V4.63636H10.2727V4.45455H10.4545V4.27273H10.6364V3.54545H10.8182V3.36364H11V2.63636H10.8182V2.45455H10.6364V1.72727ZM9.54545 2.27273H9.72727V2.45455H9.90909V2.63636H9.72727V2.81818H9.54545V3H9.36364V3.18182H9.18182V3.36364H9V3.54545H8.81818V3.72727H8.63636V3.54545H8.45455V3.36364H8.27273V3.18182H8.09091V3H8.27273V2.81818H8.45455V3H8.63636V3.18182H8.81818V3H9V2.81818H9.18182V2.63636H9.36364V2.45455H9.54545V2.27273Z" fill={tickColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 13.7273H10.4545V13.5455H10.2727V13.3636H9.54545V13.1818H9.36364V13H8.63636V13.1818H8.45455V13.3636H7.72727V13.5455H7.54545V13.7273H7.36364V14.4545H7.18182V14.6364H7V15.3636H7.18182V15.5455H7.36364V16.2727H7.54545V16.4545H7.72727V16.6364H8.45455V16.8182H8.63636V17H9.36364V16.8182H9.54545V16.6364H10.2727V16.4545H10.4545V16.2727H10.6364V15.5455H10.8182V15.3636H11V14.6364H10.8182V14.4545H10.6364V13.7273ZM9.54545 14.2727H9.72727V14.4545H9.90909V14.6364H9.72727V14.8182H9.54545V15H9.36364V15.1818H9.18182V15.3636H9V15.5455H8.81818V15.7273H8.63636V15.5455H8.45455V15.3636H8.27273V15.1818H8.09091V15H8.27273V14.8182H8.45455V15H8.63636V15.1818H8.81818V15H9V14.8182H9.18182V14.6364H9.36364V14.4545H9.54545V14.2727Z" fill={tickColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 7.72727H10.4545V7.54545H10.2727V7.36364H9.54545V7.18182H9.36364V7H8.63636V7.18182H8.45455V7.36364H7.72727V7.54545H7.54545V7.72727H7.36364V8.45455H7.18182V8.63636H7V9.36364H7.18182V9.54545H7.36364V10.2727H7.54545V10.4545H7.72727V10.6364H8.45455V10.8182H8.63636V11H9.36364V10.8182H9.54545V10.6364H10.2727V10.4545H10.4545V10.2727H10.6364V9.54545H10.8182V9.36364H11V8.63636H10.8182V8.45455H10.6364V7.72727ZM9.54545 8.27273H9.72727V8.45455H9.90909V8.63636H9.72727V8.81818H9.54545V9H9.36364V9.18182H9.18182V9.36364H9V9.54545H8.81818V9.72727H8.63636V9.54545H8.45455V9.36364H8.27273V9.18182H8.09091V9H8.27273V8.81818H8.45455V9H8.63636V9.18182H8.81818V9H9V8.81818H9.18182V8.63636H9.36364V8.45455H9.54545V8.27273Z" fill={tickColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 19.7273H10.4545V19.5455H10.2727V19.3636H9.54545V19.1818H9.36364V19H8.63636V19.1818H8.45455V19.3636H7.72727V19.5455H7.54545V19.7273H7.36364V20.4545H7.18182V20.6364H7V21.3636H7.18182V21.5455H7.36364V22.2727H7.54545V22.4545H7.72727V22.6364H8.45455V22.8182H8.63636V23H9.36364V22.8182H9.54545V22.6364H10.2727V22.4545H10.4545V22.2727H10.6364V21.5455H10.8182V21.3636H11V20.6364H10.8182V20.4545H10.6364V19.7273ZM9.54545 20.2727H9.72727V20.4545H9.90909V20.6364H9.72727V20.8182H9.54545V21H9.36364V21.1818H9.18182V21.3636H9V21.5455H8.81818V21.7273H8.63636V21.5455H8.45455V21.3636H8.27273V21.1818H8.09091V21H8.27273V20.8182H8.45455V21H8.63636V21.1818H8.81818V21H9V20.8182H9.18182V20.6364H9.36364V20.4545H9.54545V20.2727Z" fill={tickColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.6364 1.72727H22.4545V1.54545H22.2727V1.36364H21.5455V1.18182H21.3636V1H20.6364V1.18182H20.4545V1.36364H19.7273V1.54545H19.5455V1.72727H19.3636V2.45455H19.1818V2.63636H19V3.36364H19.1818V3.54545H19.3636V4.27273H19.5455V4.45455H19.7273V4.63636H20.4545V4.81818H20.6364V5H21.3636V4.81818H21.5455V4.63636H22.2727V4.45455H22.4545V4.27273H22.6364V3.54545H22.8182V3.36364H23V2.63636H22.8182V2.45455H22.6364V1.72727ZM21.5455 2.27273H21.7273V2.45455H21.9091V2.63636H21.7273V2.81818H21.5455V3H21.3636V3.18182H21.1818V3.36364H21V3.54545H20.8182V3.72727H20.6364V3.54545H20.4545V3.36364H20.2727V3.18182H20.0909V3H20.2727V2.81818H20.4545V3H20.6364V3.18182H20.8182V3H21V2.81818H21.1818V2.63636H21.3636V2.45455H21.5455V2.27273Z" fill={tickColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.6364 13.7273H22.4545V13.5455H22.2727V13.3636H21.5455V13.1818H21.3636V13H20.6364V13.1818H20.4545V13.3636H19.7273V13.5455H19.5455V13.7273H19.3636V14.4545H19.1818V14.6364H19V15.3636H19.1818V15.5455H19.3636V16.2727H19.5455V16.4545H19.7273V16.6364H20.4545V16.8182H20.6364V17H21.3636V16.8182H21.5455V16.6364H22.2727V16.4545H22.4545V16.2727H22.6364V15.5455H22.8182V15.3636H23V14.6364H22.8182V14.4545H22.6364V13.7273ZM21.5455 14.2727H21.7273V14.4545H21.9091V14.6364H21.7273V14.8182H21.5455V15H21.3636V15.1818H21.1818V15.3636H21V15.5455H20.8182V15.7273H20.6364V15.5455H20.4545V15.3636H20.2727V15.1818H20.0909V15H20.2727V14.8182H20.4545V15H20.6364V15.1818H20.8182V15H21V14.8182H21.1818V14.6364H21.3636V14.4545H21.5455V14.2727Z" fill={tickColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.6364 7.72727H22.4545V7.54545H22.2727V7.36364H21.5455V7.18182H21.3636V7H20.6364V7.18182H20.4545V7.36364H19.7273V7.54545H19.5455V7.72727H19.3636V8.45455H19.1818V8.63636H19V9.36364H19.1818V9.54545H19.3636V10.2727H19.5455V10.4545H19.7273V10.6364H20.4545V10.8182H20.6364V11H21.3636V10.8182H21.5455V10.6364H22.2727V10.4545H22.4545V10.2727H22.6364V9.54545H22.8182V9.36364H23V8.63636H22.8182V8.45455H22.6364V7.72727ZM21.5455 8.27273H21.7273V8.45455H21.9091V8.63636H21.7273V8.81818H21.5455V9H21.3636V9.18182H21.1818V9.36364H21V9.54545H20.8182V9.72727H20.6364V9.54545H20.4545V9.36364H20.2727V9.18182H20.0909V9H20.2727V8.81818H20.4545V9H20.6364V9.18182H20.8182V9H21V8.81818H21.1818V8.63636H21.3636V8.45455H21.5455V8.27273Z" fill={tickColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.6364 19.7273H22.4545V19.5455H22.2727V19.3636H21.5455V19.1818H21.3636V19H20.6364V19.1818H20.4545V19.3636H19.7273V19.5455H19.5455V19.7273H19.3636V20.4545H19.1818V20.6364H19V21.3636H19.1818V21.5455H19.3636V22.2727H19.5455V22.4545H19.7273V22.6364H20.4545V22.8182H20.6364V23H21.3636V22.8182H21.5455V22.6364H22.2727V22.4545H22.4545V22.2727H22.6364V21.5455H22.8182V21.3636H23V20.6364H22.8182V20.4545H22.6364V19.7273ZM21.5455 20.2727H21.7273V20.4545H21.9091V20.6364H21.7273V20.8182H21.5455V21H21.3636V21.1818H21.1818V21.3636H21V21.5455H20.8182V21.7273H20.6364V21.5455H20.4545V21.3636H20.2727V21.1818H20.0909V21H20.2727V20.8182H20.4545V21H20.6364V21.1818H20.8182V21H21V20.8182H21.1818V20.6364H21.3636V20.4545H21.5455V20.2727Z" fill={tickColor}/>
    </svg>
  );
};

Checks.layerType = LAYER_TYPE;
Checks.gender = GENDER;
Checks.title = TITLE;
Checks.key = KEY;
Checks.paletteNames = PALETTE_NAMES;
Checks.price = 0.0008;