import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Generic";
const TITLE = "Slobber";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Slobber: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="12" y="19" width="1" height="1" fill="#ECFCFC" />
      <rect x="11" y="19" width="1" height="2" fill="#ECFCFC" />
    </svg>
  );
};

Slobber.layerType = LAYER_TYPE;
Slobber.gender = GENDER;
Slobber.title = TITLE;
Slobber.key = KEY;
Slobber.paletteNames = PALETTE_NAMES;
Slobber.zIndex = 65;
