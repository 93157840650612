import { LayerFC } from "../../types/LayerFC";
import { Earphones } from "./Earphones";
import { Earring } from "./Earring";
import { Pencil } from "./Pencil";
import { Piercing } from "./Piercing";
import { PiercingTop } from "./PiercingTop";

export const earManLayers: LayerFC[] = [
  Piercing,
  PiercingTop,
  Earring,
  Earphones,
  Pencil,
];
