import { LongCurl } from "./LongCurl";
import { LayerFC } from "../../types/LayerFC";
import { Band } from "./Band";
import { Curl } from "./Curl";
import { LongHair } from "./LongHair";
import { MiddleHair } from "./MiddleHair";
import { ShortHair } from "./ShortHair";
import { LongSide } from "./LongSide";
import { Braid } from "./Braid";

export const hatExtensionsWomanLayers: LayerFC[] = [
  Band,
  ShortHair,
  Curl,
  Braid,
  MiddleHair,
  LongCurl,
  LongHair,
  LongSide
];
