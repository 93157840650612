import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Bandana";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hat";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Bandana: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Bandana, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hatColor = useMemo(() => palette1[0], [palette1]);
  const secondaryhatColor = useMemo(() => palette1[1], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 6H8V7H7V8H6V9H3V10H4V13H5V12H6V11H7V10H12V11H15V10H16V9H17V7H16V6Z"
        fill={hatColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 6H8V7H7V8H6V9H5V10H4V9H3V10H4V11H5V10H6V9H7V8H8V7H16V9H15V10H12V9H8V10H12V11H15V10H17V9V7H16V6Z"
        fill={secondaryhatColor}
      />
    </svg>
  );
};

Bandana.layerType = LAYER_TYPE;
Bandana.gender = GENDER;
Bandana.title = TITLE;
Bandana.key = KEY;
Bandana.paletteNames = PALETTE_NAMES;
