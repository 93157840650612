import { Man } from "../../type/Man";
import { ZombieMan } from "../../type/ZombieMan";
import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "Hearts";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Hearts0px: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 10H10V14H9V10Z" fill="#DA5E5E" />
      <path d="M10 11H11V15H10V11Z" fill="#DA5E5E" />
      <path d="M11 10H12V14H11V10Z" fill="#DA5E5E" />
      <path d="M12 11H13V13H12V11Z" fill="#DA5E5E" />
      <path d="M8 11H9V13H8V11Z" fill="#DA5E5E" />
      <path d="M14 10H15V14H14V10Z" fill="#DA5E5E" />
      <path d="M15 11H16V15H15V11Z" fill="#DA5E5E" />
      <path d="M16 10H17V14H16V10Z" fill="#DA5E5E" />
      <path d="M17 11H18V13H17V11Z" fill="#DA5E5E" />
      <path d="M13 11H14V13H13V11Z" fill="#DA5E5E" />
      <path d="M9 11H10V12H9V11Z" fill="white" fillOpacity="0.3" />
      <path d="M14 11H15V12H14V11Z" fill="white" fillOpacity="0.3" />
    </svg>
  );
};

Hearts0px.layerType = LAYER_TYPE;
Hearts0px.gender = GENDER;
Hearts0px.title = TITLE;
Hearts0px.key = KEY;
Hearts0px.paletteNames = PALETTE_NAMES;
Hearts0px.whitelist = [Man, ZombieMan];
Hearts0px.zIndex = 100;
