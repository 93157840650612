import { LayerFC } from "../types/LayerFC";
import { earManLayers } from "./man";
import { Default } from "./Default";
import { earWomanLayers } from "./woman";

export const earLayers: LayerFC[] = [
  Default,
  ...earManLayers,
  ...earWomanLayers,
];
