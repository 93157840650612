import { FC, useCallback, useMemo, useRef, useState, useEffect } from "react";
import { RemoveScroll } from "react-remove-scroll";
import styled from "styled-components";
import { COLOR_MAP } from "../constants/Color";
import { usePalettes } from "../hooks/usePalletes";
import { layerFCs } from "./layerFCs";
import { Text } from "./Text";

interface ColorPickerProps {
  paletteKey: string;
  className?: string;
  opensToTop?: boolean;
}

interface ColorPickerWrapperProps {
  $opensToTop: boolean;
}

const ColorPickerWrapper = styled.label<ColorPickerWrapperProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_MAP.gray700};
  padding: 20px 24px;
  z-index: 1;
  input {
    border: 0;
    width: 0;
    height: 0;
    font-size: 0;
    padding: 0;
    margin: 0;
  }
  @media (max-width: 768px) {
    box-shadow: 0px -2px 0px rgba(0, 0, 0, 0.25);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  @media (min-width: 769px) {
    border: 1px solid ${COLOR_MAP.gray500};
    top: ${({ $opensToTop }) => !$opensToTop && "24px"};
    bottom: ${({ $opensToTop }) => $opensToTop && "24px"};
    width: 396px;
    ${Text}:last-child {
      display: none;
    }
  }
`;

const TopBarWrapper = styled(Text)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const SwatchWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const CloseButton = styled(Text)`
  cursor: pointer;
`;

export const ColorPicker: FC<ColorPickerProps> = (props) => {
  const Swatch = useMemo(() => require("./Swatch").Swatch, []);

  const palettes = usePalettes();

  const [timeoutId, setTimeoutId] = useState<number | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);

  const isActive = useMemo(
    () =>
      palettes.isLoading ? false : palettes.selectedKey === props.paletteKey,
    [palettes, props.paletteKey]
  );

  useEffect(() => {
    if (isActive) {
      inputRef.current?.focus();
    }
  }, [inputRef, isActive]);

  useEffect(() => {
    if (!isActive) {
      window.clearTimeout(timeoutId);
    }
  }, [isActive, timeoutId]);

  const layerFC = useMemo(
    () =>
      !palettes || palettes.isLoading
        ? undefined
        : layerFCs.find(
          (layerFC) => layerFC.key === palettes.selectedLayerFCKey
        ),
    [palettes]
  );
  const palette = useMemo(() => {
    if (palettes.isLoading) {
      return [];
    }
    const palette: readonly (readonly string[])[] =
      palettes.selectedPalette ?? [];
    if (layerFC && layerFC.allowSemiTransparentColors === false) {
      return palette.filter(
        (colors) => !colors.some((color) => color.length > 7)
      );
    }
    return palette;
  }, [layerFC, palettes]);
  const selectedColors = useMemo(
    () =>
      !palettes || palettes.isLoading || !props.paletteKey
        ? []
        : palettes.selectedColors[props.paletteKey],
    [palettes, props.paletteKey]
  );
  const selectedPaletteName = useMemo(
    () =>
      !palettes || palettes.isLoading || !props.paletteKey
        ? []
        : palettes.selectedPaletteName,
    [palettes, props.paletteKey]
  );
  const selectedColorKey = useMemo(
    () => selectedColors.join("-"),
    [selectedColors]
  );
  const handleSwatchClick = useCallback(
    (colors: readonly string[]) => {
      if (!palettes || palettes.isLoading || !props.paletteKey) return;
      palettes.changeSelectedColors(props.paletteKey, colors);
    },
    [palettes, props.paletteKey]
  );
  const handleCloseClick = useCallback(() => {
    if (!palettes || palettes.isLoading) return;
    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }
    palettes.selectPalette();
  }, [palettes, timeoutId]);

  const handleBlur = useCallback(() => {
    setTimeoutId(
      window.setTimeout(() => {
        handleCloseClick();
      }, 200)
    );
  }, [handleCloseClick]);

  const handleFocus = useCallback(() => {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }
  }, [timeoutId]);

  const swatches = useMemo(
    () =>
      palette.map((colors) => {
        const key = colors.join("-");
        const isActive = key === selectedColorKey;
        return (
          <Swatch
            key={key}
            isActive={isActive}
            size={32}
            colors={colors}
            onClick={() => handleSwatchClick(colors)}
          />
        );
      }),
    [palette, selectedColorKey, handleSwatchClick, Swatch]
  );

  if (!isActive) return null;

  return (
    <ColorPickerWrapper
      className={props.className}
      $opensToTop={props.opensToTop ?? false}
    >
      <input
        ref={inputRef}
        type="text"
        inputMode="none"
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      <RemoveScroll>
        <TopBarWrapper $fontSize="normal" className="cp-top">
          <Text $fontColor="gray300">{selectedPaletteName}</Text>
          <CloseButton $fontColor="primary" onClick={handleCloseClick}>
            Done
          </CloseButton>
        </TopBarWrapper>
        <SwatchWrapper>{swatches}</SwatchWrapper>
      </RemoveScroll>
    </ColorPickerWrapper>
  );
};
