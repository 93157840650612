import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Super Short";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const SuperShort: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(SuperShort, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 8H17V12H16V11H15V10H12V11H11V10H10V11H8V12H7V8H8V7H10V6H15V7H16V8Z"
        fill={hairColor}
      />
    </svg>
  );
};

SuperShort.allowSemiTransparentColors = false;
SuperShort.layerType = LAYER_TYPE;
SuperShort.gender = GENDER;
SuperShort.title = TITLE;
SuperShort.key = KEY;
SuperShort.paletteNames = PALETTE_NAMES;
SuperShort.blacklist = [ApeWoman, AlienWoman]
