import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Mouth";
const GENDER: Gender = "Woman";
const TITLE = "Lipstick";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "MakeUp";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Lipstick: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Lipstick, PALETTE_NAME_1).colors;
  }, [palettes]);
  const lipstickColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="11" y="18" width="3" height="1" fill={lipstickColor} />
    </svg>
  );
};

Lipstick.layerType = LAYER_TYPE;
Lipstick.gender = GENDER;
Lipstick.title = TITLE;
Lipstick.key = KEY;
Lipstick.paletteNames = PALETTE_NAMES;
