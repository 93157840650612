import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Woman";
const TITLE = "Hearts";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Hearts: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 11H9V15H8V11Z" fill="#DA5E5E" />
      <path d="M9 12H10V16H9V12Z" fill="#DA5E5E" />
      <path d="M10 11H11V15H10V11Z" fill="#DA5E5E" />
      <path d="M11 12H12V14H11V12Z" fill="#DA5E5E" />
      <path d="M7 12H8V14H7V12Z" fill="#DA5E5E" />
      <path d="M14 11H15V15H14V11Z" fill="#DA5E5E" />
      <path d="M15 12H16V16H15V12Z" fill="#DA5E5E" />
      <path d="M16 11H17V15H16V11Z" fill="#DA5E5E" />
      <path d="M17 12H18V14H17V12Z" fill="#DA5E5E" />
      <path d="M13 12H14V14H13V12Z" fill="#DA5E5E" />
      <path d="M8 12H9V13H8V12Z" fill="white" fillOpacity="0.3" />
      <path d="M14 12H15V13H14V12Z" fill="white" fillOpacity="0.3" />
    </svg>
  );
};

Hearts.layerType = LAYER_TYPE;
Hearts.gender = GENDER;
Hearts.title = TITLE;
Hearts.key = KEY;
Hearts.paletteNames = PALETTE_NAMES;
