import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Police";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Police: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="7" y="6" width="10" height="3" fill="#26314A"/>
      <rect x="12" y="7" width="2" height="2" fill="#9FA6B5"/>
      <rect x="8" y="9" width="1" height="1" fill="#F3BD46"/>
      <rect x="6" y="7" width="1" height="2" fill="black"/>
      <rect x="7" y="6" width="4" height="1" fill="black"/>
      <rect x="11" y="5" width="4" height="1" fill="black"/>
      <rect x="15" y="6" width="2" height="1" fill="black"/>
      <rect x="17" y="7" width="1" height="2" fill="black"/>
      <rect x="7" y="11" width="12" height="1" fill="black"/>
      <rect x="7" y="10" width="11" height="1" fill="black"/>
      <rect x="7" y="9" width="1" height="1" fill="black"/>
      <rect x="9" y="9" width="7" height="1" fill="#161D2C"/>
      <rect x="16" y="9" width="1" height="1" fill="black"/>
    </svg>
  );
};

Police.layerType = LAYER_TYPE;
Police.gender = GENDER;
Police.title = TITLE;
Police.key = KEY;
Police.paletteNames = PALETTE_NAMES;