import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Generic";
const TITLE = "Dog";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Dog";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Dog: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Dog, PALETTE_NAME_1).colors;
  }, [palettes]);

  const dogColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 17H18V18H19V20H18V21H17V24H11V23H12V22H13V18H14V17Z" fill={dogColor}/>
      <path d="M18 16H14V17H13V18H12V22H11V23H10V24H11V23H12V22H14V21H15V19H14V21H13V18H14V17H18V18H19V20H18V21H17V24H18V21H19V20H20V17H18V16Z" fill="black"/>
      <path d="M17 18H16V19H17V18Z" fill="black"/>
    </svg>

  );
};

Dog.layerType = LAYER_TYPE;
Dog.gender = GENDER;
Dog.title = TITLE;
Dog.key = KEY;
Dog.paletteNames = PALETTE_NAMES;
Dog.zIndex = 95;