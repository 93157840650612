export const COLOR_MAP = {
  white: "#FFF",
  primary: "#06D2FF",
  warining: "#BDB378",
  error: "#DE534C",
  gray100: "#C8CED7",
  gray300: "#7E8899",
  gray500: "#4E545E",
  gray700: "#272A2F",
  dark: "#1E2024",
  black: "#000",
} as const;

export type Color = keyof typeof COLOR_MAP;
