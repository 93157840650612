import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Creature";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Creature: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="#F4E4CD"/>
      <rect width="16" height="5" fill="#637E91"/>
      <path d="M16 0H24V9H23V10H18V9H16V8H14V4H15V2H16V0Z" fill="#A64B38"/>
      <path d="M4 11H8V13H9V15H8V16H3V17H0V9H2V10H4V11Z" fill="#A64B38"/>
      <path d="M17 23V24H24V22H23V21H22V20H19V21H18V23H17Z" fill="#A64B38"/>
    </svg>
  );
};

Creature.layerType = LAYER_TYPE;
Creature.gender = GENDER;
Creature.title = TITLE;
Creature.key = KEY;
Creature.paletteNames = PALETTE_NAMES;
Creature.price = 0.001;