import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Fedora";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Fedora: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3H14V4H15V6H16V7H15H14H9H8H7V6H8V4H9V3ZM4 8H19V9H20V10H3V9H4V8Z"
        fill="#3B3020"
      />
      <rect x="6" y="7" width="11" height="1" fill="black" />
    </svg>
  );
};

Fedora.layerType = LAYER_TYPE;
Fedora.gender = GENDER;
Fedora.title = TITLE;
Fedora.key = KEY;
Fedora.paletteNames = PALETTE_NAMES;
