import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { LayerFC } from "../components/layerFCs/types/LayerFC";
import { useExport } from "./useExport";

export type MintStatus =
  | "prepared"
  | "queuedToCheckPurchase"
  | "checkingPurchase"
  | "queuedForMinting"
  | "minting"
  | "minted";

export interface BaseNFT {
  id: string;
  index?: number;
  image: string;
  attributes: {
    trait_type: string;
    value: string;
  }[];
  transactionHash?: string;
  purchaseHash?: string;
  owner?: string;
  createdAt?: number;
  lastStatusChangeAt?: number;
  status?: MintStatus;
}

export interface NFT extends BaseNFT {
  name: string;
  number: number;
  index: number;
  description: string;
  external_url: string;
  image_url: string;
  metadata_url: string;
  background_color: string;
}

interface ImagePath {
  id?: string;
  path: string;
}

export interface StorageError {
  message: string;
}

export const isStorageError = (error: any): error is StorageError => {
  return error?.message !== undefined;
};

export const useApi = () => {
  const { generatePNGImageUrl } = useExport();
  const { pathname } = useLocation();

  const getPreparedNFT = useCallback(async (): Promise<NFT | StorageError> => {
    try {
      const response = await fetch(
        `/api/nft?id=${encodeURIComponent(pathname)}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return await response.json();
    } catch (e: any) {
      return e;
    }
  }, [pathname]);

  const prepareNFT = useCallback(
    async (layerFCs: LayerFC[]): Promise<NFT | StorageError> => {
      try {
        const response = await fetch("/api/nft/prepare", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: window.location.pathname,
            layers: layerFCs.map(({ title, layerType }) => ({
              title,
              layerType,
            })),
            svgImageUrl: await generatePNGImageUrl(),
            url: window.location.href,
          }),
        });
        return await response.json();
      } catch (e: any) {
        return e;
      }
    },
    [generatePNGImageUrl]
  );

  const proofPurchase = useCallback(
    async (props: {
      id: string;
      purchaseHash: string;
      owner: string;
    }): Promise<NFT | StorageError> => {
      try {
        const response = await fetch(`/api/nft/proof-purchase`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(props),
        });
        return await response.json();
      } catch (e: any) {
        return e;
      }
    },
    []
  );

  const listMintedNFTs = useCallback(async (): Promise<NFT[]> => {
    try {
      const response = await fetch("/admin/nft/minted", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const responseData = await response.json();
      return responseData || [];
    } catch (error) {
      return [];
    }
  }, []);

  const setSlug = useCallback(
    async (props: ImagePath): Promise<string | undefined> => {
      try {
        const response = await fetch("/api/slug", {
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
          body: JSON.stringify(props),
        });
        const imageId = await response.text();
        return imageId || undefined;
      } catch (error) {
        return undefined;
      }
    },
    []
  );

  const getSlug = useCallback(
    async (id: string): Promise<Required<ImagePath> | undefined> => {
      try {
        const response = await fetch(`/api/slug/${id}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const responseData = await response.json();
        return responseData || undefined;
      } catch (error) {
        return undefined;
      }
    },
    []
  );

  const listClaimedNFTs = useCallback(async (): Promise<NFT[]> => {
    try {
      const response = await fetch("/admin/nft/claimed", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const responseData = await response.json();
      return responseData || [];
    } catch (error) {
      return [];
    }
  }, []);

  const updateId = useCallback(async (index: number, id: string) => {
    try {
      const response = await fetch("/admin/nft/change-id", {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ index, id }),
        method: "post",
      });
      const responseData = await response.json();
      return responseData || [];
    } catch (error) {
      return [];
    }
  }, []);

  return {
    // user
    getPreparedNFT,
    prepareNFT,
    proofPurchase,
    setSlug,
    getSlug,
    // admin
    updateId,
    listMintedNFTs,
    listClaimedNFTs,
  };
};
