import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";

import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "Hearts";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}-1px`;
const PALETTE_NAMES: PaletteName[] = [];

export const Hearts1px: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 10H9V14H8V10Z" fill="#DA5E5E" />
      <path d="M9 11H10V15H9V11Z" fill="#DA5E5E" />
      <path d="M10 10H11V14H10V10Z" fill="#DA5E5E" />
      <path d="M11 11H12V13H11V11Z" fill="#DA5E5E" />
      <path d="M7 11H8V13H7V11Z" fill="#DA5E5E" />
      <path d="M14 10H15V14H14V10Z" fill="#DA5E5E" />
      <path d="M15 11H16V15H15V11Z" fill="#DA5E5E" />
      <path d="M16 10H17V14H16V10Z" fill="#DA5E5E" />
      <path d="M17 11H18V13H17V11Z" fill="#DA5E5E" />
      <path d="M13 11H14V13H13V11Z" fill="#DA5E5E" />
      <path d="M8 11H9V12H8V11Z" fill="white" fillOpacity="0.3" />
      <path d="M14 11H15V12H14V11Z" fill="white" fillOpacity="0.3" />
    </svg>
  );
};

Hearts1px.layerType = LAYER_TYPE;
Hearts1px.gender = GENDER;
Hearts1px.title = TITLE;
Hearts1px.key = KEY;
Hearts1px.paletteNames = PALETTE_NAMES;
Hearts1px.whitelist = [AlienMan, ApeMan];
Hearts1px.zIndex = 100;
