import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Generic";
const TITLE = "Pizza";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Pizza: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="13" y="18" width="1" height="1" fill="#F7B31E" />
      <rect x="12" y="18" width="1" height="5" fill="#F7B31E" />
      <rect x="13" y="19" width="1" height="1" fill="#F3BD46" />
      <rect x="14" y="19" width="1" height="1" fill="#F7B31E" />
      <rect x="15" y="20" width="1" height="1" fill="#F7B31E" />
      <rect x="16" y="21" width="1" height="1" fill="#F7B31E" />
      <rect x="17" y="22" width="1" height="1" fill="#F7B31E" />
      <rect x="13" y="20" width="2" height="2" fill="#F3BD46" />
      <rect x="14" y="22" width="3" height="1" fill="#F3BD46" />
      <rect x="12" y="23" width="7" height="1" fill="#A07619" />
      <rect x="13" y="22" width="1" height="1" fill="#E76060" />
      <rect x="13" y="19" width="1" height="1" fill="#E76060" />
      <rect x="15" y="21" width="1" height="1" fill="#E76060" />
    </svg>
  );
};

Pizza.layerType = LAYER_TYPE;
Pizza.gender = GENDER;
Pizza.title = TITLE;
Pizza.key = KEY;
Pizza.paletteNames = PALETTE_NAMES;
