import { FC } from "react";
import styled from "styled-components";
import { LayerFC } from "./layerFCs/types/LayerFC";
import { LayerType } from "./layerFCs/types/LayerType";
import { Swatches } from "./Swatches";
import { Text } from "./Text";

interface LayerTitleProps {
  layerFCs: LayerFC[];
  layerType: LayerType;
  className?: string;
}

const LayerTitleWrapper = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 32px auto;
  max-width: 552px;
  gap: 16px;
  height: 20px;
  align-items: center;
  font-size: 12px;
`;

const LayerTitleContent = styled.div`
  padding-top: 1px;
`;

export const LayerTitle: FC<LayerTitleProps> = (props) => {
  return (
    <LayerTitleWrapper $fontColor="gray300">
      <LayerTitleContent className={props.className}>{props.children}</LayerTitleContent>
      <Swatches layerFCs={props.layerFCs} layerType={props.layerType} />
    </LayerTitleWrapper>
  );
};
