import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { ZombieMan } from "../../type/ZombieMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Mouth";
const GENDER: Gender = "Man";
const TITLE = "Zombie";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Mouth";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Zombie: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Zombie, PALETTE_NAME_1).colors;
  }, [palettes]);

  const mouthColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="11" y="19" width="1" height="1" fill="black" fillOpacity="0.2" />
      <rect x="11" y="18" width="3" height="1" fill={mouthColor} />
    </svg>
  );
};

Zombie.layerType = LAYER_TYPE;
Zombie.gender = GENDER;
Zombie.title = TITLE;
Zombie.key = KEY;
Zombie.paletteNames = PALETTE_NAMES;
Zombie.whitelist = [ZombieMan];
