import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Generic";
const TITLE = "Heart Kiss";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const HeartKiss: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 16H15V20H13V16Z" fill="#DA5E5E" />
      <path d="M15 17H16V22H15V17Z" fill="#DA5E5E" />
      <path d="M16 16H18V20H16V16Z" fill="#DA5E5E" />
      <path d="M18 17H19V19H18V17Z" fill="#DA5E5E" />
      <path d="M12 17H13V19H12V17Z" fill="#DA5E5E" />
      <path d="M14 20H17V21H14V20Z" fill="#DA5E5E" />
      <rect x="13" y="17" width="1" height="1" fill="white" fillOpacity="0.3" />
    </svg>
  );
};

HeartKiss.layerType = LAYER_TYPE;
HeartKiss.gender = GENDER;
HeartKiss.title = TITLE;
HeartKiss.key = KEY;
HeartKiss.paletteNames = PALETTE_NAMES;
