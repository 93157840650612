import { FC } from "react";
import styled from "styled-components";
import { Logo } from "./Logo";
import { NavItem } from "./NavItem";

interface NavigationProps {
  className?: string;
}

const NavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const NavigationItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  a span {
    font-size: 12px;
  }
`;

const Navigation: FC<NavigationProps> = (props) => {
  return (
    <NavigationWrapper className={props.className}>
      <Logo />
      <NavigationItemsWrapper>
        <NavItem to="https://twitter.com/selfmadepunks" iconType="Twitter" />
        <NavItem
          to="https://looksrare.org/collections/0xE617c86530Bc4a7A4819385cE4CAFD31779b94fe"
          iconType="LooksRare"
        />
      </NavigationItemsWrapper>
    </NavigationWrapper>
  );
};

export default Navigation;
