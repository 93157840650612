import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Long";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Long: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Long, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 4H8V5H7H6V6H5V7H4V6H3V7H4V8H3V9H4V11H3V16H2V17H3V20H4V23H5V24H6V17V15V14H5V12H6H7V13H8V10H9V9H11V8H15V9H16V10H17V11H18V6H17V5H18V4H17V5H16H15V4H11H9Z"
        fill={hairColor}
      />
    </svg>
  );
};

Long.allowSemiTransparentColors = false;
Long.layerType = LAYER_TYPE;
Long.gender = GENDER;
Long.title = TITLE;
Long.key = KEY;
Long.paletteNames = PALETTE_NAMES;
Long.blacklist = [ApeMan, AlienMan];
