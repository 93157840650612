import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Blue Cap";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const BlueCap: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 6H15V7H16V8H17V10H20V11H7V8H8V7H9V6Z" fill="#2169B5" />
      <path d="M11 6H15V7H16V8H17V10H9V8H10V7H11V6Z" fill="#2476CD" />
    </svg>
  );
};

BlueCap.layerType = LAYER_TYPE;
BlueCap.gender = GENDER;
BlueCap.title = TITLE;
BlueCap.key = KEY;
BlueCap.paletteNames = PALETTE_NAMES;