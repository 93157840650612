import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Matrix";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Matrix: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="#071005" />
      <rect x="2" width="1" height="1" fill="#102C09" />
      <rect x="2" y="2" width="1" height="1" fill="#102C09" />
      <rect x="2" y="5" width="1" height="3" fill="#102C09" />
      <rect x="2" y="4" width="1" height="1" fill="#102C09" />
      <rect x="4" y="17" width="1" height="2" fill="#102C09" />
      <rect x="2" y="14" width="1" height="1" fill="#102C09" />
      <rect x="2" y="16" width="1" height="1" fill="#102C09" />
      <rect x="2" y="18" width="1" height="1" fill="#102C09" />
      <rect x="2" y="8" width="1" height="1" fill="#1B4210" />
      <rect x="2" y="13" width="1" height="1" fill="#1B4210" />
      <rect x="2" y="15" width="1" height="2" fill="#97F684" />
      <rect x="2" y="19" width="1" height="1" fill="#C8ECBD" />
      <rect x="2" y="10" width="1" height="1" fill="#1B4210" />
      <rect x="2" y="9" width="1" height="1" fill="#5EC93C" />
      <rect x="2" y="17" width="1" height="1" fill="#5EC93C" />
      <rect x="2" y="11" width="1" height="2" fill="#5EC93C" />
      <rect x="2" y="1" width="1" height="1" fill="#204B13" />
      <rect x="4" width="1" height="2" fill="#122E0A" />
      <rect x="6" width="1" height="2" fill="#2E671D" />
      <rect x="8" width="1" height="3" fill="#122E0A" />
      <rect x="4" y="2" width="1" height="2" fill="#14320B" />
      <rect x="6" y="2" width="1" height="2" fill="#14320B" />
      <rect x="13" y="5" width="1" height="2" fill="#14320B" />
      <rect x="17" y="13" width="1" height="2" fill="#14320B" />
      <rect x="15" y="6" width="1" height="2" fill="#14320B" />
      <rect x="17" y="9" width="1" height="2" fill="#14320B" />
      <rect x="4" y="4" width="1" height="1" fill="#2E671D" />
      <rect x="8" y="3" width="1" height="1" fill="#2E671D" />
      <rect x="8" y="5" width="1" height="2" fill="#2E671D" />
      <rect x="17" y="2" width="1" height="1" fill="#2E671D" />
      <rect x="17" y="5" width="1" height="2" fill="#6DE648" />
      <rect x="17" y="11" width="1" height="2" fill="#6DE648" />
      <rect x="17" y="4" width="1" height="1" fill="#2E671D" />
      <rect x="10" width="1" height="2" fill="#2E671D" />
      <rect x="10" y="4" width="1" height="2" fill="#2E671D" />
      <rect x="15" y="4" width="1" height="2" fill="#2E671D" />
      <rect x="17" y="7" width="1" height="2" fill="#2E671D" />
      <rect x="13" y="1" width="1" height="4" fill="#2E671D" />
      <rect x="8" y="4" width="1" height="1" fill="#70D058" />
      <rect x="6" y="7" width="1" height="1" fill="#70D058" />
      <rect x="6" y="10" width="1" height="1" fill="#70D058" />
      <rect x="17" y="1" width="1" height="1" fill="#70D058" />
      <rect x="10" y="2" width="1" height="1" fill="#70D058" />
      <rect x="4" y="5" width="1" height="1" fill="#1B4110" />
      <rect x="6" y="5" width="1" height="2" fill="#1B4110" />
      <rect x="6" y="8" width="1" height="2" fill="#1B4110" />
      <rect x="17" width="1" height="1" fill="#1B4110" />
      <rect x="15" y="3" width="1" height="1" fill="#1B4110" />
      <rect x="4" y="9" width="1" height="1" fill="#1B4110" />
      <rect x="4" y="12" width="1" height="2" fill="#1B4110" />
      <rect x="4" y="19" width="1" height="2" fill="#1B4110" />
      <rect x="4" y="6" width="1" height="3" fill="#14320B" />
      <rect x="4" y="10" width="1" height="2" fill="#14320B" />
      <rect x="4" y="14" width="1" height="3" fill="#14320B" />
      <rect x="19" width="1" height="2" fill="#49A02F" />
      <rect x="19" y="10" width="1" height="2" fill="#49A02F" />
      <rect x="19" y="16" width="1" height="2" fill="#49A02F" />
      <rect
        x="21"
        y="13"
        width="1"
        height="4"
        fill="#49A02F"
        fillOpacity="0.2"
      />
      <rect x="19" y="20" width="1" height="1" fill="#49A02F" />
      <rect x="19" y="21" width="1" height="1" fill="#D0F8C4" />
      <rect x="19" y="2" width="1" height="1" fill="#1C4310" />
      <rect x="21" y="1" width="1" height="3" fill="#1C4310" />
      <rect x="19" y="4" width="1" height="1" fill="#3A8123" />
      <rect x="21" y="4" width="1" height="1" fill="#3A8123" />
      <rect x="21" y="18" width="1" height="1" fill="#3A8123" />
      <rect x="19" y="3" width="1" height="1" fill="#235315" />
      <rect x="19" y="9" width="1" height="1" fill="#235315" />
      <rect x="19" y="12" width="1" height="1" fill="#235315" />
      <rect x="21" y="10" width="1" height="1" fill="#235315" />
      <rect x="19" y="15" width="1" height="1" fill="#235315" />
      <rect x="21" y="17" width="1" height="1" fill="#235315" />
      <rect x="19" y="5" width="1" height="1" fill="#50AE32" />
      <rect x="19" y="8" width="1" height="1" fill="#6DE648" />
      <rect
        x="21"
        y="9"
        width="1"
        height="1"
        fill="#6DE648"
        fillOpacity="0.4"
      />
      <rect x="21" width="1" height="1" fill="#6DE648" fillOpacity="0.1" />
      <rect x="19" y="13" width="1" height="2" fill="#6DE648" />
      <rect
        x="21"
        y="11"
        width="1"
        height="2"
        fill="#6DE648"
        fillOpacity="0.4"
      />
      <rect x="19" y="18" width="1" height="2" fill="#6DE648" />
      <rect x="19" y="6" width="1" height="2" fill="#1C3D14" />
      <rect x="21" y="5" width="1" height="4" fill="#1C3D14" />
      <rect x="15" width="1" height="3" fill="#122E0A" />
      <rect x="13" width="1" height="1" fill="#122E0A" />
    </svg>
  );
};

Matrix.layerType = LAYER_TYPE;
Matrix.gender = GENDER;
Matrix.title = TITLE;
Matrix.key = KEY;
Matrix.paletteNames = PALETTE_NAMES;
Matrix.price = 0.001;
