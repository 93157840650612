import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Ear";
const GENDER: Gender = "Man";
const TITLE = "Piercing";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Jewelry";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Piercing: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Piercing, PALETTE_NAME_1).colors;
  }, [palettes]);

  const jewelryColor = useMemo(() => palette1[0], [palette1]);
  
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="5" y="12" width="1" height="1"
      fill={jewelryColor}
    />
    </svg>
    
  );
};

Piercing.isExtension = true;
Piercing.layerType = LAYER_TYPE;
Piercing.gender = GENDER;
Piercing.title = TITLE;
Piercing.key = KEY;
Piercing.paletteNames = PALETTE_NAMES;
Piercing.blacklist = [AlienMan]
