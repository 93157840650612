import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Wild";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Wild: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Wild, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2H11V3H12V4H13V3H16H17V4H16V5H18V4H20V5H19V6H20V7H19V8V9H18V10H19V12H18V14H17V15H16V10H15V9H14V8H12V9H13V10H11V9H9V10H8V12V14H7V15H6V14H5V15H4V13H5V12H4V13H3V11H4V10H3H2V9H3V8H4V7H3V4H4V5H6V3H8V4H10V2Z"
        fill={hairColor}
      />
    </svg>
  );
};

Wild.allowSemiTransparentColors = false;
Wild.layerType = LAYER_TYPE;
Wild.gender = GENDER;
Wild.title = TITLE;
Wild.key = KEY;
Wild.paletteNames = PALETTE_NAMES;
Wild.blacklist = [ApeMan, AlienMan]