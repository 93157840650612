import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Man } from "../../type/Man";
import { ZombieMan } from "../../type/ZombieMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "Thin";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}-2px`;
const PALETTE_NAME_1: PaletteName = "Glass";
const PALETTE_NAME_2: PaletteName = "Frame";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const Thin2px: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Thin2px, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Thin2px, PALETTE_NAME_2).colors;
  }, [palettes]);

  const glassesColor = useMemo(() => palette1[0], [palette1]);
  const frameColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 12H17V13V14H14V13H12V14H9V13V12Z"
        fill={glassesColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 11H6V12H8V14H9V15H12V14H14V15H17V14H18V11H17ZM17 12V14H14V13H12V14H9V12H17Z"
        fill={frameColor}
      />
    </svg>
  );
};

Thin2px.layerType = LAYER_TYPE;
Thin2px.gender = GENDER;
Thin2px.title = TITLE;
Thin2px.key = KEY;
Thin2px.paletteNames = PALETTE_NAMES;
Thin2px.whitelist = [Man, ZombieMan];
