import { LayerFC } from "../../types/LayerFC";
import { Nice1px } from "./Nice1px";
import { Nice2px } from "./Nice2px";
import { Transparent } from "./Transparent";
import { Hearts0px } from "./Hearts0px";
import { Hearts1px } from "./Hearts1px";
import { Stylish } from "./Stylish";
import { VR } from "./VR";
import { ColoredSunglasses1px } from "./ColoredSunglasses1px";
import { ColoredSunglasses2px } from "./ColoredSunglasses2px";
import { Mask } from "./Mask";
import { Thin1px } from "./Thin1px";
import { Thin2px } from "./Thin2px";
import { Diamonds0px } from "./Diamonds0px";
import { Diamonds1px } from "./Diamonds1px";
import { Plain1px } from "./Plain1px";
import { Plain2px } from "./Plain2px";
import { ShadesM1px } from "./ShadesM1px";
import { ShadesM2px } from "./ShadesM2px";
import { ShadesL1px } from "./ShadesL1px";
import { ShadesL2px } from "./ShadesL2px";
import { Sport1px } from "./Sport1px";
import { Sport2px } from "./Sport2px";
import { ThreeD1px } from "./ThreeD1px";
import { ThreeD2px } from "./ThreeD2px";
import { Trucker1px } from "./Trucker1px";
import { Trucker2px } from "./Trucker2px";
import { Ski1px } from "./Ski1px";
import { Ski2px } from "./Ski2px";
import { NoFrame } from "./NoFrame";
import { Nerd } from "./Nerd";
import { TopFrame } from "./TopFrame";
import { BigShades } from "./BigShades";
import { Sunglasses } from "./Sunglasses";

export const glassesManLayers: LayerFC[] = [
  ShadesM1px,
  ShadesM2px,
  ShadesL1px,
  ShadesL2px,
  Stylish,
  BigShades,
  Transparent,
  TopFrame,
  Nerd,
  Thin1px,
  Thin2px,
  Nice1px,
  Nice2px,
  ColoredSunglasses1px,
  ColoredSunglasses2px,
  Sunglasses,
  Plain1px,
  Plain2px,
  Sport1px,
  Sport2px,
  Trucker1px,
  Trucker2px,
  Ski1px,
  Ski2px,
  NoFrame,
  Mask,
  VR,
  ThreeD1px,
  ThreeD2px,
  Hearts0px,
  Hearts1px,
  Diamonds0px,
  Diamonds1px,
];
