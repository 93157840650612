import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Woman";
const TITLE = "Diadem";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const DiademWoman: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8H9V9H8V10V11H9V10H15V11H16V10V9H15V8Z"
        fill="#FDF84D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 7H12V8H11V10H12V8H13V10H14V8H13V7Z"
        fill="#FAA631"
      />
      <rect x="12" y="8" width="1" height="2" fill="#FA3131" />
    </svg>
  );
};

DiademWoman.layerType = LAYER_TYPE;
DiademWoman.gender = GENDER;
DiademWoman.title = TITLE;
DiademWoman.key = KEY;
DiademWoman.paletteNames = PALETTE_NAMES;
