import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Long Braid 1";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const LongBraid1: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(LongBraid1, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4H10V5H11V6H15V7H16V8H17V12H16V11H15V10H10V11H9V12H8V19V20H7V22H6V21H5V8V7H6V5H7V4Z"
        fill={hairColor}
      />
    </svg>
  );
};

LongBraid1.allowSemiTransparentColors = false;
LongBraid1.layerType = LAYER_TYPE;
LongBraid1.gender = GENDER;
LongBraid1.title = TITLE;
LongBraid1.key = KEY;
LongBraid1.paletteNames = PALETTE_NAMES;
LongBraid1.blacklist = [ApeWoman, AlienWoman]
