import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Mouth";
const GENDER: Gender = "Man";
const TITLE = "Kiss";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Mouth";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Kiss: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Kiss, PALETTE_NAME_1).colors;
  }, [palettes]);

  const mouthColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="13" y="18" width="1" height="1" fill={mouthColor} />
    </svg>
  );
};

Kiss.layerType = LAYER_TYPE;
Kiss.gender = GENDER;
Kiss.title = TITLE;
Kiss.key = KEY;
Kiss.paletteNames = PALETTE_NAMES;
