import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { ApeMan } from "../../type/ApeMan";
import { Man } from "../../type/Man";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Eyes";
const GENDER: Gender = "Man";
const TITLE = "Right";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Eyes";
const PALETTE_NAME_2: PaletteName = "Sclera";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const Right: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Right, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Right, PALETTE_NAME_2).colors;
  }, [palettes]);

  const eyesColor = useMemo(() => palette1[0], [palette1]);
  const scleraColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 12H11V13H10V12Z" fill={eyesColor}  />
      <path d="M15 12H16V13H15V12Z" fill={eyesColor}  />
      <path d="M9 12H10V13H9V12Z" fill={scleraColor} />
      <path d="M14 12H15V13H14V12Z" fill={scleraColor} />
      <path d="M9 11H11V12H9V11Z" fill="black" fillOpacity="0.15" />
      <path d="M14 11H16V12H14V11Z" fill="black" fillOpacity="0.15" />
    </svg>
  );
};

Right.layerType = LAYER_TYPE;
Right.gender = GENDER;
Right.title = TITLE;
Right.key = KEY;
Right.paletteNames = PALETTE_NAMES;
Right.whitelist = [Man, ApeMan];