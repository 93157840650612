import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Woman";
const TITLE = "Bindl";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const BindlWoman: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="12" y="11" width="1" height="1" fill="#D85454" />
    </svg>
  );
};

BindlWoman.layerType = LAYER_TYPE;
BindlWoman.gender = GENDER;
BindlWoman.title = TITLE;
BindlWoman.key = KEY;
BindlWoman.paletteNames = PALETTE_NAMES;
