import { useCallback, useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";

export const useMintCount = singletonHook({ mintCount: undefined }, () => {
  const getNFTCount = useCallback(async (): Promise<number> => {
    try {
      const response = await fetch("/api/nft/count", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const responseData = await response.json();
      return responseData.count || 0;
    } catch (error) {
      return 0;
    }
  }, []);

  const [mintCount, setMintCount] = useState<number | undefined>();

  const getMintCount = useCallback(async () => {
    const count = await getNFTCount();
    setMintCount(count);
  }, [getNFTCount]);

  useEffect(() => {
    const interval = window.setInterval(() => {
      getMintCount();
    }, 60_000);
    return () => window.clearInterval(interval);
  }, [getMintCount]);

  getMintCount();

  return { mintCount };
});
