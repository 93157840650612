import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Curls";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Curls: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Curls, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 11V9H15V8H14V9H12V8H11V9H10V8H9V10H8V9H7V11H6V12H5V13H4V11H5V10H4V9H5V7H6V6H7V5H9V4H10V5H11V4H14V5H16V6H17V7H18V9H19V10H17V11H16Z"
        fill={hairColor}
      />
    </svg>
  );
};

Curls.allowSemiTransparentColors = false;
Curls.layerType = LAYER_TYPE;
Curls.gender = GENDER;
Curls.title = TITLE;
Curls.key = KEY;
Curls.paletteNames = PALETTE_NAMES;
Curls.blacklist = [ApeMan, AlienMan]
