import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Cap Check";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapCheck: LayerFC = (props) => {
  return (
    <svg 
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 9H20V10H6V6H7V5H8V4H15V5H16V6H17V9Z" fill="black"/>
      <path d="M14 6H15V7H14V6Z" fill="white"/>
      <path d="M13 7H14V8H13V7Z" fill="white"/>
      <path d="M11 7H12V8H11V7Z" fill="white"/>
      <path d="M12 8H13V9H12V8Z" fill="white"/>
    </svg>
  );
};

CapCheck.layerType = LAYER_TYPE;
CapCheck.gender = GENDER;
CapCheck.title = TITLE;
CapCheck.key = KEY;
CapCheck.paletteNames = PALETTE_NAMES;
CapCheck.price = 0.0008;
