import { FC, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontSize, Text } from "./Text";
import styled from "styled-components";
import { Icon, IconType } from "./Icon";
import { Color } from "../constants/Color";

interface NavItemProps {
  to: string;
  iconType: IconType;
  className?: string;
  $fontColor?: Color;
  $fontSize?: FontSize;
}

const NavItemWrapper = styled(Text)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const NavItem: FC<NavItemProps> = (props) => {
  const { pathname } = useLocation();
  const $fontColor = useMemo(
    () => props.$fontColor || (pathname === props.to ? "primary" : "gray300"),
    [pathname, props.$fontColor, props.to]
  );

  const isExternalLink = useMemo(() => props.to.startsWith("http"), [props.to]);

  return (
    <NavItemWrapper
      className={props.className}
      $fontColor={$fontColor}
      $fontSize={props.$fontSize}
      as={isExternalLink ? "a" : Link}
      to={isExternalLink ? undefined : props.to}
      href={isExternalLink ? props.to : undefined}
      target={isExternalLink ? "_blank" : undefined}
      rel={isExternalLink ? "noreferrer" : undefined}
    >
      <Icon type={props.iconType} />
      {props.children}
    </NavItemWrapper>
  );
};
