import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Cap TCG";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapTCG: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 10H20V11H7V8H8V7H9V6H15V7H16V8H17V10Z" fill="#192445" />
      <path d="M11.5 7.75H11.75V8H12V8.25H11.75V9H12V9.25H11.5V8.25H11.25V8H11.5V7.75Z" fill="white" />
      <path d="M12.25 8.25H12.5V9H12.25V8.25Z" fill="white" />
      <path d="M12.5 9H13V9.25H12.5V9Z" fill="white" />
      <path d="M12.5 8.25H13V8H12.5V8.25Z" fill="white" />
      <path d="M13.25 8.25H13.5V9H13.25V8.25Z" fill="white" />
      <path d="M14 9.5V9.25H13.5V9H14V8.25H13.5V8H14.25V9.5H14Z" fill="white" />
      <path d="M14 9.5V9.75H13.5V9.5H14Z" fill="white" />
    </svg>
  );
};

CapTCG.layerType = LAYER_TYPE;
CapTCG.gender = GENDER;
CapTCG.title = TITLE;
CapTCG.key = KEY;
CapTCG.paletteNames = PALETTE_NAMES;
CapTCG.isBranded = true;
CapTCG.price = 0.01;
