import { AlienMan } from "../type/AlienMan";
import { Man } from "../type/Man";
import { ZombieMan } from "../type/ZombieMan";
import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Man";
const TITLE = "Chin";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const ChinMan: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="9" y="20" width="1" height="1" fill="black" />
    </svg>
  );
};

ChinMan.layerType = LAYER_TYPE;
ChinMan.gender = GENDER;
ChinMan.title = TITLE;
ChinMan.key = KEY;
ChinMan.paletteNames = PALETTE_NAMES;
ChinMan.whitelist = [Man, ZombieMan, AlienMan];
ChinMan.zIndex = 15;
