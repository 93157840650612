import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";

import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "3D";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const ThreeD1px: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 10H7H8H9H12H13H16H17V11V13V14H13H12H8V13V12H7V11H6V10ZM9 13H12V11H9V13ZM13 13H16V11H13V13Z"
        fill="white"
      />
      <rect x="9" y="11" width="3" height="2" fill="#328DFD" />
      <rect x="13" y="11" width="3" height="2" fill="#FD3232" />
    </svg>
  );
};

ThreeD1px.layerType = LAYER_TYPE;
ThreeD1px.gender = GENDER;
ThreeD1px.title = TITLE;
ThreeD1px.key = KEY;
ThreeD1px.paletteNames = PALETTE_NAMES;
ThreeD1px.whitelist = [AlienMan, ApeMan];
