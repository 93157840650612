import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Messy";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Messy: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Messy, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4H13H14V5H15V4H16V6H17H18V7H17V9H18V10H17H16H15V9H14V8H13V9V10H12V9H10V10H9V9H8V10H7V12H6V10H5V9H6V8H5V7H6V6H7V5H8V4ZM8 10H9V11H8V10Z"
        fill={hairColor}
      />
    </svg>
  );
};

Messy.allowSemiTransparentColors = false;
Messy.layerType = LAYER_TYPE;
Messy.gender = GENDER;
Messy.title = TITLE;
Messy.key = KEY;
Messy.paletteNames = PALETTE_NAMES;
Messy.blacklist = [ApeMan, AlienMan]
