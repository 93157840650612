import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Long 11";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Long11: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Long11, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 6H15V7H16V8H17V10H18V20H19V22H20V24H13V22H14V21H15V20H16V19H17V13H16V12H13V10H12V12H11V11H10V12H9V13H7V19H8V23H7V22H6V20H5V12H6V10H7V8H8V7H10V6Z" fill={hairColor} />
    </svg>
  );
};

Long11.allowSemiTransparentColors = false;
Long11.layerType = LAYER_TYPE;
Long11.gender = GENDER;
Long11.title = TITLE;
Long11.key = KEY;
Long11.paletteNames = PALETTE_NAMES;
Long11.blacklist = [ApeWoman, AlienWoman]