import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Woman";
const TITLE = "Sunglasses";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Glass";
const PALETTE_NAME_2: PaletteName = "Frame";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const ColoredGlasses: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(ColoredGlasses, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(ColoredGlasses, PALETTE_NAME_2).colors;
  }, [palettes]);
  const glassesColor = useMemo(() => palette1[0], [palette1]);
  const frameColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 12H12V14H9V12Z" fill={glassesColor}/>
    <path d="M13 12H16V14H13V12Z" fill={glassesColor}/>
    <path d="M16 14V12H13V14H12V12H9V14H8V12H7V11H17V14H16Z" fill={frameColor}/>
    <path d="M9 15V14H12V15H9Z" fill={frameColor}/>
    <path d="M13 15V14H16V15H13Z" fill={frameColor}/>
    </svg>
  );
};

ColoredGlasses.layerType = LAYER_TYPE;
ColoredGlasses.gender = GENDER;
ColoredGlasses.title = TITLE;
ColoredGlasses.key = KEY;
ColoredGlasses.paletteNames = PALETTE_NAMES;
