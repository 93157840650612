import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Elegant";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Elegant: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3H16V4H17V8H20V9H3V8H6V4H7V3Z"
        fill="black"
      />
      <rect x="6" y="7" width="11" height="1" fill="white" />
    </svg>
  );
};

Elegant.layerType = LAYER_TYPE;
Elegant.gender = GENDER;
Elegant.title = TITLE;
Elegant.key = KEY;
Elegant.paletteNames = PALETTE_NAMES;
