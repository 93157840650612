import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Pixelenca 12";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Pixelenca12: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_261_6842)">
        <rect width="24" height="24" fill="#ECB4A4" />
        <rect x="1" y="1" width="8" height="2" fill="#513F30" />
        <rect x="3" y="17" width="21" height="3" fill="#513F30" />
        <rect y="4" width="5" height="1" fill="#513F30" />
        <rect x="-1" y="5" width="4" height="1" fill="#F6D276" />
        <rect x="1" y="7" width="9" height="1" fill="#513F30" />
        <rect x="1" y="9" width="3" height="1" fill="#CC5958" />
        <rect x="7" y="9" width="12" height="1" fill="#BED8CE" />
        <rect y="13" width="24" height="2" fill="#BED8CE" />
        <rect y="11" width="24" height="1" fill="#DED7C7" />
        <rect x="4" y="9" width="1" height="1" fill="#513F30" />
        <rect x="12" y="1" width="12" height="2" fill="#223356" />
        <rect x="13" y="4" width="11" height="4" fill="#BED8CE" />
        <rect x="11" y="1" width="1" height="2" fill="#BED8CE" />
        <rect x="7" y="4" width="4" height="2" fill="#51A492" />
        <rect x="17" y="8" width="7" height="1" fill="#513F30" />
        <rect x="21" y="9" width="3" height="1" fill="#F5D276" />
        <rect y="17" width="1" height="1" fill="#BED8CE" />
        <rect y="21" width="12" height="2" fill="#84A8BD" />
        <rect x="15" y="21" width="9" height="2" fill="#F3BD46" />
        <rect x="14" y="21" width="1" height="2" fill="#223356" />
      </g>
      <defs>
        <clipPath id="clip0_261_6842">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

Pixelenca12.layerType = LAYER_TYPE;
Pixelenca12.gender = GENDER;
Pixelenca12.title = TITLE;
Pixelenca12.key = KEY;
Pixelenca12.paletteNames = PALETTE_NAMES;
Pixelenca12.price = 0.001;
