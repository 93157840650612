import { LayerFC } from "../../types/LayerFC";
import { Cry } from "./Cry";
import { CryWide } from "./CryWide";
import { Default } from "./Default";
import { Grin } from "./Grin";
import { Kiss } from "./Kiss";
import { Laugh } from "./Laugh";
import { LaughTongue } from "./LaughTongue";
import { LaughTongueWide } from "./LaughTongueWide";
import { LaughWide } from "./LaughWide";
import { Mask } from "./Mask";
import { Oh } from "./Oh";
import { Sad } from "./Sad";
import { Smile } from "./Smile";
import { Tongue } from "./Tongue";
import { TongueOutside } from "./TongueOutside";
import { Wide } from "./Wide";
import { Zombie } from "./Zombie";

export const mouthManLayers: LayerFC[] = [
  Default,
  Zombie,
  Wide,
  Cry,
  CryWide,
  LaughTongue,
  LaughTongueWide,
  Laugh,
  LaughWide,
  Tongue,
  TongueOutside,
  Oh,
  Kiss,
  Grin,
  Smile,
  Sad,
  Mask,
];
