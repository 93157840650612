import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";
import { hairWhitelist } from "./whitelists";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Long Wild";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const LongWild: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(LongWild, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 10H3V11H4V14H3V13H2V14H3V17H2V18H3V20H4V21H3V22H4V21H5V22H6V20H7V18H6V16H7V15H6V13H7V12H8V11H9V10H5V11H4V10Z"
        fill={hairColor}
      />
      <path
        d="M18 20H19V21H18V20Z"
        fill={hairColor}
      />
      <path
        d="M18 18V20H17V12H16V11H15V10H18V11H19V15H20V16H19V18H18Z"
        fill={hairColor}
      />
    </svg>
  );
};

LongWild.layerType = LAYER_TYPE;
LongWild.allowSemiTransparentColors = false;
LongWild.gender = GENDER;
LongWild.title = TITLE;
LongWild.key = KEY;
LongWild.isExtension = true;
LongWild.paletteNames = PALETTE_NAMES;
LongWild.whitelist = hairWhitelist;
