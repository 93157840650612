import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Fisherman";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hat";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Fisherman: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Fisherman, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hatColor = useMemo(() => palette1[0], [palette1]);
  const secondaryhatColor = useMemo(() => palette1[1], [palette1]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 4H14V5H9V4Z" fill="black" />
      <path d="M8 6V5H9V6H8Z" fill="black" />
      <path d="M7 7V6H8V7H7Z" fill="black" />
      <path d="M5 8H7V7H5V8Z" fill="black" />
      <path d="M5 9V8H4V9H5Z" fill="black" />
      <path d="M5 9V10H6V9H5Z" fill="black" />
      <path d="M15 6H14V5H15V6Z" fill="black" />
      <path d="M16 7H15V6H16V7Z" fill="black" />
      <path d="M18 8H16V7H18V8Z" fill="black" />
      <path d="M18 9V8H19V9H18Z" fill="black" />
      <path d="M18 9V10H17V9H18Z" fill="black" />
      <path d="M14 5H9V6H8V7H7V8H5V9H6V10H17V9H18V8H16V7H15V6H14V5Z" fill={hatColor} />
      <rect x="7" y="7" width="9" height="1" fill={secondaryhatColor} />
    </svg>
  );
};

Fisherman.allowSemiTransparentColors = false;
Fisherman.layerType = LAYER_TYPE;
Fisherman.gender = GENDER;
Fisherman.title = TITLE;
Fisherman.key = KEY;
Fisherman.paletteNames = PALETTE_NAMES;
