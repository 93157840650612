import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Mouth";
const GENDER: Gender = "Woman";
const TITLE = "Mask";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Mask";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Mask: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Mask, PALETTE_NAME_1).colors;
  }, [palettes]);
  const maskColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 13H8V14H7V13Z" fill={maskColor} />
      <path d="M8 14H9V15H8V14Z" fill={maskColor} />
      <path d="M9 15H10V19H9V15Z" fill={maskColor} />
      <path d="M8 17H10V18H8V17Z" fill={maskColor} />
      <path d="M15 15H16V19H15V15Z" fill={maskColor} />
      <path d="M10 15H15V20H10V15Z" fill={maskColor} />
      <path d="M11 20H14V21H11V20Z" fill={maskColor} />
    </svg>
  );
};

Mask.layerType = LAYER_TYPE;
Mask.gender = GENDER;
Mask.title = TITLE;
Mask.key = KEY;
Mask.paletteNames = PALETTE_NAMES;
