import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Pixelenca 13";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Pixelenca13: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="#3C374B" />
      <rect x="1" width="3" height="17" fill="#2B2932" />
      <rect x="9" width="3" height="19" fill="#2B2932" />
      <rect x="13" y="2" width="2" height="8" fill="#2B2932" />
      <rect x="19" width="5" height="9" fill="#2B2932" />
      <rect x="19" y="11" width="2" height="13" fill="#2B2932" />
      <rect x="22" y="15" width="2" height="9" fill="#3E3F57" />
      <rect x="16" width="2" height="5" fill="#2B2932" />
      <rect x="16" y="8" width="2" height="16" fill="#2B2932" />
      <rect x="13" y="14" width="2" height="8" fill="#2B2932" />
      <rect x="5" y="5" width="2" height="14" fill="#2B2932" />
      <rect x="5" y="4" width="2" height="1" fill="#96B097" />
      <rect x="13" y="10" width="2" height="1" fill="#96B097" />
      <rect x="19" y="9" width="5" height="1" fill="#404D4D" />
      <rect x="5" y="19" width="2" height="2" fill="#C9BAB8" />
      <rect x="16" y="5" width="2" height="1" fill="#C9BAB8" />
      <rect x="5" y="3" width="2" height="1" fill="#2B2932" />
      <rect x="5" y="21" width="2" height="1" fill="#2B2932" />
      <path d="M1 18H4V24H1V18Z" fill="#404D4D" />
      <path d="M13 23H15V24H13V23Z" fill="#7E737F" />
      <path d="M22 14H24V15H22V14Z" fill="#7E737F" />
      <path d="M22 13H24V14H22V13Z" fill="#2B2932" />
    </svg>
  );
};

Pixelenca13.layerType = LAYER_TYPE;
Pixelenca13.gender = GENDER;
Pixelenca13.title = TITLE;
Pixelenca13.key = KEY;
Pixelenca13.paletteNames = PALETTE_NAMES;
Pixelenca13.price = 0.001;
