import { BeenieBubble } from "../../hat/man/BeenieBubble";
import { BeenieL } from "../../hat/man/BeenieL";
import { BeenieM } from "../../hat/man/BeenieM";
import { CapDiamond } from "../../hat/man/CapDiamond";
import { CapForward } from "../../hat/man/CapForward";
import { CapNewbie } from "../../hat/man/CapNewbie";
import { CowboyHat } from "../../hat/man/CowboyHat";
import { Fedora } from "../../hat/man/Fedora";
import { Police } from "../../hat/man/Police";
import { TopHat } from "../../hat/man/TopHat";
import { Cap } from "../../hat/man/Cap";
import { CapBackward } from "../../hat/man/CapBackward";
import { CapBackwardOpen } from "../../hat/man/CapBackwardOpen";
import { CapDesigner } from "../../hat/man/CapDesigner";
import { CapCheck } from "../../hat/man/CapCheck";
import { Crown } from "../../hat/man/Crown";
import { Elegant } from "../../hat/man/Elegant";
import { Bandana } from "../../hat/man/Bandana";
import { CapLayer3 } from "../../hat/man/CapLayer3";
import { CapTCG } from "../../hat/man/CapTCG";
import { CapC1 } from "../../hat/man/CapC1";
import { Man } from "../../type/Man";
import { ZombieWoman } from "../../type/ZombieWoman";
import { BlueCap } from "../../hat/man/BlueCap";
import { CapBackwardColor } from "../../hat/man/CapBackwardColor";
import { CapRainbow } from "../../hat/man/CapRainbow";
import { CapNouns } from "../../hat/man/CapNouns";
import { CapKevin } from "../../hat/man/CapKevin";
import { CapRyze } from "../../hat/man/CapRyze";
import { Fisherman } from "../../hat/man/Fisherman";
import { CapES } from "../../hat/man/CapES";

export const hairWhitelist = [
  Man,
  ZombieWoman,
  TopHat,
  BeenieM,
  BeenieL,
  BeenieBubble,
  Fisherman,
  CowboyHat,
  Fedora,
  CapForward,
  CapNewbie,
  Police,
  CapDiamond,
  Crown,
];

export const capWhitelist = [
  Man,
  ZombieWoman,
  Cap,
  CapBackward,
  CapBackwardOpen,
  CapDesigner,
  CapCheck,
  Bandana,
  Elegant,
  CapLayer3,
  CapTCG,
  CapC1,
  CapES,
  BlueCap,
  CapBackwardColor,
  CapRainbow,
  CapNouns,
  CapKevin,
  CapRyze
];
