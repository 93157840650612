import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Pixelenca 2";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Pixelenca2: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="#EAE4D9" />
      <rect x="1" y="1" width="6" height="1" fill="#54894F" />
      <rect x="1" y="3" width="12" height="2" fill="#54894F" />
      <rect x="1" y="17" width="12" height="2" fill="#54894F" />
      <rect x="1" y="10" width="3" height="2" fill="#54894F" />
      <rect x="5" y="10" width="7" height="2" fill="#54894F" />
      <rect x="13" y="10" width="6" height="2" fill="#635241" />
      <rect x="20" y="10" width="3" height="2" fill="#ECB4A4" />
      <rect x="14" y="3" width="9" height="2" fill="#54894F" />
      <rect x="14" y="17" width="9" height="2" fill="#54894F" />
      <rect x="17" y="6" width="6" height="1" fill="#54894F" />
      <rect x="14" y="8" width="9" height="1" fill="#54894F" />
      <rect x="15" y="13" width="8" height="1" fill="#54894F" />
      <rect x="20" y="15" width="3" height="1" fill="#54894F" />
      <rect x="12" y="20" width="11" height="1" fill="white" />
      <rect x="19" y="22" width="4" height="1" fill="#54894F" />
      <rect x="11" y="22" width="7" height="1" fill="#ECB4A4" />
      <rect x="10" y="6" width="6" height="1" fill="#ECB4A4" />
      <rect x="1" y="15" width="5" height="1" fill="white" />
      <rect x="1" y="20" width="5" height="1" fill="#ECB4A4" />
      <rect x="5" y="8" width="8" height="1" fill="#CFE4CE" />
      <rect x="10" y="13" width="4" height="1" fill="#CFE4CE" />
      <rect x="1" y="6" width="8" height="1" fill="#54894F" />
      <rect x="1" y="8" width="3" height="1" fill="#635241" />
      <rect x="1" y="13" width="8" height="1" fill="#54894F" />
      <rect x="1" y="22" width="8" height="1" fill="#54894F" />
      <rect x="7" y="15" width="12" height="1" fill="#54894F" />
      <rect x="7" y="20" width="4" height="1" fill="#54894F" />
      <rect x="8" y="1" width="6" height="1" fill="#CFE4CE" />
      <rect x="15" y="1" width="8" height="1" fill="white" />
    </svg>
  );
};

Pixelenca2.layerType = LAYER_TYPE;
Pixelenca2.gender = GENDER;
Pixelenca2.title = TITLE;
Pixelenca2.key = KEY;
Pixelenca2.paletteNames = PALETTE_NAMES;
Pixelenca2.price = 0.001;
