import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Generic";
const TITLE = "Drink";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Drink: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="15" y="19" width="1" height="5" fill="white" />
      <rect x="14" y="20" width="4" height="4" fill="white" fill-opacity="0.7" />
      <rect x="14" y="22" width="4" height="2" fill="#FEC299" fill-opacity="0.8" />
      <rect x="13" y="18" width="2" height="1" fill="white" />
    </svg>
  );
};

Drink.layerType = LAYER_TYPE;
Drink.gender = GENDER;
Drink.title = TITLE;
Drink.key = KEY;
Drink.paletteNames = PALETTE_NAMES;
