import { PaletteName } from "../../../../constants/Palettes";
import { AlienMan } from "../../type/AlienMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Ear";
const GENDER: Gender = "Man";
const TITLE = "Large";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Large: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5" y="11" width="1" height="4" fill="black" />
    </svg>
  );
};

Large.layerType = LAYER_TYPE;
Large.gender = GENDER;
Large.title = TITLE;
Large.key = KEY;
Large.paletteNames = PALETTE_NAMES;
Large.blacklist = [AlienMan];
