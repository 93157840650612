import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Top Hat";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const TopHat: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2H16V3H17V9H19V10H4V9H6V3H7V2Z"
        fill="black"
      />
    </svg>
  );
};

TopHat.layerType = LAYER_TYPE;
TopHat.gender = GENDER;
TopHat.title = TITLE;
TopHat.key = KEY;
TopHat.paletteNames = PALETTE_NAMES;
