import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Beenie Bubble";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hat";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const BeenieBubble: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(BeenieBubble, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hatColor = useMemo(() => palette1[0], [palette1]);
  const secondaryhatColor = useMemo(() => palette1[1], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3H9V4H7V3ZM7 6V4H6V6H7ZM8 6H7V7V8H6V11H7V8H8V7H9V6H10H15V7H16V8H17V11H18V8H17V7H16V6H15V5H10V4H9V5V6H8Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6H9V7H8V8H7V11H17V8H16V7H15V6Z"
        fill={hatColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 4H7V6H9V4ZM8 8H7V9H8V10H7V11H8V10H9V11H10V10H11V11H12V10H13V11H14V10H15V11H16V10H17V9H16V8H15V9H14V8H13V9H12V8H11V9H10V8H9V9H8V8ZM9 9V10H10V9H9ZM11 9V10H12V9H11ZM13 9V10H14V9H13ZM15 9V10H16V9H15Z"
        fill={secondaryhatColor}
      />
    </svg>
  );
};

BeenieBubble.layerType = LAYER_TYPE;
BeenieBubble.gender = GENDER;
BeenieBubble.title = TITLE;
BeenieBubble.key = KEY;
BeenieBubble.paletteNames = PALETTE_NAMES;
