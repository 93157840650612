import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Cap Kevin";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapKevin: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 10H20V11H7V8H8V7H9V6H15V7H16V8H17V10Z" fill="#63EA81" />
      <rect x="10" y="8" width="2" height="2" fill="white" />
      <rect x="13" y="8" width="2" height="2" fill="white" />
      <rect x="10" y="9" width="1" height="1" fill="black" />
      <rect x="14" y="8" width="1" height="1" fill="black" />
    </svg>
  );
};

CapKevin.layerType = LAYER_TYPE;
CapKevin.gender = GENDER;
CapKevin.title = TITLE;
CapKevin.key = KEY;
CapKevin.paletteNames = PALETTE_NAMES;
CapKevin.isBranded = true;
CapKevin.price = 0.001;
