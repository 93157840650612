import { PaletteName } from "../../../../constants/Palettes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Mouth";
const GENDER: Gender = "Man";
const TITLE = "Laugh";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Laugh: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="11" y="17" width="3" height="1" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 17H10V18H11V19H14V18H11V17Z"
        fill="black"
      />
    </svg>
  );
};

Laugh.layerType = LAYER_TYPE;
Laugh.gender = GENDER;
Laugh.title = TITLE;
Laugh.key = KEY;
Laugh.paletteNames = PALETTE_NAMES;
Laugh.blacklist = [ApeMan, AlienMan];
