import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { None } from "../../hat/None";
import { Hoodie } from "../../hat/woman/Hoodie";
import { Woman } from "../../type/Woman";
import { ZombieWoman } from "../../type/ZombieWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Long Curl";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const LongCurl: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(LongCurl, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 9H11V10H14V9ZM6 11H7H9V12H7V13H6V15H7V19H8V24H7H6H5H4V22H5V12H6V11ZM11 11H12H13V13H12V12H11V11ZM16 12V13H17V12V11H15V12H16Z"
        fill={hairColor}
      />
    </svg>
  );
};

LongCurl.layerType = LAYER_TYPE;
LongCurl.gender = GENDER;
LongCurl.allowSemiTransparentColors = false;
LongCurl.title = TITLE;
LongCurl.key = KEY;
LongCurl.isExtension = true;
LongCurl.paletteNames = PALETTE_NAMES;
LongCurl.whitelist = [Woman, ZombieWoman];
LongCurl.blacklist = [None, Hoodie]
