import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Pixelenca 7";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Pixelenca7: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="#3B5F88" />
      <rect x="1" width="2" height="2" fill="#DED7C7" />
      <path d="M1 2H3V5H1V2Z" fill="#F6D276" />
      <path d="M4 0H5V2H4V0Z" fill="#CF5856" />
      <path d="M4 9H5V10H4V9Z" fill="#52A492" />
      <path d="M4 2H5V3H4V2Z" fill="#DED7C7" />
      <path d="M4 19H5V20H4V19Z" fill="#52A492" />
      <path d="M4 21H5V22H4V21Z" fill="#392C22" />
      <path d="M4 20H5V21H4V20Z" fill="#DED7C7" />
      <path d="M4 22H5V24H4V22Z" fill="#DED7C7" />
      <path d="M4 13H5V14H4V13Z" fill="#DED7C7" />
      <path d="M4 3H5V9H4V3Z" fill="#ECB4A4" />
      <path d="M4 14H5V19H4V14Z" fill="#392C22" />
      <rect x="1" y="5" width="2" height="3" fill="#392C22" />
      <rect x="1" y="8" width="2" height="3" fill="#F6D276" />
      <rect x="1" y="20" width="2" height="4" fill="#F3BD46" />
      <rect x="1" y="16" width="2" height="4" fill="#DED7C7" />
      <rect x="1" y="11" width="2" height="3" fill="#ECB4A4" />
      <rect x="6" width="3" height="2" fill="#F6D276" />
      <rect x="12" width="2" height="4" fill="#ECB4A4" />
      <rect x="12" y="6" width="2" height="5" fill="#392C22" />
      <rect x="12" y="13" width="2" height="5" fill="#F6D276" />
      <rect x="12" y="18" width="2" height="6" fill="#BED8CE" />
      <rect x="15" width="2" height="2" fill="#DED7C7" />
      <rect x="15" y="2" width="2" height="1" fill="#F6D276" />
      <rect x="15" y="5" width="2" height="4" fill="#52A492" />
      <rect x="15" y="22" width="2" height="2" fill="#F6D276" />
      <rect x="15" y="16" width="2" height="6" fill="#DED7C7" />
      <rect x="6" y="4" width="3" height="5" fill="#F3BD46" />
      <rect x="6" y="11" width="3" height="6" fill="#392C22" />
      <rect x="6" y="18" width="3" height="6" fill="#ECB4A4" />
      <rect x="10" width="1" height="2" fill="#52A492" />
      <rect x="10" y="2" width="1" height="5" fill="#DED7C7" />
      <rect x="10" y="9" width="1" height="15" fill="#DED7C7" />
      <rect x="15" y="11" width="2" height="3" fill="#392C22" />
      <rect x="18" width="3" height="1" fill="#CF5856" />
      <rect x="18" y="1" width="3" height="5" fill="#DED7C7" />
      <rect x="18" y="8" width="3" height="4" fill="#BED8CE" />
      <rect x="18" y="14" width="3" height="4" fill="#F6D276" />
      <rect x="18" y="13" width="3" height="1" fill="#52A492" />
      <rect x="18" y="20" width="3" height="4" fill="#ECB4A4" />
      <rect x="22" width="1" height="4" fill="#F6D276" />
      <rect x="22" y="4" width="1" height="4" fill="#F3BD46" />
      <rect x="22" y="10" width="1" height="4" fill="#392C22" />
      <rect x="22" y="22" width="1" height="2" fill="#DED7C7" />
      <rect x="22" y="14" width="1" height="2" fill="#DED7C7" />
      <rect x="22" y="16" width="1" height="6" fill="#F6D276" />
    </svg>
  );
};

Pixelenca7.layerType = LAYER_TYPE;
Pixelenca7.gender = GENDER;
Pixelenca7.title = TITLE;
Pixelenca7.key = KEY;
Pixelenca7.paletteNames = PALETTE_NAMES;
Pixelenca7.price = 0.001;
