import { PaletteName } from "../../../../constants/Palettes";
import { Man } from "../../type/Man";
import { ZombieMan } from "../../type/ZombieMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Mouth";
const GENDER: Gender = "Man";
const TITLE = "Wide";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Wide: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="10" y="18" width="5" height="1" fill="black" />
    </svg>
  );
};

Wide.layerType = LAYER_TYPE;
Wide.gender = GENDER;
Wide.title = TITLE;
Wide.key = KEY;
Wide.paletteNames = PALETTE_NAMES;
Wide.blacklist = [Man, ZombieMan]
