import { LayerFC } from "../../types/LayerFC";
import { Collar } from "./Collar";
import { Fur } from "./Fur";
import { FurXL } from "./FurXL";
import { Hoodie } from "./Hoodie";
import { JoggingSuit } from "./JoggingSuit";
import { Shirt } from "./Shirt";
import { Suit } from "./Suit";
import { Sweater } from "./Sweater";
import { Turtleneck } from "./Turtleneck";

export const clothesWomanLayers: LayerFC[] = [
  Shirt,
  Turtleneck,
  Sweater,
  Suit,
  Collar,
  JoggingSuit,
  Fur,
  FurXL,
  Hoodie,
];
