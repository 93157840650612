import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Woman";
const TITLE = "Choker";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const ChokerWoman: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 20H9V22H10V23H12V21H11V20Z"
        fill="black"
      />
    </svg>
  );
};

ChokerWoman.layerType = LAYER_TYPE;
ChokerWoman.gender = GENDER;
ChokerWoman.title = TITLE;
ChokerWoman.key = KEY;
ChokerWoman.paletteNames = PALETTE_NAMES;
ChokerWoman.zIndex = 15;