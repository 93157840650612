import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { AlienMan } from "../type/AlienMan";
import { ApeMan } from "../type/ApeMan";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Beard";
const GENDER: Gender = "Man";
const TITLE = "Shaved Mustage Chin";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Beard";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const ShavedMustageChin: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(ShavedMustageChin, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 15H8V17H9V19H10V20H12V19H13V20H15V21H10H9V20H8V19H7V15ZM15 20V19H16V20H15ZM15 17H10V18H15V17Z"
        fill={hairColor}
      />
    </svg>
  );
};

ShavedMustageChin.layerType = LAYER_TYPE;
ShavedMustageChin.gender = GENDER;
ShavedMustageChin.title = TITLE;
ShavedMustageChin.key = KEY;
ShavedMustageChin.paletteNames = PALETTE_NAMES;
ShavedMustageChin.blacklist = [ApeMan, AlienMan]
