import { ZombieMan } from "../../type/ZombieMan";
import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Eyes";
const GENDER: Gender = "Man";
const TITLE = "Zombie Full";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];


export const ZombieFull: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 11H11V12H9V11ZM10 13H9V14H10V13ZM15 13H14V14H15V13ZM16 11H14V12H16V11Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 12H9V13H11V12ZM16 12H14V13H16V12Z"
        fill="#F24E1E"
      />
    </svg>
  );
};

ZombieFull.layerType = LAYER_TYPE;
ZombieFull.gender = GENDER;
ZombieFull.title = TITLE;
ZombieFull.key = KEY;
ZombieFull.paletteNames = PALETTE_NAMES;
ZombieFull.whitelist = [ZombieMan];
