import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Rainbow";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Rainbow: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="#EAE4D9" />
      <rect width="24" height="4" fill="#D93933" />
      <rect y="4" width="24" height="4" fill="#E5943E" />
      <rect y="8" width="24" height="4" fill="#F8CB63" />
      <rect y="12" width="24" height="4" fill="#688E49" />
      <rect y="16" width="24" height="4" fill="#346794" />
      <rect y="20" width="24" height="4" fill="#826AA8" />
    </svg>
  );
};

Rainbow.layerType = LAYER_TYPE;
Rainbow.gender = GENDER;
Rainbow.title = TITLE;
Rainbow.key = KEY;
Rainbow.paletteNames = PALETTE_NAMES;
