import { PaletteName } from "../../../constants/Palettes";
import { ZombieMan } from "../type/ZombieMan";
import { ZombieWoman } from "../type/ZombieWoman";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Generic";
const TITLE = "Blood Mouth Extra Large";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const BloodXL: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="11" y="19" width="1" height="2" fill="#C54424" />
      <rect x="12" y="19" width="1" height="1" fill="#C54424" />
    </svg>
  );
};

BloodXL.layerType = LAYER_TYPE;
BloodXL.gender = GENDER;
BloodXL.title = TITLE;
BloodXL.key = KEY;
BloodXL.paletteNames = PALETTE_NAMES;
BloodXL.whitelist = [ZombieMan, ZombieWoman];
