import { FC } from "react";
import styled from "styled-components";
import { Text } from "../components/Text";
import { COLOR_MAP } from "../constants/Color";

const AboutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  margin: 0 auto;
  font-size: 12px;
  line-height: 28px;
  color: white;
  padding: 40px 0;
  a {
    color: ${COLOR_MAP.primary};
    text-decoration: none;
  }
`;

const About: FC = () => {
  return (
    <AboutWrapper>
      <Text>
        GM
        <br />
        <br />
        This is a profile picture (PFP) experiment where you can create and mint
        your own SelfMadePunks. The Owners of minted SelfMadePunks are free to do whatever they want
        with them.
        <br />
        <br />
        You can decide for yourself which traits you get. It's up to you.
        <br />
        <br />
        We are looking forward to many beautiful Punks. Feel free
        to post them with the hashtags #SelfMadePunk or #SMP.
        <br />
        <br />
        SelfMadePunks is not in any association with CryptoPunks.
        <br />
        <br />
        Don't mint for profit. Do it for the vibes and support
        our small team to build software.
        <br />
        <br />
        WAGMI
      </Text>
    </AboutWrapper>
  );
};

export default About;
