import { PaletteName } from "../../../constants/Palettes";
import { hairManLayers } from "../hair/man";
import { Bandana } from "../hat/man/Bandana";
import { BeenieM } from "../hat/man/BeenieM";
import { CowboyHat } from "../hat/man/CowboyHat";
import { Crown } from "../hat/man/Crown";
import { Elegant } from "../hat/man/Elegant";
import { Fedora } from "../hat/man/Fedora";
import { Hoodie } from "../hat/man/Hoodie";
import { Police } from "../hat/man/Police";
import { TopHat } from "../hat/man/TopHat";
import { None } from "../hat/None";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Man";
const TITLE = "Beats";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const BeatsManCap: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6" y="15" width="1" height="1" fill="#E7E9E5"/>
      <rect x="7" y="11" width="1" height="4" fill="#E7E9E5"/>
      <rect x="6" y="6" width="1" height="5" fill="#E7E9E5"/>
      <rect x="4" y="11" width="3" height="4" fill="#CECFCB"/>
      <rect x="5" y="6" width="1" height="10" fill="#CECFCB"/>
      <rect x="6" y="5" width="1" height="1" fill="#CECFCB"/>
      <rect x="17" y="11" width="1" height="4" fill="#E7E9E5"/>
    </svg>
  );
};

BeatsManCap.layerType = LAYER_TYPE;
BeatsManCap.gender = GENDER;
BeatsManCap.title = TITLE;
BeatsManCap.key = KEY;
BeatsManCap.paletteNames = PALETTE_NAMES;
BeatsManCap.blacklist = [...hairManLayers, TopHat, BeenieM, CowboyHat, Elegant, Fedora, Hoodie, Bandana, Crown, Police, None]