import { PaletteName } from "../../../../constants/Palettes";
import { ApeWoman } from "../../type/ApeWoman";
import { Woman } from "../../type/Woman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Eyes";
const GENDER: Gender = "Woman";
const TITLE = "Sleep";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Sleep: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 13H16V14H14V13Z" fill="black" />
      <path d="M9 14H11V13H9V14Z" fill="black" />
      <path d="M9 12H11V13H9V12Z" fill="black" fillOpacity="0.15" />
      <path d="M14 12H16V13H14V12Z" fill="black" fillOpacity="0.15" />
    </svg>
  );
};

Sleep.layerType = LAYER_TYPE;
Sleep.gender = GENDER;
Sleep.title = TITLE;
Sleep.key = KEY;
Sleep.paletteNames = PALETTE_NAMES;
Sleep.whitelist = [Woman, ApeWoman];