import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Woman";
const TITLE = "Nerd";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Glass";
const PALETTE_NAME_2: PaletteName = "Frame";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const NerdGlasses: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(NerdGlasses, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(NerdGlasses, PALETTE_NAME_2).colors;
  }, [palettes]);
  const glassesColor = useMemo(() => palette1[0], [palette1]);
  const frameColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 12H16V14H14V12Z" fill={glassesColor} />
      <path d="M9 12H11V14H9V12Z" fill={glassesColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 14H16V12H14V14H13V13H12V14H11V12H9V14H8V13H7V12H8V11H12V12H13V11H17V14ZM9 14H11V15H9V14ZM14 14V15H16V14H14Z"
        fill={frameColor}
      />
    </svg>
  );
};

NerdGlasses.layerType = LAYER_TYPE;
NerdGlasses.gender = GENDER;
NerdGlasses.title = TITLE;
NerdGlasses.key = KEY;
NerdGlasses.paletteNames = PALETTE_NAMES;
