import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "Stylish";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Glass";
const PALETTE_NAME_2: PaletteName = "Frame";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const Stylish: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Stylish, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Stylish, PALETTE_NAME_2).colors;
  }, [palettes]);
  const glassesColor = useMemo(() => palette1[0], [palette1]);
  const frameColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 10H17V11H13V13H12V11H8V10ZM8 13V11H7H6V12H7V13H8ZM9 14H8V13H9V14ZM11 14V15H9V14H11ZM11 14H12V13H11V14ZM14 14H13V13H14V14ZM16 14V15H14V14H16ZM17 13V14H16V13H17ZM17 13H18V11H17V13Z"
        fill={frameColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11H8V13H9V14H11V13H12V11ZM17 11H13V13H14V14H16V13H17V11Z"
        fill={glassesColor}
      />
    </svg>
  );
};

Stylish.layerType = LAYER_TYPE;
Stylish.gender = GENDER;
Stylish.title = TITLE;
Stylish.key = KEY;
Stylish.paletteNames = PALETTE_NAMES;
