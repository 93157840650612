import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Upturned";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Upturned: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Upturned, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3H14V4H15V5H16V6H17V11H16V9H15V8H10V9H8V10H7V11H6V7H7V6H8V5H10V4H12V3Z"
      fill={hairColor}
    />
  </svg>
  );
};

Upturned.allowSemiTransparentColors = false;
Upturned.layerType = LAYER_TYPE;
Upturned.gender = GENDER;
Upturned.title = TITLE;
Upturned.key = KEY;
Upturned.paletteNames = PALETTE_NAMES;
Upturned.blacklist = [ApeMan, AlienMan]
