export const PALETTES = {
  Background: {
    defaultIndex: 5,
    colors: [
      ["#D6E5E4"],
      ["#CCE8D5"],
      ["#ced4da"],
      ["#cccdce"],
      ["#F2E7EA"],
      ["#EAD7EB"],
      ["#F9E3C2"],
      ["#dec5a8"],
      ["#FFC897"],
      ["#C08569"],
      ["#7B4946"],
      ["#E4E4A8"],
      ["#FFE881"],
      ["#f8d655"],
      ["#CDF871"],
      ["#C2DC94"],
      ["#BDBB88"],
      ["#717234"],
      ["#B4FFC6"],
      ["#17E6B7"],
      ["#F5BBB2"],
      ["#F8ADB1"],
      ["#FFC1DF"],
      ["#FFA4D4"],
      ["#F871B2"],
      ["#F36C6C"],
      ["#EB3D49"],
      ["#DBC2FC"],
      ["#E490FC"],
      ["#A4A4F4"],
      ["#C28ED1"],
      ["#7979D3"],
      ["#46436F"],
      ["#7698B1"],
      ["#B0E4FF"],
      ["#80B1FF"],
      ["#4C68C2"],
      ["#2151F5"],
      ["#A2A2A2"],
      ["#5F6066"],
      ["#98A79B"],
      ["#A59399"],
      ["#7E647F"],
      ["#53786B"],
      ["#3A677D"],
      ["#2B335A"],
      ["#21373B"],
      ["#1C1D20"],
    ],
  },
  Space: {
    defaultIndex: 4,
    colors: [
      ["#e5cbca", "#f2e7ea"],
      ["#f4cd7c", "#ffe375"],
      ["#ffa4d4", "#ffc5e6"],
      ["#6cb9ff", "#8fcfff"],
      ["#A3A6EE", "#C4C6FB"],
      ["#84ffc2", "#c7ffdf"],

    ],
  },
  "Human Skin": {
    defaultIndex: 3,
    colors: [
      ["#ffffff"],
      ["#ffeedc"],
      ["#fedcbd"],
      ["#FDC6A2"],
      ["#EBBBA0"],
      ["#E5BCA2"],
      ["#efb4b4"],
      ["#D49C7C"],
      ["#e9b07d"],
      ["#FDC581"],
      ["#ffe894"],
      ["#DA9556"],
      ["#b3907d"],
      ["#a36952"],
      ["#915533"],
      ["#5D3C2D"],
    ],
  },
  "Ape Skin": {
    defaultIndex: 1,
    colors: [
      ["#f5eee9", "#e3c8a1"],
      ["#e8a88a", "#ffecd3"],
      ["#AA7D2B", "#DAB87A"],
      ["#83633C", "#C2996A"],
      ["#624428", "#C2996A"],
      ["#36250F", "#856F56"],
      ["#91201A", "#DFC8A6"],
      ["#1E56A0", "#DFC8A6"],
      ["#3B2859", "#C992DE"],
      ["#F1DF81", "#F7F4CD"],
      ["#ffa000", "#fce300"],
      ["#E89B9F", "#EAD9D9"],
      ["#f7f5c9", "#e3c8a1"],
      ["#898889", "#e8c69c"],
      ["#57544F", "#8C8882"],
      ["#3F403F", "#D1DA8E"],
      ["#EB8DEC", "#F0D6AE", "#3B43ED", "#99F7A2", "#3B43ED", "#F7ED8D"],
      ["#637C4D", "#F0D6AE", "#6C130A", "#6C130A", "#AD6661", "#AD6661"],
      ["#3C444D", "#F0D6AE", "#3C444D", "#7F8FA4", "#7F8FA4", "#7F8FA4"],
      ["#FE015C", "#E4C8A1", "#FE015C", "#15CB6B", "#FFFFFF", "#FE015C"],

    ],
  },
  "Alien Skin": {
    defaultIndex: 0,
    colors: [
      ["#bdf0ff", "#9ae3ff"],
      ["#C8FBFB", "#9AE0E0"],
      ["#79FFFF", "#65CFCF"],
      ["#98D9D9", "#53A8A8"],
      ["#57BEC2", "#389FA3"],
      ["#ffc2df", "#ffa4d4"],
      ["#e78db8", "#c2558a"],
    ],
  },
  "Zombie Skin": {
    defaultIndex: 0,
    colors: [
      ["#89B586"],
      ["#c8e097"],
      ["#9CAB72"],
      ["#7CA269"],
      ["#4AB373"],
      ["#55DD7F"],
      ["#90EE90"],
      ["#8C8882"],
    ],
  },
  Nose: {
    defaultIndex: 5,
    colors: [
      ["#b122224f"],
      ["#00000020"],
      ["#00000040"],
      ["#00000060"],
      ["#00000080"],
      ["#000000"],
    ],
  },
  Glass: {
    defaultIndex: 7,
    colors: [
      ["#FFFFFF80"],
      ["#FFFFFF"],
      ["#BDF81280"],
      ["#D2FB51"],
      ["#ffa4d480"],
      ["#FF4F4F80"],
      ["#a4a4f4"],
      ["#2E6FF560"],
      ["#73B2B180"],
      ["#55555580"],
      ["#00000080"],
      ["#000000"],
    ],
  },
  Frame: {
    defaultIndex: 9,
    colors: [
      ["#FFFFFF"],
      ["#ffe780"],
      ["#FF4F4F"],
      ["#06D2FF"],
      ["#74E3E7"],
      ["#664E31"],
      ["#7C6363"],
      ["#5F6066"],
      ["#323236"],
      ["#000000"],
    ],
  },
  Clothes: {
    defaultIndex: 7,
    colors: [
      ["#FFFFFF"],
      ["#e5d2d2"],
      ["#d0b6b5"],
      ["#ffe780"],
      ["#BDF812"],
      ["#a4a4f4"],
      ["#80b1ff"],
      ["#338DE1"],
      ["#153e66"],
      ["#9D5E5E"],
      ["#E490FC"],
      ["#ff4f4f"],
      ["#86ebbd"],
      ["#7c9b79"],
      ["#449392"],
      ["#ffa4d4"],
      ["#ffac99"],
      ["#5F6066"],
      ["#323236"],
      ["#000000"],
    ],
  },
  Shirt: {
    defaultIndex: 0,
    colors: [
      ["#FFFFFF"],
      ["#e5d2d2"],
      ["#d0b6b5"],
      ["#ffe780"],
      ["#BDF812"],
      ["#a4a4f4"],
      ["#80b1ff"],
      ["#338DE1"],
      ["#153e66"],
      ["#9D5E5E"],
      ["#E490FC"],
      ["#ff4f4f"],
      ["#86ebbd"],
      ["#7c9b79"],
      ["#449392"],
      ["#ffa4d4"],
      ["#ffac99"],
      ["#5F6066"],
      ["#323236"],
      ["#000000"],
    ],
  },
  Beard: {
    defaultIndex: 18,
    colors: [
      ["#FEEEE4"],
      ["#DDDDDD"],
      ["#d8bebf"],
      ["#feefb8"],
      ["#F3E2BC"],
      ["#F6D276"],
      ["#dab3ff"],
      ["#b8fccf"],
      ["#fea6a6"],
      ["#99e2ff"],
      ["#FD9FF3"],
      ["#9E3535"],
      ["#e8a68b"],
      ["#B47649"],
      ["#634A38"],
      ["#5a4b42"],
      ["#64553E"],
      ["#1E1E1E"],
      ["#000000"],
      ["#00000020"],
      ["#00000040"],
    ],
  },
  Hair: {
    defaultIndex: 20,
    colors: [
      ["#FEEEE4"],
      ["#DDDDDD"],
      ["#d8bebf"],
      ["#feefb8"],
      ["#F3E2BC"],
      ["#F6D276"],
      ["#EE9020"],
      ["#dab3ff"],
      ["#b8fccf"],
      ["#fea6a6"],
      ["#99e2ff"],
      ["#FD9FF3"],
      ["#9E3535"],
      ["#ff4f4f"],
      ["#e8a68b"],
      ["#B47649"],
      ["#634A38"],
      ["#5a4b42"],
      ["#64553E"],
      ["#1E1E1E"],
      ["#000000"],
      ["#00000020"],
      ["#00000040"],
    ],
  },
  Holder: {
    defaultIndex: 0,
    colors: [
      ["#FFFFFF"],
      ["#DFDFDF"],
      ["#F3B1B1"],
      ["#FFD926"],
      ["#FE63A4"],
      ["#06D2FF"],
      ["#b8fccf"],
    ],
  },
  Mouth: {
    defaultIndex: 6,
    colors: [
      ["#FFFFFF"],
      ["#e3aeae"],
      ["#833b3b"],
      ["#692e08"],
      ["#00000020"],
      ["#00000040"],
      ["#000000"],
    ],
  },
  Eyes: {
    defaultIndex: 9,
    colors: [
      ["#89977e"],
      ["#6e9e4b"],
      ["#8bb5db"],
      ["#4078c0"],
      ["#853603"],
      ["#626262"],
      ["#758a9d"],
      ["#1F97F3"],
      ["#785547"],
      ["#000000"],
    ],
  },
  Sclera: {
    defaultIndex: 2,
    colors: [["#FFFFFF80"], ["#FFFFFF90"], ["#FFFFFF"]],
  },
  "Laser Eyes": {
    defaultIndex: 0,
    colors: [["#ffffff"], ["#FFE44C"], ["#f34d1d"], ["#06D2FF"],],
  },
  MakeUp: {
    defaultIndex: 8,
    colors: [
      ["#ffe881"],
      ["#C9B57D"],
      ["#F5BCBC"],
      ["#EC9586"],
      ["#AA76ED"],
      ["#74E3E7"],
      ["#6E8AEC"],
      ["#67B787"],
      ["#ED76BD"],
      ["#BF008A"],
      ["#FF5555"],
      ["#CB344C"],
      ["#da2427"],
      ["#5D3435"],
    ],
  },
  Brow: {
    defaultIndex: 10,
    colors: [
      ["#ffe881"],
      ["#C9B57D"],
      ["#F5BCBC"],
      ["#AA76ED"],
      ["#74E3E7"],
      ["#FF5555"],
      ["#CB344C"],
      ["#ED76BD"],
      ["#67B787"],
      ["#6E8AEC"],
      ["#00000015"],
    ],
  },
  Mask: {
    defaultIndex: 0,
    colors: [
      ["#DFDFDF"],
      ["#F3B1B1"],
      ["#06D2FF"],
    ],
  },
  "Zombie Eyes": {
    defaultIndex: 0,
    colors: [
      ["#C54424"],
      ["#FFFFFF"],
      ["#000000"],
      ["#FEFF40"],
    ],
  },
  Jewelry: {
    defaultIndex: 0,
    colors: [
      ["#FFD926"],
      ["#DCAB61"],
      ["#DFDFDF"],
      ["#F3B1B1"],
      ["#FE63A4"],
      ["#06D2FF"],
      ["#69E074"],
    ],
  },
  Cap: {
    defaultIndex: 1,
    colors: [
      ["#FFFFFF"],
      ["#000000"],
      ["#323236"],
      ["#338de1"],
      ["#9A50D4"],
      ["#F2D742"],
      ["#da1d1d"]
    ],
  },
  Fur: {
    defaultIndex: 1,
    colors: [
      ["#FFFFFF", "#EEEEEE"],
      ["#9A50D4", "#531486"],
      ["#DD7290", "#FF9987"],
      ["#A56E3F", "#5C3E24"],
      ["#2F2F2F", "#000000"],
    ],
  },
  Check: {
    defaultIndex: 0,
    colors: [
      ["#1c9bf0", "#FFFFFF"],
      ["#000000", "#FFFFFF"],
      ["#000000", "#1c9bf0"],
      ["#000000", "#e2b819"],
      ["#000000", "#839aac"],
      ["#111111", "#1f1f1f"],
      ["#1e2024", "#282a2f"],
      ["#FFFFFF", "#1c9bf0"],
      ["#FFFFFF", "#e2b819"],
      ["#FFFFFF", "#839aac"],
      ["#638596", "#aac8d8"],

    ],
  },
  Hat: {
    defaultIndex: 8,
    colors: [
      ["#DC8484", "#CF6D6D"],
      ["#FF4F4F", "#DA1D1D"],
      ["#F7CAF4", "#DAA0DB"],
      ["#FFE9C0", "#EBC070"],
      ["#E7AC68", "#D1924A"],
      ["#E17F33", "#C76B2D"],
      ["#7B9C79", "#628061"],
      ["#70C0E2", "#2BA0D2"],
      ["#338DE1", "#2671B7"],
      ["#7E7CED", "#6c69c9"],
      ["#555555", "#353535"],
    ],
  },
  Laser: {
    defaultIndex: 1,
    colors: [
      ["#FFFFFF"],
      ["#FFD84F60"],
      ["#4FDFFF60"],
      ["#FF559C60"],
      ["#55FF9960"],
    ],
  },
  Bubble: {
    defaultIndex: 0,
    colors: [["#F7ACF4"], ["#F7ACAC"], ["#ACF7AF"], ["#7DE8FF"]],
  },
  Dog: {
    defaultIndex: 1,
    colors: [["#FFFFFF"], ["#e0d5c6"], ["#6d6d6d"], ["#705f4e"], ["#363636"]],
  },
} as const;

export type PaletteName = keyof typeof PALETTES;
export type Palette = typeof PALETTES[PaletteName]["colors"];
