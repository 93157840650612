import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Woman";
const TITLE = "VR";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const VR: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="8" y="12" width="9" height="4" fill="#B4B4B4" />
      <path
        d="M17 11H8V12H7V13H8V12H17V16H8V15H7V16H8V17H17V16H18V12H17V11Z"
        fill="black"
      />
      <path d="M16 13H9V15H16V13Z" fill="black" />
      <path d="M8 12H9V13H8V12Z" fill="#8D8D8D" />
      <path d="M8 15H7V13H8V15Z" fill="#8D8D8D" />
      <path d="M8 15H9V16H8V15Z" fill="#8D8D8D" />
      <path d="M16 15H17V16H16V15Z" fill="#8D8D8D" />
      <path d="M17 12H16V13H17V12Z" fill="#8D8D8D" />
    </svg>
  );
};

VR.layerType = LAYER_TYPE;
VR.gender = GENDER;
VR.title = TITLE;
VR.key = KEY;
VR.paletteNames = PALETTE_NAMES;
