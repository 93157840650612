import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Police";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Police: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6" y="5" width="11" height="3" fill="#26314A" />
      <rect x="12" y="6" width="2" height="2" fill="#9FA6B5" />
      <rect x="7" y="8" width="1" height="1" fill="#F3BD46" />
      <rect x="8" y="8" width="8" height="1" fill="#161D2C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 4H11V5H6V6H5V8H6V9V10V11H19V10H18V9H17V8H18V6H17V5H15V4ZM15 5V6H17V8H16V9H7V8H6V6H11V5H15Z"
        fill="black"
      />
    </svg>
  );
};

Police.layerType = LAYER_TYPE;
Police.gender = GENDER;
Police.title = TITLE;
Police.key = KEY;
Police.paletteNames = PALETTE_NAMES;
