import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Cap Ryze";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapRyze: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 8H20V9H6V6H7V5H8V4H15V5H16V6H17V8Z" fill="black" />
      <path d="M13.125 5H11.25V5.375H11.625V5.75H12V6.125H12.375V6.5H12.75V6.875H11.625V7.25H12V7.625H12.375V8H13.5V7.625H13.125V7.25H12.75V6.875H13.125V6.5H13.5V5.375H13.125V5Z" fill="white" />
    </svg>
  );
};

CapRyze.layerType = LAYER_TYPE;
CapRyze.gender = GENDER;
CapRyze.title = TITLE;
CapRyze.key = KEY;
CapRyze.paletteNames = PALETTE_NAMES;
CapRyze.isBranded = true
CapRyze.price = 0.01;
