import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Pigtails";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAME_2: PaletteName = "Holder";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const Pigtails: LayerFC = (props) => {
  const palettes = usePalettes();

  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Pigtails, PALETTE_NAME_1).colors;
  }, [palettes]);

  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Pigtails, PALETTE_NAME_2).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  const holderColor = useMemo(() => palette2[0], [palette2]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 5H9V6H8V8H7V7H6V6H5H4V7H3V8H2V12H3V13H4V12H5V10H6V9H7V12H8V11H9V10H10V9H15V10H16V11H17V9H18V10H19V12H20V13H21V12H22V8H21V7H20V6H19H18V7H17V8H16V6H15V5H10Z"
        fill={hairColor}
      />
      <path d="M7 7H8V8H7V7Z" fill={holderColor} />
      <path d="M16 7H17V8H16V7Z" fill={holderColor} />
    </svg>
  );
};

Pigtails.allowSemiTransparentColors = false;
Pigtails.layerType = LAYER_TYPE;
Pigtails.gender = GENDER;
Pigtails.title = TITLE;
Pigtails.key = KEY;
Pigtails.paletteNames = PALETTE_NAMES;
Pigtails.blacklist = [ApeWoman, AlienWoman]