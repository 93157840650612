import { useCallback, useState } from "react";
import { singletonHook } from "react-singleton-hook";
import { layerFCs } from "../components/layerFCs";
import { LayerFC } from "../components/layerFCs/types/LayerFC";
import { PaletteName, Palette, PALETTES } from "../constants/Palettes";

type SelectedColors = Record<string, readonly string[]>;

const DIVIDER = "|";

// export const keyForLayerFCPaletteName = (
//   layerFCKey: string,
//   paletteName: PaletteName
// ) => [layerFCKey, paletteName].join(DIVIDER);

export const keyForLayerFCPaletteName = (
  layerFCKey: string,
  paletteName: PaletteName
) => [layerFCKey.split("-")[0], paletteName].join(DIVIDER);

interface PalettesLoadingState {
  isLoading: true;
}

type GetColorForLayerFC = (
  layerFC: LayerFC,
  paletteName: PaletteName
) => { key: string; colors: readonly string[] };

type SelectPalette = (layerFC?: LayerFC, paletteName?: PaletteName) => void;
type ChangeSelectedColors = (key: string, colors: readonly string[]) => void;

interface PalettesLoadedState {
  isLoading: false;
  selectedKey: string | undefined;
  selectedLayerFCKey: string | undefined;
  selectPalette: SelectPalette;
  selectedPalette: Palette | undefined;
  selectedColors: SelectedColors;
  selectedPaletteName: string | undefined;
  getColorForLayerFC: GetColorForLayerFC;
  changeSelectedColors: ChangeSelectedColors;
}

export const usePalettes = singletonHook(
  { isLoading: true },
  (): PalettesLoadingState | PalettesLoadedState => {
    const defaultSelectedColors: SelectedColors = layerFCs.reduce(
      (acc, layerFC) => {
        layerFC.paletteNames.forEach((paletteName) => {
          acc[keyForLayerFCPaletteName(layerFC.key, paletteName)] =
            PALETTES[paletteName].colors[PALETTES[paletteName].defaultIndex]!;
        });
        return acc;
      },
      {} as SelectedColors
    );

    const [selectedColors, setSelectedColors] = useState<SelectedColors>(
      defaultSelectedColors
    );
    const [selectedPalette, setSelectedPalette] = useState<
      Palette | undefined
    >();
    const [selectedLayerFCKey, setSelectedLayerFCKey] = useState<
      string | undefined
    >();
    const getColorForLayerFC = useCallback(
      (layerFC: LayerFC, paletteName: PaletteName) => ({
        key: keyForLayerFCPaletteName(layerFC.key, paletteName),
        colors:
          selectedColors[keyForLayerFCPaletteName(layerFC.key, paletteName)],
      }),
      [selectedColors]
    );

    const [selectedKey, setSelectedKey] = useState<string | undefined>();
    const [selectedPaletteName, setSelectedPaletteName] = useState<
      string | undefined
    >();
    const selectPalette: SelectPalette = useCallback((layerFC, paletteName) => {
      if (paletteName && layerFC) {
        setSelectedKey(keyForLayerFCPaletteName(layerFC.key, paletteName));
        setSelectedPalette(PALETTES[paletteName].colors);
        setSelectedPaletteName(paletteName);
        setSelectedLayerFCKey(layerFC.key);
      } else {
        setSelectedKey(undefined);
        setSelectedPalette(undefined);
        setSelectedPaletteName(undefined);
        setSelectedLayerFCKey(undefined);
      }
    }, []);

    const changeSelectedColors: ChangeSelectedColors = useCallback(
      (key, colors) => {
        if (selectedColors[key].join("|") !== colors.join("|")) {
          selectedColors[key] = colors;
          setSelectedColors({
            ...selectedColors,
            ...{
              [key]: colors,
            },
          });
        }
      },
      [selectedColors]
    );

    return {
      isLoading: false,
      selectedKey,
      selectPalette,
      selectedPalette,
      selectedLayerFCKey,
      selectedColors,
      selectedPaletteName,
      getColorForLayerFC,
      changeSelectedColors,
    };
  }
);
