import { LayerFC } from "../../types/LayerFC";
import { Cap } from "./Cap";
import { Bandana } from "./Bandana";
import { BeenieBubble } from "./BeenieBubble";
import { BeenieL } from "./BeenieL";
import { BeenieM } from "./BeenieM";
import { CapBackward } from "./CapBackward";
import { CapBackwardOpen } from "./CapBackwardOpen";
import { CapDiamond } from "./CapDiamond";
import { CapForward } from "./CapForward";
import { CapNewbie } from "./CapNewbie";
import { Crown } from "./Crown";
import { Hoodie } from "./Hoodie";
import { Police } from "./Police";
import { CapDesigner } from "./CapDesigner";
import { CapCheck } from "./CapCheck";
import { CapLayer3 } from "./CapLayer3";
import { CapTCG } from "./CapTCG";
import { CapC1 } from "./CapC1";
import { BlueCap } from "./BlueCap";
import { CapBackwardColor } from "./CapBackwardColor";
import { CapRainbow } from "./CapRainbow";
import { CapKevin } from "./CapKevin";
import { CapNouns } from "./CapNouns";
import { CapRyze } from "./CapRyze";
import { CapES } from "./CapES";

export const hatWomanLayers: LayerFC[] = [
  Bandana,
  BeenieBubble,
  BeenieL,
  BeenieM,
  Crown,
  Hoodie,
  Police,
  CapBackward,
  CapBackwardOpen,
  CapBackwardColor,
  CapForward,
  CapNewbie,
  Cap,
  BlueCap,
  CapDiamond,
  CapDesigner,
  CapCheck,
  CapRainbow,
  CapRyze,
  CapLayer3,
  CapTCG,
  CapC1,
  CapES,
  CapNouns,
  CapKevin,
];
