import { FC, useMemo } from "react";
import styled from "styled-components";
import { COLOR_MAP } from "../constants/Color";
import { BannerType, useBanner } from "../hooks/useBanner";
import { Text } from "./Text";

interface BannerProps {
  className?: string;
}

interface BannerWrapperProps {
  $type: BannerType;
  $isHidden: boolean;
}

const BannerWrapper = styled.div<BannerWrapperProps>`
  position: fixed;
  top: ${({ $isHidden }) => ($isHidden ? "-100px" : "0")};
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: top 0.2s ease-in-out;
  background-color: ${({ $type }) =>
    $type === "error" ? COLOR_MAP.error : COLOR_MAP.primary};
  color: ${({ $type }) =>
    $type === "error" ? COLOR_MAP.white : COLOR_MAP.black};
  a {
    color: ${({ $type }) =>
      $type === "error" ? COLOR_MAP.white : COLOR_MAP.black};
  }
`;

export const Banner: FC<BannerProps> = (props) => {
  const bannerHander = useBanner();

  const bannerType: BannerType = useMemo(() => {
    if (bannerHander.isLoading) {
      return "info";
    }
    return bannerHander.banner?.type || "info";
  }, [bannerHander]);

  const bannerContent = useMemo(() => {
    if (bannerHander.isLoading) {
      return;
    }
    return bannerHander.banner?.content;
  }, [bannerHander]);

  return (
    <BannerWrapper
      className={props.className}
      $isHidden={bannerHander.isLoading || !bannerHander.banner}
      $type={bannerType}
    >
      <Text $fontSize="small">{bannerContent}</Text>
    </BannerWrapper>
  );
};
