import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Dreads";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAME_2: PaletteName = "Holder";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const Dreads: LayerFC = (props) => {
  const palettes = usePalettes();

  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Dreads, PALETTE_NAME_1).colors;
  }, [palettes]);

  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Dreads, PALETTE_NAME_2).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  const holderColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 5H8V6H7H6V7H5V8H4V12H5V15H6V12H7V10H8V14H9V10H10V9H13V10H14V9H15V10H16V14H17V11H18V8H17V7H16V6H15V5Z"
        fill={hairColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 10H13V11H14V10ZM17 11H18V12H17V11ZM4 12H5V13H4V12ZM9 14H8V15H9V14ZM17 14H16V15H17V14ZM6 15H5V16H6V15Z"
        fill={holderColor}
      />
    </svg>
  );
};

Dreads.allowSemiTransparentColors = false;
Dreads.layerType = LAYER_TYPE;
Dreads.gender = GENDER;
Dreads.title = TITLE;
Dreads.key = KEY;
Dreads.paletteNames = PALETTE_NAMES;
Dreads.blacklist = [ApeMan, AlienMan]
