import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Clothes";
const GENDER: Gender = "Woman";
const TITLE = "Fur";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Fur: LayerFC = (props) => {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9 21H8V22H9V24H10V22H9V21ZM12 22H13V24H12V22Z" fill="#E6D5C7"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9 22H6V24H9V22ZM14 22H13V24H14V22Z" fill="#A56E3F"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7 21H8V22H7V21ZM7 23V22H6V23H5V24H6V23H7ZM8 23V24H7V23H8ZM8 23H9V22H8V23ZM13 22H14V23H13V22ZM14 23V24H15V23H14Z" fill="#5C3E24"/>
    </svg>
  );
};

Fur.layerType = LAYER_TYPE;
Fur.gender = GENDER;
Fur.title = TITLE;
Fur.key = KEY;
Fur.paletteNames = PALETTE_NAMES;
