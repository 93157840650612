import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { AlienMan } from "../type/AlienMan";
import { ApeMan } from "../type/ApeMan";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Beard";
const GENDER: Gender = "Man";
const TITLE = "Shaved Chin";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Beard";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const ShavedChin: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(ShavedChin, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16H7V19H8V20H9V21H15V20H16V19H15V20H13V19H12V20H10V19H9V18H8V16Z"
        fill={hairColor}
      />
    </svg>
  );
};

ShavedChin.layerType = LAYER_TYPE;
ShavedChin.gender = GENDER;
ShavedChin.title = TITLE;
ShavedChin.key = KEY;
ShavedChin.paletteNames = PALETTE_NAMES;
ShavedChin.blacklist = [ApeMan, AlienMan]
