import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Long 5";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Long5: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Long5, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6H12H14H15V7H16V8H17V12H16V11H15V10H14V11H13V12H11V11H12V10H11V11H9V12H8V13H7V12H6V10H7V8H8V7H9V6H11ZM7 15H6V24H7H8V19H7V15Z"
        fill={hairColor}
      />
    </svg>
  );
};

Long5.allowSemiTransparentColors = false;
Long5.layerType = LAYER_TYPE;
Long5.gender = GENDER;
Long5.title = TITLE;
Long5.key = KEY;
Long5.paletteNames = PALETTE_NAMES;
Long5.blacklist = [ApeWoman, AlienWoman]
