import { LayerFC } from "../types/LayerFC";
import { glassesManLayers } from "./man";
import { glassesWomanLayers } from "./woman";
import { None } from "./None";

export const glassesLayers: LayerFC[] = [
  None,
  ...glassesManLayers,
  ...glassesWomanLayers,
];
