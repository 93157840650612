import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Woman";
const TITLE = "Sport";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const SportGlasses: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="7" y="12" width="10" height="1" fill="black" />
      <path d="M13 13H17V14H13V13Z" fill="black" />
      <path d="M13 14H16V15H13V14Z" fill="black" />
      <path d="M8 13H12V14H8V13Z" fill="black" />
      <path d="M9 14H12V15H9V14Z" fill="black" />
    </svg>
  );
};

SportGlasses.layerType = LAYER_TYPE;
SportGlasses.gender = GENDER;
SportGlasses.title = TITLE;
SportGlasses.key = KEY;
SportGlasses.paletteNames = PALETTE_NAMES;
