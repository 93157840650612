import { availableLayerFCs } from "../components/layerFCs";
import { None } from "../components/layerFCs/special/None";
import { LayerFC } from "../components/layerFCs/types/LayerFC";
import { LAYER_TYPES } from "../components/layerFCs/types/LayerType";
import { layerFCsGender } from "./layerFCsGender";

export const MAX_SPECIAL_LAYERS = 3;

export const layerFCsCleanup = (layerFCs: LayerFC[]): LayerFC[] => {
  let index = 0;
  const gender = layerFCsGender(layerFCs);
  const specialLayerFCs = layerFCs.filter(
    (layerFC) => layerFC.layerType === "Special"
  );

  // console.log(layerFCs.map((layerFC) => layerFC.key));

  // Special layers if none is selected
  if (
    specialLayerFCs.length &&
    specialLayerFCs[specialLayerFCs.length - 1].title === "None"
  ) {
    // console.log("Special layers if none is selected");
    return layerFCsCleanup(
      layerFCs.filter((layerFC) => layerFC.layerType !== "Special")
    );
  }

  // Ensure every layer extension is only used once (excluding Special)
  for (const layerType of LAYER_TYPES) {
    if (layerType === "Special") {
      continue;
    }
    const layerFCsOfLayerType = layerFCs.filter(
      (layerFC) => layerFC.layerType === layerType && !layerFC.isExtension
    );
    const layerExtensionFCsOfLayerType = layerFCs.filter(
      (layerFC) => layerFC.layerType === layerType && layerFC.isExtension
    );
    if (
      layerExtensionFCsOfLayerType.length > 1 ||
      (layerExtensionFCsOfLayerType.length === 1 &&
        layerFCsOfLayerType.length === 0)
    ) {
      // console.log(
      //   "Ensure every layer extension is only used once (excluding Special)"
      // );
      return layerFCsCleanup(
        layerFCs.filter(
          (layerFC) => layerFC.key !== layerExtensionFCsOfLayerType[0].key
        )
      );
    }
  }

  // Ensure every key is only used once
  for (const layerFC of layerFCs) {
    const layerFCsOfKey = layerFCs.filter((l) => l.key === layerFC.key);
    if (layerFCsOfKey.length > 1) {
      // console.log("Ensure every key is only used once");
      return layerFCsCleanup(layerFCs.filter((l) => l.key !== layerFC.key));
    }
  }

  // Ensure every layer is only used once (excluding Special)
  for (const layerType of LAYER_TYPES) {
    if (layerType === "Special" || layerType === "Hat") {
      continue;
    }
    const layerFCsOfLayerType = layerFCs.filter(
      (layerFC) =>
        (layerFC.layerType === "Hat" ? "Hair" : layerFC.layerType) ===
          layerType && !layerFC.isExtension
    );
    if (layerFCsOfLayerType.length > 1) {
      // console.log("Ensure every layer is only used once (excluding Special)");
      return layerFCsCleanup(
        layerFCs.filter(
          (layerFC) =>
            layerFC.key !== layerFCsOfLayerType[0].key || layerFC.isExtension
        )
      );
    }
  }

  // Remove 'None' layers
  const noneLayers = layerFCs.filter((layerFC) => layerFC.title === "None");
  if (noneLayers.length === 1) {
    // console.log("Remove 'None' layers");
    return layerFCsCleanup(
      layerFCs.filter((layerFC) => layerFC.title !== "None")
    );
  }

  // Ensure the current combination is valid
  while (index < layerFCs.length) {
    const layerFC = layerFCs[index];
    const availableLayerFCsForLayer = availableLayerFCs({
      gender,
      layerType: layerFC.layerType,
      selectedLayerFCs: layerFCs,
      isExtension: layerFC.isExtension,
    });
    if (
      !availableLayerFCsForLayer.some(
        (availableLayerFC) => availableLayerFC.key === layerFC.key
      )
    ) {
      // console.log("Ensure the current combination is valid");
      return layerFCsCleanup([
        ...layerFCs.slice(0, index),
        ...layerFCs.slice(index + 1),
      ]);
    } else {
      index += 1;
    }
  }

  // Remove special layer 'None' of some other layer is selected
  if (
    specialLayerFCs.length > 1 &&
    specialLayerFCs.some((layerFC) => layerFC.key === "None")
  ) {
    // console.log("Remove special layer 'None of some other layer is selected");
    return layerFCsCleanup(
      layerFCs.filter((layerFC) => layerFC.key !== None.key)
    );
  }

  // Limit Special layers to MAX_SPECIAL_LAYERS
  if (specialLayerFCs.length > MAX_SPECIAL_LAYERS) {
    // console.log(`Limit Special layers to ${MAX_SPECIAL_LAYERS}`);
    return layerFCsCleanup(
      layerFCs.filter((layerFC) => layerFC.key !== specialLayerFCs[0].key)
    );
  }

  return layerFCs;
};
