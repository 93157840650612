import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Short Wild";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const ShortWild: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(ShortWild, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4H10V5H8V6H7V7H6V10H7H8V9H9V10H10V9H11V8H12V9H13V10H14V9H13V8H15V9H16V10H17V7V6H16V5H14V4Z"
        fill={hairColor}
      />
    </svg>
  );
};

ShortWild.allowSemiTransparentColors = false;
ShortWild.layerType = LAYER_TYPE;
ShortWild.gender = GENDER;
ShortWild.title = TITLE;
ShortWild.key = KEY;
ShortWild.paletteNames = PALETTE_NAMES;
ShortWild.blacklist = [ApeMan, AlienMan]
