import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Beenie L";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hat";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const BeenieL: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(BeenieL, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hatColor = useMemo(() => palette1[0], [palette1]);
  const secondaryhatColor = useMemo(() => palette1[1], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 10H17V7H16V5H14V4H9V5H7V7H6V10Z" fill={hatColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 7H6V8V9H7V10H8V9H9V10H10V9H11V10H12V9H13V10H14V9H15V10H16V9H17V8V7ZM16 9H15V8H16V9ZM13 8H14V9H13V8ZM12 8V9H11V8H12ZM10 9H9V8H10V9ZM8 9H7V8H8V9Z"
        fill={secondaryhatColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3H14V4H9V3ZM7 5V4H9V5H7ZM6 7V5H7V7H6ZM6 7V10H5V7H6ZM16 5H14V4H16V5ZM17 7V5H16V7H17ZM17 7V10H18V7H17Z"
        fill="black"
      />
    </svg>
  );
};

BeenieL.allowSemiTransparentColors = false;
BeenieL.layerType = LAYER_TYPE;
BeenieL.gender = GENDER;
BeenieL.title = TITLE;
BeenieL.key = KEY;
BeenieL.paletteNames = PALETTE_NAMES;
