import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Pigtails S";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAME_2: PaletteName = "Holder";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const PigtailsS: LayerFC = (props) => {
  const palettes = usePalettes();

  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(PigtailsS, PALETTE_NAME_1).colors;
  }, [palettes]);

  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(PigtailsS, PALETTE_NAME_2).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  const holderColor = useMemo(() => palette2[0], [palette2]);
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 6H7V7H9V6H15V7H17V6H19V7H20V8H21V11H20V13H19V17H18V9H17V11H16V10H15V9H10V10H9V11H8V12H7V9H6V18H5V13H4V11H3V8H4V7H5V6Z" fill={hairColor} />
      <path d="M5 18V19H4V18H5Z" fill={hairColor} />
      <path d="M19 17H20V18H19V17Z" fill={hairColor} />
      <path fillRule="evenodd" clip-rule="evenodd" d="M8 7H7V8H6V9H7V8H8V7ZM16 7H17V8H16V7ZM17 8V9H18V8H17Z" fill={holderColor} />
    </svg>
  );
};

PigtailsS.allowSemiTransparentColors = false;
PigtailsS.layerType = LAYER_TYPE;
PigtailsS.gender = GENDER;
PigtailsS.title = TITLE;
PigtailsS.key = KEY;
PigtailsS.paletteNames = PALETTE_NAMES;
PigtailsS.blacklist = [ApeWoman, AlienWoman]