import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienMan } from "../../type/AlienMan";
import { ApeMan } from "../../type/ApeMan";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Man";
const TITLE = "Short Cut";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const ShortCut: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(ShortCut, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 5H8V6H7V7H6V8V9V10V11H7V10H9V9H14V8H15V9H16V10H17V9V8V7H16V6H15V5ZM14 8H13V7H14V8Z"
        fill={hairColor}
      />
    </svg>

  );
};

ShortCut.allowSemiTransparentColors = false;
ShortCut.layerType = LAYER_TYPE;
ShortCut.gender = GENDER;
ShortCut.title = TITLE;
ShortCut.key = KEY;
ShortCut.paletteNames = PALETTE_NAMES;
ShortCut.blacklist = [ApeMan, AlienMan];
