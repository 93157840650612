import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Type";
const GENDER: Gender = "Woman";
const TITLE = "Zombie";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Zombie Skin";
const PALETTE_NAME_2: PaletteName = "Nose";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const ZombieWoman: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(ZombieWoman, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(ZombieWoman, PALETTE_NAME_2).colors;
  }, [palettes]);

  const skinColor = useMemo(() => palette1[0], [palette1]);
  const noseColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 24H8V19H7V15H6V12H7V9H8V8H9V7H15V8H16V9H17V19H16V20H15V21H14V22H13V24Z"
        fill={skinColor}
      />
      <rect
        x="12" y="16" width="1" height="1"
        fill={noseColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 7H9V8H8V9H7V12H6V15H7V19H8V24H9V19H8V14H7V12H8V9H9V8H15V9H16V19H15V20H14V21H11V20H10V21H11V22H12V24H13V22H14V21H15V20H16V19H17V9H16V8H15V7Z" 
        fill="#000000"/>
    </svg>
  );
};

ZombieWoman.layerType = LAYER_TYPE;
ZombieWoman.gender = GENDER;
ZombieWoman.title = TITLE;
ZombieWoman.key = KEY;
ZombieWoman.paletteNames = PALETTE_NAMES;
