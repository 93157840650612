import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "Mask";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Mask: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6" y="10" width="10" height="1" fill="black" />
      <rect x="6" y="13" width="10" height="1" fill="black" />
      <rect x="6" y="11" width="3" height="2" fill="black" />

      <rect x="11" y="11" width="3" height="2" fill="black" />
      <rect x="16" y="10" width="1" height="4" fill="black" />
    </svg>
  );
};

Mask.layerType = LAYER_TYPE;
Mask.gender = GENDER;
Mask.title = TITLE;
Mask.key = KEY;
Mask.paletteNames = PALETTE_NAMES;
Mask.zIndex = 25;
