import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Type";
const GENDER: Gender = "Man";
const TITLE = "Human";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Human Skin";
const PALETTE_NAME_2: PaletteName = "Nose";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const Man: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Man, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Man, PALETTE_NAME_2).colors;
  }, [palettes]);

  const skinColor = useMemo(() => palette1[0], [palette1]);
  const noseColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6H8V7H7V12H6V14H7V20V21V24H10V21H15V20H16V7H15V6Z"
        fill={skinColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 5H8V6H7V7H6V12H5V14H6V24H7V14H6V12H7V7H8V6H15V7H16V20H15V21H11H10V24H11V22H15V21H16V20H17V7H16V6H15V5Z"
        fill="black"
      />
      <rect
        x="12"
        y="15"
        width="2"
        height="1"
        fill={noseColor}
      />
    </svg>
  );
};

Man.layerType = LAYER_TYPE;
Man.gender = GENDER;
Man.title = TITLE;
Man.key = KEY;
Man.paletteNames = PALETTE_NAMES;

