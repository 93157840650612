import { FC, useMemo } from "react";
import styled from "styled-components";
import { COLOR_MAP } from "../constants/Color";
import { Icon } from "./Icon";
import { Layer } from "./Layer";
import { availableLayerFCs } from "./layerFCs";
import { Gender } from "./layerFCs/types/Gender";
import { LayerFC } from "./layerFCs/types/LayerFC";
import { LayerType } from "./layerFCs/types/LayerType";

interface LayersProps {
  selectedLayerFCs: LayerFC[];
  onSelectedLayerFCChange: (layerFC: LayerFC) => void;
  layerType: LayerType;
  gender: Gender;
  isExtension?: boolean;
  isBranded?: boolean;
  className?: string;
}

const LayersWrapper = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
`;

const LayersContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 72px);
  grid-template-rows: repeat(auto-fit, 72px);
  flex-wrap: wrap;
  justify-content: center;
  max-width: 552px;
  margin: 0 auto;
  gap: 8px;
`;

const YourBrandWrapper = styled.a`
  background-color: ${`${COLOR_MAP.gray300}33`};
  height: 72px;
`

export const Layers: FC<LayersProps> = (props) => {
  const typeLayerFC = useMemo(
    () =>
      props.selectedLayerFCs.find((layerFC) => layerFC.layerType === "Type")!,
    [props.selectedLayerFCs]
  );

  const layerFCs = useMemo(
    () =>
      availableLayerFCs({
        layerType: props.layerType,
        gender: props.gender,
        selectedLayerFCs: props.selectedLayerFCs,
        isExtension: props.isExtension,
        isBranded: props.isBranded
      }),
    [props]
  );

  const children = useMemo(
    () =>
      layerFCs.map((layerFC) => (
        <Layer
          key={layerFC.key}
          selectedLayerFCs={props.selectedLayerFCs}
          typeLayerFC={typeLayerFC}
          layerFC={layerFC}
          onClick={() => {
            props.onSelectedLayerFCChange(layerFC);
          }}
        />
      )),
    [props, layerFCs, typeLayerFC]
  );

  const title = useMemo(
    () => (!props.isExtension || layerFCs.length ? props.children : null),
    [layerFCs, props]
  );

  const yourBrand = useMemo(() => {
    if (props.layerType === 'Hat' && props.isBranded && !props.isExtension) {
      return (<YourBrandWrapper href="mailto:info@selfmadepunks.com?subject=Branded%20Cap&body=We%20would%20like%20to%20have%20a%20cap%20with%20our%20brand.%0AOur%20brand%20information:%20URL%20or%20IMAGE">
        <Icon type="YourBrand" />
      </YourBrandWrapper>)
    }
  }, [props.layerType, props.isBranded, props.isExtension])

  return (
    <LayersWrapper>
      {title}
      <LayersContent className={props.className}>
        {children}
        {yourBrand}
      </LayersContent>
    </LayersWrapper>
  );
};
