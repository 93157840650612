import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { None } from "../../hat/None";
import { Woman } from "../../type/Woman";
import { ZombieWoman } from "../../type/ZombieWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Braid";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Braid: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Braid, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 6H8V8H7V10H6V9H5V7H6V6Z"
        fill={hairColor}
      />
      <path
        d="M7 11H9V12H8V13H7V11Z"
        fill={hairColor}
      />
      <path
        d="M15 11V12H16V13H17V11H15Z"
        fill={hairColor}
      />
      <path
        d="M14 9H11V10H14V9Z"
        fill={hairColor}
      />
    </svg>
  );
};

Braid.layerType = LAYER_TYPE;
Braid.gender = GENDER;
Braid.allowSemiTransparentColors = false;
Braid.title = TITLE;
Braid.key = KEY;
Braid.isExtension = true;
Braid.paletteNames = PALETTE_NAMES;
Braid.whitelist = [Woman, ZombieWoman];
Braid.blacklist = [None]
