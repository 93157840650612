import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Ocean";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Ocean: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="#4396D5" />
      <rect width="24" height="13" fill="#BBF1FD" />
      <path d="M0 20H24V24H0V20Z" fill="#F6D9B5" />
      <path d="M18 1H15V2H14V3H19V2H18V1Z" fill="white" />
      <path d="M2 2H0V5H6V4H4V3H2V2Z" fill="white" />
      <path d="M24 4H22V5H20V6H18V7H24V4Z" fill="white" />
      <path d="M4 9H7V10H8V11H3V10H4V9Z" fill="white" />
    </svg>
  );
};

Ocean.layerType = LAYER_TYPE;
Ocean.gender = GENDER;
Ocean.title = TITLE;
Ocean.key = KEY;
Ocean.paletteNames = PALETTE_NAMES;
Ocean.price = 0.001;
