import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Middle";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Middle: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Middle, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6H9V7H7V8H6V10H5V12H4V20H5V22H7V23H8V19H7V15H6V12H7V11H8V10H9V9H12V8H14V10H15V11H16H17V19H16V20H15V21H14V22H13V23H14H15H16V22H17V21H18V20H19V18H20V14H19V10H18V9H17V8H16V7H15V6Z"
        fill={hairColor}
      />
    </svg>
  );
};

Middle.allowSemiTransparentColors = false;
Middle.layerType = LAYER_TYPE;
Middle.gender = GENDER;
Middle.title = TITLE;
Middle.key = KEY;
Middle.paletteNames = PALETTE_NAMES;
Middle.blacklist = [ApeWoman, AlienWoman]
