import { PaletteName } from "../../../constants/Palettes";
import { ApeMan } from "../type/ApeMan";
import { Man } from "../type/Man";
import { ZombieMan } from "../type/ZombieMan";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Man";
const TITLE = "Blood Nose";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const BloodNose: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="13" y="16" width="1" height="1" fill="#DE1919"/>
    </svg>
  );
};

BloodNose.layerType = LAYER_TYPE;
BloodNose.gender = GENDER;
BloodNose.title = TITLE;
BloodNose.key = KEY;
BloodNose.paletteNames = PALETTE_NAMES;
BloodNose.whitelist = [Man, ZombieMan, ApeMan];
