import { PaletteName } from "../../../../constants/Palettes";
import { ZombieWoman } from "../../type/ZombieWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Mouth";
const GENDER: Gender = "Woman";
const TITLE = "Zombie";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Zombie: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="11" y="18" width="3" height="1" fill="black" />
      <rect x="11" y="19" width="1" height="1" fill="black" fillOpacity="0.2" />
    </svg>
  );
};

Zombie.layerType = LAYER_TYPE;
Zombie.gender = GENDER;
Zombie.title = TITLE;
Zombie.key = KEY;
Zombie.paletteNames = PALETTE_NAMES;
Zombie.whitelist = [ZombieWoman];
