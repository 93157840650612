import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Cap";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Cap";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Cap: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Cap, PALETTE_NAME_1).colors;
  }, [palettes]);

  const capColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 6V8H20V9H6V6H7V5H8V4H15V5H16V6H17Z" fill={capColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6H14V7H15V8H16V7H15V6Z"
        fill="white"
        fillOpacity="0.2"
      />
    </svg>
  );
};

Cap.layerType = LAYER_TYPE;
Cap.gender = GENDER;
Cap.title = TITLE;
Cap.key = KEY;
Cap.paletteNames = PALETTE_NAMES;
