import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Long 13";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Long13: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Long13, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 7H9V8H8V9H7V10H6V13H5V21H7V22H8V19H7V14H8V13H10V12H11V13H12V11H13V13H16V14H17V19H16V20H15V21H14V22H16V21H18V11H17V9H16V8H15V7Z" fill={hairColor} />
    </svg>
  );
};

Long13.allowSemiTransparentColors = false;
Long13.layerType = LAYER_TYPE;
Long13.gender = GENDER;
Long13.title = TITLE;
Long13.key = KEY;
Long13.paletteNames = PALETTE_NAMES;
Long13.blacklist = [ApeWoman, AlienWoman]