import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Hoodie";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Clothes";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Hoodie: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Hoodie, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hatColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3H13V4H10V3ZM8 5V4H10V5H8ZM7 6V5H8V6H7ZM6 7V6H7V7H6ZM5 8V7H6V8H5ZM4 11V8H5V11H4ZM4 14V11H3V14H2V19H3V21H4V24H5V21H6V24H7V14H6V12H7V10H8V9H15V10H16V12V14V19V20H15V21H13H12H10V24H12V23H13V24H16V23H17V22H18V21H19V20H20V19H21V14H20V11H19V8H18V7H17V6H16V5H15V4H13V5H15V6H16V7H17V8H18V11H19V14H18V12H17V10H16V9H15V8H8V9H7V10H6V12H5V14H4ZM4 19H3V14H4V19ZM19 19V20H18V19H19ZM17 21V22H16V21H17ZM17 21H18V20H17V21ZM19 19H20V14H19V19ZM16 22H13V23H16V22ZM4 19H5V21H4V19Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 4H10V5H8V6H7V7H6V8H5V11H4V14H3V19H4V21H5V24H6V21H5V19H4V14H5V12H6V10H7V9H8V8H15V9H16V10H17V12H18V14H19V19H18V20H17V21H16V22H13V23H12V24H13V23H16V22H17V21H18V20H19V19H20V14H19V11H18V8H17V7H16V6H15V5H13V4Z"
        fill={hatColor}
      />
    </svg>
  );
};

Hoodie.layerType = LAYER_TYPE;
Hoodie.gender = GENDER;
Hoodie.title = TITLE;
Hoodie.key = KEY;
Hoodie.paletteNames = PALETTE_NAMES;
Hoodie.zIndex = 45;
