import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Pixelenca 6";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Pixelenca6: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="#CFC6B1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H1V10H0V0ZM7 10V19H5V10H7ZM19 12H16V14H19V12ZM5 21H8V24H5V21ZM19 24V16H17V24H19ZM5 5H8V8H5V5ZM19 0H16V3H19V0ZM6 0H8V3H6V0ZM18 5H16V10H18V5ZM2 12V14H0V12H2ZM2 17V16H0V17H2ZM0 19H2V24H0V19ZM12 19H11V24H12V19ZM22 22H23V24H22V22ZM22 14H24V20H22V14ZM13 8H12V13H13V8ZM23 4H24V12H23V4ZM13 0H11V6H13V0ZM22 0H24V2H22V0ZM10 0H9V14H10V0ZM14 0H15V2H14V0ZM15 4H14V16H15V4ZM14 18H15V24H14V18ZM21 18H20V24H21V18ZM20 9H21V16H20V9ZM21 0H20V7H21V0ZM3 0H4V6H3V0ZM4 8H3V15H4V8ZM3 17H4V24H3V17ZM10 16H9V24H10V16ZM13 15H11V17H13V15Z"
        fill="#EAE4D9"
      />
    </svg>
  );
};

Pixelenca6.layerType = LAYER_TYPE;
Pixelenca6.gender = GENDER;
Pixelenca6.title = TITLE;
Pixelenca6.key = KEY;
Pixelenca6.paletteNames = PALETTE_NAMES;
Pixelenca6.price = 0.001;
