import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { glassesWomanLayers } from "../glasses/woman";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Woman";
const TITLE = "Laser";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Laser";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const LaserWoman: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(LaserWoman, PALETTE_NAME_1).colors;
  }, [palettes]);

  const laserColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.5"
        x="9"
        y="13"
        width="15"
        height="1"
        fill={laserColor}
      />
    </svg>
  );
};

LaserWoman.layerType = LAYER_TYPE;
LaserWoman.gender = GENDER;
LaserWoman.title = TITLE;
LaserWoman.key = KEY;
LaserWoman.paletteNames = PALETTE_NAMES;
LaserWoman.blacklist = [...glassesWomanLayers];
