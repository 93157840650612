import { PaletteName } from "../../../constants/Palettes";
import { AlienMan } from "../type/AlienMan";
import { AlienWoman } from "../type/AlienWoman";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Ear";
const GENDER: Gender = "Generic";
const TITLE = "Default";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Default: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    ></svg>
  );
};

Default.layerType = LAYER_TYPE;
Default.gender = GENDER;
Default.title = TITLE;
Default.key = KEY;
Default.paletteNames = PALETTE_NAMES;
Default.price = 0;
Default.blacklist = [AlienWoman, AlienMan];
