import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { AlienMan } from "../type/AlienMan";
import { ApeMan } from "../type/ApeMan";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Beard";
const GENDER: Gender = "Man";
const TITLE = "Small";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Beard";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const SmallBeard: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(SmallBeard, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17H15V18V19V20H14V21H11V20H10V19V18V17ZM14 19V18H11V19H14Z"
        fill={hairColor}
      />
    </svg>
  );
};

SmallBeard.layerType = LAYER_TYPE;
SmallBeard.gender = GENDER;
SmallBeard.title = TITLE;
SmallBeard.key = KEY;
SmallBeard.paletteNames = PALETTE_NAMES;
SmallBeard.blacklist = [ApeMan, AlienMan]
