import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Untreated";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Untreated: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Untreated, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 19V12H8V10H10V9H11V10H12V9H15V10H16V11H17V19H18V18H19V17H20V15H21V9H20V7H19V6H18V5H17V4H15V3H9V4H7V5H6V6H5V7H4V9H3V15H4V17H5V18H6V19H7Z"
        fill={hairColor}
      />
    </svg>
  );
};

Untreated.allowSemiTransparentColors = false;
Untreated.layerType = LAYER_TYPE;
Untreated.gender = GENDER;
Untreated.title = TITLE;
Untreated.key = KEY;
Untreated.paletteNames = PALETTE_NAMES;
Untreated.blacklist = [ApeWoman, AlienWoman]
