import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Cap Check";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapCheck: LayerFC = (props) => {
  return (
    <svg 
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15 5H9V6H8V7H7V11H17H20V10H17V7H16V6H15V5Z" fill="black"/>
      <path d="M14 7H15V8H14V7Z" fill="white"/>
      <path d="M13 8H14V9H13V8Z" fill="white"/>
      <path d="M11 8H12V9H11V8Z" fill="white"/>
      <path d="M12 9H13V10H12V9Z" fill="white"/>
    </svg>
  );
};

CapCheck.layerType = LAYER_TYPE;
CapCheck.gender = GENDER;
CapCheck.title = TITLE;
CapCheck.key = KEY;
CapCheck.paletteNames = PALETTE_NAMES;
CapCheck.price = 0.0008;