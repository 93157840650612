import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Clothes";
const GENDER: Gender = "Woman";
const TITLE = "Collar";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Clothes";
const PALETTE_NAME_2: PaletteName = "Shirt";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const Collar: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Collar, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Collar, PALETTE_NAME_2).colors;
  }, [palettes]);

  const collarColor = useMemo(() => palette1[0], [palette1]);
  const shirtColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 23H8V22H10V23H12V22H13V23H14V24H7V23Z"
      fill={collarColor}
    />
    <rect
      x="10" y="23" width="2" height="1"
      fill={shirtColor}
    />
    </svg>
  );
};

Collar.layerType = LAYER_TYPE;
Collar.gender = GENDER;
Collar.title = TITLE;
Collar.key = KEY;
Collar.paletteNames = PALETTE_NAMES;