import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Cap Newbie";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapNewbie: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4H15V5H8V4ZM7 6H8V5H7V6ZM7 9V6H6V10H7H19V9V8H18V7H17V6H16V5H15V6H16V7H10V8H9V9H7ZM10 8H18V9H10V8Z"
        fill="black"
      />
      <rect x="10" y="8" width="8" height="1" fill="#34A853" />
      <path d="M13 5H15V6H13V5Z" fill="#EA4335" />
      <path d="M14 6H16V7H14V6Z" fill="#EA4335" />
      <path d="M8 5H10V6H8V5Z" fill="#4285F4" />
      <path d="M7 6H9V9H7V6Z" fill="#4285F4" />
      <path d="M10 5H13V7H10V5Z" fill="#FBBC05" />
      <path d="M9 6H10V8H9V6Z" fill="#FBBC05" />
      <path d="M13 6H14V7H13V6Z" fill="#FBBC05" />
      <rect x="11" y="3" width="1" height="1" fill="black" />
      <rect x="9" y="2" width="5" height="1" fill="#4285F4" />
    </svg>
  );
};

CapNewbie.layerType = LAYER_TYPE;
CapNewbie.gender = GENDER;
CapNewbie.title = TITLE;
CapNewbie.key = KEY;
CapNewbie.paletteNames = PALETTE_NAMES;
