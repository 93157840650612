import { PaletteName } from "../../../constants/Palettes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Man";
const TITLE = "Cheeks";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CheeksMan: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="15"
        y="15"
        width="1"
        height="2"
        fill="#F24E1E"
        fillOpacity="0.25"
      />
      <rect
        x="8"
        y="15"
        width="2"
        height="2"
        fill="#F24E1E"
        fillOpacity="0.25"
      />
    </svg>
  );
};

CheeksMan.layerType = LAYER_TYPE;
CheeksMan.gender = GENDER;
CheeksMan.title = TITLE;
CheeksMan.key = KEY;
CheeksMan.paletteNames = PALETTE_NAMES;
CheeksMan.zIndex = 45;
