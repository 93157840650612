import { FC, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { NFT, useApi } from "../hooks/useApi";

interface AdminNFTListProps {
  className?: string;
  type: "minted" | "claimed";
}

const AdminNFTListWrapper = styled.div`
  margin: 16px;
  color: white;
  font-family: sans-serif;
`;

const NFTTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  > *:nth-child(6n + 1),
  > *:nth-child(6n + 2),
  > *:nth-child(6n + 3) {
    background-color: grey;
  }
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  img {
    width: 96px;
  }
`;

export const AdminNFTList: FC<AdminNFTListProps> = (props) => {
  const { listClaimedNFTs, listMintedNFTs, updateId } = useApi();
  const [nfts, setNFTs] = useState<NFT[]>([]);
  useEffect(() => {
    if (props.type === "claimed") {
      listClaimedNFTs().then(setNFTs);
    } else {
      listMintedNFTs().then(setNFTs);
    }
  }, [props, listMintedNFTs, listClaimedNFTs]);

  const cells = useMemo(
    () =>
      nfts.flatMap((nft) => [
        <div key={`${nft.id}-#`}>
          <a href={nft.id} key={`${nft.id}-id`}>
            {nft.index !== undefined ? nft.name : nft.id}
          </a>
          <br />
          {nft.transactionHash && (
            <a
              href={`https://etherscan.io/tx/${nft.transactionHash}`}
              key={`${nft.id}-transaction`}
            >
              Transaction
            </a>
          )}
          <br />
          {nft.owner && (
            <a
              href={`https://etherscan.io/address/${nft.owner}`}
              key={`${nft.id}-owner`}
            >
              Owner
            </a>
          )}
          <br />
          {nft.purchaseHash && (
            <a
              href={`https://etherscan.io/tx/${nft.purchaseHash}`}
              key={`${nft.id}-purchase`}
            >
              Purchase
            </a>
          )}
        </div>,
        <div key={`${nft.id}-preview`}>
          <img key={`${nft.id}-preview`} alt="nft" src={nft.image} />
        </div>,
        <div key={`${nft.id}-actions`}>
          {nft.index !== undefined && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const id: string = (e.target as any)[0].value;
                if (id) {
                  updateId(nft.index, id.startsWith("/") ? id : `/${id}`);
                }
              }}
            >
              <input type="text"></input>
              <button>Update ID</button>
            </form>
          )}
        </div>,
      ]),
    [nfts]
  );

  const toggle = useMemo(() => {
    const targetType = props.type === "minted" ? "claimed" : "minted";
    const targetUrl = `/admin/${targetType}`;
    return <a href={targetUrl}>Go to {targetType}</a>;
  }, [props]);

  return (
    <AdminNFTListWrapper className={props.className}>
      {toggle}
      <NFTTable>
        <b>#</b>
        <b>Preview</b>
        <b>Actions</b>
        {cells}
      </NFTTable>
    </AdminNFTListWrapper>
  );
};
