import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "Big Shades";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}-2px`;
const PALETTE_NAME_1: PaletteName = "Glass";
const PALETTE_NAME_2: PaletteName = "Frame";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const BigShades: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(BigShades, PALETTE_NAME_1)
      .colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(BigShades, PALETTE_NAME_2)
      .colors;
  }, [palettes]);
  const glassesColor = useMemo(() => palette1[0], [palette1]);
  const frameColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 11V10H12V11H13V10H18V14H17V11H14V14H13V12H12V14H11V11H8V14H7V12H6V11H7Z" fill={frameColor} />
      <path d="M8 14H11V15H8V14Z" fill={frameColor} />
      <path d="M14 14H17V15H14V14Z" fill={frameColor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M11 11H8V14H11V11ZM17 11H14V14H17V11Z" fill={glassesColor} />
    </svg>
  );
};

BigShades.layerType = LAYER_TYPE;
BigShades.gender = GENDER;
BigShades.title = TITLE;
BigShades.key = KEY;
BigShades.paletteNames = PALETTE_NAMES;
