import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Woman";
const TITLE = "Cap Forward";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hat";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const CapForward: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(CapForward, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hatColor = useMemo(() => palette1[0], [palette1]);
  const secondaryhatColor = useMemo(() => palette1[1], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="8" y="6" width="8" height="4" fill={hatColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6H9V7H8V8H9V7H10V6ZM11 9H18V10H11V9Z"
        fill={secondaryhatColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 5H15V6H9V5ZM8 7H9V6H8V7ZM8 10V7H7V11H8H19V10V9H18V8H17V7H16V6H15V7H16V8H11V9H10V10H8ZM11 9H18V10H11V9Z"
        fill="black"
      />
    </svg>
  );
};

CapForward.layerType = LAYER_TYPE;
CapForward.gender = GENDER;
CapForward.title = TITLE;
CapForward.key = KEY;
CapForward.paletteNames = PALETTE_NAMES;
