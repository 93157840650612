import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Clothes";
const GENDER: Gender = "Woman";
const TITLE = "Fur XL";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Fur";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const FurXL: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(FurXL, PALETTE_NAME_1).colors;
  }, [palettes]);

  const furColor = useMemo(() => palette1[0], [palette1]);
  const darkerColor = useMemo(() => palette1[1], [palette1]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 19H8V21H9V22H10V23H11V24H4V23H5V22H6V20H7V19Z" fill={furColor} />
      <path d="M13 22H14V23H15V24H13V22Z" fill={furColor} />
      <path d="M6 20V19H7V20H6Z" fill={darkerColor} />
      <path d="M6 21V20H5V22H4V23H3V24H4V23H5V22H7V23H8V22H7V21H6Z" fill={darkerColor} />
      <path d="M14 22H15V23H14V22Z" fill={darkerColor} />
      <path d="M15 23H16V24H15V23Z" fill={darkerColor} />
    </svg>
  );
};

FurXL.layerType = LAYER_TYPE;
FurXL.gender = GENDER;
FurXL.title = TITLE;
FurXL.key = KEY;
FurXL.paletteNames = PALETTE_NAMES;
FurXL.price = 0.01;
