import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "Top Frame";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Glass";
const PALETTE_NAME_2: PaletteName = "Frame";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const TopFrame: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(TopFrame, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(TopFrame, PALETTE_NAME_2).colors;
  }, [palettes]);

  const glassesColor = useMemo(() => palette1[0], [palette1]);
  const frameColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 11H13V12H12V11H8V12H6V11H8V10H12V11H13V10H17V11Z"
         fill={frameColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 11V13H9V14H11V13H12V11H8ZM13 11V13H14V14H16V13H17V11H13Z"
        fill={glassesColor}
      />
    </svg>
  );
};

TopFrame.layerType = LAYER_TYPE;
TopFrame.gender = GENDER;
TopFrame.title = TITLE;
TopFrame.key = KEY;
TopFrame.paletteNames = PALETTE_NAMES;

