import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Long 7";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Long7: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Long7, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 6H10V7H8V8H7V10H6V14H5V21H6V22H7V24H8V16H7V13H8V12H9V11H10V10H11V11H12V12H13V10H15V11H16V14H17V16H16V19H17V17H18V11H17V8H16V7H15V6Z" fill={hairColor} />
      <path d="M13 22H14V21H15V23H16V24H13V22Z" fill={hairColor} />
    </svg>
  );
};

Long7.allowSemiTransparentColors = false;
Long7.layerType = LAYER_TYPE;
Long7.gender = GENDER;
Long7.title = TITLE;
Long7.key = KEY;
Long7.paletteNames = PALETTE_NAMES;
Long7.blacklist = [ApeWoman, AlienWoman]
