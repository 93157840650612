import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Type";
const GENDER: Gender = "Woman";
const TITLE = "Ape";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Ape Skin";
const PALETTE_NAME_2: PaletteName = "Nose";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const ApeWoman: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(ApeWoman, PALETTE_NAME_1).colors;
  }, [palettes]);

  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(ApeWoman, PALETTE_NAME_2).colors;
  }, [palettes]);


  const skinColor = useMemo(() => palette1[0], [palette1]);
  const secondarySkinColor = useMemo(() => palette1[1], [palette1]);
  const noseColor = useMemo(() => palette2[0], [palette2]);

  if (skinColor === "#EB8DEC") {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 10H15V11H16V19H15V20H14V21H11V20H10V19H9V15H8V12H9V11H10V10Z"
          fill="#DFC8A6"
        />
        <path d="M9 8H15V9H16V11H15V10H10V11H9V12H8V9H9V8Z" fill="#EB8DEC" />
        <path d="M8 12V14H7V12H8Z" fill="#EB8DEC" />
        <path d="M8 15H9V16H10V17H9V19H10V21H12V24H8V15Z" fill="#EB8DEC" />
        <path d="M16 16H15V17H16V16Z" fill="#EB8DEC" />
        <rect
          width="3"
          height="1"
          transform="matrix(1 0 0 -1 12 9)"
          fill="#3B43ED"
        />
        <rect
          width="3"
          height="1"
          transform="matrix(1 0 0 -1 11 10)"
          fill="#F7ED8D"
        />
        <rect
          width="2"
          height="1"
          transform="matrix(1 0 0 -1 8 11)"
          fill="#F7ED8D"
        />
        <rect
          width="1"
          height="1"
          transform="matrix(1 0 0 -1 7 13)"
          fill="#F7ED8D"
        />
        <rect
          width="1"
          height="2"
          transform="matrix(1 0 0 -1 9 24)"
          fill="#F7ED8D"
        />
        <rect
          width="1"
          height="3"
          transform="matrix(1 0 0 -1 8 20)"
          fill="#3B43ED"
        />
        <rect
          width="1"
          height="1"
          transform="matrix(1 0 0 -1 9 20)"
          fill="#3B43ED"
        />
        <rect
          width="1"
          height="1"
          transform="matrix(1 0 0 -1 15 10)"
          fill="#3B43ED"
        />
        <rect x="9" y="8" width="1" height="1" fill="#99F7A2" />
        <rect x="8" y="9" width="1" height="1" fill="#99F7A2" />
        <rect x="8" y="16" width="1" height="1" fill="#99F7A2" />
        <rect x="9" y="20" width="1" height="2" fill="#99F7A2" />
        <rect x="10" y="23" width="1" height="1" fill="#99F7A2" />
        <path d="M9 7H15V8H9V7Z" fill="black" />
        <path d="M8 9V8H9V9H8Z" fill="black" />
        <path d="M7 12H8V9H7V12Z" fill="black" />
        <path d="M7 14V12H6V15H7V19H8V24H9V19H8V14H7Z" fill="black" />
        <path d="M16 9H15V8H16V9Z" fill="black" />
        <path d="M16 19V9H17V19H16Z" fill="black" />
        <path d="M15 20V19H16V20H15Z" fill="black" />
        <path d="M14 21V20H15V21H14Z" fill="black" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 21H10V20H11V21ZM11 21H14V22H13V24H12V22H11V21Z"
          fill="black"
        />
        <path d="M11 16H12V17H11V16Z" fill={noseColor} />
        <path d="M13 16H14V17H13V16Z" fill={noseColor} />
      </svg>
    );
  }

  if (skinColor === "#637C4D") {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 10H15V11H16V19H15V20H14V21H11V20H10V19H9V15H8V12H9V11H10V10Z"
          fill="#DFC8A6"
        />
        <path d="M9 8H15V9H16V11H15V10H10V11H9V12H8V9H9V8Z" fill="#637C4D" />
        <path d="M8 12V14H7V12H8Z" fill="#637C4D" />
        <path d="M8 15H9V16H10V17H9V19H10V21H12V24H9V19H8V15Z" fill="#637C4D" />
        <path d="M16 16H15V17H16V16Z" fill="#637C4D" />
        <rect x="10" y="9" width="1" height="1" fill="#6C130A" />
        <rect x="11" y="8" width="1" height="1" fill="#6C130A" />
        <rect x="14" y="8" width="1" height="1" fill="#6C130A" />
        <rect x="15" y="9" width="1" height="1" fill="#6C130A" />
        <rect x="7" y="12" width="1" height="1" fill="#6C130A" />
        <rect x="8" y="10" width="2" height="1" fill="#6C130A" />
        <rect x="8" y="9" width="2" height="1" fill="#AD6661" />
        <rect x="9" y="8" width="2" height="1" fill="#AD6661" />
        <rect x="9" y="22" width="1" height="2" fill="#AD6661" />
        <rect x="10" y="22" width="1" height="2" fill="#6C130A" />
        <rect x="9" y="21" width="1" height="1" fill="#6C130A" />
        <path d="M9 7H15V8H9V7Z" fill="black" />
        <path d="M8 9V8H9V9H8Z" fill="black" />
        <path d="M7 12H8V9H7V12Z" fill="black" />
        <path d="M7 14V12H6V15H7V19H8V24H9V19H8V14H7Z" fill="black" />
        <path d="M16 9H15V8H16V9Z" fill="black" />
        <path d="M16 19V9H17V19H16Z" fill="black" />
        <path d="M15 20V19H16V20H15Z" fill="black" />
        <path d="M14 21V20H15V21H14Z" fill="black" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 21H10V20H11V21ZM11 21H14V22H13V24H12V22H11V21Z"
          fill="black"
        />
        <path d="M11 16H12V17H11V16Z" fill={noseColor} />
        <path d="M13 16H14V17H13V16Z" fill={noseColor} />
      </svg>
    );
  }

  if (skinColor === "#3C444D") {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 24H8V19H7V15H6V12H7V9H8V8H9V7H15V8H16V9H17V19H16V20H15V21H14V22H13V24Z" fill="#3C444D" />
        <path d="M15 11H16V16H15V17H16V19H15V20H14V21H11V20H10V19H9V17H10V16H9V15H8V12H9V11H10V10H15V11Z" fill="#DFC197" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15 7H9V8H8V9H7V12H6V15H7V19H8V24H9V19H8V14H7V12H8V9H9V8H15V9H16V19H15V20H14V21H11V20H10V21H11V22H12V24H13V22H14V21H15V20H16V19H17V9H16V8H15V7Z" fill="black" />
        <path d="M12 16H11V17H12V16Z" fill={noseColor} />
        <path d="M14 16H13V17H14V16Z" fill={noseColor} />
        <path d="M12 8H11V10H12V8Z" fill="#7F8FA4" />
        <path d="M11 21H10V22H11V24H12V22H11V21Z" fill="#7F8FA4" />
        <path d="M9 15H8V16H9V15Z" fill="#7F8FA4" />
      </svg>
    );
  }

  if (skinColor === "#FE015C") {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 24H8V19H7V15H6V12H7V9H8V8H9V7H15V8H16V9H17V19H16V20H15V21H14V22H13V24Z" fill="#FE015C" />
        <path d="M15 11H16V16H15V17H16V19H15V20H14V21H11V20H10V19H9V17H10V16H9V15H8V12H9V11H10V10H15V11Z" fill="#E4C8A1" />
        <path d="M11 8H14V9H11V8Z" fill="white" />
        <path d="M10 23H11V24H10V23Z" fill="white" />
        <path d="M10 21H11V22H10V21Z" fill="white" />
        <path d="M12 8H13V9H12V8Z" fill="#15CB6B" />
        <path d="M12 23H11V24H12V23Z" fill="#15CB6B" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15 7H9V8H8V9H7V12H6V15H7V19H8V24H9V19H8V14H7V12H8V9H9V8H15V9H16V19H15V20H14V21H11V20H10V21H11V22H12V24H13V22H14V21H15V20H16V19H17V9H16V8H15V7Z" fill="black" />
        <rect x="11" y="16" width="1" height="1" fill="black" />
        <rect x="13" y="16" width="1" height="1" fill="black" />
      </svg>
    );
  }

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 24H8V19H7V15H6V12H7V9H8V8H9V7H15V8H16V9H17V19H16V20H15V21H14V22H13V24Z" fill={skinColor} />
      <path d="M15 11H16V16H15V17H16V19H15V20H14V21H11V20H10V19H9V17H10V16H9V15H8V12H9V11H10V10H15V11Z" fill={secondarySkinColor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M15 7H9V8H8V9H7V12H6V15H7V19H8V24H9V19H8V14H7V12H8V9H9V8H15V9H16V19H15V20H14V21H11V20H10V21H11V22H12V24H13V22H14V21H15V20H16V19H17V9H16V8H15V7Z" fill="black" />
      <rect x="11" y="16" width="1" height="1" fill={noseColor} />
      <rect x="13" y="16" width="1" height="1" fill={noseColor} />
    </svg>
  );
};

ApeWoman.layerType = LAYER_TYPE;
ApeWoman.gender = GENDER;
ApeWoman.title = TITLE;
ApeWoman.key = KEY;
ApeWoman.paletteNames = PALETTE_NAMES;