import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Cap ES";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const CapES: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 8H20V9H6V6H7V5H8V4H15V5H16V6H17V8Z" fill="#043361"/>
      <path d="M11.5 5H12.625V5.375H11.875V5.75H12.25V6.125H11.875V6.5H12.625V6.125H13.75V6.5H13V6.875H13.75V8H12.625V7.625H13.375V7.25H12.625V6.875H11.5V5Z" fill="white"/>
    </svg>
  );
};

CapES.layerType = LAYER_TYPE;
CapES.gender = GENDER;
CapES.title = TITLE;
CapES.key = KEY;
CapES.paletteNames = PALETTE_NAMES;
CapES.isBranded = true
CapES.price = 0.01;
