import { Man } from "../../type/Man";
import { ZombieMan } from "../../type/ZombieMan";
import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "3D";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}-2px`;
const PALETTE_NAMES: PaletteName[] = [];

export const ThreeD2px: LayerFC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 10H18V11V13V14H8V13V12H7V11H6V10ZM9 13H12V11H9V13ZM14 13H17V11H14V13Z"
        fill="white"
      />
      <rect x="9" y="11" width="3" height="2" fill="#328DFD" />
      <rect x="14" y="11" width="3" height="2" fill="#FD3232" />
    </svg>
  );
};

ThreeD2px.layerType = LAYER_TYPE;
ThreeD2px.gender = GENDER;
ThreeD2px.title = TITLE;
ThreeD2px.key = KEY;
ThreeD2px.paletteNames = PALETTE_NAMES;
ThreeD2px.whitelist = [Man, ZombieMan];
