import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Beenie M";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hat";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const BeenieM: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(BeenieM, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hatColor = useMemo(() => palette1[0], [palette1]);
  const secondaryhatColor = useMemo(() => palette1[1], [palette1]);
  
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6H15V7L16 7V8H17V10H6V8H7V7L8 7V6Z"
        fill={hatColor} 
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 8H6V9H7V10H8V9H9V10H10V9H11V10H12V9H13V10H14V9H15V10H16V9H17V8Z"
        fill={secondaryhatColor} 
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 5H8V6L7 6V7H6V8H5V10H6L6 8H7V7H8V6H15V7H16V8H17V10H18V8H17V7H16V6L15 6V5Z"
        fill="black"
      />
    </svg>
  );
};

BeenieM.allowSemiTransparentColors = false;
BeenieM.layerType = LAYER_TYPE;
BeenieM.gender = GENDER;
BeenieM.title = TITLE;
BeenieM.key = KEY;
BeenieM.paletteNames = PALETTE_NAMES;
