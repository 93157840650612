import { LayerFC } from "../types/LayerFC";
import { earManLayers } from "./man";
import { None } from "./None";
import { earWomanLayers } from "./woman";

export const earExtensionLayers: LayerFC[] = [
  None,
  ...earManLayers,
  ...earWomanLayers,
];
