import { LayerFC } from "../../types/LayerFC";
import { Band } from "./Band";
import { Curl } from "./Curl";
import { CurlCap } from "./CurlCap";
import { LongWild } from "./LongWild";
import { LongWildCap } from "./LongWildCap";
import { MiddleHair } from "./MiddleHair";
import { MiddleHairCap } from "./MiddleHairCap";
import { ShortHair } from "./ShortHair";
import { ShortHairCap } from "./ShortHairCap";
import { WildCap } from "./WildCap";

export const hatExtensionsManLayers: LayerFC[] = [
  Band,
  ShortHair,
  ShortHairCap,
  Curl,
  CurlCap,
  MiddleHair,
  MiddleHairCap,
  WildCap,
  LongWild,
  LongWildCap,
];
