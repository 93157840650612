import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Clothes";
const GENDER: Gender = "Man";
const TITLE = "Collar M";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Clothes";
const PALETTE_NAME_2: PaletteName = "Shirt";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const CollarM: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(CollarM, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(CollarM, PALETTE_NAME_2).colors;
  }, [palettes]);

  const collarColor = useMemo(() => palette1[0], [palette1]);
  const shirtColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 23H6V22H8V23H10V22H11V23H12V24H5V23Z"
        fill={collarColor}
      />
      <rect
        x="8" y="23" width="2" height="1"
        fill={shirtColor}
      />
    </svg>
  );
};

CollarM.layerType = LAYER_TYPE;
CollarM.gender = GENDER;
CollarM.title = TITLE;
CollarM.key = KEY;
CollarM.paletteNames = PALETTE_NAMES;

