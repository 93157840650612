import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Wild";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Wild: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Wild, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 5H14V6H15V7H16V8H17V9H18V8H19V10H18V11H19V12H18V13H19V15H20V16H19H18V17H17V12H16V11H15V10H14V11H13V10H11V9H10V10H9V12H8V13H7V14V17H5V15H4H3V14H4V13H5V14H6V13H5V12H6V11H5V10H6V9H5V8H6H7V6H8V7H9V6H10V5ZM5 11V12H4V11H5ZM5 17V18H4V17H5ZM10 10H11V11H10V10ZM13 11V12H12V11H13ZM18 17V18H19V17H18ZM19 13H20V12H19V13ZM18 8H17V7H18V8ZM15 6V5H16V6H15Z"
        fill={hairColor}
      />
    </svg>
  );
};

Wild.allowSemiTransparentColors = false;
Wild.layerType = LAYER_TYPE;
Wild.gender = GENDER;
Wild.title = TITLE;
Wild.key = KEY;
Wild.paletteNames = PALETTE_NAMES;
Wild.blacklist = [ApeWoman, AlienWoman]
