import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { glassesManLayers } from "../glasses/man";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Special";
const GENDER: Gender = "Man";
const TITLE = "Laser Top";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Laser";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const LaserTopMan: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(LaserTopMan, PALETTE_NAME_1).colors;
  }, [palettes]);

  const laserColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 0H21V1H20V2H19V3H18V4H17V5H16V6H15V7H14V8H13V9H12V10H11V11H10V12H9V13H11V12H12V11H13V10H14V9H15V8H16V7H17V6H18V5H19V4H20V3H21V2H22V1H23V0ZM16 12V13H14V12H15V11H16V10H17V9H18V8H19V7H20V6H21V5H22V4H23V3H24V4V5H23V6H22V7H21V8H20V9H19V10H18V11H17V12H16Z"
        fill={laserColor}
      />
    </svg>
  );
};

LaserTopMan.layerType = LAYER_TYPE;
LaserTopMan.gender = GENDER;
LaserTopMan.title = TITLE;
LaserTopMan.key = KEY;
LaserTopMan.paletteNames = PALETTE_NAMES;
LaserTopMan.blacklist = [...glassesManLayers]
