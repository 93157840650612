import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Clothes";
const GENDER: Gender = "Woman";
const TITLE = "Hoodie";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Clothes";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Hoodie: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Hoodie, PALETTE_NAME_1).colors;
  }, [palettes]);

  const clothesColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 19H7V20H6V21H5V23H6V24H7V23H8V21H7V20H8V19ZM7 21V23H6V21H7ZM14 22H15V23H14V22ZM14 23V24H13V23H14Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 20H8V21H7V20ZM7 23H6V21H7V23ZM7 23H8V24H7V23ZM14 22H13V23H14V22Z"
        fill={clothesColor}
      />
    </svg>
  );
};

Hoodie.layerType = LAYER_TYPE;
Hoodie.gender = GENDER;
Hoodie.title = TITLE;
Hoodie.key = KEY;
Hoodie.paletteNames = PALETTE_NAMES;
