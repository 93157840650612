import { LayerFC } from "./types/LayerFC";
import { typeLayers } from "./type";
import { eyesLayers } from "./eyes";
import { glassesLayers } from "./glasses";
import { earLayers } from "./ear";
import { earExtensionLayers } from "./ear_extensions";
import { mouthLayers } from "./mouth";
import { beardLayers } from "./beard";
import { clothesLayers } from "./clothes";
import { hairLayers } from "./hair";
import { hatLayers } from "./hat";
import { hatextensionLayers } from "./hat_extensions";
import { specialLayers } from "./special";
import { backgroundLayers } from "./background";
import {
  isLayerFCAvailable,
  IsLayerFCAvailableContext,
} from "./isLayerFCAvailable";
import { LayerType } from "./types/LayerType";

export const layerFCs: LayerFC[] = [
  ...typeLayers,
  ...eyesLayers,
  ...glassesLayers,
  ...earLayers,
  ...earExtensionLayers,
  ...mouthLayers,
  ...beardLayers,
  ...clothesLayers,
  ...hairLayers,
  ...hatLayers,
  ...hatextensionLayers,
  ...specialLayers,
  ...backgroundLayers,
];

export const getLayerTypesForLayerType = (layerType: LayerType): LayerType[] =>
  layerType === "Hair" ? ["Hair", "Hat"] : [layerType];

export const availableLayerFCs = (context: IsLayerFCAvailableContext) => {
  return layerFCs.filter((layerFC) => isLayerFCAvailable(layerFC, context));
};
