import { Gender } from "./types/Gender";
import { LayerFC } from "./types/LayerFC";
import { LayerType } from "./types/LayerType";

export interface IsLayerFCAvailableContext {
  layerType?: LayerType | LayerType[];
  gender: Gender;
  selectedLayerFCs?: LayerFC[];
  isExtension?: boolean;
  isBranded?: boolean;
}

export const isLayerFCAvailable = (
  layerFC: LayerFC,
  context: IsLayerFCAvailableContext
) => {
  if (
    typeof context.layerType === "string" &&
    layerFC.layerType !== context.layerType
  ) {
    return false;
  }
  if (
    Array.isArray(context.layerType) &&
    !context.layerType.includes(layerFC.layerType)
  ) {
    return false;
  }
  if (layerFC.gender !== "Generic" && layerFC.gender !== context.gender) {
    return false;
  }
  if (!!context.isExtension !== !!layerFC.isExtension) {
    return false;
  }
  if (
    context.isBranded !== undefined &&
    !!context.isBranded !== !!layerFC.isBranded
  ) {
    return false;
  }
  const selectedLayerFCs = context.selectedLayerFCs;
  if (selectedLayerFCs !== undefined) {
    const whitelist = layerFC.whitelist;
    if (whitelist) {
      const layerTypes = new Set(whitelist.map((layerFC) => layerFC.layerType));
      for (const layerType of layerTypes) {
        if (
          !whitelist
            .filter((layerFC) => layerFC.layerType === layerType)
            .some((layerFC) => selectedLayerFCs.includes(layerFC))
        ) {
          return false;
        }
      }
    }
    if (
      layerFC.blacklist &&
      layerFC.blacklist.some((excludedLayerFC) =>
        selectedLayerFCs.includes(excludedLayerFC)
      )
    ) {
      return false;
    }
  }
  return true;
};
