import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { AlienWoman } from "../../type/AlienWoman";
import { ApeWoman } from "../../type/ApeWoman";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hair";
const GENDER: Gender = "Woman";
const TITLE = "Bob";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Bob: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Bob, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6H11H15V7H16V8H17V9H18V16H19V17H18V18H17V11H16V10H15V9H11V10H10V11H9V12H8V13H7H6V15H7V19H6V18H5V17H4V16H5V14H4V13H5V10H6V8H7V7H8V6H9H10Z"
        fill={hairColor}
      />
    </svg>
  );
};

Bob.allowSemiTransparentColors = false;
Bob.layerType = LAYER_TYPE;
Bob.gender = GENDER;
Bob.title = TITLE;
Bob.key = KEY;
Bob.paletteNames = PALETTE_NAMES;
Bob.blacklist = [ApeWoman, AlienWoman]
