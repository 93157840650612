import { PaletteName } from "../../../../constants/Palettes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Crown";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAMES: PaletteName[] = [];

export const Crown: LayerFC = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 1H13V2H14V3H13V4H16V5H17V6H18V8H17V10H6V8H5V6H6V5H7V4H12V3H11V2H12V1ZM7 3V4H6V3H7ZM7 3H8V2H7V3ZM16 3V4H17V3H16ZM16 3V2H15V3H16Z" fill="#F1D743"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10 5H8V6H7V8H9V6H10V5ZM13 5H12V6H11V8H14V6H13V5ZM17 6H16V5H15V6H16V8H17V6Z" fill="#D8BD28"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13 2H12V3H13V2ZM9 8H8V9H9V8ZM13 8H12V9H13V8ZM16 8H17V9H16V8Z" fill="#E53D65"/>
    </svg>
  );
};

Crown.layerType = LAYER_TYPE;
Crown.gender = GENDER;
Crown.title = TITLE;
Crown.key = KEY;
Crown.paletteNames = PALETTE_NAMES;