import { useMemo } from "react";
import { PaletteName } from "../../../constants/Palettes";
import { usePalettes } from "../../../hooks/usePalletes";
import { Gender } from "../types/Gender";
import { LayerFC } from "../types/LayerFC";
import { LayerType } from "../types/LayerType";

const LAYER_TYPE: LayerType = "Background";
const GENDER: Gender = "Generic";
const TITLE = "Check";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Check";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const Check: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Check, PALETTE_NAME_1).colors;
  }, [palettes]);

  const bgColor = useMemo(() => palette1[0], [palette1]);
  const tickColor = useMemo(() => palette1[1], [palette1]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="24"
        height="24"
        fill={bgColor}
      />
      <path 
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 6H19V5H18V4H15V3H14V2H10V3H9V4H6V5H5V6H4V9H3V10H2V14H3V15H4V18H5V19H6V20H9V21H10V22H14V21H15V20H18V19H19V18H20V15H21V14H22V10H21V9H20V6ZM15 8H16V9H17V10H16V11H15V12H14V13H13V14H12V15H11V16H10V15H9V14H8V13H7V12H8V11H9V12H10V13H11V12H12V11H13V10H14V9H15V8Z"
        fill={tickColor}
      />
    </svg>
  );
};

Check.layerType = LAYER_TYPE;
Check.gender = GENDER;
Check.title = TITLE;
Check.key = KEY;
Check.paletteNames = PALETTE_NAMES;
Check.price = 0.0008;