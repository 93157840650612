import { FC } from "react";
import styled from "styled-components";
import { Text } from "./Text";

interface FooterProps {
  className?: string;
}

const FooterWrapper = styled(Text)`
  flex: 1;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 10px;
  margin-top: 48px;
`;

const ExternalLink = styled.a``;
const InternalLink = styled.a``;

const Footer: FC<FooterProps> = (props) => {
  return (
    <FooterWrapper $fontColor="gray300" className={props.className}>
      <Text>Made with fun</Text>
      <Text>◆</Text>
      <Text $fontColor="gray300" as={InternalLink} href="/about">
        About
      </Text>
      <Text>◆</Text>
      <Text
        $fontColor="gray300"
        as={ExternalLink}
        target="_blank"
        href="https://nextco.de/legal.txt"
      >
        Legal
      </Text>
    </FooterWrapper>
  );
};

export default Footer;
