import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";
import { capWhitelist } from "./whitelists";

const LAYER_TYPE: LayerType = "Hat";
const GENDER: Gender = "Man";
const TITLE = "Curl";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}-Cap`;
const PALETTE_NAME_1: PaletteName = "Hair";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1];

export const CurlCap: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(CurlCap, PALETTE_NAME_1).colors;
  }, [palettes]);

  const hairColor = useMemo(() => palette1[0], [palette1]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7H11V8H14V7ZM14 9H13V10H12V11H13V10H14V9ZM16 9H15V10H16V9ZM7 10V11H8V10H9V9H7V10Z"
        fill={hairColor}
      />
    </svg>
  );
};

CurlCap.layerType = LAYER_TYPE;
CurlCap.gender = GENDER;
CurlCap.title = TITLE;
CurlCap.key = KEY;
CurlCap.isExtension = true;
CurlCap.paletteNames = PALETTE_NAMES;
CurlCap.whitelist = capWhitelist;
