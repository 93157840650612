import { useMemo } from "react";
import { PaletteName } from "../../../../constants/Palettes";
import { usePalettes } from "../../../../hooks/usePalletes";
import { Gender } from "../../types/Gender";
import { LayerFC } from "../../types/LayerFC";
import { LayerType } from "../../types/LayerType";

const LAYER_TYPE: LayerType = "Glasses";
const GENDER: Gender = "Man";
const TITLE = "Nerd";
const KEY = `${LAYER_TYPE}-${GENDER}-${TITLE}`;
const PALETTE_NAME_1: PaletteName = "Glass";
const PALETTE_NAME_2: PaletteName = "Frame";
const PALETTE_NAMES: PaletteName[] = [PALETTE_NAME_1, PALETTE_NAME_2];

export const Nerd: LayerFC = (props) => {
  const palettes = usePalettes();
  const palette1 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Nerd, PALETTE_NAME_1).colors;
  }, [palettes]);
  const palette2 = useMemo(() => {
    if (!palettes || palettes.isLoading) {
      return [];
    }
    return palettes.getColorForLayerFC(Nerd, PALETTE_NAME_2).colors;
  }, [palettes]);
  const glassesColor = useMemo(() => palette1[0], [palette1]);
  const frameColor = useMemo(() => palette2[0], [palette2]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 10H11V11H9V10ZM9 13V11H8H5V12H8V13H9ZM11 13V14H9V13H11ZM14 13H13V12H12V13H11V11H12H13H14V13ZM16 13V14H14V13H16ZM16 11H17V13H16V11ZM16 11V10H14V11H16Z"
        fill={frameColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 11H9V13H11V11ZM16 11H14V13H16V11Z"
        fill={glassesColor}
      />
    </svg>
  );
};

Nerd.layerType = LAYER_TYPE;
Nerd.gender = GENDER;
Nerd.title = TITLE;
Nerd.key = KEY;
Nerd.paletteNames = PALETTE_NAMES;
